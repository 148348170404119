.stock-quote-container .stock-quote_item {
  transition: 0.3s ease;
}
.stock-quote-container .stock-quote_separator {
  display: inline-block;
  width: 5px;
  margin-left: 5px;
  border-left: 1px solid #939ba0;
  height: 9px;
  vertical-align: middle;
  margin-top: -3px;
}
.stock-quote-container .stock-quote_price {
  display: inline-block;
  padding-right: 15px;
  font-size: 45px;
  margin: 0;
  font-weight: 300;
  line-height: 0.85;
}
.stock-quote-container .stock-quote_price--medium {
  font-size: 38px;
}
.stock-quote-container .stock-quote_price--small {
  font-size: 32px;
}
.stock-quote-container .stock-quote_date {
  font-size: 13px;
  text-align: right;
  margin-top: 16px;
  display: block;
  color: #939ba0;
}
.stock-quote-container .stock-quote_delay {
  font-size: 13px;
  text-align: right;
  display: block;
}
.stock-quote-container .stock-quote_details {
  display: inline-block;
  vertical-align: top;
}
.stock-quote-container .stock-quote_item {
  display: block;
  line-height: 1.4;
}
.stock-quote-container .stock-quote_item--change {
  color: #ffffff;
}
.stock-quote-container .stock-quote_item--change:after {
  margin-left: 5px;
  content: "Chg";
}
.stock-quote-container .stock-quote_item--up {
  color: #1abc9c;
}
.stock-quote-container .stock-quote_item--down {
  color: #ec6a4c;
}
