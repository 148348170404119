/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.dropdown {
  display: inline-block;
  height: 40px;
  font-size: 13px;
  z-index: 5;
}
.dropdown-portal {
  position: relative;
  z-index: 55;
}
.dropdown_inner, .dropdown_value, .dropdown_toggle, .dropdown_option {
  transition: 0.3s ease;
}
.dropdown_value, .dropdown_toggle {
  transition-property: color;
}
.dropdown_inner {
  display: flex;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  background-color: #f1f1f1;
  border-radius: 3px;
  cursor: pointer;
  transition-property: background-color;
}
.dropdown_value, .dropdown_option {
  color: #545b62;
}
.dropdown_toggle {
  color: #297ac5;
  transition-property: transform;
}
.dropdown_value, .dropdown_option, .dropdown_no-data-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown_value {
  flex-grow: 1;
}
.dropdown_toggle {
  text-align: right;
  font-size: 13px;
}
.dropdown_toggle i {
  font-size: inherit;
}
.dropdown_mask, .dropdown_options {
  position: fixed;
  z-index: 55;
}
.dropdown_mask {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.dropdown_options {
  border-radius: 3px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.dropdown_list {
  position: relative;
  background-color: #eeeeee;
  list-style: none;
}
.dropdown_option, .dropdown_no-data-text {
  display: block;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  background-color: #eeeeee;
  border-top: 1px solid #e0e0e0;
  transition-property: background-color, color;
}
.dropdown_option {
  cursor: pointer;
}
.dropdown_scrollbar:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #e0e0e0;
  z-index: 1;
}
.dropdown_scrollbar .react-scrollbar_track {
  opacity: 1;
  visibility: visible;
}
.dropdown--expanded .dropdown_inner {
  border-radius: 3px 3px 0 0;
  background-color: #eeeeee;
}
.dropdown--expanded .dropdown_option:hover {
  background-color: #e0e0e0;
}
.dropdown--white .dropdown_inner {
  background-color: #ffffff;
}
.dropdown--white .dropdown_list, .dropdown--white .dropdown_option, .dropdown--white .dropdown_no-data-text {
  background-color: #ffffff;
}
.dropdown--white .dropdown_value, .dropdown--white .dropdown_option, .dropdown--white .dropdown_no-data-text {
  color: #545b62;
}
.dropdown--white .dropdown_option, .dropdown--white .dropdown_no-data-text {
  border-top: 1px solid #eeeeee;
}
.dropdown--white .dropdown_toggle {
  color: #545b62;
}
.dropdown--white .dropdown_scrollbar:before {
  background-color: #eeeeee;
}
.dropdown--white.dropdown--expanded .dropdown_inner {
  background-color: #ffffff;
}
.dropdown--white.dropdown--expanded .dropdown_option:hover {
  background-color: #f7f7f7;
}
.dropdown--white.dropdown--disabled .dropdown_value, .dropdown--white.dropdown--disabled .dropdown_option {
  color: rgba(84, 91, 98, 0.3);
}
.dropdown--white.dropdown--disabled .dropdown_toggle {
  color: rgba(84, 91, 98, 0.3);
}
.dropdown--slate .dropdown_inner {
  background-color: #2a3035;
}
.dropdown--slate .dropdown_list, .dropdown--slate .dropdown_option, .dropdown--slate .dropdown_no-data-text {
  background-color: #2a3035;
}
.dropdown--slate .dropdown_value, .dropdown--slate .dropdown_option, .dropdown--slate .dropdown_no-data-text {
  color: #ffffff;
}
.dropdown--slate .dropdown_option, .dropdown--slate .dropdown_no-data-text {
  border-top: 1px solid #22272b;
}
.dropdown--slate .dropdown_toggle {
  color: #f1af0f;
}
.dropdown--slate .dropdown_scrollbar:before {
  background-color: #22272b;
}
.dropdown--slate.dropdown--expanded .dropdown_inner {
  background-color: #2a3035;
}
.dropdown--slate.dropdown--expanded .dropdown_option:hover {
  background-color: #545b62;
}
.dropdown--slate.dropdown--disabled .dropdown_value, .dropdown--slate.dropdown--disabled .dropdown_option {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--slate.dropdown--disabled .dropdown_toggle {
  color: rgba(241, 175, 15, 0.3);
}
.dropdown--dark-slate .dropdown_inner {
  background-color: #22272b;
}
.dropdown--dark-slate .dropdown_list, .dropdown--dark-slate .dropdown_option, .dropdown--dark-slate .dropdown_no-data-text {
  background-color: #22272b;
}
.dropdown--dark-slate .dropdown_value, .dropdown--dark-slate .dropdown_option, .dropdown--dark-slate .dropdown_no-data-text {
  color: #ffffff;
}
.dropdown--dark-slate .dropdown_option, .dropdown--dark-slate .dropdown_no-data-text {
  border-top: 1px solid #1a1d21;
}
.dropdown--dark-slate .dropdown_toggle {
  color: #f1af0f;
}
.dropdown--dark-slate .dropdown_scrollbar:before {
  background-color: #1a1d21;
}
.dropdown--dark-slate.dropdown--expanded .dropdown_inner {
  background-color: #22272b;
}
.dropdown--dark-slate.dropdown--expanded .dropdown_option:hover {
  background-color: #1f2327;
}
.dropdown--dark-slate.dropdown--disabled .dropdown_value, .dropdown--dark-slate.dropdown--disabled .dropdown_option {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--dark-slate.dropdown--disabled .dropdown_toggle {
  color: rgba(241, 175, 15, 0.3);
}
.dropdown--pale-grey .dropdown_inner {
  background-color: #f1f1f1;
}
.dropdown--pale-grey .dropdown_list, .dropdown--pale-grey .dropdown_option, .dropdown--pale-grey .dropdown_no-data-text {
  background-color: #eeeeee;
}
.dropdown--pale-grey .dropdown_value, .dropdown--pale-grey .dropdown_option, .dropdown--pale-grey .dropdown_no-data-text {
  color: #545b62;
}
.dropdown--pale-grey .dropdown_option, .dropdown--pale-grey .dropdown_no-data-text {
  border-top: 1px solid #e0e0e0;
}
.dropdown--pale-grey .dropdown_toggle {
  color: #297ac5;
}
.dropdown--pale-grey .dropdown_scrollbar:before {
  background-color: #e0e0e0;
}
.dropdown--pale-grey.dropdown--expanded .dropdown_inner {
  background-color: #eeeeee;
}
.dropdown--pale-grey.dropdown--expanded .dropdown_option:hover {
  background-color: #e0e0e0;
}
.dropdown--pale-grey.dropdown--disabled .dropdown_value, .dropdown--pale-grey.dropdown--disabled .dropdown_option {
  color: rgba(84, 91, 98, 0.3);
}
.dropdown--pale-grey.dropdown--disabled .dropdown_toggle {
  color: rgba(41, 122, 197, 0.3);
}
.dropdown--steel .dropdown_inner {
  background-color: #1f6bb0;
}
.dropdown--steel .dropdown_list, .dropdown--steel .dropdown_option, .dropdown--steel .dropdown_no-data-text {
  background-color: #0f5ca3;
}
.dropdown--steel .dropdown_value, .dropdown--steel .dropdown_option, .dropdown--steel .dropdown_no-data-text {
  color: #ffffff;
}
.dropdown--steel .dropdown_option, .dropdown--steel .dropdown_no-data-text {
  border-top: 1px solid #10528f;
}
.dropdown--steel .dropdown_toggle {
  color: #ffffff;
}
.dropdown--steel .dropdown_scrollbar:before {
  background-color: #10528f;
}
.dropdown--steel.dropdown--expanded .dropdown_inner {
  background-color: #0f5ca3;
}
.dropdown--steel.dropdown--expanded .dropdown_option:hover {
  background-color: #10528f;
}
.dropdown--steel.dropdown--disabled .dropdown_value, .dropdown--steel.dropdown--disabled .dropdown_option {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--steel.dropdown--disabled .dropdown_toggle {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--rain .dropdown_inner {
  background-color: #297ac5;
}
.dropdown--rain .dropdown_list, .dropdown--rain .dropdown_option, .dropdown--rain .dropdown_no-data-text {
  background-color: #1f6bb0;
}
.dropdown--rain .dropdown_value, .dropdown--rain .dropdown_option, .dropdown--rain .dropdown_no-data-text {
  color: #ffffff;
}
.dropdown--rain .dropdown_option, .dropdown--rain .dropdown_no-data-text {
  border-top: 1px solid #0f5ca3;
}
.dropdown--rain .dropdown_toggle {
  color: #ffffff;
}
.dropdown--rain .dropdown_scrollbar:before {
  background-color: #0f5ca3;
}
.dropdown--rain.dropdown--expanded .dropdown_inner {
  background-color: #1f6bb0;
}
.dropdown--rain.dropdown--expanded .dropdown_option:hover {
  background-color: #0f5ca3;
}
.dropdown--rain.dropdown--disabled .dropdown_value, .dropdown--rain.dropdown--disabled .dropdown_option {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--rain.dropdown--disabled .dropdown_toggle {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--q4-blue .dropdown_inner {
  background-color: #0f5ca3;
}
.dropdown--q4-blue .dropdown_list, .dropdown--q4-blue .dropdown_option, .dropdown--q4-blue .dropdown_no-data-text {
  background-color: #10528f;
}
.dropdown--q4-blue .dropdown_value, .dropdown--q4-blue .dropdown_option, .dropdown--q4-blue .dropdown_no-data-text {
  color: #ffffff;
}
.dropdown--q4-blue .dropdown_option, .dropdown--q4-blue .dropdown_no-data-text {
  border-top: 1px solid #0A457C;
}
.dropdown--q4-blue .dropdown_toggle {
  color: #ffffff;
}
.dropdown--q4-blue .dropdown_scrollbar:before {
  background-color: #0A457C;
}
.dropdown--q4-blue.dropdown--expanded .dropdown_inner {
  background-color: #10528f;
}
.dropdown--q4-blue.dropdown--expanded .dropdown_option:hover {
  background-color: #0A457C;
}
.dropdown--q4-blue.dropdown--disabled .dropdown_value, .dropdown--q4-blue.dropdown--disabled .dropdown_option {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--q4-blue.dropdown--disabled .dropdown_toggle {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--ink .dropdown_inner {
  background-color: #10528f;
}
.dropdown--ink .dropdown_list, .dropdown--ink .dropdown_option, .dropdown--ink .dropdown_no-data-text {
  background-color: #10528f;
}
.dropdown--ink .dropdown_value, .dropdown--ink .dropdown_option, .dropdown--ink .dropdown_no-data-text {
  color: #ffffff;
}
.dropdown--ink .dropdown_option, .dropdown--ink .dropdown_no-data-text {
  border-top: 1px solid #0A457C;
}
.dropdown--ink .dropdown_toggle {
  color: #ffffff;
}
.dropdown--ink .dropdown_scrollbar:before {
  background-color: #0A457C;
}
.dropdown--ink.dropdown--expanded .dropdown_inner {
  background-color: #10528f;
}
.dropdown--ink.dropdown--expanded .dropdown_option:hover {
  background-color: #0A457C;
}
.dropdown--ink.dropdown--disabled .dropdown_value, .dropdown--ink.dropdown--disabled .dropdown_option {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--ink.dropdown--disabled .dropdown_toggle {
  color: rgba(255, 255, 255, 0.3);
}
.dropdown--disabled {
  pointer-events: none;
}
