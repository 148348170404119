/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.editor_label {
  display: block;
  margin-bottom: 8px;
  color: #545b62;
  user-select: none;
}
.editor-fontsize {
  width: 100px;
  height: 30px;
  border-radius: 3px;
  background: #1f6bb0;
  cursor: pointer;
  z-index: 1;
}
.editor-fontsize .rdw-dropdown-selectedtext, .editor-fontsize .rdw-dropdown-optionwrapper {
  width: 100%;
  color: #ffffff;
}
.editor-fontsize .rdw-dropdown-selectedtext {
  height: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-fontsize .rdw-dropdown-selectedtext:after {
  font-family: "iris";
  content: "\e995";
}
.editor-fontsize .rdw-dropdown-selectedtext:after {
  transition: transform 0.3s;
  color: #ffffff;
}
.editor-fontsize .rdw-dropdown-optionwrapper {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.editor-fontsize .rdw-dropdown-optionwrapper li {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  background: #0f5ca3;
  border-top: 1px solid #10528f;
  transition: background 0.3s;
}
.editor-fontsize .rdw-dropdown-optionwrapper li:hover {
  background: #10528f;
}
.editor-fontsize[aria-expanded=true] {
  border-radius: 3px 3px 0 0;
  background-color: #0f5ca3;
}
.editor-fontsize[aria-expanded=true] .rdw-dropdown-selectedtext:after {
  transform: rotate(180deg);
}
.editor .align-left .public-DraftStyleDefault-block {
  text-align: left;
}
.editor .align-center .public-DraftStyleDefault-block {
  text-align: center;
}
.editor .align-right .public-DraftStyleDefault-block {
  text-align: right;
}
.editor_container {
  border-radius: 3px;
  background: #eeeeee;
  overflow: hidden;
  height: 300px;
}
.editor_toolbar {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background: #297ac5;
  align-items: center;
}
.editor_toolbar > div {
  display: flex;
  flex-wrap: wrap;
  margin-right: 4px;
}
.editor_toolbar > div:last-child {
  margin-right: 0;
}
.editor_design, .editor_html {
  transition-property: opacity, visibility;
  border: none;
  height: calc(100% - 70px);
  width: 100%;
  padding: 10px;
  color: #545b62;
  background: #eeeeee;
  overflow: auto;
}
.editor_design ul {
  display: block;
  list-style-type: disc;
  margin: 14px 0;
  padding-left: 30px;
}
.editor_design .public-DraftEditorPlaceholder-inner {
  color: #939ba0;
  opacity: 0.7;
}
.editor_design .public-DraftEditorPlaceholder-hasFocus {
  display: none;
}
.editor_design .public-DraftEditor-content > div {
  display: inline;
}
.editor_design .public-DraftEditor-content > div > div {
  display: inline;
}
.editor_design figure {
  margin: 0;
}
.editor_design .rdw-justify-aligned-block {
  text-align: justify;
}
.editor_design .rdw-center-aligned-block {
  text-align: center;
}
.editor_design .rdw-right-aligned-block {
  text-align: right;
}
.editor_design .rdw-left-aligned-block {
  text-align: left;
}
.editor_design .rdw-image-alignment.rdw-image-right {
  float: right;
}
.editor_design .rdw-image-alignment.rdw-image-left {
  float: left;
}
.editor_design .rdw-image-alignment-options-popup, .editor_design .rdw-link-decorator-icon {
  display: none;
}
.editor .rdw-list-wrapper,
.editor .rdw-link-wrapper,
.editor .rdw-inline-wrapper,
.editor .rdw-text-align-wrapper {
  margin: 0 10px;
}
.editor .rdw-option-wrapper {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 0 4px;
}
.editor .rdw-option-wrapper img {
  display: none;
}
.editor .rdw-option-active {
  color: black;
}
.editor-button--bold {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--bold:before {
  font-family: "iris";
  content: "\e963";
}
.editor-button--italic {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--italic:before {
  font-family: "iris";
  content: "\e976";
}
.editor-button--underline {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--underline:before {
  font-family: "iris";
  content: "\e973";
}
.editor-button--bullet {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--bullet:before {
  font-family: "iris";
  content: "\e96a";
}
.editor-button--numbered {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--numbered:before {
  font-family: "iris";
  content: "\e971";
}
.editor-button--left {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--left:before {
  font-family: "iris";
  content: "\e95e";
}
.editor-button--center {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--center:before {
  font-family: "iris";
  content: "\e95d";
}
.editor-button--right {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--right:before {
  font-family: "iris";
  content: "\e962";
}
.editor-button--link {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor-button--link:before {
  font-family: "iris";
  content: "\e968";
}
.editor-button.end {
  margin-right: 14px;
}
.editor .rdw-link-wrapper .rdw-option-wrapper {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editor .rdw-link-wrapper .rdw-option-wrapper:before {
  font-family: "iris";
  content: "\e968";
}
.editor .dropdown {
  margin-right: 4px;
}
.editor .dropdown_inner {
  width: 100%;
}
