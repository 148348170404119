/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.app-header {
  position: relative;
  width: calc(100vw - 50px);
  min-width: 1230px;
  height: 80px;
  background-color: #0f5ca3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  transition: width 0.3s ease;
  z-index: 15;
}
.app-header_actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.app-header_button {
  position: relative;
  padding: 0 8px;
  min-width: 56px;
  height: 80px;
  color: #ffffff;
  background-color: transparent;
  cursor: pointer;
  overflow: visible;
}
.app-header_button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-header_button-badge {
  position: absolute;
  top: 27px;
  right: 20px;
  left: auto;
  height: 8px;
  width: 8px;
  background-color: #ec6a4c;
  border-radius: 50%;
}
.app-header_button--active, .app-header_button--active:hover {
  color: #f1af0f;
  background-color: #22272b;
}
.app-header_button--single-security, .app-header_button--security {
  padding: 0 16px;
  font-size: 12px;
  text-align: left;
}
.app-header_button--single-security {
  cursor: initial;
}
.app-header_button--single-security:hover {
  background-color: transparent;
}
.app-header_button-divider {
  position: relative;
  top: -2px;
  padding: 0 4px;
  font-size: 11px;
}
.app-header_button_stock {
  height: 18px;
  font-weight: 600;
  text-align: left;
}
.app-header_button_indicator--up {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-header_button_indicator--up:before {
  font-family: "iris";
  content: "\ea07";
}
.app-header_button_indicator--down {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-header_button_indicator--down:before {
  font-family: "iris";
  content: "\ea05";
}
.app-header_button_indicator--up, .app-header_button_indicator--down {
  position: relative;
  top: 1px;
  margin-left: 4px;
}
.app-header_button--activity .tooltip {
  display: none;
  top: 65px;
  right: 10px;
}
.app-header_button--activity:hover .tooltip {
  display: block;
}
.app-header_icon {
  font-size: 18px;
}
