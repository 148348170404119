/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.watchlist-widget {
  height: 100%;
  color: #ffffff;
}
.watchlist-widget, .watchlist-widget_header, .watchlist-widget_item, .watchlist-widget_notifications, .watchlist-widget_footer {
  transition: 0.3s ease;
}
.watchlist-widget_header table, .watchlist-widget_items table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
.watchlist-widget_header {
  height: 35px;
  border-bottom: 1px solid #2a3035;
}
.watchlist-widget_header .watchlist-widget_cell {
  padding: 0;
  height: 35px;
}
.watchlist-widget_header .watchlist-widget_cell i {
  font-size: 16px;
  line-height: 35px;
}
.watchlist-widget_items {
  width: 100%;
  height: calc(100% - 35px - 25px);
  border: none;
  border-collapse: collapse;
}
.watchlist-widget_item {
  border-bottom: 1px solid #2a3035;
}
.watchlist-widget_item--mine {
  background-color: #000;
}
.watchlist-widget_item--mine .watchlist-widget_cell:first-child {
  background: linear-gradient(to right, #f1af0f 0, #f1af0f 4px, rgba(0, 0, 0, 0) 4px);
}
.watchlist-widget_cell {
  padding: 7px 0;
  line-height: 13px;
  text-transform: uppercase;
  text-align: right;
  vertical-align: middle;
  cursor: pointer;
}
.watchlist-widget_cell > span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.watchlist-widget_cell span + span:not(.watchlist-widget_change) {
  color: #939ba0;
}
.watchlist-widget_cell i {
  font-size: 11px;
  line-height: 13px;
}
.watchlist-widget_cell:first-child {
  padding-left: 12px;
  text-align: left;
}
.watchlist-widget_cell:last-child {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.watchlist-widget_change--up {
  color: #1abc9c;
}
.watchlist-widget_change--down {
  color: #ec6a4c;
}
.watchlist-widget_silver-text {
  color: #939ba0;
}
.watchlist-widget_notifications {
  display: inline-block;
  min-width: 18px;
  padding: 0 4px;
  background-color: #2a3035;
  border-radius: 9px;
  cursor: pointer;
  line-height: 18px;
  text-align: center;
}
.watchlist-widget_notifications--new {
  background: #f1af0f;
  color: #22272b;
}
.watchlist-widget_alerts {
  height: 300px;
}
.watchlist-widget_alerts-list {
  list-style: none;
}
.watchlist-widget_footer {
  height: 35px;
  line-height: 12px;
  padding: 4px 10px;
  border-top: 1px solid #2a3035;
  font-size: 11px;
  color: #939ba0;
}
.watchlist-widget:not(.watchlist-widget--narrow) .watchlist-widget_footer {
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
}
.watchlist-widget--narrow .watchlist-widget_items {
  height: calc(100% - 35px - 35px);
}
.watchlist-widget--narrow .watchlist-widget_cell--52-week, .watchlist-widget--narrow .watchlist-widget_cell--day-range, .watchlist-widget--narrow .watchlist-widget_cell--average-volume {
  display: none;
}
.watchlist-widget--light {
  color: #545b62;
}
.watchlist-widget--light .watchlist-widget_header {
  border-bottom: 1px solid #e0e0e0;
}
.watchlist-widget--light .watchlist-widget_item {
  border-bottom: 1px solid #e0e0e0;
}
.watchlist-widget--light .watchlist-widget_item--mine {
  background-color: #f7f7f7;
}
.watchlist-widget--light .watchlist-widget_item--mine .watchlist-widget_cell:first-child {
  background: linear-gradient(to right, #297ac5 0, #297ac5 4px, rgba(0, 0, 0, 0) 4px);
}
.watchlist-widget--light .watchlist-widget_notifications {
  background-color: #e0e0e0;
}
.watchlist-widget--light .watchlist-widget_notifications--new {
  background-color: #297ac5;
  color: #ffffff;
}
.watchlist-widget--light .watchlist-widget_footer {
  border-top: 1px solid #e0e0e0;
}
