@import '../../../../../resources/styles/variables';

.historical-holding-grid_table {

  .ag-header {
    border-bottom: 1px solid $light-grey !important;

    &-row:last-child {
      height: 49px;
      color: $light-slate;
      background-color: $light-grey;

      .ag-header-cell {
        text-transform: none;

        &--even {
          background-color: $soft-grey;
        }
      }
    }
  }
}
