@keyframes slideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes slideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes slideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes slideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

.ant-picker-dropdown.slide-up {
  &-enter,
  &-appear {
    opacity: 0;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);

    &.slide-up-enter-active {
      .ant-picker-dropdown-placement {
        &-bottomLeft .ant-picker-range-arrow {
          top: 1.66666667px;
          display: block;
          transform: rotate(-45deg);
        }

        &-topLeft .ant-picker-range-arrow {
          bottom: 1.66666667px;
          display: block;
          transform: rotate(135deg);
        }
      }
    }

    &.slide-up-enter-active,
    &.slide-up-appear-active {
      animation-play-state: running;

      &.ant-picker-dropdown-placement {
        &-topLeft, &-topRight {
          animation-name: slideDownIn;
        }

        &-bottomLeft, &-bottomRight {
          animation-name: slideUpIn;
        }
      }
    }
  }

  &-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);

    &.slide-up-leave-active {
      animation-play-state: running;
      pointer-events: none;

      &.ant-picker-dropdown-placement {
        &-topLeft, &-topRight {
          animation-name: slideDownOut;
        }

        &-bottomLeft, &-bottomRight {
          animation-name: slideUpOut;
        }
      }
    }
  }
}
