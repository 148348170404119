/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.fund-grid_table .cell {
  line-height: 18px;
  display: flex;
  align-items: center;
  height: 50px;
}
.fund-grid_table .cell_icon {
  font-size: 18px;
}
.fund-grid_table .cell_icon--fund, .fund-grid_table .cell_icon--target {
  color: #1abc9c;
}
.fund-grid_table .cell_icon--utility {
  margin-left: 16px;
  color: #dddddd;
}
.fund-grid_table .cell_icon--deal {
  color: #939ba0;
}
.fund-grid_table .cell_title {
  margin-left: 14px;
  width: 85%;
}
.fund-grid_table .cell_title--name {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #545b62;
}
.fund-grid_table .cell_title--detail {
  color: #939ba0;
}
.fund-grid_table .cell_rating {
  background-color: #0f5ca3;
  color: #ffffff;
  font-size: 11px;
  border-radius: 50%;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
}
