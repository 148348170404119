.activity-form__activity-multi-location {
  display: flex;
  flex-wrap: wrap;
}
.activity-form__activity-multi-location .field--text {
  flex-basis: calc(50% - 10px);
}
.activity-form__activity-multi-location .checkbox-field {
  flex-basis: 100%;
}
