@import '../../../resources/styles/variables';
@import '../../../resources/styles/mixins/icons';
@import '../../../resources/styles/icons/variables';
@import '../../../resources/styles/mixins/all';
@import '../../../resources/styles/utils';


@mixin textInputTheme($textColor, $backgroundColor, $placeholderColor, $errorColor, $disabledBackgroundColor, $loadingColor) {
  .text-field_label {
    color: $textColor;
  }

  .text-field_input {
    color: $textColor;
    background-color: $backgroundColor;
    @include placeholder((color: $placeholderColor));
  }

  .text-field_loading-spinner {
    @include pulsing-spinner($borderColor: $loadingColor);
    left: calc(100% - #{gux(4)});
  }

  &.text-field--error {
    .text-field_input {
      background-color: lighten($errorColor, 30);
      color: $errorColor;
      @include placeholder((color: lighten($errorColor, 15)));
    }
  }

  &.text-field--readonly {
    .text-field_input {
      color: $placeholderColor;
    }
  }

  &.text-field--disabled {
    .text-field_input {
      background-color: $disabledBackgroundColor;
    }
  }
}

.text-field {

  &_label {
    display: block;
    margin-bottom: gux();
    user-select: none;

    &--required {
      font-size: $font-size-table;
      color: $silver;
    }
  }

  &-input {
    position: relative;
  }

  &_input {
    padding: 0 gux();
    width: 100%;
    line-height: 40px;
    min-height: 40px;
    border-radius: $border-radius-small;

    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &--readonly .text-field_input {
    cursor: default;
    user-select: none;
  }

  &--disabled .text-field_input {
    cursor: $cursor-disabled;
  }

  // themes
  &--light {
    @include textInputTheme($light-slate, $light-grey, $silver, $spice, $light-grey, $rain);
  }

  &--dark {
    //@include textInputTheme();
  }

  &--white {
    @include textInputTheme($light-slate, $white, $silver, $spice, $pale-grey, $rain);
  }
}
