// Global Variables
//-------------------------------------//
$inner-max-width: 1400px;
$inner-min-width: 1230px; // 1280 minus 50 nav width

$app-nav-width: 50px;
$app-nav-expanded-width: 250px;

$app-header-height: 80px;
$global-banner-height: 130px;
$global-toolbar-height: 70px;
$global-footer-height: 150px;

// Colours
//-------------------------------------//
$midnight: #053A6B;
$deep-blue: #0A457C;
$ink: #10528f;
$q4-blue: #0f5ca3;
$steel: #1f6bb0;
$rain: #297ac5;
$sky: #3498db;
$surf: #4696E0;
$olympic: #428BCF;
$teal: #1abc9c;
$dark-teal: #0da084;
$lime: #2ecc71;
$apple: #aa2d40;
$ice: #94e9f6;
$avocado: #3a925f;
$sunshine: #f1c40f;
$citrus: #f1af0f;
$mustard: #dc9e27;
$tangerine: #fc7e26;
$ginger: #e67f22;
$amber: #d95608;
$spice: #ec6a4c;
$cherry: #e74c3c;
$eggplant: #6a3476;
$plum: #804a8c;
$raspberry: #a4305e;
$walnut: #935f39;
$black: #000000;
$black-pearl: #14171a;
$black-smoke: #121517;
$charcoal: #1a1d21;
$raisin-black: #1f2327;
$gunpowder: #1D2124;
$dark-slate: #22272b;
$slate: #2a3035;
$titanium: #373B41;
$light-slate: #545b62;
$grey: #646d75;
$cold-grey: #858e93;
$silver: #939ba0;
$ash: #cccccc;
$ghost: #dddddd;
$soft-grey: #e0e0e0;
$light-grey: #eeeeee;
$pale-grey: #f1f1f1;
$muted-grey: #f7f7f7;
$white: #ffffff;


// Transparent Colours
//-------------------------------------//
$transparent-dark-05: rgba(0, 0, 0, 0.05);
$transparent-dark-10: rgba(0, 0, 0, 0.1);
$transparent-dark-15: rgba(0, 0, 0, 0.15);
$transparent-dark-20: rgba(0, 0, 0, 0.2);
$transparent-dark-50: rgba(0, 0, 0, 0.5);
$transparent-dark-80: rgba(0, 0, 0, 0.8);

$transparent-light-10: rgba(255, 255, 255, 0.1);
$transparent-light-15: rgba(255, 255, 255, 0.15);
$transparent-light-25: rgba(255, 255, 255, 0.25);
$transparent-light-50: rgba(255, 255, 255, 0.5);
$transparent-light-80: rgba(255, 255, 255, 0.8);

$opacity--full: 1;
$opacity--high: 0.75;
$opacity--medium: 0.5;
$opacity--low: 0.3;
$opacity--subtle: 0.15;
$opacity--transparent: 0;


// Padding and Margin spacing
//-------------------------------------//
$padding-vertical-xlarge: 60px;
$padding-vertical-large: 40px;
$padding-vertical-medium: 30px;
$padding-vertical-base: 20px;
$padding-vertical-small: 14px;
$padding-vertical-xsmall: 10px;
$padding-vertical-xxsmall: 4px;

$padding-horizontal-xlarge: 70px;
$padding-horizontal-large: 50px;
$padding-horizontal-base: 30px;
$padding-horizontal-medium: 20px;
$padding-horizontal-small: 14px;
$padding-horizontal-xsmall: 10px;
$padding-horizontal-icon: 8px;
$padding-horizontal-xxsmall: 4px;


// Fonts and Line Heights
//-------------------------------------//
$font-family-base: 'Open Sans', sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-style-italic: italic;

$font-size-base: 13px;

$font-size-xxsmall: 9px;
$font-size-xsmall: 10px;
$font-size-small: 11px;
$font-size-table: 12px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-xlarge: 26px;
$font-size-xxlarge: 30px;

$font-size-icon: 18px;
$font-size-search-icon: 24px;

// Line Height
//-------------------------------------//
$line-height-base: 1.3;
$line-height-large: 1.5;

$line-height-xsmall: 15px;
$line-height-small: 16px;
$line-height-medium: 22px;
$line-height-xlarge: 40px;


// Letter Spacing
//-------------------------------------//
$letter-spacing-small: 0.5px;
$letter-spacing-medium: 1px;
$letter-spacing-large: 1.5px;
$letter-spacing-xlarge: 2px;


// Border Radius
//-------------------------------------//
$border-radius-base: 5px;
$border-radius-xsmall: 2px;
$border-radius-small: 3px;
$border-radius-medium: 8px;
$border-radius-large: 10px;
$border-radius-xlarge: 25px;
$border-radius-xxlarge: 35px;
$border-radius-circle: 50%;


// Box Shadow
//-------------------------------------//
$box-shadow--light: 0 2px 10px rgba(0, 0, 0, 0.1);
$box-shadow--default: 0 5px 20px rgba(0, 0, 0, 0.3);
$box-shadow--medium: 0 5px 10px rgba(0, 0, 0, 0.1);

// Widgets
//-------------------------------------//
$widget-header-size: 35px;
$widget-header-size--large: 50px;
$widget-header-size--xlarge: 70px;
$widget-header-size--xxlarge: 105px;
$widget-footer-size: 35px;
$widget-footer-size--small: 25px;
$widget-footer-size--smaller: 10px;


// Form Elements
//-------------------------------------//
$label-height-base: 22px;
$input-height-base: 40px;


// Z-Space Management
// Each level is separated by 5 index layers, allowing 5 different instances per level
//-------------------------------------//
$z-index--base: 0; // base z-index
$z-index--level-one: $z-index--base + 5; // ui elements with a bit of 'pop'. ie: dropdown / autocomplete
$z-index--level-two: $z-index--base + 10;
$z-index--level-three: $z-index--base + 15; // used for global app header
$z-index--mask: 50; // used when masking a portion or the whole of the viewport
$z-index--modal-level-one: $z-index--mask + 5; // used for the base modal / message components
$z-index--modal-level-two: $z-index--mask + 10; // ui elements with a bit of 'pop' within modals
$z-index--modal-level-three: $z-index--mask + 15;
$z-index--modal-level-four: $z-index--mask + 20; // used for global toast notification


// Animations
//-------------------------------------//
$transition-duration--seamless: 0.1s;
$transition-duration--fast: 0.2s;
$transition-duration--default: 0.3s;
$transition-duration--medium: 0.5s;
$transition-duration--slow: 1s;
$transition-timing-function--default: ease;


// Media Query Breakpoints
//-------------------------------------//
$screen-small: 1280px !default;
$screen-medium: 1680px !default;
$screen-large: 1920px !default;

// So media queries don't overlap when required, provide a maximum
$screen-medium-min: ($screen-small + 1) !default;
$screen-large-min: ($screen-medium + 1) !default;


//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;

