/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
@keyframes filter-collapse {
  from {
    height: 55px;
    margin-bottom: 3px;
  }
  to {
    height: 0;
    margin-bottom: 0;
  }
}
.report-builder-filter {
  height: 55px;
  margin-bottom: 3px;
  position: relative;
  border-radius: 3px;
  background-color: #ffffff;
  animation: filter-collapse 0.3s 1 ease 0.3s, fadeout-opacity 0.6s 1 ease;
  animation-play-state: paused;
  animation-fill-mode: forwards;
  transition: height 0.3s ease;
}
.report-builder-filter_summary {
  cursor: pointer;
}
.report-builder-filter_toggle, .report-builder-filter_close {
  line-height: 55px;
  cursor: pointer;
}
.report-builder-filter_toggle {
  display: inline-block;
  padding-left: 15px;
  font-size: 12px;
}
.report-builder-filter_toggle i {
  display: inline-block;
  transform: none;
  transition: transform 0.3s ease;
}
.report-builder-filter_close {
  display: inline-block;
  float: right;
  padding: 0 10px;
  font-size: 8px;
  transition: color 0.3s ease;
}
.report-builder-filter_close:hover {
  color: #939ba0;
}
.report-builder-filter_details {
  display: inline-block;
  width: calc(100% - 55px);
  padding: 9px 15px;
  vertical-align: top;
}
.report-builder-filter_field-label, .report-builder-filter_type {
  display: inline-block;
  line-height: 18px;
  vertical-align: middle;
}
.report-builder-filter_field-label {
  max-width: calc(100% - 16px - 5px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #939ba0;
}
.report-builder-filter_type {
  font-size: 16px;
  margin-right: 5px;
}
.report-builder-filter_type i {
  line-height: inherit;
}
.report-builder-filter_type--institution, .report-builder-filter_type--shareholderid, .report-builder-filter_type--institutionSurveillance {
  color: #ec6a4c;
}
.report-builder-filter_type--fundShareholderid, .report-builder-filter_type--fundSurveillance, .report-builder-filter_type--fund {
  color: #1abc9c;
}
.report-builder-filter_type--contact {
  color: #3498db;
}
.report-builder-filter_type--activity {
  color: #aa2d40;
}
.report-builder-filter_type--stock {
  color: #6a3476;
}
.report-builder-filter_value-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
}
.report-builder-filter_config {
  display: none;
  padding: 10px 10px 20px;
  border-top: 1px solid #eeeeee;
}
.report-builder-filter .field {
  position: relative;
}
.report-builder-filter .field:not(:last-child) {
  margin-bottom: 10px;
}
.report-builder-filter--basic {
  display: inline-block;
  width: 50%;
  height: auto;
  margin: 0;
  background: transparent;
}
.report-builder-filter--basic:nth-child(odd) {
  padding-right: 10px;
}
.report-builder-filter--basic:nth-child(even) {
  padding-left: 10px;
}
.report-builder-filter--basic:last-child {
  padding-right: 0;
}
.report-builder-filter--open .report-builder-filter_toggle i {
  transform: rotate(-180deg);
}
.report-builder-filter--open .report-builder-filter_config {
  display: block;
}
.report-builder-filter--opening {
  overflow: hidden;
}
.report-builder-filter--removing {
  animation-play-state: running;
}
.report-builder-filter--disabled {
  pointer-events: none;
}
