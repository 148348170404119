.top-shareholders {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.top-shareholders, .top-shareholders_header, .top-shareholders_row, .top-shareholders_footer, .top-shareholders_link {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.top-shareholders_header {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.top-shareholders_title {
  width: 400px;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 10px 0 0;
}
.top-shareholders_title a {
  color: #ffffff;
}
.top-shareholders_details {
  display: none;
  float: right;
  color: #939ba0;
}
.top-shareholders_column {
  display: inline-block;
  text-transform: uppercase;
}
.top-shareholders_items {
  height: calc(100% - 35px - 25px);
}
.top-shareholders_current-change {
  display: none;
}
.top-shareholders_up {
  color: #1abc9c;
}
.top-shareholders_down {
  color: #ec6a4c;
}
.top-shareholders_holder {
  width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.top-shareholders_icon {
  margin-right: 5px;
}
.top-shareholders_icon.q4i-institution-4pt {
  color: #ec6a4c;
}
.top-shareholders_icon.q4i-insider-4pt {
  color: #297ac5;
}
.top-shareholders_current, .top-shareholders_change, .top-shareholders_column {
  width: 100px;
  text-align: right;
  margin-left: 5px;
}
.top-shareholders_row {
  padding: 5px 10px;
  border-bottom: 1px solid #2a3035;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.top-shareholders_row:last-child {
  border-bottom: none;
}
.top-shareholders_footer {
  height: 25px;
  line-height: 25px;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
  color: #939ba0;
}
.top-shareholders_caption {
  font-size: 11px;
}
.top-shareholders_link {
  float: right;
  text-transform: uppercase;
  color: #f1af0f;
}
.top-shareholders_link:hover {
  color: #dc9e27;
}
.top-shareholders--narrow .top-shareholders_title {
  width: auto;
}
.top-shareholders--narrow .top-shareholders_details {
  display: block;
}
.top-shareholders--narrow .top-shareholders_column {
  display: none;
}
.top-shareholders--narrow .top-shareholders_current-change {
  display: block;
}
.top-shareholders--narrow .top-shareholders_row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.top-shareholders--narrow .top-shareholders_holder {
  width: 210px;
  line-height: 32px;
}
.top-shareholders--narrow .top-shareholders_current p {
  line-height: 16px;
}
.top-shareholders--narrow .top-shareholders_change {
  display: none;
}
.top-shareholders--large:not(.top-shareholders--narrow) .top-shareholders_row {
  padding: 11px 10px;
}
.top-shareholders--light {
  color: #545b62;
}
.top-shareholders--light .top-shareholders_header, .top-shareholders--light .top-shareholders_row {
  border-bottom: 1px solid #e0e0e0;
}
.top-shareholders--light .top-shareholders_row:last-child {
  border-bottom: none;
}
.top-shareholders--light .top-shareholders_table thead tr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.top-shareholders--light .top-shareholders_table tbody tr {
  color: #545b62;
}
.top-shareholders--light .top-shareholders_table .highlight-circle {
  color: #545b62;
}
.top-shareholders--light .top-shareholders_footer {
  border-top: 1px solid #e0e0e0;
}
.top-shareholders--light .top-shareholders_link {
  color: #297ac5;
}
.top-shareholders--light .top-shareholders_link:hover {
  color: #0f5ca3;
}
