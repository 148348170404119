@import '../../../resources/styles/variables';
@import '../../../resources/styles/mixins/all';

.eventsTranscripts-page {
    display: flex;
    
    .event-transcript-page-tab_body {
        flex: 1;
        position: relative;
        min-height: calc(100vh - #{$app-header-height} - #{$global-banner-height});
        background-color: $white;
        overflow: hidden;
    }
}
