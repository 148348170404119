.upcoming-activities {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.upcoming-activities, .upcoming-activities_header, .upcoming-activities_footer, .upcoming-activities_footer a {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.upcoming-activities_header, .upcoming-activities_footer {
  text-transform: uppercase;
  padding: 0 10px;
}
.upcoming-activities_header {
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #2a3035;
}
.upcoming-activities_header-title {
  cursor: pointer;
}
.upcoming-activities_items {
  height: calc(100% - 35px - 25px);
}
.upcoming-activities_list {
  height: 100%;
}
.upcoming-activities_footer {
  height: 25px;
  line-height: 25px;
  border-top: 1px solid #2a3035;
  text-align: right;
}
.upcoming-activities_footer a {
  color: #f1af0f;
}
.upcoming-activities_footer a:hover {
  color: #dc9e27;
}
.upcoming-activities_nodata {
  margin: 10px;
  padding: 6px 15px 8px;
  background-color: #ec6a4c;
  border-radius: 3px;
  color: #ffffff;
}
.upcoming-activities_nodata i, .upcoming-activities_nodata span {
  display: inline-block;
  vertical-align: middle;
  line-height: 16px;
}
.upcoming-activities_nodata i {
  margin-right: 8px;
  font-size: 16px;
}
.upcoming-activities--light {
  color: #545b62;
}
.upcoming-activities--light .upcoming-activities_header {
  border-bottom: 1px solid #e0e0e0;
}
.upcoming-activities--light .upcoming-activities_footer {
  border-top: 1px solid #e0e0e0;
}
.upcoming-activities--light .upcoming-activities_footer a {
  color: #297ac5;
}
.upcoming-activities--light .upcoming-activities_footer a:hover {
  color: #0f5ca3;
}
