@import '../../../../../resources/styles/variables';
@import '../../../../../resources/styles/utils';

$width: 1100px;
$height: 32px;
$circle-height: 24px;

.deal-detail-page_progress {
  width: $width;
  height: $height;
  background-color: $dark-slate;
  border-radius: $border-radius-xlarge;
  display: flex;

  .stage {
    cursor: default;
    user-select: none;
    height: $height;
    border-radius: $border-radius-xlarge;
    display: flex;
    align-items: center;
    flex: 1;

    &_label {
      padding: 0 gux(2);
      width: 100%;
      height: $height;
      line-height: $height;
      color: $white;
      flex: 1;
    }

    &_status {
      position: relative;
      width: $height;
      height: $height;
      background-color: $slate;
      border-radius: $border-radius-circle;

      &--indicator {
        position: absolute;
        top: 4px;
        right: 4px;
        width: $circle-height;
        height: $circle-height;
        line-height: calc(#{$circle-height} + 2px);
        text-align: center;
        color: $silver;
        background-color: $gunpowder;
        border-radius: $border-radius-circle;

        i:before {
          position: relative;
          left: 1px;
        }
      }
    }

    &--active, &--past, &--lost {
      border-radius: $border-radius-xlarge;

      &:not(:first-child) {
        margin-left: -10px;
        border-radius: 0 $border-radius-xlarge $border-radius-xlarge 0;
      }
    }

    &--active {
      background-color: $teal;

      .stage_status--indicator {
        color: $dark-teal;
        background-color: $white;
      }
    }

    &--past {
      background-color: $teal;

      .stage_status--indicator {
        color: $white;
        background-color: $dark-teal;
      }
    }

    &--lost {
      background-color: $cherry;

      .stage_status--indicator {
        color: $white;
        background-color: $cherry;
      }
    }
  }
}
