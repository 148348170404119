.section-search {
  display: inline-block;
  width: 200px;
  height: 32px;
  border-radius: 3px;
  background-color: #1f6bb0;
  color: #ffffff;
  transition: 0.3s ease;
  transition-property: width, background-color, color;
}
.section-search, .section-search_inner {
  position: relative;
}
.section-search_inner {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.section-search_icon, .section-search_input {
  display: inline-block;
  vertical-align: top;
}
.section-search_icon, .section-search_clear {
  text-align: center;
}
.section-search_icon {
  width: 32px;
  line-height: 32px;
  padding-left: 8px;
  font-size: 16px;
}
.section-search_icon i {
  line-height: inherit;
}
.section-search_input {
  width: calc(100% - 32px);
  height: 32px;
  line-height: 32px;
  padding: 0 24px 0 8px;
  border: none;
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #ffffff;
  transition: color 0.3s ease;
}
.section-search_input::placeholder {
  font-family: "Open Sans", sans-serif;
  color: rgba(255, 255, 255, 0.3);
}
.section-search_input:focus {
  outline: none;
}
.section-search_clear {
  position: absolute;
  top: calc(50% - 5px);
  right: 5px;
  width: 20px;
  font-size: 10px;
  cursor: pointer;
}
.section-search--rain {
  background-color: #297ac5;
  color: #ffffff;
}
.section-search--rain .section-search_input {
  color: #ffffff;
}
.section-search--rain .section-search_input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.section-search--q4-blue {
  background-color: #0f5ca3;
  color: #ffffff;
}
.section-search--q4-blue .section-search_input {
  color: #ffffff;
}
.section-search--q4-blue .section-search_input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.section-search--ink {
  background-color: #10528f;
  color: #ffffff;
}
.section-search--ink .section-search_input {
  color: #ffffff;
}
.section-search--ink .section-search_input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.section-search--white {
  background-color: #ffffff;
  color: #545b62;
}
.section-search--white .section-search_input {
  color: #545b62;
}
.section-search--white .section-search_input::placeholder {
  color: rgba(84, 91, 98, 0.3);
}
.section-search--light-grey {
  background-color: #eeeeee;
  color: #545b62;
}
.section-search--light-grey .section-search_input {
  color: #545b62;
}
.section-search--light-grey .section-search_input::placeholder {
  color: rgba(84, 91, 98, 0.3);
}
.section-search--slate {
  background-color: #2a3035;
  color: #f1af0f;
}
.section-search--slate .section-search_input {
  color: #ffffff;
}
.section-search--slate .section-search_input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.section-search--disabled .section-search {
  pointer-events: none;
}
.section-search--disabled .section-search_inner {
  opacity: 0.3;
}
