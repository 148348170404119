.nlg {
  height: 100%;
  background-color: transparent;
  border-radius: 3px;
  font-size: 13px;
  color: #ffffff;
}
.nlg, .nlg_header, .nlg_title, .nlg_item, .nlg_tab-item, .nlg-item_wrapper, .nlg_peer-label, .nlg_footer {
  transition: 0.3s ease;
}
.nlg_header {
  width: 100%;
  height: 50px;
  line-height: 13px;
  padding: 10px;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.nlg_footer {
  height: 10px;
  border-top: 1px solid #2a3035;
}
.nlg_title {
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
  text-transform: uppercase;
}
.nlg_container {
  height: calc(100% - 50px - 10px);
}
.nlg_peer-label {
  color: #ffffff;
  cursor: pointer;
}
.nlg_peer-label-symbol {
  display: inline-block;
}
.nlg_peer-label-exchange {
  margin-left: 4px;
  color: #939ba0;
}
.nlg_peer-label--light {
  color: #545b62;
}
.nlg_commentary {
  padding: 10px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}
.nlg_performance {
  padding: 0 10px 10px;
  cursor: pointer;
}
.nlg_performance .nlg_charts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.nlg_performance .nlg_tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  margin: 10px 0;
}
.nlg_performance .nlg_tab-item {
  width: 50%;
  font-size: 13px;
  text-transform: uppercase;
  background-color: transparent;
  color: #888;
  border: 1px solid #1f2327;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.nlg_performance .nlg_tab-item--active {
  cursor: auto;
  background-color: #1f2327;
  color: #f1af0f;
}
.nlg_performance .nlg_tab-item:first-child {
  border-radius: 4px 0 0 4px;
}
.nlg_performance .nlg_tab-item:last-child {
  border-radius: 0 4px 4px 0;
}
.nlg_performance--tabs .nlg_tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.nlg_performance--tabs .nlg-item:nth-child(2) {
  padding-left: 0;
}
.nlg_performance--tabs .nlg-item--inactive, .nlg_performance--tabs .nlg-item_title {
  display: none;
}
.nlg--narrow .nlg_header {
  height: 70px;
  display: block;
}
.nlg--narrow .nlg_container {
  height: calc(100% - 70px - 10px);
}
.nlg--narrow .nlg_peer-dropdown {
  margin-top: 6px;
}
.nlg--narrow .nlg_peer-dropdown, .nlg--narrow .nlg_peer-dropdown-container {
  width: 100% !important;
}
.nlg--light {
  color: #545b62;
}
.nlg--light .nlg_header, .nlg--light .nlg_footer {
  border-color: #e0e0e0;
}
.nlg--light .nlg_title {
  color: #545b62;
}
.nlg--light .nlg_tab-item {
  color: #545b62;
  border: 1px solid #e0e0e0;
}
.nlg--light .nlg_tab-item--active {
  background-color: #eeeeee;
  color: #0f5ca3;
}
