/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.app-nav_mask, .app-nav_drawer {
  position: absolute;
  top: 0;
  left: 0;
}
.app-nav_mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 15;
}
.app-nav_drawer {
  width: 50px;
  height: 100%;
  background-color: #22272b;
  overflow: hidden;
  transition: width 0.3s ease;
  z-index: 16;
}
.app-nav_menu {
  padding-bottom: 20px;
  height: 100%;
  width: calc(100% + 17px);
  overflow-y: auto;
  overflow-x: hidden;
}
.app-nav_menu-header {
  cursor: pointer;
  position: relative;
  height: 80px;
  font-size: 24px;
  color: #ffffff;
  border-bottom: 1px solid #1a1d21;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.app-nav_menu-header:hover {
  background: none;
}
.app-nav_menu-header_toggle {
  display: block;
  position: absolute;
  min-width: 50px;
  text-align: center;
}
.app-nav_menu-header_logo {
  display: none;
  position: absolute;
  left: 14px;
}
.app-nav_menu-header_chevron {
  display: none;
  position: absolute;
  left: 190px;
  font-size: 13px;
}
.app-nav_menu-section {
  overflow: hidden;
  height: 100%;
  transition: height 0.2s ease-in-out;
}
.app-nav_menu-section_header {
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 45px;
  color: #f1af0f;
  background-color: #1a1d21;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.app-nav_menu-section_header:before {
  content: "";
  position: relative;
  left: 22px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f1af0f;
}
.app-nav_menu-section_header--collapsed .app-nav_menu-section_icon {
  transform: rotate(-90deg);
}
.app-nav_menu-section_icon, .app-nav_menu-section_title {
  display: none;
  vertical-align: middle;
  line-height: 45px;
}
.app-nav_menu-section_icon {
  padding: 0 10px 0 14px;
  transform: rotate(0);
  transition: transform 0.5s ease;
}
.app-nav_menu-section_title {
  text-transform: uppercase;
}
.app-nav_menu-item {
  width: 100%;
  height: 45px;
  color: #ffffff;
  border-bottom: 1px solid #1a1d21;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.app-nav_menu-item_icon {
  position: relative;
  height: 45px;
  min-width: 50px;
  font-size: 18px;
  text-align: center;
}
.app-nav_menu-item_icon:before {
  line-height: 45px;
}
.app-nav_menu-item_icon--new {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-nav_menu-item_icon--new:after {
  font-family: "iris";
  content: "\e923";
}
.app-nav_menu-item_icon--new:after {
  font-size: 10px;
  color: #f1af0f;
  position: absolute;
  top: 4px;
  right: 5px;
}
.app-nav_menu-item_icon--new_tab {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f1af0f;
  padding-right: 32px;
  line-height: 45px;
}
.app-nav_menu-item_icon--new_tab:after {
  font-family: "iris";
  content: "\e95f";
}
.app-nav_menu-item_label {
  display: block;
  padding-right: 14px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-nav_menu-item:visited {
  color: #ffffff;
}
.app-nav_menu-item:hover {
  background-color: #2a3035;
}
.app-nav_menu-item--active, .app-nav_menu-item--active:visited {
  color: #f1af0f;
}
.app-nav_menu-item:focus {
  outline: none;
}
.app-nav--expanded .app-nav_mask {
  opacity: 1;
  pointer-events: initial;
  z-index: 50;
}
.app-nav--expanded .app-nav_drawer {
  width: 250px;
  z-index: 51;
}
.app-nav--expanded .app-nav_menu-header_toggle {
  left: 200px;
}
.app-nav--expanded .app-nav_menu-header_logo, .app-nav--expanded .app-nav_menu-header_chevron {
  display: block;
}
.app-nav--expanded .app-nav_menu-section_header:before {
  display: none;
}
.app-nav--expanded .app-nav_menu-section_icon, .app-nav--expanded .app-nav_menu-section_title {
  display: inline-block;
}
