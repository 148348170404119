.try-engagement-analytics_container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 360px);
  background-color: #ffffff;
  animation: fade-in 0.3s ease;
}
.try-engagement-analytics_container p {
  max-width: 500px;
  margin-top: 16px;
  text-align: center;
}
.try-engagement-analytics_container h3 {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 400;
  color: #545b62;
}
.try-engagement-analytics_container img {
  margin-top: 80px;
}
.try-engagement-analytics_container a {
  margin-top: 24px;
  margin-bottom: 150px;
}
