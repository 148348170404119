.report-template-list {
  padding: 20px 20px;
}
.report-template-list_item {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition-property: border-color, color, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.report-template-list_item:first-child {
  border-top: none;
  border-radius: 3px 3px 0 0;
}
.report-template-list_item:last-child {
  border-radius: 0 0 3px 3px;
}
.report-template-list_item:not(.report-template-list_item--selected):hover {
  background-color: #e0e0e0;
}
.report-template-list_item--selected {
  background-color: #297ac5;
  border-color: #297ac5;
}
.report-template-list_item--selected, .report-template-list_item--selected .report-template-list_icon, .report-template-list_item--selected .report-template-list_title {
  color: #ffffff;
}
.report-template-list_title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #297ac5;
  transition: color 0.3s ease;
}
