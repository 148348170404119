/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.page-manager-item {
  position: relative;
}
.page-manager-item:hover {
  cursor: move;
  cursor: -webkit-grab;
}
.page-manager-item:not(:last-child) {
  margin-bottom: 14px;
}
.page-manager-item_index, .page-manager-item_settings {
  position: absolute;
}
.page-manager-item_index {
  opacity: 1;
  top: 0;
  left: 0;
  color: #545b62;
  font-size: 16px;
  line-height: 16px;
  transition: opacity, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  pointer-events: none;
}
.page-manager-item_index--inner {
  opacity: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.page-manager-item_inner {
  position: relative;
  width: calc(100% - 30px);
  height: 100%;
  margin-left: 30px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  transition: width, height, margin, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  cursor: pointer;
}
.page-manager-item_thumbnail, .page-manager-item_grid-container, .page-manager-item_settings {
  transition: opacity 0.3s ease;
}
.page-manager-item_thumbnail {
  max-width: 100%;
  max-height: 100%;
}
.page-manager-item_grid-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.page-manager-item_grid-item {
  position: absolute;
  border: 1px solid #ffffff;
  border-radius: 2px;
  background-color: #eeeeee;
  transition: top, left, width, height;
  transition-duration: 0.1s;
  transition-timing-function: ease;
}
.page-manager-item_settings {
  top: 10px;
  right: 10px;
  opacity: 0;
}
.page-manager-item:hover:not(.page-manager-item--collapsed) .page-manager-item_settings {
  opacity: 1;
}
.page-manager-item--popoveropen .page-manager-item_settings {
  opacity: 1;
}
.page-manager-item:hover .page-manager-item_inner {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}
.page-manager-item--portrait .page-manager-item_inner {
  height: 240px;
}
.page-manager-item--landscape .page-manager-item_inner {
  height: 100px;
}
.page-manager-item--selected .page-manager-item_index {
  color: #297ac5;
}
.page-manager-item--selected .page-manager-item_inner {
  border-color: #297ac5;
}
.page-manager-item--collapsed .page-manager-item_index {
  opacity: 0;
}
.page-manager-item--collapsed .page-manager-item_index--inner {
  opacity: 1;
}
.page-manager-item--collapsed .page-manager-item_inner {
  width: 100%;
  height: 30px;
  margin: 0;
}
.page-manager-item--collapsed .page-manager-item_thumbnail, .page-manager-item--collapsed .page-manager-item_grid-container {
  opacity: 0;
}
.page-manager-item.gu-mirror {
  opacity: 0.5;
  position: fixed;
  z-index: 15;
}
.page-manager-item.gu-mirror .page-manager-item_settings {
  display: none;
}
.page-manager-item.gu-transit {
  height: 2px;
  background-color: #297ac5;
  overflow: hidden;
}
.page-manager-item.gu-transit .page-manager-item_index, .page-manager-item.gu-transit .page-manager-item_inner, .page-manager-item.gu-transit .page-manager-item_settings {
  display: none;
}
.page-manager-item.gu-hide {
  display: none;
}
.page-manager-item.gu-unselectalble {
  user-select: none;
}
.page-manager-item--dragging {
  height: 2px;
  background-color: #297ac5;
  overflow: hidden;
}
