@import '../../../../resources/styles/variables';

.briefing-book-detail-page {

  &_table {
    min-height: 460px;
    height: calc(100vh - 80px - 130px);
    background-color: $white;
  }
}
