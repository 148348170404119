.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner, .spinner_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.spinner_mask {
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.spinner_logo, .spinner_message, .spinner_pulse {
  z-index: 51;
}
.spinner_logo {
  padding-right: 8px;
  font-size: 60px;
}
.spinner_message {
  margin: 30px 0;
  font-size: 20px;
  line-height: 40px;
}
.spinner_pulse {
  height: 50px;
  width: 50px;
  border: 2px solid #f1af0f;
  border-radius: 50%;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}
.spinner--fixed {
  position: fixed;
}
.spinner .spinner_pulse {
  border-color: #f1af0f;
}
.spinner .spinner_logo, .spinner .spinner_message {
  color: #22272b;
}
.spinner--rain .spinner_pulse, .spinner--light .spinner_pulse {
  border-color: #297ac5;
}
.spinner--rain .spinner_logo, .spinner--rain .spinner_message, .spinner--light .spinner_logo, .spinner--light .spinner_message {
  color: #ffffff;
}
.spinner--white .spinner_pulse, .spinner--dark .spinner_pulse {
  border-color: #ffffff;
}
.spinner--white .spinner_logo, .spinner--white .spinner_message, .spinner--dark .spinner_logo, .spinner--dark .spinner_message {
  color: #ffffff;
}
