.pipeline-widget_chart-container {
  max-width: 500px;
}
.pipeline-widget g.highcharts-label {
  display: none;
}
.pipeline-widget .highcharts-data-labels > div:first-child span {
  color: #2a3035 !important;
}
.pipeline-widget .highcharts-data-label {
  position: relative !important;
  left: 0 !important;
}
.pipeline-widget .highcharts-data-label > span {
  top: 0 !important;
  left: 17.5px !important;
  width: 265px !important;
  text-align: center;
}
.pipeline-widget .highcharts-legend-item rect {
  width: 10px;
  height: 10px;
  rx: 5;
  ry: 5;
  x: 5;
  y: 5;
}
.pipeline-widget .highcharts-tooltip {
  margin: 0 !important;
  padding: 0 !important;
}
.pipeline-widget .highcharts-tooltip_wrapper {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;
}
.pipeline-widget .highcharts-tooltip_wrapper h2 {
  font-size: 13px;
  color: #297ac5;
}
.pipeline-widget .highcharts-tooltip_wrapper h3 {
  font-size: 13px;
  color: #939ba0;
}
.pipeline-widget .highcharts-tooltip_wrapper h3 span {
  color: #545b62;
}
.pipeline-widget--light .pipeline-widget_chart-container {
  color: #ffffff;
}
.pipeline-widget--light .highcharts-tooltip_wrapper {
  background-color: #f7f7f7;
}
