/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.activity-grid--old_table .cell-category_icon {
  position: relative;
  top: 4px;
  font-size: 20px;
  color: #3498db;
}
.activity-grid--old_table .cell-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activity-grid--old_table .cell-title_text {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-grid--old_table .cell-title_toggle {
  line-height: 24px;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
}
.activity-grid--old_table .cell-title_toggle--open {
  transform: rotate(-180deg);
}
.activity-grid--old_table .cell-title_toggle:hover {
  background: #eeeeee;
}
.activity-grid--old_table .cell--text {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-grid--old_table .cell--disabled {
  color: #939ba0;
}
