.widget-unsubscribed {
  position: relative;
  height: 100%;
  padding: 20px 10px;
  color: #ffffff;
}
.widget-unsubscribed, .widget-unsubscribed_link {
  transition: 0.3s ease;
}
.widget-unsubscribed_warning {
  margin-bottom: 15px;
  font-size: 13px;
  text-transform: uppercase;
  color: #ec6a4c;
}
.widget-unsubscribed_title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.widget-unsubscribed_indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  padding: 6px 0 0 4px;
  background-color: #ec6a4c;
  color: #ffffff;
}
.widget-unsubscribed_indicator:before, .widget-unsubscribed_indicator:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 25px 0;
  border-color: transparent #ec6a4c transparent transparent;
}
.widget-unsubscribed_indicator:before {
  top: 0;
  left: -25px;
}
.widget-unsubscribed_indicator:after {
  top: 25px;
  right: 0;
}
.widget-unsubscribed_link {
  margin-left: 5px;
  color: #f1af0f;
  text-transform: uppercase;
  cursor: pointer;
}
.widget-unsubscribed_link--sent {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.widget-unsubscribed--light {
  color: #545b62;
}
.widget-unsubscribed--light .widget-unsubscribed_link {
  color: #297ac5;
}
