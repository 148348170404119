.search-page_filter {
  position: relative;
  padding: 0 24px 96px 0;
  width: 288px;
}
.search-page_filter-section {
  width: 264px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.search-page_filter-section:not(:first-child) {
  margin-top: 16px;
}
.search-page_filter-header {
  padding: 19.2px 16px;
  text-transform: uppercase;
  background-color: #f7f7f7;
}
.search-page_filter-content {
  padding: 16px;
}
.search-page_filter-content:not(:first-child) {
  border-top: 1px solid #eeeeee;
}
.search-page_filter-note {
  padding-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #939ba0;
}
.search-page_filter-reset {
  position: absolute;
  right: 38px;
  top: 12px;
}
