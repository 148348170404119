/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.removable-list-item {
  height: 40px;
  line-height: 40px;
  margin-top: 3px;
  border-radius: 3px;
  background-color: #eeeeee;
  color: #545b62;
  overflow: hidden;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.removable-list-item:hover .removable-list-item_icon {
  opacity: 1;
}
.removable-list-item:not(.removable-list-item--auto) .removable-list-item_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.removable-list-item:first-child {
  margin-top: 0;
}
.removable-list-item_inner {
  display: flex;
}
.removable-list-item_label {
  flex: 1;
  padding-left: 14px;
}
.removable-list-item_icon {
  opacity: 1;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s ease, color 0.3s ease;
}
.removable-list-item_icon:hover {
  color: #939ba0;
}
.removable-list-item_icon--remove {
  font-size: 8px;
}
.removable-list-item_icon--lock {
  font-size: 10px;
  opacity: 1;
}
.removable-list-item--locked {
  pointer-events: none;
}
.removable-list-item--small {
  height: 30px;
  line-height: 30px;
}
.removable-list-item--large {
  height: 60px;
  line-height: 60px;
}
.removable-list-item--auto {
  height: auto;
  line-height: initial;
}
.removable-list-item--auto .removable-list-item_label, .removable-list-item--auto .removable-list-item_icon {
  padding: 8px;
}
.removable-list-item--auto .removable-list-item_icon {
  display: flex;
}
.removable-list-item--auto .removable-list-item_icon :before {
  line-height: calc(40px - 16px);
}
.removable-list-item--enter, .removable-list-item--appear {
  opacity: 0;
  height: 0;
}
.removable-list-item--exit, .removable-list-item--enter-active, .removable-list-item--appear-active {
  opacity: 1;
  height: 40px;
}
.removable-list-item--exit.removable-list-item--small, .removable-list-item--enter-active.removable-list-item--small, .removable-list-item--appear-active.removable-list-item--small {
  height: 30px;
}
.removable-list-item--exit.removable-list-item--large, .removable-list-item--enter-active.removable-list-item--large, .removable-list-item--appear-active.removable-list-item--large {
  height: 60px;
}
.removable-list-item--enter-active, .removable-list-item--appear-active, .removable-list-item--exit-active, .removable-list-item--auto {
  transition-property: opacity, height, margin-top;
}
.removable-list-item--exit-active {
  opacity: 0;
  margin-top: 0;
}
.removable-list-item--exit-active, .removable-list-item--exit-active.removable-list-item--small, .removable-list-item--exit-active.removable-list-item--large {
  height: 0;
}
.removable-list-item--white {
  background-color: #ffffff;
}
