@import '../../../resources/styles/mixins/icons';
@import '../../../resources/styles/icons/variables';
@import '../../../resources/styles/variables';
@import '../../../resources/styles/icons/variables';
@import '../../../resources/styles/utils';

@mixin checkboxTheme($textColor, $checkColor, $backgroundColor, $checkedBackgroundColor) {
  .checkbox_label {
    color: $textColor;

    &:before {
      background-color: $backgroundColor;
      color: transparent;
    }
  }

  &.checkbox--selected {
    .checkbox_label:before {
      background-color: $checkedBackgroundColor;
      color: $checkColor;
    }
  }
}

.checkbox {
  padding: gux(0.5) 0;
  line-height: $line-height-medium;

  &_label {
    display: inline-block;
    white-space: nowrap;
    user-select: none;

    &:before {
      content: '';
      display: inline-block;
      margin-right: gux();
      width: 16px;
      height: 16px;
      padding-top: 1px;
      line-height: 16px;
      border-radius: $border-radius-small;
      font-size: $font-size-small;
      text-align: center;
      vertical-align: text-bottom;
      transition: background-color $transition-duration--default $transition-timing-function--default;
      cursor: pointer;
    }
  }

  &_input {
    display: none;
  }

  &--selected {
    .checkbox_label {
      @include q4-icon($q4i-checkmark-4pt);
    }
  }

  &--inline {
    display: inline-block;
    padding: 0 gux() 0 0;
  }

  &--medium {
    line-height: gux(4);
  }

  &--large {
    line-height: gux(5);
  }

  // themes
  &--light {
    @include checkboxTheme($light-slate, $white, $light-grey, $rain);
  }

  &--dark {
    @include checkboxTheme($muted-grey, $charcoal, $charcoal, $citrus);
  }

  &--white {
    @include checkboxTheme($light-slate, $white, $white, $rain);
  }

  &--steel {
    @include checkboxTheme($white, $light-slate, $steel, $white);
  }

  &--q4-blue {
    @include checkboxTheme($white, $light-slate, $q4-blue, $white);
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
