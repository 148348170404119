/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.text-input {
  position: relative;
}
.text-input--condensed {
  border-bottom: 1px solid #dddddd;
}
.text-input--not-labeled {
  margin-bottom: 8px;
}
.text-input_error-container {
  padding-top: 8px;
  display: flex;
  align-items: center;
}
.text-input_error-container-icon {
  width: 14px;
  height: 14px;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
}
.text-input_error-container-icon:before {
  font-size: 10px;
  line-height: 14px;
}
.text-input_error-container-message {
  padding-left: 6px;
  font-size: 11px;
}
.text-input_field {
  padding: 0 8px;
  width: 100%;
  line-height: 40px;
  min-height: 40px;
  border-radius: 3px;
}
.text-input_field[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.text-input_field[type=number] {
  -moz-appearance: textfield;
}
.text-input--readonly .text-input_field {
  cursor: default;
  user-select: none;
}
.text-input--light-grey .text-input_field {
  color: #545b62;
  background-color: #eeeeee;
}
.text-input--light-grey .text-input_field::placeholder {
  color: #939ba0;
}
.text-input--light-grey .text-input_field::-webkit-input-placeholder {
  color: #939ba0;
}
.text-input--light-grey .text-input_field::-moz-placeholder {
  color: #939ba0;
}
.text-input--light-grey .text-input_field:-moz-placeholder {
  color: #939ba0;
}
.text-input--light-grey .text-input_field:-ms-input-placeholder {
  color: #939ba0;
}
.text-input--light-grey .text-input_field::-ms-input-placeholder {
  color: #939ba0;
}
.text-input--light-grey.text-input--readonly .text-input_field {
  color: #939ba0;
}
.text-input--light-grey.text-input--error .text-input_field {
  background-color: #fbddd6;
}
.text-input--light-grey.text-input--error .text-input_field::placeholder {
  color: #f3a491;
}
.text-input--light-grey.text-input--error .text-input_field::-webkit-input-placeholder {
  color: #f3a491;
}
.text-input--light-grey.text-input--error .text-input_field::-moz-placeholder {
  color: #f3a491;
}
.text-input--light-grey.text-input--error .text-input_field:-moz-placeholder {
  color: #f3a491;
}
.text-input--light-grey.text-input--error .text-input_field:-ms-input-placeholder {
  color: #f3a491;
}
.text-input--light-grey.text-input--error .text-input_field::-ms-input-placeholder {
  color: #f3a491;
}
.text-input--light-grey .text-input_error-container-icon {
  background-color: #ec6a4c;
}
.text-input--light-grey .text-input_error-container-message {
  color: #ec6a4c;
}
.text-input--pale-grey .text-input_field {
  color: #545b62;
  background-color: #f1f1f1;
}
.text-input--pale-grey .text-input_field::placeholder {
  color: #939ba0;
}
.text-input--pale-grey .text-input_field::-webkit-input-placeholder {
  color: #939ba0;
}
.text-input--pale-grey .text-input_field::-moz-placeholder {
  color: #939ba0;
}
.text-input--pale-grey .text-input_field:-moz-placeholder {
  color: #939ba0;
}
.text-input--pale-grey .text-input_field:-ms-input-placeholder {
  color: #939ba0;
}
.text-input--pale-grey .text-input_field::-ms-input-placeholder {
  color: #939ba0;
}
.text-input--pale-grey.text-input--readonly .text-input_field {
  color: #939ba0;
}
.text-input--pale-grey.text-input--error .text-input_field {
  background-color: #fbddd6;
}
.text-input--pale-grey.text-input--error .text-input_field::placeholder {
  color: #f3a491;
}
.text-input--pale-grey.text-input--error .text-input_field::-webkit-input-placeholder {
  color: #f3a491;
}
.text-input--pale-grey.text-input--error .text-input_field::-moz-placeholder {
  color: #f3a491;
}
.text-input--pale-grey.text-input--error .text-input_field:-moz-placeholder {
  color: #f3a491;
}
.text-input--pale-grey.text-input--error .text-input_field:-ms-input-placeholder {
  color: #f3a491;
}
.text-input--pale-grey.text-input--error .text-input_field::-ms-input-placeholder {
  color: #f3a491;
}
.text-input--pale-grey .text-input_error-container-icon {
  background-color: #ec6a4c;
}
.text-input--pale-grey .text-input_error-container-message {
  color: #ec6a4c;
}
.text-input--dark-slate .text-input_field {
  color: #ffffff;
  background-color: #22272b;
}
.text-input--dark-slate .text-input_field::placeholder {
  color: #646d75;
}
.text-input--dark-slate .text-input_field::-webkit-input-placeholder {
  color: #646d75;
}
.text-input--dark-slate .text-input_field::-moz-placeholder {
  color: #646d75;
}
.text-input--dark-slate .text-input_field:-moz-placeholder {
  color: #646d75;
}
.text-input--dark-slate .text-input_field:-ms-input-placeholder {
  color: #646d75;
}
.text-input--dark-slate .text-input_field::-ms-input-placeholder {
  color: #646d75;
}
.text-input--dark-slate.text-input--readonly .text-input_field {
  color: #646d75;
}
.text-input--dark-slate.text-input--error .text-input_field {
  background-color: #fbddd6;
}
.text-input--dark-slate.text-input--error .text-input_field::placeholder {
  color: #f3a491;
}
.text-input--dark-slate.text-input--error .text-input_field::-webkit-input-placeholder {
  color: #f3a491;
}
.text-input--dark-slate.text-input--error .text-input_field::-moz-placeholder {
  color: #f3a491;
}
.text-input--dark-slate.text-input--error .text-input_field:-moz-placeholder {
  color: #f3a491;
}
.text-input--dark-slate.text-input--error .text-input_field:-ms-input-placeholder {
  color: #f3a491;
}
.text-input--dark-slate.text-input--error .text-input_field::-ms-input-placeholder {
  color: #f3a491;
}
.text-input--dark-slate .text-input_error-container-icon {
  background-color: #ec6a4c;
}
.text-input--dark-slate .text-input_error-container-message {
  color: #ec6a4c;
}
.text-input--white .text-input_field {
  color: #545b62;
  background-color: #ffffff;
}
.text-input--white .text-input_field::placeholder {
  color: #939ba0;
}
.text-input--white .text-input_field::-webkit-input-placeholder {
  color: #939ba0;
}
.text-input--white .text-input_field::-moz-placeholder {
  color: #939ba0;
}
.text-input--white .text-input_field:-moz-placeholder {
  color: #939ba0;
}
.text-input--white .text-input_field:-ms-input-placeholder {
  color: #939ba0;
}
.text-input--white .text-input_field::-ms-input-placeholder {
  color: #939ba0;
}
.text-input--white.text-input--readonly .text-input_field {
  color: #939ba0;
}
.text-input--white.text-input--error .text-input_field {
  background-color: #fbddd6;
}
.text-input--white.text-input--error .text-input_field::placeholder {
  color: #f3a491;
}
.text-input--white.text-input--error .text-input_field::-webkit-input-placeholder {
  color: #f3a491;
}
.text-input--white.text-input--error .text-input_field::-moz-placeholder {
  color: #f3a491;
}
.text-input--white.text-input--error .text-input_field:-moz-placeholder {
  color: #f3a491;
}
.text-input--white.text-input--error .text-input_field:-ms-input-placeholder {
  color: #f3a491;
}
.text-input--white.text-input--error .text-input_field::-ms-input-placeholder {
  color: #f3a491;
}
.text-input--white .text-input_error-container-icon {
  background-color: #ec6a4c;
}
.text-input--white .text-input_error-container-message {
  color: #ec6a4c;
}
