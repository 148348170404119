@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';

// date range field
.ant-picker.date-range-picker {

  margin-bottom: $padding-vertical-xxsmall;

  .ant-picker {

    &-input:first-child {
      max-width: 110px;
    }

    &-suffix,
    &-clear {
      position: absolute;
      right: 8px;
    }

    &-range-separator {
      padding: 0;
    }
  }

  &--sized {
    .ant-picker-input {
      width: 72px;
    }
  }

  .ant-picker-range-separator {
    padding: 0 gux(0.5);
  }

  .ant-picker-active-bar {
    display: none;
  }
}

@mixin dateRangePickerModalTheme(
  $borderColor
) {
  .ant-picker-panels {
    .ant-picker-panel:first-child {
      border-right-color: $borderColor;
    }
  }
}

// date range modal
.ant-picker-dropdown.date-range-picker-modal {
  padding: 0;

  .ant-picker-range-arrow {
    display: none;
  }

  .ant-picker-panels {
    .ant-picker-panel:first-child {
      border-right-width: 1px;
      border-right-style: solid;
    }

    .ant-picker-header .ant-picker-header-view {
      .ant-picker-month-btn,
      .ant-picker-year-btn {
        pointer-events: none;
      }
    }
  }

  // themes
  &--white {
    @include dateRangePickerModalTheme(rgba(0, 0, 0, .1));
  }

  &--light {
    @include dateRangePickerModalTheme(rgba(0, 0, 0, .1));
  }

  &--rain {
    @include dateRangePickerModalTheme(rgba(0, 0, 0, .1));
  }

  &--ink {
    @include dateRangePickerModalTheme(rgba(0, 0, 0, .1));
  }

  &--dark {
    @include dateRangePickerModalTheme(rgba(0, 0, 0, .1));
  }
}
