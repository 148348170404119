/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.modal-root {
  z-index: 55 !important;
}
.modal-root .modal {
  display: block;
  max-height: 95vh;
  margin: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: visible;
}
.modal-root .modal_wrap {
  position: relative;
}
.modal-root .modal_exit {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 13px;
  transition: color 0.3s ease;
  cursor: pointer;
}
.modal-root .modal_exit:hover {
  color: rgba(84, 91, 98, 0.3);
}
.modal-root .modal_header, .modal-root .modal_footer {
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.modal-root .modal_header {
  height: 100px;
  color: #545b62;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
}
.modal-root .modal_header--base {
  height: 80px;
}
.modal-root .modal_badge {
  position: absolute;
  top: 30px;
  left: 20px;
  width: 50px;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #1a1d21;
}
.modal-root .modal_badge ~ * {
  margin-left: 50px;
}
.modal-root .modal_title {
  font-size: 20px;
  color: #1a1d21;
}
.modal-root .modal_subtitle {
  margin-top: 5px;
  font-size: 13px;
  color: #939ba0;
}
.modal-root .modal_error {
  position: relative;
  top: -20px;
  color: #ec6a4c;
}
.modal-root .modal_note {
  margin-top: 20px;
  color: #939ba0;
}
.modal-root .modal_footer {
  justify-content: space-between;
  height: 80px;
  border-radius: 0 0 8px 8px;
  background-color: #2a3035;
  transition: opacity 0.3s ease;
}
.modal-root .modal_actions .checkbox {
  display: inline-block;
  color: #ffffff;
}
.modal-root .modal_actions .checkbox + .button {
  margin-left: 16px;
}
.modal-root .modal_actions .button + .button {
  margin-left: 8px;
}
.modal-root .modal_chin {
  height: 30px;
  background-color: #ffffff;
  border-top: 1px solid #f1f1f1;
  border-radius: 0 0 8px 8px;
}
.modal-root .modal--scrollable .react-scrollbar_track {
  opacity: 1;
  visibility: visible;
}
.modal-root .modal--content-background .modal_content {
  background-color: #f1f1f1;
}
.modal-root .modal--narrow {
  width: 400px;
}
.modal-root .modal--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  margin: 0;
  border-radius: 0;
}
.modal-root .modal--fullscreen .modal_header {
  height: 80px;
  padding: 0 30px;
  border-radius: 0;
  background-color: #0f5ca3;
}
.modal-root .modal--fullscreen .modal_title-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.modal-root .modal--fullscreen .modal_badge {
  position: static;
  margin: 0 20px 0 0;
  font-size: 32px;
  color: #ffffff;
  width: auto;
}
.modal-root .modal--fullscreen .modal_badge ~ .modal_title {
  margin-left: 0;
}
.modal-root .modal--fullscreen .modal_badge ~ .modal_subtitle {
  margin-left: 50px;
  color: #ffffff;
}
.modal-root .modal--fullscreen .modal_title, .modal-root .modal--fullscreen .modal_exit {
  color: #ffffff;
}
.modal-root .modal--fullscreen .modal_exit {
  top: 32px;
  right: 32px;
  font-size: 16px;
}
.modal-root .modal--fullscreen .modal_content {
  height: calc(100% - 160px);
  overflow-y: scroll;
}
.modal-root .modal--fullscreen .modal_content--footerless {
  height: calc(100% - 80px);
}
.modal-root .modal--fullscreen .modal_component {
  height: 100%;
}
.modal-root .modal--fullscreen .modal_footer {
  border-radius: 0;
}
.modal-root .modal--loading .spinner {
  border-radius: 5px;
  overflow: hidden;
}
.modal-root .modal--loading.modal--fullscreen .spinner {
  border-radius: 0;
}
.modal-root--events-widget .modal_header {
  height: unset;
  min-height: 100px;
}
.modal-root--events-widget .modal_header .modal_title {
  margin-top: 15px;
}
.modal-root--centered .modal {
  text-align: center;
}
.modal-root--centered .modal_title-container {
  width: calc(100% - 20px);
}
.modal-root--centered .modal_title {
  font-size: 30px;
}
.modal-root--centered .modal_footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.modal-root--centered .modal_actions--left:not(:empty) {
  margin-right: 10px;
}
.modal-root--marketing .modal .message_icon {
  position: relative;
  top: 3px;
  padding: 0 4px;
  color: #297ac5;
  font-size: 16px;
}
.modal-root--marketing .modal iframe {
  margin-top: 30px;
}
.modal-root--trial .modal_header {
  height: 90px;
  color: #1a1d21;
  padding: 40px 20px 0;
}
.modal-root--trial .modal_title-container {
  width: 100%;
}
.modal-root--trial .modal_content {
  font-size: 16px;
}
.modal-root--trial .modal_exit {
  display: none;
}
