/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.select-list__item {
  display: flex;
  align-items: center;
  padding: 14px 14px;
}
.select-list__item-multi {
  padding: 4px 14px;
  justify-content: space-between;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select-list__item-multi:after {
  font-family: "iris";
  content: "\e984";
}
.select-list__item-multi_label {
  padding-right: 14px;
  width: calc(100% - 18px);
  word-break: break-word;
  white-space: normal;
}
.select-list__item-multi:after {
  width: 18px;
  height: 18px;
  padding-top: 1px;
  line-height: 18px;
  border-radius: 3px;
  font-size: 11px;
  text-align: center;
  color: transparent;
  vertical-align: text-bottom;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}
.select-list__item-multi--rain:after {
  background-color: #eeeeee;
}
.select-list__item-multi--rain.select-list__item-multi--checked:after {
  background-color: #2a3035;
  color: #ffffff;
}
.select-list__item-multi--whites:after {
  background-color: #eeeeee;
}
.select-list__item-multi--whites.select-list__item-multi--checked:after {
  background-color: #297ac5;
  color: #ffffff;
}
.select-list__item--action {
  padding: 0;
}
.select-list__item--action button {
  display: flex;
  align-items: center;
  border-radius: 0;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
}
.select-list__item--action i {
  font-size: 24px;
  margin-right: 10px;
}

.is-highlighted {
  background: orange;
}
