/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.lane-header {
  height: 62px;
  padding: 10px;
}
.lane-header_row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
}
.lane-header_row + .lane-header_row:not(.lane-header_row--target-filter):not(.lane-header_row--add-deal) {
  padding-top: 4px;
}
.lane-header_row--controls {
  height: 56px;
}
.lane-header_row--target-filter {
  height: 32px;
  margin: 10px 0 4px 0;
}
.lane-header_row--add-deal {
  margin: 0 4px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  border-radius: 3px;
  background-color: #2a3035;
  cursor: pointer;
  width: calc(100% - 8px);
}
.lane-header--target {
  padding: 0;
  height: 102px;
}
.lane-header_title {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.lane-header_title--add-deal {
  color: #f1af0f;
  line-height: 40px;
  text-align: center;
}
.lane-header_count {
  color: #f1af0f;
}
.lane-header_percentage:before {
  content: "|";
  position: relative;
  top: -2px;
  padding: 0 4px;
  font-size: 10px;
}
.lane-header_percentage, .lane-header_value {
  position: relative;
  color: #939ba0;
}
.lane-header_percentage:hover .tooltip, .lane-header_value:hover .tooltip {
  display: block;
}
.lane-header .tooltip {
  display: none;
}
.lane-header_expand-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 60px;
  border-radius: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  transition: background-color 0.3s ease 0.2s;
}
.lane-header_target-title {
  cursor: pointer;
  position: absolute;
  left: 45px;
  top: 10px;
  text-transform: uppercase;
  color: #ffffff;
  animation: fadein-opacity 1.5s;
}
.lane-header_target-count {
  margin-top: 4px;
  min-width: 22px;
  line-height: 22px;
  padding: 0 4px;
  text-align: center;
  color: #22272b;
  background-color: #f1af0f;
  border-radius: 25px;
  transition: background-color 0.4s ease-in;
}
.lane-header_icon--collapse {
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 16px;
  color: #f1af0f;
  animation: fadein-opacity 0.75s;
}
.lane-header_icon--collapse:before {
  line-height: 60px;
}
.lane-header_icon--target {
  font-size: 24px;
  color: #ffffff;
  transition: color 0.4s ease-in;
}
.lane-header_icon--lost {
  padding-right: 10px;
  color: #ffffff;
}
.lane-header_link {
  color: #ffffff;
}
