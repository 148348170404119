@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/generic';
@import '../../../../resources/styles/utils';

.tearsheet_header {

  &-title,
  &-content {
    display: flex;
    align-items: center;
  }

  &-icon {
    font-size: $font-size-medium;
    padding-right: gux();
    color: $q4-blue;

    &--fund {
      color: $teal;
    }

    &--institution {
      color: $spice;
    }
  }

  &-name {
    max-width: calc(100% - 24px);
    font-size: $font-size-large;
    font-weight: normal;
    color: $q4-blue;
    @include truncate();
  }

  &-detail {
    padding-top: gux(0.5);

    &--address {
      padding-left: gux(2);
      color: $silver;
    }

    &--position {
      color: $silver;
    }

    &--gdpr {
      padding-top: gux(0.5);
      color: $cherry;
    }
  }
}
