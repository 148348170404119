@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/all';

.eventsTranscripts-page_sidebar {
  &.sidebar {
    .sidebar_header {

      padding: 16px 16px 0px 16px;

      .section-tabs {
        &_item {
          letter-spacing: 0.5px;
        }
      }

      .clear-button {
        padding-right: gux(2);
        cursor: pointer;
        color: $white;
      }
    }
    .sidebar_footer {
      justify-content: right;
    }
  }
}
