/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.error-page {
  min-height: calc(100vh - 80px - 150px);
}
.error-page_badge {
  display: block;
  margin: auto;
  width: 70px;
  height: 120px;
  text-align: center;
  background-color: #22272b;
  border-radius: 0 0 35px 35px;
}
.error-page_badge:before {
  font-size: 38px;
  line-height: 168px;
  color: #f1af0f;
}
.error-page_message {
  margin-top: 60px;
  font-size: 16px;
  text-align: center;
  color: #e0e0e0;
}
