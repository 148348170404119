/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.dashboard-manager {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  color: #ffffff;
  overflow: hidden;
  z-index: 50;
}
.dashboard-manager, .dashboard-manager_inner, .dashboard-manager_action, .dashboard-manager_mask {
  transition: 0.3s ease;
}
.dashboard-manager_inner, .dashboard-manager_mask {
  position: absolute;
  top: 0;
}
.dashboard-manager_inner {
  right: 0;
  left: 0;
  height: 260px;
  padding-top: 10px;
  background-color: #2a3035;
  z-index: 51;
}
.dashboard-manager_header {
  padding: 0 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.dashboard-manager_title, .dashboard-manager_back {
  font-size: 16px;
  line-height: 60px;
}
.dashboard-manager_back {
  color: #f1af0f;
  cursor: pointer;
}
.dashboard-manager_dashboards, .dashboard-manager_new {
  display: inline-block;
  vertical-align: middle;
}
.dashboard-manager_dashboards {
  padding-left: 20px;
  list-style: none;
}
.dashboard-manager_dashboard, .dashboard-manager_new {
  min-height: 160px;
  padding: 16px;
  background-color: #121517;
  border-radius: 8px;
  text-align: center;
}
.dashboard-manager_dashboard {
  display: inline-block;
  width: 150px;
  margin-right: 10px;
}
.dashboard-manager_dashboard-icon {
  color: #f1af0f;
  cursor: pointer;
}
.dashboard-manager_dashboard-icon i {
  font-size: 30px;
  line-height: 55px;
}
.dashboard-manager_label {
  max-width: 115px;
  margin: 0 auto 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.dashboard-manager_action {
  display: inline-block;
  width: 50px;
  height: 35px;
  background-color: #1a1d21;
  font-size: 16px;
  color: #f1af0f;
  cursor: pointer;
}
.dashboard-manager_action, .dashboard-manager_action i {
  line-height: 35px;
}
.dashboard-manager_action:hover {
  background-color: #f1af0f;
  color: #22272b;
}
.dashboard-manager_action--disabled, .dashboard-manager_action--disabled:hover {
  cursor: default;
  pointer-events: none;
  color: #545b62;
  background-color: #1a1d21;
}
.dashboard-manager_action:first-child {
  border-radius: 3px 0 0 3px;
}
.dashboard-manager_action:last-child {
  border-radius: 0 3px 3px 0;
}
.dashboard-manager_action:first-child:last-child {
  border-radius: 3px;
}
.dashboard-manager_action:first-child:not(:last-child) {
  border-right: 1px solid #121517;
}
.dashboard-manager_new {
  width: 100px;
  color: #f1af0f;
}
.dashboard-manager_new .button {
  margin: 30px 0 20px;
}
.dashboard-manager_new .button .tooltip {
  opacity: 0;
}
.dashboard-manager_new-label {
  text-transform: uppercase;
}
.dashboard-manager_new--disabled .dashboard-manager_new-label {
  color: #545b62;
}
.dashboard-manager_new--disabled .button {
  cursor: default;
}
.dashboard-manager_new--disabled .button, .dashboard-manager_new--disabled .button:hover, .dashboard-manager_new--disabled .button:focus {
  background-color: #1a1d21;
  color: #545b62;
}
.dashboard-manager_new--disabled .button:hover .tooltip {
  opacity: 1;
}
.dashboard-manager_mask {
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  z-index: 50;
}
.dashboard-manager--hidden {
  height: 0;
}
.dashboard-manager--hidden .dashboard-manager_inner {
  top: -265px;
}
.dashboard-manager--hidden .dashboard-manager_mask {
  opacity: 0;
}
