.report-filters_search + .report-filters_body {
  margin-top: 8px;
  position: relative;
}
.report-filters_search {
  z-index: 1;
}
.report-filters_body {
  z-index: 0;
}
.report-filters--disabled {
  opacity: 0.5;
  pointer-events: none;
}
