@import '../../../../../resources/styles/variables';
@import '../../../../../resources/styles/mixins/generic';
@import '../../../../../resources/styles/utils';

$height: 32px;

.entity-ownership_peer {
  padding-top: gux();
  display: flex;

  &-select {
    width: 220px;

    &_item {
      display: block;
      @include truncate();

      &-symbol {
        margin-right: gux(0.5)
      }

      &-exchange {
        color: $silver;
      }

      &-name {
        color: $silver;
        @include divider();
      }
    }
  }

  &-item {
    margin-left: gux();
    height: $height;
    max-width: 160px;
    background-color: $gunpowder;
    border-radius: $border-radius-small;
    display: flex;
    align-items: center;

    &_label {
      color: $ice;
      padding-left: gux();
      @include truncate();

      &-divider {
        @include divider();
      }
    }

    &_delete {
      cursor: pointer;
      width: $height;
      font-size: $font-size-table;
      text-align: center;

      &:before {
        line-height: $height;
      }
    }
  }
}
