@import '../../resources/styles/variables';
@import '../../resources/styles/utils';

.targeting-page {
  &_tabs {
    > .section-tab_nav {
      padding-left: gux(2);
    }

    &_tab {
      background-color: $white;
    }
  }
}
