.splash-screen {
  height: 100vh;
  font-weight: 300;
  background-color: #0f5ca3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.splash-screen_logo {
  margin: 0 auto;
  font-size: 45px;
  color: #ffffff;
}
.splash-screen_text {
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  margin: 60px auto;
  width: 290px;
}
.splash-screen_loading {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 36px;
  color: #ffffff;
  padding: 0 30px;
  background-color: rgba(0, 0, 0, 0.1);
}
