.targeting-toolbar_result {
  font-size: 20px;
  color: #ffffff;
}
.targeting-toolbar_result-label {
  vertical-align: middle;
}
.targeting-toolbar_result-count {
  vertical-align: middle;
  padding-left: 4px;
}
.targeting-toolbar_filter-button:not(:first-child) {
  margin-left: 14px;
}
