/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.estimates-overview {
  position: relative;
}
.estimates-overview .overview {
  padding: 40px 30px;
  background-color: #ffffff;
}
.estimates-overview .overview_inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.estimates-overview .overview_section:first-child {
  padding-right: 14px;
}
.estimates-overview .overview_section:last-child {
  padding-left: 14px;
}
.estimates-overview .overview_section:first-child:last-child {
  padding-right: 0;
  padding-left: 0;
}
.estimates-overview .overview_section:not(.overview_section--contained) {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.estimates-overview_box {
  min-width: 300px;
  padding: 25px 35px 20px;
  border-radius: 3px;
  background-color: #f1f1f1;
}
.estimates-overview_box-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.estimates-overview_box-row > div:first-child {
  min-width: 105px;
}
.estimates-overview_box-row:not(:first-child) {
  margin-top: 10px;
}
.estimates-overview_box h3, .estimates-overview_box h4, .estimates-overview_box h5 {
  margin: 0;
  text-transform: uppercase;
}
.estimates-overview_box h3, .estimates-overview_box h4 {
  font-weight: 300;
  color: #2a3035;
}
.estimates-overview_box h3 {
  min-width: 95px;
  margin-right: 10px;
  font-size: 50px;
  line-height: 50px;
  font-weight: 300;
}
.estimates-overview_box h4 {
  font-size: 30px;
  line-height: 30px;
}
.estimates-overview_box h5 {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  color: #939ba0;
}
.estimates-overview_table {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  border-radius: 3px 3px 0 0;
  background-color: #3498db;
  height: 40px;
  line-height: 40px;
}
.estimates-overview_table-cell {
  width: 300px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.estimates-overview_table-cell:first-child {
  background-color: #2f89c5;
}
.estimates-overview_date {
  margin-top: 1px;
}
.estimates-overview .data-table_head th.data-table_cell {
  background-color: #f1f1f1;
  text-transform: capitalize;
  color: #545b62;
}
.estimates-overview .data-table_head th.data-table_cell:nth-child(even) {
  background-color: #e0e0e0;
}
