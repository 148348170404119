.targeting-page_sidebar.sidebar--tabs .sidebar_header {
  background-color: #297ac5;
}
.targeting-page_sidebar.sidebar--tabs .sidebar_header .section-tabs_item {
  padding: 0 16px;
  letter-spacing: 0.5px;
}
.targeting-page_sidebar.sidebar--tabs .sidebar_header .clear-button {
  padding-right: 16px;
  cursor: pointer;
  color: #ffffff;
}
