.iframe-widget-container {
  height: 100%;
  width: 100%;
}
.iframe-widget-container_header {
  height: 35px;
  line-height: 35px;
  padding: 0 14px;
  border-bottom: 1px solid #2a3035;
  text-transform: uppercase;
  color: #ffffff;
  transition: 0.3s ease;
}
.iframe-widget-container_frame {
  height: calc(100% - 35px);
}
.iframe-widget-container_frame--full {
  height: 100%;
}
.iframe-widget-container_frame iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.iframe-widget-container--light .iframe-widget-container_header {
  border-bottom: 1px solid #e0e0e0;
  color: #545b62;
}
