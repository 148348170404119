.report-wizard_header-title span, .report-wizard_header-details, .report-wizard_stage {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.report-wizard_header {
  height: 200px;
  padding: 35px 0 0;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}
.report-wizard_header-title {
  position: relative;
  height: 40px;
  margin-bottom: 14px;
  font-size: 30px;
  line-height: 30px;
}
.report-wizard_header-title span {
  position: absolute;
  display: block;
  width: 100%;
}
.report-wizard_header-details {
  font-size: 13px;
  text-transform: uppercase;
}
.report-wizard_header-details span + span {
  margin-left: 14px;
}
.report-wizard_header--1 .report-wizard_header-title span:first-child, .report-wizard_header--2 .report-wizard_header-title span:nth-child(2), .report-wizard_header--2 .report-wizard_header-details, .report-wizard_header--3 .report-wizard_header-title span:nth-child(3), .report-wizard_header--3 .report-wizard_header-details, .report-wizard_header--4 .report-wizard_header-title span:last-child, .report-wizard_header--4 .report-wizard_header-details {
  opacity: 1;
}
.report-wizard_stages {
  position: relative;
  height: calc(95vh - 20px - 200px - 80px);
  max-height: 350px;
}
.report-wizard_stages--1 .report-wizard_stage:first-child, .report-wizard_stages--2 .report-wizard_stage:nth-child(2), .report-wizard_stages--3 .report-wizard_stage:nth-child(3), .report-wizard_stages--4 .report-wizard_stage:last-child {
  opacity: 1;
  pointer-events: initial;
}
.report-wizard_stage {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.report-wizard_stage--scrolling {
  background-color: #eeeeee;
}
.report-wizard_form {
  padding: 30px 30px;
}
.report-wizard_form > * {
  position: relative;
  margin-bottom: 20px;
  z-index: 5;
}
