/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.contact-grid_table .cell {
  display: flex;
  align-items: center;
}
.contact-grid_table .cell_title {
  max-width: 90%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-grid_table .cell_value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-grid_table .cell_icon {
  font-size: 20px;
}
.contact-grid_table .cell_icon--target {
  color: #1abc9c;
}
.contact-grid_table .cell_icon--deal {
  color: #939ba0;
}
.contact-grid_table .cell--email a {
  color: #545b62;
  max-width: 90%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
