@use 'sass:math';
@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/button';
@import '../../../../resources/styles/utils';

$actionHeight: 32px;

.entity-ownership_chart {
  width: 100%;
  height: 100%;

  visibility: hidden;
  position: absolute;

  &--active {
    visibility: visible;
  }

  &-legend {
    width: 100%;
    height: $actionHeight;
    font-size: $font-size-table;
    display: flex;
    justify-content: space-between;

    &--position {
      flex: 1;
      position: relative;
      left: 16px;
      line-height: $actionHeight;
      color: $silver;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: calc(50% - 3px);
        left: -16px;
        background-color: $q4-blue;
        border-radius: $border-radius-circle;
      }
    }

    // button overrides
    .button {
      font-size: $font-size-table;
      text-transform: capitalize;

      & ~ .button:not(.button--hidden) {
        margin-left: gux(math.div(1, 2));
      }

      &--stock {
        @include color-attributes($charcoal, $surf, $black-smoke, $surf);
      }

      &--activity {
        @include color-attributes($charcoal, $citrus, $black-smoke, $citrus);
      }

      &--events {
        @include color-attributes($charcoal, $teal, $black-smoke, $teal);
      }

      &--news {
        @include color-attributes($charcoal, $tangerine, $black-smoke, $tangerine);
      }

      &--off,
      &--off:hover,
      &--disabled {
        color: $silver;
      }
    }
  }

  &-label {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: $border-radius-circle;

    &--stock {
      background-color: $q4-blue;
    }

    &--position {
      background-color: $surf;
    }
  }

  // highcharts overwrites
  .highcharts {
    &-axis-labels {
      z-index: $z-index--base - 1;
    }

    &-column-series text {
      tspan {
        font-weight: normal;

        &:last-of-type {
          fill: $silver;
        }
      }
    }

    &-tooltip {
      pointer-events: auto;

      .chart_tip {
        width: 200px;
        display: block;
        white-space: normal;

        &_header {
          color: $silver;
          display: block;
          margin-bottom: gux(math.div(1, 2));
        }

        &_body {
          color: $light-slate;
          margin-bottom: gux(math.div(1, 2));
        }
      }

      path {
        fill: $white;
        stroke: $white;
        stroke-width: 0;
      }
    }
  }
}
