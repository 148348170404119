.estimates-panel {
  padding-left: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.estimates-panel_item {
  font-size: 11px;
  line-height: 13px;
  padding: 0 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.estimates-panel_item--year {
  margin-left: 0;
}
.estimates-panel_value {
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;
  padding-right: 8px;
  text-align: right;
}
.estimates-panel_value--up {
  color: #1abc9c;
}
.estimates-panel_value--down {
  color: #ec6a4c;
}
.estimates-panel_label {
  white-space: nowrap;
  width: 100%;
  color: #939ba0;
}
.estimates-panel_info {
  margin-top: 3px;
  text-align: left;
}
.estimates-panel--small .estimates-panel_value {
  font-size: 26px;
  line-height: 40px;
}
.estimates-panel--narrow {
  display: inline-block;
}
.estimates-panel--narrow.estimates-panel--medium .estimates-panel_value {
  font-size: 26px;
  line-height: 32px;
}
.estimates-panel--narrow.estimates-panel--small .estimates-panel_value {
  width: 90px;
  font-size: 20px;
  line-height: 32px;
}
.estimates-panel--narrow .estimates-panel_value {
  width: 100px;
}
.estimates-panel--narrow .estimates-panel_item {
  padding: 0;
}
