.report-fields {
  display: flex;
  flex-direction: column;
  color: #545b62;
}
.report-fields_search {
  margin-top: 8px;
}
.report-fields_group + .report-fields_group {
  margin-top: 24px;
}
.report-fields_group-title {
  font-size: 13px;
  text-transform: capitalize;
  line-height: 20px;
}
.report-fields_group-icon {
  font-size: 16px;
  margin-right: 8px;
}
.report-fields_group-icon--institution, .report-fields_group-icon--shareholderid, .report-fields_group-icon--institutionSurveillance {
  color: #ec6a4c;
}
.report-fields_group-icon--fundShareholderid, .report-fields_group-icon--fundSurveillance, .report-fields_group-icon--fund {
  color: #1abc9c;
}
.report-fields_group-icon--contact {
  color: #3498db;
}
.report-fields_group-icon--activity {
  color: #aa2d40;
}
.report-fields_group-icon--stock {
  color: #6a3476;
}
.report-fields_indicator {
  margin-left: 8px;
  color: #939ba0;
}
.report-fields_sub-group {
  margin-top: 3px;
  padding: 0 10px 10px 14px;
  border-radius: 3px;
  background-color: #ffffff;
}
.report-fields_sub-group-title {
  line-height: 30px;
}
.report-fields_sub-group, .report-fields_sub-group .removable-list-item {
  position: relative;
}
.report-fields_sub-group:before, .report-fields_sub-group .removable-list-item:before {
  content: "";
  position: absolute;
  background-color: #dddddd;
}
.report-fields_sub-group:before {
  top: 33px;
  left: 17px;
  bottom: 25px;
  width: 1px;
}
.report-fields_sub-group .removable-list-item {
  margin-left: 24px;
  overflow: visible;
}
.report-fields_sub-group .removable-list-item:before {
  top: 50%;
  left: -21px;
  width: 12px;
  height: 1px;
}
