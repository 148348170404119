.search-page_toolbar {
  height: 38px;
  display: flex;
  justify-content: space-between;
}
.search-page_toolbar-counter {
  font-size: 16px;
  color: #939ba0;
}
.search-page_toolbar-counter--term {
  color: #545b62;
}
