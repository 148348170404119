/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.top-buyers-sellers {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.top-buyers-sellers, .top-buyers-sellers_item, .top-buyers-sellers_header, .top-buyers-sellers_footer {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.top-buyers-sellers_header {
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
}
.top-buyers-sellers_header--break .top-buyers-sellers_title {
  display: block;
}
.top-buyers-sellers_title {
  cursor: pointer;
  display: inline;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 10px 0 0;
}
.top-buyers-sellers_details {
  text-transform: capitalize;
  color: #939ba0;
}
.top-buyers-sellers_items {
  padding: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.top-buyers-sellers_items--break {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.top-buyers-sellers_items--break .top-buyers-sellers_buyers {
  margin: 0 0 10px;
}
.top-buyers-sellers_items--break .top-buyers-sellers_buyers, .top-buyers-sellers_items--break .top-buyers-sellers_sellers {
  width: 100%;
}
.top-buyers-sellers_items--break .top-buyers-sellers_item {
  height: 25px;
  line-height: 25px;
}
.top-buyers-sellers_content {
  height: calc(100% - 35px - 25px);
}
.top-buyers-sellers_footer {
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
  color: #939ba0;
}
.top-buyers-sellers_buyers, .top-buyers-sellers_sellers {
  width: calc(50% - 2px);
}
.top-buyers-sellers_buyers {
  margin-right: 2px;
}
.top-buyers-sellers_sellers {
  margin-left: 2px;
}
.top-buyers-sellers_item {
  margin-bottom: 1px;
  padding: 0 10px;
  height: 20px;
  background-color: #22272b;
  line-height: 20px;
  cursor: pointer;
}
.top-buyers-sellers_item:first-child {
  border-radius: 3px 3px 0 0;
}
.top-buyers-sellers_item:last-child {
  border-radius: 0 0 3px 3px;
}
.top-buyers-sellers_item:not(.top-buyers-sellers_item--detailed) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.top-buyers-sellers_item:not(.top-buyers-sellers_item--detailed) .top-buyers-sellers_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -moz-box-flex: 2;
  -moz-flex: 2;
  -ms-flex: 2;
  flex: 2;
}
.top-buyers-sellers_item:not(.top-buyers-sellers_item--detailed) .top-buyers-sellers_prev {
  display: none;
}
.top-buyers-sellers_item:not(.top-buyers-sellers_item--detailed) .top-buyers-sellers_change {
  max-width: 80px;
  text-align: right;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.top-buyers-sellers_item--tall {
  height: 43px;
  line-height: 43px;
}
.top-buyers-sellers_item--detailed {
  position: relative;
  height: 75px;
  padding: 20px 10px 16px;
}
.top-buyers-sellers_item--detailed .top-buyers-sellers_name {
  position: absolute;
  left: 10px;
  bottom: 16px;
  width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.top-buyers-sellers_item--detailed .top-buyers-sellers_prev {
  display: inline;
  color: #939ba0;
}
.top-buyers-sellers_item--detailed .top-buyers-sellers_change {
  font-size: 25px;
  font-weight: 300;
  margin-right: 10px;
}
.top-buyers-sellers_change--buyers {
  color: #1abc9c;
}
.top-buyers-sellers_change--sellers {
  color: #ec6a4c;
}
.top-buyers-sellers_change--empty {
  color: #939ba0;
}
.top-buyers-sellers--tall .top-buyers-sellers_item {
  height: 35px;
  line-height: 35px;
}
.top-buyers-sellers--light {
  color: #545b62;
}
.top-buyers-sellers--light .top-buyers-sellers_header, .top-buyers-sellers--light .top-buyers-sellers_footer {
  border-color: #e0e0e0;
}
.top-buyers-sellers--light .top-buyers-sellers_item {
  background-color: #f7f7f7;
}
