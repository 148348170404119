.report-data-visualizer {
  position: relative;
  display: flex;
  height: 100%;
}
.report-data-visualizer_pane {
  height: 100%;
}
.report-data-visualizer_pane--widget {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 20px 20px;
}
.report-data-visualizer_pane--config {
  width: 350px;
  background-color: #eeeeee;
}
.report-data-visualizer_header {
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 20px;
  font-size: 20px;
  color: #545b62;
}
.report-data-visualizer_header i {
  margin-right: 10px;
  font-size: 24px;
}
.report-data-visualizer_dropdown:not(:last-child) {
  margin-bottom: 3px;
}
.report-data-visualizer .report-widget {
  width: 100%;
  max-width: calc(100vw - 550px);
  max-height: 415px;
  padding: 30px 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
