.page-widget {
  position: relative;
  background-color: #ffffff;
  animation: fade-in 0.3s ease;
  overflow: hidden;
}
.page-widget, .page-widget_inner {
  height: 100%;
}
.page-widget_actions {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  list-style: none;
  border-radius: 3px;
  background-color: #297ac5;
  cursor: default;
  transition: opacity 0.3s ease;
  z-index: 6;
}
.page-widget_action {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.page-widget_action:hover {
  background-color: #256eb1;
}
.page-widget_error {
  text-align: center;
}
.page-widget:hover .page-widget_actions, .page-widget--selected .page-widget_actions {
  opacity: 1;
}
.page-widget--empty .page-widget_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
}
