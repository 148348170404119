/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.autosuggest {
  position: relative;
  z-index: 55;
}
.autosuggest_input {
  display: flex;
  padding: 0 10px;
  width: 100%;
  height: 40px;
}
.autosuggest_input input {
  flex: 1;
  padding: 0;
  max-width: 100%;
  height: 100%;
  background-color: transparent;
}
.autosuggest_input--state {
  padding: 0 0 0 10px;
}
.autosuggest_input-indicator {
  position: relative;
  width: 40px;
  height: 40px;
}
.autosuggest_input-indicator--error {
  text-align: center;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.autosuggest_input-indicator--error:before {
  font-family: "iris";
  content: "\e9bb";
}
.autosuggest_input-indicator--error:before {
  font-size: 16px;
  line-height: 40px;
}
.autosuggest_results {
  position: absolute;
  width: 100%;
  height: 0;
}
.autosuggest_results-item {
  cursor: pointer;
  padding: 0 10px;
  line-height: calc(40px - 1px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.autosuggest_results-action {
  padding: 0 24px;
  line-height: 60px;
}
.autosuggest--thin .autosuggest_input {
  height: 32px;
}
.autosuggest--thin .autosuggest_input-indicator {
  width: 32px;
  height: 32px;
}
.autosuggest--thin .autosuggest_input-indicator--error:before {
  line-height: 32px;
}
.autosuggest--thin .autosuggest_results-item {
  line-height: calc(32px - 1px);
}
.autosuggest .react-scrollbar > div {
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
.autosuggest--light .autosuggest_input {
  background-color: #eeeeee;
}
.autosuggest--light .autosuggest_input input {
  color: #545b62;
}
.autosuggest--light .autosuggest_input input::placeholder {
  color: #939ba0;
}
.autosuggest--light .autosuggest_input input::-webkit-input-placeholder {
  color: #939ba0;
}
.autosuggest--light .autosuggest_input input::-moz-placeholder {
  color: #939ba0;
}
.autosuggest--light .autosuggest_input input:-moz-placeholder {
  color: #939ba0;
}
.autosuggest--light .autosuggest_input input:-ms-input-placeholder {
  color: #939ba0;
}
.autosuggest--light .autosuggest_input input::-ms-input-placeholder {
  color: #939ba0;
}
.autosuggest--light .autosuggest_input-indicator--loading {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border: 2px solid #939ba0;
  border-radius: 50%;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  z-index: 51;
}
.autosuggest--light .autosuggest_input-indicator--error {
  background-color: #ec6a4c;
}
.autosuggest--light .autosuggest_input-indicator--error:before {
  color: #ffffff;
}
.autosuggest--light .autosuggest_results {
  background-color: #939ba0;
}
.autosuggest--light .autosuggest_results-item {
  color: #ffffff;
  border-top: 1px solid #7d8488;
}
.autosuggest--light .autosuggest_results-item:first-child {
  border-top: 1px solid #939ba0;
}
.autosuggest--light .autosuggest_results-item--selected {
  background-color: #7d8488;
}
.autosuggest--light .autosuggest_results-item--selected:first-child {
  border-top: 1px solid #7d8488;
}
.autosuggest--light .autosuggest_results-action {
  background-color: #939ba0;
}
.autosuggest--dark .autosuggest_input {
  background-color: #f1af0f;
}
.autosuggest--dark .autosuggest_input input {
  color: #22272b;
}
.autosuggest--dark .autosuggest_input input::placeholder {
  color: #373B41;
}
.autosuggest--dark .autosuggest_input input::-webkit-input-placeholder {
  color: #373B41;
}
.autosuggest--dark .autosuggest_input input::-moz-placeholder {
  color: #373B41;
}
.autosuggest--dark .autosuggest_input input:-moz-placeholder {
  color: #373B41;
}
.autosuggest--dark .autosuggest_input input:-ms-input-placeholder {
  color: #373B41;
}
.autosuggest--dark .autosuggest_input input::-ms-input-placeholder {
  color: #373B41;
}
.autosuggest--dark .autosuggest_input-indicator--loading {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border: 2px solid #373B41;
  border-radius: 50%;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  z-index: 51;
}
.autosuggest--dark .autosuggest_input-indicator--error {
  background-color: #ec6a4c;
}
.autosuggest--dark .autosuggest_input-indicator--error:before {
  color: #ffffff;
}
.autosuggest--dark .autosuggest_results {
  background-color: #f1af0f;
}
.autosuggest--dark .autosuggest_results-item {
  color: #22272b;
  border-top: 1px solid #dc9e27;
}
.autosuggest--dark .autosuggest_results-item:first-child {
  border-top: 1px solid #dc9e27;
}
.autosuggest--dark .autosuggest_results-item--selected {
  background-color: #dc9e27;
}
.autosuggest--dark .autosuggest_results-item--selected:first-child {
  border-top: 1px solid #dc9e27;
}
.autosuggest--dark .autosuggest_results-action {
  background-color: #dc9e27;
}
.autosuggest--rain .autosuggest_input {
  background-color: #297ac5;
}
.autosuggest--rain .autosuggest_input input {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_input input::placeholder {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_input input::-webkit-input-placeholder {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_input input::-moz-placeholder {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_input input:-moz-placeholder {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_input input:-ms-input-placeholder {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_input input::-ms-input-placeholder {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_input-indicator--loading {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border: 2px solid #373B41;
  border-radius: 50%;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  z-index: 51;
}
.autosuggest--rain .autosuggest_input-indicator--error {
  background-color: #ec6a4c;
}
.autosuggest--rain .autosuggest_input-indicator--error:before {
  color: #ffffff;
}
.autosuggest--rain .autosuggest_results {
  background-color: #297ac5;
}
.autosuggest--rain .autosuggest_results-item {
  color: #ffffff;
  border-top: 1px solid #1f6bb0;
}
.autosuggest--rain .autosuggest_results-item:first-child {
  border-top: 1px solid #1f6bb0;
}
.autosuggest--rain .autosuggest_results-item--selected {
  background-color: #1f6bb0;
}
.autosuggest--rain .autosuggest_results-item--selected:first-child {
  border-top: 1px solid #1f6bb0;
}
.autosuggest--rain .autosuggest_results-action {
  background-color: #1f6bb0;
}
.autosuggest--ink .autosuggest_input {
  background-color: #10528f;
}
.autosuggest--ink .autosuggest_input input {
  color: #ffffff;
}
.autosuggest--ink .autosuggest_input input::placeholder {
  color: #939ba0;
}
.autosuggest--ink .autosuggest_input input::-webkit-input-placeholder {
  color: #939ba0;
}
.autosuggest--ink .autosuggest_input input::-moz-placeholder {
  color: #939ba0;
}
.autosuggest--ink .autosuggest_input input:-moz-placeholder {
  color: #939ba0;
}
.autosuggest--ink .autosuggest_input input:-ms-input-placeholder {
  color: #939ba0;
}
.autosuggest--ink .autosuggest_input input::-ms-input-placeholder {
  color: #939ba0;
}
.autosuggest--ink .autosuggest_input-indicator--loading {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  z-index: 51;
}
.autosuggest--ink .autosuggest_input-indicator--error {
  background-color: #ffffff;
}
.autosuggest--ink .autosuggest_input-indicator--error:before {
  color: #ffffff;
}
.autosuggest--ink .autosuggest_results {
  background-color: #ffffff;
}
.autosuggest--ink .autosuggest_results-item {
  color: #545b62;
  border-top: 1px solid #e0e0e0;
}
.autosuggest--ink .autosuggest_results-item:first-child {
  border-top: 1px solid #ffffff;
}
.autosuggest--ink .autosuggest_results-item--selected {
  background-color: #e0e0e0;
}
.autosuggest--ink .autosuggest_results-item--selected:first-child {
  border-top: 1px solid #e0e0e0;
}
.autosuggest--ink .autosuggest_results-action {
  background-color: #eeeeee;
}
