@import 'animation.scss';
@import '~antd/lib/date-picker/style/index.css';

@import '../../resources/styles/variables';
@import '../../resources/styles/mixins/all';
@import '../../resources/styles/icons/variables';
@import '../../resources/styles/utils';

// BASE STYLES ------------------------------------------//
// ------------------------------------------------------//

@mixin datePickerInputTheme(
  $placeholder,
  $backgroundColor,
  $color,
  $disabledPlaceholder,
  $disabledBackgroundColor,
  $disabledColor,
  $errorColor) {
  color: $color;
  background-color: $backgroundColor;

  .ant-picker-input > input {
    color: $color;
    background-color: transparent;

    @include placeholder((color: $placeholder));
  }

  .ant-picker-suffix:before {
    color: $placeholder;
  }

  .ant-picker-clear {
    background-color: $backgroundColor;

    &:before, &:hover {
      color: $placeholder;
    }
  }

  &.ant-picker-disabled {
    background-color: $disabledBackgroundColor;

    .ant-picker-input > input {
      color: $disabledColor;
      background-color: $disabledBackgroundColor;

      @include placeholder((color: $disabledPlaceholder));
    }

    .ant-picker-suffix:before {
      color: $disabledPlaceholder;
    }
  }

  .ant-picker-range-separator {
    color: $placeholder;
  }

  &.date-picker--error {
    color: $errorColor;
    background-color: lighten($errorColor, 30);

    .ant-picker-input > input {
      color: $errorColor;
      background-color: transparent;

      @include placeholder((color: lighten($errorColor, 15)));
    }

    .ant-picker-suffix:before {
      color: lighten($errorColor, 15);
    }

    .ant-picker-clear {
      background-color: lighten($errorColor, 30);

      &:before, &:hover {
        color: lighten($errorColor, 15);
      }
    }
  }
}

.date-picker_label {
  display: block;
  margin-bottom: gux();
  color: $light-slate;
  user-select: none;

  &--required {
    font-size: $font-size-table;
    color: $silver;
  }
}

.ant-picker.date-picker {
  width: 100%;
  min-width: 140px;
  padding: gux(0.5) gux();
  border-radius: $border-radius-small;
  border-color: transparent;
  box-shadow: none;

  &:hover,
  &-focused {
    border-color: transparent;
    box-shadow: none;
  }

  .ant-picker-input > input {
    padding: 0;
    font-size: $font-size-base;
    border-radius: $border-radius-small;
  }

  .ant-picker-suffix,
  .ant-picker-clear {
    svg {
      display: none;
    }
  }

  .ant-picker-suffix {
    @include q4-icon($q4i-calendar-4pt);

    &:before {
      font-size: $font-size-medium;
      line-height: $line-height-medium;
    }
  }

  .ant-picker-clear {
    width: 18px;
    @include q4-icon($q4i-close-4pt);

    &:before {
      font-size: $font-size-small;
      line-height: $line-height-medium;
      position: relative;
      top: 2px;
      left: 4px;
    }
  }

  &.ant-picker-large {
    padding: gux() + 1px;
    border-color: transparent;
    box-shadow: none;
  }

  // themes
  &--light {
    @include datePickerInputTheme(
                    $silver, // $placeholder
                    $light-grey, // $backgroundColor
                    $light-slate, // $color
                    lighten($silver, 15), // $disabledPlaceholder
                    lighten($light-grey, 1), // $disabledBackgroundColor
                    $silver, // $disabledColor
                    $spice // error
    );
  }

  &--white {
    @include datePickerInputTheme(
                    $silver, // $placeholder
                    $white, // $backgroundColor
                    $light-slate, // $color
                    lighten($silver, 15), // $disabledPlaceholder
                    darken($white, 3), // $disabledBackgroundColor
                    $silver, // $disabledColor
                    $spice // error
    );
  }

  &--rain {

  }

  &--ink {

  }

  &--dark {
    @include datePickerInputTheme(
                    $silver, // $placeholder
                    $gunpowder, // $backgroundColor
                    $white, // $color
                    lighten($silver, 15), // $disabledPlaceholder
                    lighten($gunpowder, 1), // $disabledBackgroundColor
                    $silver, // $disabledColor
                    $spice // error
    );
  }
}

// PICKER MODAL STYLES ----------------------------------//
// ------------------------------------------------------//

@mixin datePickerModalTheme(
  $background-color--calendar,
  $text-color,
  $text-color--action-hover,
  $text-color--inactive,
  $background-color--hover,
  $text-color--selected,
  $background-color--selected,
  $text-color--today,
  $background-color--today
) {
  .ant-picker-panel-container {
    background-color: $background-color--calendar;
  }
  .ant-picker-panel {
    border-color: $background-color--calendar;
  }

  .ant-picker-header {
    > button,
    > button:hover,
    .ant-picker-header-view > button,
    .ant-picker-header-view > button:hover {
      color: $text-color;
    }
  }

  .ant-picker-body {
    padding: 0 gux(2) gux(2);

    .ant-picker-content {
      width: 100%;
      table-layout: fixed;

      th {
        color: $text-color;
      }

      .ant-picker-cell {
        color: $text-color--inactive;

        &-in-view {
          color: $text-color;

          &.ant-picker-cell {
            &-in-range,
            &-range-start:not(.ant-picker-cell-range-start-single),
            &-range-end:not(.ant-picker-cell-range-end-single) {
              &:before {
                background-color: $background-color--hover;
              }

              &.ant-picker-cell-range-hover {
                &:before {
                  background-color: $background-color--hover;
                }
              }
            }

            &-range:not(.ant-picker-cell-today),
            &-range-start:not(.ant-picker-cell-today),
            &-range-end:not(.ant-picker-cell-today),
            &-selected:not(.ant-picker-cell-today) {
              .ant-picker-cell-inner {
                color: $text-color--selected;
                background-color: $background-color--selected;
              }

              &:hover .ant-picker-cell-inner {
                background-color: $background-color--selected !important;
              }
            }

            &-range-hover,
            &-range-hover-start,
            &-range-hover-end {
              user-select: none;

              &:after {
                border-color: $background-color--hover;
              }

              .ant-picker-cell-inner:after {
                background-color: transparent;
              }
            }
          }

          &:hover .ant-picker-cell-inner {
            background-color: $background-color--hover !important;
          }
        }

        &-today {
          .ant-picker-cell-inner {
            color: $text-color--today;
            background-color: $background-color--today;
          }

          &:hover .ant-picker-cell-inner {
            background-color: $background-color--today !important;
          }
        }
      }
    }
  }
}

.ant-picker-dropdown.date-picker-modal {
  font-size: $font-size-base;

  .ant-picker-panel-container {
    border-radius: $border-radius-small;
  }

  .ant-picker-header {
    padding: gux(0.5) gux(2) 0;
    border-bottom: none;

    .ant-picker-header-view {
      font-weight: $font-weight-normal;
      text-align: left;
      flex: 1;
      order: 1;

      > button {
        cursor: default;

        &:not(:first-child) {
          margin-left: gux(0.5);
        }
      }
    }

    .ant-picker-header-prev-btn {
      order: 2;

      .ant-picker-prev-icon {
        @include q4-icon-after($q4i-arrow-left-4pt);
        transform: none;

        &:after {
          font-size: $font-size-base;
        }
      }
    }

    .ant-picker-header-next-btn {
      order: 3;

      .ant-picker-next-icon {
        @include q4-icon-after($q4i-arrow-right-4pt);
        transform: none;

        &:after {
          font-size: $font-size-base;
        }
      }
    }

    .ant-picker-prev-icon:before,
    .ant-picker-next-icon:before,
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: none;
    }
  }

  .ant-picker-body {
    padding: 0 gux(2) gux(2);

    .ant-picker-content {
      width: 100%;
      table-layout: fixed;

      tr {
        height: 28px;

        & ~ tr {
          padding-top: gux(0.5);
        }
      }

      th, td {
        width: auto;
        height: 24px;
        line-height: 24px;
        padding: 0;
      }

      .ant-picker-cell {
        &:focus {
          outline: none;
        }

        &-inner {
          font-size: $font-size-small;
          user-select: none;

          &:before,
          &:after {
            user-select: none;
          }
        }

        &-today {
          .ant-picker-cell-inner {
            font-weight: $font-weight-normal;

            &:before {
              border: none;
            }
          }
        }
      }
    }
  }

  // themes
  &--light {
    @include datePickerModalTheme(
                    $light-grey, // $background-color--calendar
                    $light-slate, // $text-color
                    $slate, // $text-color--action-hover
                    $silver, // $text-color--inactive
                    mix(black, $white, 10%), // $background-color--hover
                    $white, // $text-color--selected
                    mix(black, $white, 30%), // $background-color--selected
                    $white, // $text-color--today
                    $rain // $background-color--today
    );
  }

  &--white {
    @include datePickerModalTheme(
                    $white, // $background-color--calendar
                    $light-slate, // $text-color
                    $slate, // $text-color--action-hover
                    $silver, // $text-color--inactive
                    mix(black, $white, 10%), // $background-color--hover
                    $white, // $text-color--selected
                    mix(black, $white, 30%), // $background-color--selected
                    $white, // $text-color--today
                    $rain // $background-color--today
    );
  }

  &--rain {

  }

  &--ink {

  }

  &--dark {
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
      background-color: $transparent-dark-15;
    }

    .ant-picker-cell-disabled {
      &:before {
        background-color: $transparent-dark-15;
      }
      .ant-picker-cell-inner {
        color: $transparent-light-25;
      }
    }

    .ant-picker-body .ant-picker-content .ant-picker-cell-today .ant-picker-cell-inner {
      background-color: $transparent-dark-05 !important;
    }

    @include datePickerModalTheme(
                    $gunpowder, // $background-color--calendar
                    $white, // $text-color
                    $dark-slate, // $text-color--action-hover
                    $silver, // $text-color--inactive
                    mix(black, $gunpowder, 30%), // $background-color--hover
                    $white, // $text-color--selected
                    mix(black, $gunpowder, 50%), // $background-color--selected
                    $citrus, // $text-color--today
                    $raisin-black // $background-color--today
    );
  }
}
