@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/icons';
@import '../../../../resources/styles/icons/variables';
@import '../../../../resources/styles/mixins/all';
@import '../../../../resources/styles/utils';

.tearsheet-export-modal_thumbnail {
  height: 180px;
  padding: gux(3) gux(4);
  color: $light-slate;
  background-color: $light-grey;
  border-radius: $border-radius-medium;
  cursor: pointer;

  .icon {
    font-size: 80px;
    color: $rain;
  }

  .name {
    padding: gux(2) 0 0;
    font-size: $font-size-medium;
  }

  .description {
    padding: 0 0 gux();
  }

  .content {
    display: flex;

    ul {
      width: 33.33%;

      &:not(:last-child) {
        padding-right: gux(2);
      }
    }

    &-item {
      @include q4-icon($q4i-checkmark-4pt);

      &:before {
        padding: 0 gux() 0 0;
        font-size: $font-size-xsmall;
        color: $teal;
      }

      display: block;
      line-height: $line-height-medium;
      color: $silver;
      @include truncate();
    }
  }

  &--selected {
    color: $white;
    background-color: $rain;
    transition: background-color $transition-duration--medium $transition-timing-function--default;

    .icon {
      color: $white;
    }

    .content-item {
      color: $white;

      &:before {
        color: $white;
      }
    }
  }

  &:hover:not(.tearsheet-export-modal_thumbnail--selected) {
    background-color: darken($light-grey, 3);
  }
}
