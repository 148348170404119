/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.ai-targeting-widget {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.ai-targeting-widget, .ai-targeting-widget_header, .ai-targeting-widget_items, .ai-targeting-widget_footer, .ai-targeting-widget_link, .ai-targeting-widget_tab {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.ai-targeting-widget_header {
  padding: 0 10px;
  height: 50px;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.ai-targeting-widget_filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.ai-targeting-widget_title {
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  cursor: pointer;
}
.ai-targeting-widget_tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}
.ai-targeting-widget_tabs + .ai-targeting-widget_tabs {
  margin-left: 10px;
}
.ai-targeting-widget_tab {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  border: 1px solid #1f2327;
  color: #939ba0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.ai-targeting-widget_tab--active {
  background-color: #1f2327;
  color: #f1af0f;
  cursor: default;
}
.ai-targeting-widget_tab:first-child {
  border-radius: 3px 0 0 3px;
}
.ai-targeting-widget_tab:last-child {
  border-radius: 0 3px 3px 0;
}
.ai-targeting-widget_dropdown {
  margin-left: 10px;
  text-transform: uppercase;
}
.ai-targeting-widget_items {
  position: relative;
  height: calc(100% - 50px - 25px);
}
.ai-targeting-widget_items div[data-highcharts-chart] {
  height: 100%;
}
.ai-targeting-widget_list {
  height: 300px;
}
.ai-targeting-widget_list .react-scrollbar {
  height: 250px !important;
}
.ai-targeting-widget_list-header, .ai-targeting-widget_list-item {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.ai-targeting-widget_list-header span, .ai-targeting-widget_list-item span {
  width: calc(100% - 60px);
}
.ai-targeting-widget_list-header span:last-child, .ai-targeting-widget_list-item span:last-child {
  text-align: center;
  width: 60px;
}
.ai-targeting-widget_list ul {
  list-style: none;
}
.ai-targeting-widget_list-header {
  background-color: #3498db;
  color: #ffffff;
  text-transform: uppercase;
}
.ai-targeting-widget_list-header i {
  font-size: 16px;
  margin-right: 5px;
}
.ai-targeting-widget_list-header i, .ai-targeting-widget_list-header i:before {
  line-height: 50px;
}
.ai-targeting-widget_list-item {
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
}
.ai-targeting-widget_footer {
  height: 25px;
  line-height: 25px;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
  border-radius: 3px 3px 0 0;
  text-align: right;
}
.ai-targeting-widget_link {
  display: inline-block;
  text-transform: uppercase;
  color: #f1af0f;
  cursor: pointer;
}
.ai-targeting-widget_link:hover {
  color: #dc9e27;
}
.ai-targeting-widget_link + .ai-targeting-widget_link {
  margin-left: 10px;
}
.ai-targeting-widget_link + .ai-targeting-widget_link:before {
  display: inline-block;
  content: "";
  width: 1px;
  height: 11px;
  margin-right: 10px;
  background-color: #2a3035;
  vertical-align: baseline;
  transition: background-color 0.3s ease;
}
.ai-targeting-widget_map .highcharts-tooltip path {
  stroke: rgba(0, 0, 0, 0);
  fill: #ffffff;
}
.ai-targeting-widget_map .highcharts-null-point {
  fill: #2a3035;
}
.ai-targeting-widget_map path {
  stroke: rgba(0, 0, 0, 0.1);
}
.ai-targeting-widget--light {
  color: #545b62;
}
.ai-targeting-widget--light .ai-targeting-widget_map .highcharts-null-point {
  fill: #f7f7f7;
}
.ai-targeting-widget--light .ai-targeting-widget_map .highcharts-tooltip path {
  fill: #f7f7f7;
}
.ai-targeting-widget--light .ai-targeting-widget_header {
  border-bottom: 1px solid #e0e0e0;
}
.ai-targeting-widget--light .ai-targeting-widget_tab {
  color: #545b62;
  border-color: #eeeeee;
}
.ai-targeting-widget--light .ai-targeting-widget_tab--active {
  color: #297ac5;
  background-color: #eeeeee;
}
.ai-targeting-widget--light .ai-targeting-widget_footer {
  border-top: 1px solid #e0e0e0;
}
.ai-targeting-widget--light .ai-targeting-widget_link {
  color: #297ac5;
}
.ai-targeting-widget--light .ai-targeting-widget_link:hover {
  color: #0f5ca3;
}
.ai-targeting-widget--light .ai-targeting-widget_link + .ai-targeting-widget_link:before {
  background-color: #e0e0e0;
}
.ai-targeting-widget--narrow .ai-targeting-widget_header {
  display: block;
  height: 105px;
  padding: 10px;
}
.ai-targeting-widget--narrow .ai-targeting-widget_filters {
  display: block;
}
.ai-targeting-widget--narrow .ai-targeting-widget_tabs, .ai-targeting-widget--narrow .ai-targeting-widget_dropdown {
  margin-top: 6px;
}
.ai-targeting-widget--narrow .ai-targeting-widget_tab {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.ai-targeting-widget--narrow .ai-targeting-widget_dropdown {
  margin-left: 0;
}
.ai-targeting-widget--narrow .ai-targeting-widget_items {
  height: calc(100% - 105px - 25px);
}
