/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.target-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 0 4px;
  transition: opacity 0.15s ease-in;
}
.target-filter .target-filter__input {
  padding: 0 10px;
  height: 32px;
  line-height: 18px;
  color: #ffffff;
  background-color: #545b62;
  border-radius: 3px;
  width: 222px;
}
.target-filter .target-filter__input::placeholder {
  color: #939ba0;
}

.pipeline_board--target-collapsed .lane-header .target-filter {
  opacity: 0;
}
.pipeline_board--target-collapsed .lane-header .target-filter .target-filter__input::placeholder {
  opacity: 0;
}
