/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.login-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #22272b;
  z-index: 55;
}
.login-page.login-oAuth {
  background-color: #0f5ca3;
}
.login-page_background-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.login-page_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-page_logo {
  margin: 0 0 32px 0;
  font-size: 44px;
  color: #ffffff;
}
.login-page_success {
  color: #f1af0f;
  text-align: center;
}
.login-page .button--transparent {
  width: 180px;
  color: #eeeeee;
  text-transform: uppercase;
}
.login-page .button--transparent:hover {
  color: #f1af0f;
  background-color: transparent;
}
.login-page .marketing-message {
  width: 955px;
  margin-top: 48px;
  padding: 32px 40px 40px;
  border-radius: 3px;
  background-image: url("https://q4-touch.s3.amazonaws.com/q4marketing/Desktop_Banner_Background.jpg");
  background-size: cover;
  color: white;
}
.login-page .marketing-message_column-container {
  display: flex;
}
.login-page .marketing-message_column--left {
  flex: 1;
}
.login-page .marketing-message_column--right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px 0 16px 8px;
}
.login-page .marketing-message_title {
  font-size: 30px;
  line-height: 40px;
}
.login-page .marketing-message_detail {
  padding-top: 5.3333333333px;
  font-size: 15px;
  line-height: 20px;
}
.login-page .marketing-message_detail:last-child {
  padding-top: 24px;
  width: 620px;
  line-height: 25px;
}
.login-page .marketing-message_logo-container {
  display: flex;
}
.login-page .marketing-message_logo--q4 {
  font-size: 41px;
}
