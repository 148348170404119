.error-message-placeholder .placeholder-message_inner {
  padding-top: 54px;
  padding-bottom: 54px;
}
.error-message-placeholder .placeholder-message_title {
  padding-top: 24px;
}
.error-message-placeholder .placeholder-message_image {
  min-height: 142px;
}
