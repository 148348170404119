.pipeline-widget {
  height: 100%;
  color: #ffffff;
}
.pipeline-widget_header, .pipeline-widget_item, .pipeline-widget_footer {
  transition-property: background-color, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.pipeline-widget_header {
  height: 35px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
  line-height: 35px;
  border-radius: 3px 3px 0 0;
}
.pipeline-widget_security {
  color: #939ba0;
  float: right;
}
.pipeline-widget_heading {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
}
.pipeline-widget_footer {
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
  border-radius: 0 0 3px 3px;
  color: #939ba0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.pipeline-widget_footer a {
  font-size: 13px;
  text-transform: uppercase;
  color: #f1af0f;
}
.pipeline-widget_footer a:hover {
  color: #dc9e27;
}
.pipeline-widget_items {
  height: calc(100% - 35px - 25px);
}
.pipeline-widget--light {
  color: #545b62;
}
.pipeline-widget--light .pipeline-widget_header {
  border-bottom: 1px solid #e0e0e0;
}
.pipeline-widget--light .pipeline-widget_item {
  border-bottom: 1px solid #e0e0e0;
}
.pipeline-widget--light .pipeline-widget_item:last-child {
  border-bottom: 0;
}
.pipeline-widget--light .pipeline-widget_footer {
  color: #545b62;
  border-top: 1px solid #e0e0e0;
}
.pipeline-widget--light .pipeline-widget_footer a {
  color: #297ac5;
}
.pipeline-widget--light .pipeline-widget_footer a:hover {
  color: #0f5ca3;
}
