@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/icons/variables';
@import '../../../../resources/styles/mixins/all';
@import '../../../../resources/styles/utils';

@mixin institutionTheme($backgroundColor, $color) {
  .contact-institution_position {
    &-item {
      background-color: $backgroundColor;
    }

    &-value {
      color: $color;
    }
  }
}


.contact-institution {

  &_header {
    min-height: 88px;
  }

  &_position {
    display: flex;
    justify-content: flex-end;

    &-item {
      height: 60px;
      min-width: 180px;
      margin-left: gux();
      padding: gux(1.5) gux();
      border-radius: $border-radius-small;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-value {
      padding-left: gux(2);
      font-size: $font-size-large;
    }
  }

  // themes
  &--dark {
    @include institutionTheme($black-smoke, $white)
  }

  &--light {
    @include institutionTheme($light-grey, $light-slate)
  }
}
