.events-widget {
  height: 100%;
  border-radius: 3px;
  color: #ffffff;
}
.events-widget, .events-widget_header, .events-widget_item, .events-widget_type, .events-widget_footer, .events-widget_all-link {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.events-widget_header {
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
  border-radius: 3px 3px 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.events-widget_heading {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
}
.events-widget_peer-switch .switch {
  margin-left: 8px;
}
.events-widget_items {
  height: calc(100% - 35px - 25px);
  overflow: hidden;
}
.events-widget_item {
  cursor: pointer;
  position: relative;
  padding: 10px 10px 12px 0;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.events-widget_item:last-child {
  border-bottom: 0;
}
.events-widget_item--my-ticker:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 100%;
  background-color: #f1af0f;
}
.events-widget_type {
  position: relative;
  width: 34px;
  padding-top: 16px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}
.events-widget_details {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.events-widget_ticker {
  color: #939ba0;
}
.events-widget_title {
  font-size: 13px;
  cursor: pointer;
}
.events-widget_date {
  color: #858e93;
}
.events-widget_footer {
  height: 25px;
  line-height: 25px;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
  text-align: right;
}
.events-widget_all-link {
  text-transform: uppercase;
  color: #f1af0f;
}
.events-widget_all-link:hover {
  color: #dc9e27;
}
.events-widget--light {
  color: #545b62;
}
.events-widget--light .events-widget_header {
  border-bottom: 1px solid #e0e0e0;
}
.events-widget--light .events-widget_item {
  border-bottom: 1px solid #e0e0e0;
}
.events-widget--light .events-widget_item:last-child {
  border-bottom: 0;
}
.events-widget--light .events-widget_item--my-ticker:before {
  background-color: #297ac5;
}
.events-widget--light .events-widget_type {
  color: #545b62;
}
.events-widget--light .events-widget_all-link {
  color: #297ac5;
}
.events-widget--light .events-widget_all-link:hover {
  color: #0f5ca3;
}
.events-widget--light .events-widget_footer {
  border-top: 1px solid #e0e0e0;
}
