/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.dropdown-select {
  position: fixed;
  border-radius: 3px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 55;
}
.dropdown-select_mask {
  position: fixed;
  z-index: 55;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
