.drawer-library {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #ffffff;
  overflow: hidden;
  z-index: 50;
}
.drawer-library_inner, .drawer-library_mask, .drawer-library_pane {
  position: absolute;
  top: 0;
  bottom: 0;
}
.drawer-library_mask, .drawer-library_inner {
  left: 0;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.drawer-library_inner {
  width: 256px;
  background-color: #2a3035;
  transition-property: left, width;
  z-index: 51;
}
.drawer-library_mask {
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 50;
}
.drawer-library_header, .drawer-library_pane {
  border-right: 1px solid #121517;
}
.drawer-library_header, .drawer-library_footer {
  padding-left: 20px;
  padding-right: 20px;
}
.drawer-library_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #121517;
}
.drawer-library_body {
  position: relative;
  height: calc(100% - 60px);
}
.drawer-library_title {
  font-size: 16px;
}
.drawer-library_close {
  font-size: 16px;
  color: #f1af0f;
  cursor: pointer;
}
.drawer-library_pane {
  height: 100%;
  background-color: #2a3035;
  overflow: hidden;
  transition: margin-left 0.3s ease;
}
.drawer-library_pane-body {
  height: 100%;
}
.drawer-library_pane-footer {
  padding: 16px;
  border-top: 1px solid #121517;
}
.drawer-library_pane-footer .button {
  display: block;
  width: 100%;
}
.drawer-library_pane--categories {
  left: 0;
  width: 256px;
}
.drawer-library_pane--primary {
  z-index: 54;
}
.drawer-library_pane--secondary {
  z-index: 53;
}
.drawer-library_pane--items {
  right: 0;
  width: 440px;
  z-index: 52;
}
.drawer-library_pane--items .drawer-library_pane-body {
  padding: 20px 20px;
}
.drawer-library_pane--items .drawer-library_pane-body + .drawer-library_pane--items .drawer-library_pane-body {
  padding-top: 0;
}
.drawer-library_pane--with-action .drawer-library_pane-body {
  height: calc(100% - 1px - 40px - 32px);
}
.drawer-library_message {
  margin: 4px 0 20px;
  line-height: 1.5;
}
.drawer-library--with-category-selection:not(.drawer-library--with-subcategories) .drawer-library_inner {
  width: 696px;
}
.drawer-library--with-subcategories.drawer-library--with-category-selection .drawer-library_inner {
  width: 512px;
}
.drawer-library--with-subcategories.drawer-library--with-category-selection .drawer-library_pane--categories:not(:first-child) {
  margin-left: 256px;
}
.drawer-library--with-subcategories.drawer-library--with-subcategory-selection .drawer-library_inner {
  width: 952px;
}
.drawer-library--with-subcategories.drawer-library--with-subcategory-selection .drawer-library_pane--primary .drawer-library-category-list_item--selected {
  background-color: #d99e0e;
}
.drawer-library--closed {
  pointer-events: none;
}
.drawer-library--closed .drawer-library_inner {
  left: -256px;
}
.drawer-library--closed .drawer-library_mask {
  opacity: 0;
}
