/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.activity-form_activity-attendees .dropdown {
  z-index: inherit;
}
.activity-form_activity-attendees .dropdown-toggle_value {
  z-index: inherit;
}
.activity-form_activity-attendees .attendees-pagination {
  margin-top: 10px;
}
.activity-form_activity-attendees .attendees-options {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding-bottom: 10px;
}
.activity-form_activity-attendees .attendees-options_select-type {
  padding-right: 8px;
}
.activity-form_activity-attendees .all-contacts-checkbox-wrapper {
  margin-top: 16px;
}
.activity-form_activity-attendees .item-header {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.activity-form_activity-attendees .item-header_delete-btn {
  justify-content: right;
}
.activity-form_activity-attendees .item-header_text {
  flex-grow: 1;
}
.activity-form_activity-attendees .item-header .checkbox-field {
  margin-right: 5px;
}
.activity-form_activity-attendees i {
  line-height: 1.3;
}

.activity-entity-link {
  display: flex;
  align-items: center;
}
.activity-entity-link_favorite {
  font-size: 16px;
  padding-left: 10px;
}
.activity-entity-link_icon {
  font-size: 18px;
  padding-right: 10px;
}
.activity-entity-link_icon--institution {
  color: #ec6a4c;
}
.activity-entity-link_icon--contact {
  color: #3498db;
}
.activity-entity-link_icon--fund {
  color: #1abc9c;
}
.activity-entity-link_contact-job-list .radio-button {
  margin-bottom: 4px;
}

.activity-entity-link-suggested_icon {
  margin-left: 10px;
}
