@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/all';
@import '../../../../resources/styles/utils';

.reset-password-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $dark-slate;
  z-index: $z-index--modal-level-one;

  &_background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  &_title {
    font-size: $font-size-large;
    padding: gux(4) 0 gux(2);
    color: $white;
  }

  &_rules-title {
    color: $white;
    text-align: center;
  }

  &_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  &_input {
    margin-bottom: gux();
    .text-input_field{
      border-radius: $border-radius-small;
    }
  }

  &_error {
    color: $spice;
    text-align: center;
    margin-top: gux(2);
  }

  &_form {
    position: relative;
    padding-top: gux(3);
    width: 290px;

    .button--q4-blue {
      width: 100%;
    }
  }

  &_logo {
    margin: 0 auto;
    font-size: 44px;
    color: $white;
  }
}
