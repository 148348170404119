.alerts-list-item {
  margin-bottom: 10px;
}
.alerts-list-item_type {
  padding: 8px 10px 0 0;
}
.alerts-list-item_type .icon {
  font-size: 24px;
  color: #545b62;
}
.alerts-list-item_link {
  text-decoration: none;
  margin-right: 10px;
}
.alerts-list-item_title h2 {
  font-size: 16px;
}
.alerts-list-item_content, .alerts-list-item_footer {
  background-color: #ffffff;
}
.alerts-list-item_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px 4px 0 0;
  padding: 10px;
}
.alerts-list-item_footer {
  padding: 5px 10px;
  border-top: 1px solid #e0e0e0;
  border-radius: 0 0 4px 4px;
  color: #545b62;
}
.alerts-list-item_footer span {
  margin-right: 10px;
}
.alerts-list-item_footer .icon {
  margin-right: 5px;
}
