/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/

@mixin placeholder($declarations) {
  $prefixes: (':', ':-webkit-input-', ':-moz-', '-moz-', '-ms-input-', ':-ms-input-');

  @each $prefix in $prefixes {
    &:#{$prefix}#{'placeholder'} {
      @each $property, $value in $declarations {
        #{$property}: $value;
      }
    }
  }
}