/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.list-editable_item {
  border-bottom: 1px solid #e0e0e0;
}
.list-editable_item .item-header {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 38px;
}
.list-editable_item .item-header_delete-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 28px;
  cursor: pointer;
  margin-left: 10px;
}
.list-editable_item .item-header_delete-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: #ec6a4c;
  border-radius: 50%;
}
.list-editable_item .item-header_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 0;
}
.list-editable_item .item-content {
  padding: 0 8px 0 28px;
}
