@import '../../resources/styles/variables';
@import '../../resources/styles/mixins/generic';
@import '../../resources/styles/utils';

.removable-list_item {
  display: flex;
  align-items: center;
  height: 40px;
  color: $light-slate;
  border-bottom: 1px solid $light-grey;

  &-content {
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    line-height: 40px;
    cursor: default;
  }

  &-icon {
    padding-left: $padding-horizontal-icon;
    font-size: $font-size-medium;

    &--teal {
      color: $teal;
    }
  }

  &-label {
    padding: 0 $padding-horizontal-icon;
    @include truncate();
  }

  &-delete {
    width: 40px;
    font-size: $font-size-small;
    text-align: center;
    opacity: 0;
    transition: opacity $transition-duration--fast $transition-timing-function--default;
    cursor: pointer;

    &:before {
      line-height: 40px;
    }
  }

  &:hover {
    .removable-list_item-delete {
      opacity: 1;
    }
  }
}