.estimates-container {
  width: 100%;
  height: 100%;
  color: #eeeeee;
}
.estimates-container, .estimates-container_title, .estimates-container_dropdown, .estimates-container_header, .estimates-container_footer, .estimates-container_footer a {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.estimates-container_button-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.estimates-container_header {
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.estimates-container_title {
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}
.estimates-container_dropdown.dropdown {
  text-transform: uppercase;
}
.estimates-container_body {
  position: relative;
  height: calc(100% - 50px - 25px);
  text-transform: uppercase;
  padding: 22px 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.estimates-container_footer {
  line-height: 25px;
  height: 25px;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
}
.estimates-container_footer a {
  float: right;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 13px;
  color: #f1af0f;
}
.estimates-container_footer a:hover {
  color: #dc9e27;
}
.estimates-container_footer-label {
  width: 100%;
  color: #939ba0;
  font-size: 11px;
}
.estimates-container .rating-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.estimates-container .rating-container--wide {
  min-width: 180px;
}
.estimates-container .rating-container--wide .rating-container_value {
  line-height: 32px;
}
.estimates-container .rating-container_value {
  font-size: 30px;
  text-transform: uppercase;
  padding-right: 8px;
}
.estimates-container .rating-container_label {
  color: #939ba0;
  margin-left: 5px;
}
.estimates-container .rating-container_details {
  width: 100%;
  white-space: nowrap;
}
.estimates-container--narrow .rating-container {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 8px;
  line-height: 18px;
}
.estimates-container--narrow .rating-container_value {
  font-size: 13px;
}
.estimates-container--narrow .estimates-container_body {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding: 0 10px;
}
.estimates-container--narrow .current-estimates-container {
  text-align: center;
  margin-top: 6px;
}
.estimates-container--wide .estimates-container_body {
  padding: 20px 10px;
}
.estimates-container--light {
  color: #545b62;
}
.estimates-container--light .estimates-container {
  padding-top: 20px;
}
.estimates-container--light .estimates-container_header {
  border-bottom: 1px solid #e0e0e0;
}
.estimates-container--light .estimates-container_title {
  color: #545b62;
}
.estimates-container--light .estimates-container_footer {
  border-top: 1px solid #e0e0e0;
}
.estimates-container--light .estimates-container_footer a {
  color: #297ac5;
}
.estimates-container--light .estimates-container_footer a:hover {
  color: #0f5ca3;
}
