/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.email-sync {
  display: inline;
  align-items: center;
  color: #939ba0;
  background-color: #22272b;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 4px;
}
.email-sync .sync-icon {
  margin-right: 4px;
}
