@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';

$size: 32px;

@mixin scoreTheme($backgroundColor, $color, $titleColor, $infoBackground, $infoColor) {
  background-color: $backgroundColor;
  color: $color;

  .entity-score {
    &_title {
      color: $titleColor;
    }

    &_message {
      background-color: $infoBackground;
      color: $infoColor;
    }
  }
}

.entity-score {
  padding: 0 gux(2);
  height: $size;
  border-radius: $border-radius-small;
  display: flex;
  align-items: center;
  cursor: pointer;

  &_title,
  &_value {
    text-transform: uppercase;
  }

  &_title {
    white-space: nowrap;
  }

  &_value {
    min-width: 15px;
    text-align: right;
  }

  &_message {
    margin: 0 gux(1);
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: $border-radius-circle;

    &:before {
      font-size: $font-size-xsmall;
      line-height: 17px;
    }
  }

  // themes
  &--dark {
    @include scoreTheme($dark-slate, $white, $citrus, $gunpowder, $citrus)
  }

  &--light {
    @include scoreTheme($light-grey, $slate, $rain, $ghost, $slate)
  }
}
