.q4-wrapper .toggle-list .toggle-list_item {
  border-top: 1px solid;
  background-color: transparent;
  box-shadow: none;
}
.q4-wrapper .toggle-list .toggle-list_item:last-child {
  border-bottom: 1px solid;
}
.q4-wrapper .toggle-list .toggle-list_item:before {
  display: none;
}
.q4-wrapper .toggle-list .toggle-list_item--open {
  margin: 0;
}
.q4-wrapper .toggle-list .toggle-list_item--open .toggle-list_panel {
  opacity: 1;
}
.q4-wrapper .toggle-list .toggle-list_header {
  min-height: 50px;
  padding: 0 20px;
}
.q4-wrapper .toggle-list .toggle-list_header--focused {
  background-color: transparent;
}
.q4-wrapper .toggle-list .toggle-list_header--focused .toggle-list_title {
  text-decoration: underline;
}
.q4-wrapper .toggle-list .toggle-list_title {
  margin: 0;
  font-size: 16px;
}
.q4-wrapper .toggle-list .toggle-list_toggle {
  font-size: 13px;
}
.q4-wrapper .toggle-list .toggle-list_toggle i {
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.q4-wrapper .toggle-list .toggle-list_toggle:hover i, .q4-wrapper .toggle-list .toggle-list_toggle:focus i {
  opacity: 0.3;
}
.q4-wrapper .toggle-list .toggle-list_panel {
  display: block;
  opacity: 0;
  padding: 0 20px 20px;
  transition: opacity 0.3s ease;
}
.q4-wrapper .toggle-list--light-grey .toggle-list_item {
  border-top-color: #eeeeee;
}
.q4-wrapper .toggle-list--light-grey .toggle-list_item:last-child {
  border-bottom-color: #eeeeee;
}
.q4-wrapper .toggle-list--light-grey .toggle-list_title {
  color: #545b62;
}
.q4-wrapper .toggle-list--light-grey .toggle-list_panel {
  color: #545b62;
}
.q4-wrapper .toggle-list--soft-grey .toggle-list_item {
  border-top-color: #e0e0e0;
}
.q4-wrapper .toggle-list--soft-grey .toggle-list_item:last-child {
  border-bottom-color: #e0e0e0;
}
.q4-wrapper .toggle-list--soft-grey .toggle-list_title {
  color: #545b62;
}
.q4-wrapper .toggle-list--soft-grey .toggle-list_panel {
  color: #545b62;
}
