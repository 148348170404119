/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.target-detail_overview {
  padding: 24px 32px 56px;
  min-width: 800px;
  max-width: none;
}
.target-detail .grid-section {
  height: 100%;
}
.target-detail .card-attributes {
  height: 100%;
}
.target-detail .card_children {
  min-height: 140px;
}
.target-detail_comment .card_children {
  height: 140px;
}
