@import '../../../../../../../resources/styles/variables';
@import '../../../../../../../resources/styles/icons/variables';
@import '../../../../../../../resources/styles/mixins/all';
@import '../../../../../../../resources/styles/utils';

.contact-detail_overview {
  &--flyout {

    .institution {
      &_position {
        background-color: $light-grey;
      }
    }
  }
}
