/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.pipeline-card {
  padding: 10px 8px;
  color: #545b62;
}
.pipeline-card--target {
  height: 30px;
  line-height: 30px;
  padding: 0;
  cursor: pointer;
}
.pipeline-card_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pipeline-card_title {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 100%;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #1a1d21;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pipeline-card_title a, .pipeline-card_title a:visited, .pipeline-card_title a:hover {
  color: #1a1d21;
}
.pipeline-card_indicator {
  position: relative;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 18px;
  height: 18px;
  margin: 0 0 0 4px;
  border-radius: 50%;
  font-size: 11px;
  text-align: center;
  line-height: 18px;
}
.pipeline-card_indicator:before {
  font-family: "iris";
  content: "\e9c4";
}
.pipeline-card_indicator--warning {
  background-color: #ec6a4c;
  color: #ffffff;
}
.pipeline-card_indicator .tooltip {
  display: none;
  min-width: 198px;
  text-align: left;
  right: -10px;
  pointer-events: auto;
  cursor: default;
  z-index: 10;
}
.pipeline-card_indicator .tooltip:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  display: block;
  width: 100%;
  height: 20px;
}
.pipeline-card_indicator:hover .tooltip {
  display: block;
}
.pipeline-card_details {
  margin-bottom: 10px;
  line-height: 16px;
}
.pipeline-card_detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.pipeline-card_detail + .pipeline-card_detail {
  margin-top: 4px;
}
.pipeline-card_value {
  min-width: 100px;
}
.pipeline-card_entity {
  height: 30px;
  padding: 0 6px;
  border-radius: 3px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
}
.pipeline-card_entity-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pipeline-card_icon {
  padding-right: 4px;
}
.pipeline-card_icon--close-date {
  color: #ec6a4c;
}
.pipeline-card_icon--institution {
  color: #ec6a4c;
}
.pipeline-card_icon--fund {
  color: #1abc9c;
}
.pipeline-card_icon--contact {
  color: #3498db;
}
.pipeline-card_count {
  margin-right: 8px;
  padding: 0 6px;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 11px;
}
