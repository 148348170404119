@import '../../resources/styles/variables';
@import '../../resources/styles/utils';

.tag-input {

  // autosuggest overrides
  .autosuggest-wrapper {
    width: 0;
    transition: width $transition-duration--fast $transition-timing-function--default;

    .autosuggest {

      &_results {
        box-shadow: 0 20px 20px $transparent-dark-20;
      }

      &_input {
        height: gux(4);
        padding: 0;
        width: 100%;
        border-radius: 0 $border-radius-small $border-radius-small 0;
      }

      &--open .autosuggest_input {
        border-radius: 0 $border-radius-small 0 0;
      }
    }

    &--expanded {
      width: 260px;

      .autosuggest_input {
        padding: 0 $padding-horizontal-xsmall;

        &--state {
          padding: 0 0 0 $padding-horizontal-xsmall;
        }
      }
    }
  }
}
