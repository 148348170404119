/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.attendee-grid .cell {
  display: flex;
  align-items: center;
  height: 50px;
}
.attendee-grid .cell_icon {
  font-size: 20px;
}
.attendee-grid .cell_icon--institution {
  color: #ec6a4c;
}
.attendee-grid .cell_icon--fund {
  color: #1abc9c;
}
.attendee-grid .cell_icon--contact {
  color: #3498db;
}
.attendee-grid .cell_title, .attendee-grid .cell_sub-title {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attendee-grid .cell_title {
  line-height: 18px;
  margin-left: 14px;
  width: 100%;
}
.attendee-grid .cell_sub-title {
  color: #939ba0;
}
.attendee-grid .cell--email {
  justify-content: center;
}
.attendee-grid .cell--email a {
  color: inherit;
}
.attendee-grid .cell--email .cell_icon {
  font-size: 16px;
}
