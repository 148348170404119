/* Boxed Layout
============================================================================== */

@mixin boxed-layout($min-width: $inner-min-width, $max-width: none, $top: 3, $side: 4, $bottom: 7) {
  padding: gux($top) gux($side) gux($bottom);
  min-width: $min-width;
  max-width: $max-width;
}

/* Pipe divider
============================================================================== */
@mixin divider($top: -1, $font-size: $font-size-table) {
  &:before {
    padding: 0 gux(0.5);
    content: '|';
    position: relative;
    top: #{$top}px;
    font-size: $font-size;
  }
}


/* Clear Fix
============================================================================== */

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* Truncate
============================================================================== */

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Link Styling
============================================================================== */

@mixin link {
  color: $sky;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $q4-blue;
  }
}

/* Drag and Drop Styling
============================================================================== */

@mixin draggable {
  &:hover {
    cursor: move;
    cursor: -webkit-grab;
  }
}

@mixin dragging {
  opacity: 0.5;
}

/*  Mixin to target IE 10+
============================================================================== */

@mixin ie-fix {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
