@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/utils';

.security-recent-update_modal {

  .modal_title {
    max-height: 52px;
    overflow: hidden;
  }

  &-subtitle {
    & > span {
      margin-right: gux(2);
    }

    i {
      margin-right: gux();
    }
  }

  &-body {
    overflow: hidden;

    .qmnews_headline {
      display: none;
    }

    h2, p, li, a, table {
      &:not(:last-child) {
        margin-bottom: gux(2);
      }
    }

    strong, b {
      font-weight: $font-weight-normal;
      color: $charcoal;
    }

    table {
      width: 100%;
      font-size: $font-size-table;
    }

    img {
      max-width: 100%;
    }

    a {
      color: $rain;
    }
  }
}
