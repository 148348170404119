/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.saved-targets-grid .cell {
  display: flex;
  align-items: center;
  height: 50px;
}
.saved-targets-grid .cell_icon {
  font-size: 20px;
}
.saved-targets-grid .cell_icon--institution {
  color: #ec6a4c;
}
.saved-targets-grid .cell_icon--fund {
  color: #1abc9c;
}
.saved-targets-grid .cell_icon--contact {
  color: #3498db;
}
.saved-targets-grid .cell_title {
  max-width: 90%;
  line-height: 18px;
  margin-left: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.saved-targets-grid .cell_title--name {
  color: #545b62;
}
.saved-targets-grid .cell_title--detail {
  color: #939ba0;
}
