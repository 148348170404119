/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.report-widget {
  position: relative;
  height: 100%;
  pointer-events: none;
}
.report-widget_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #2a3035;
  text-transform: uppercase;
}
.report-widget_title + .report-widget_inner {
  height: calc(100% - 32px);
}
.report-widget_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
.report-widget--text .report-widget_inner {
  justify-content: flex-start;
}
.report-widget .no-content-message, .report-widget .no-content-message_inner {
  padding: 0;
}
.report-widget .render-html {
  width: 100%;
  padding: 4px 4px;
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-group-cell, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-cell {
  line-height: 50px;
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-body-horizontal-scroll {
  display: none;
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-group-contracted, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-group-expanded {
  display: none;
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-layout-normal, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-center-cols-viewport {
  overflow: hidden;
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-center-cols-container {
  width: 100% !important;
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-header-viewport:before, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header:before, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header:after, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-center-cols-clipper:before {
  display: none;
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header .ag-header-group-cell:last-child, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header .ag-header-cell:last-child, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-cols-container .ag-cell:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header .ag-header-group-cell:first-child, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header .ag-header-cell:first-child, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-cols-container .ag-cell:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root:not(.ag-grid-root--pinned) .ag-header-container .ag-header-cell--first, .report-widget .report-builder-grid.ag-theme-bootstrap.ag-grid-root:not(.ag-grid-root--pinned) .ag-center-cols-container .ag-column--first {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.report-widget_inner > div[data-highcharts-chart] {
  width: 100%;
}
.report-widget .series--trendline {
  opacity: 0.6;
}
