.read-more_link {
  margin-left: 2px;
  cursor: pointer;
  transition: color 0.3s ease;
}
.read-more--dark .read-more {
  color: #ffffff;
}
.read-more--dark .read-more_link {
  color: #f1af0f;
}
.read-more--dark .read-more_link:hover {
  color: #dc9e27;
}
.read-more--light .read-more {
  color: #545b62;
}
.read-more--light .read-more_link {
  color: #297ac5;
}
.read-more--light .read-more_link:hover {
  color: #1f6bb0;
}
