.digital-clock-widget {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}
.digital-clock-widget, .digital-clock-widget_header, .digital-clock-widget_block, .digital-clock-widget_block:after {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.digital-clock-widget_header {
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
}
.digital-clock-widget_title {
  font-size: 13px;
  color: #ffffff;
}
.digital-clock-widget_container {
  height: calc(100% - 35px);
  padding-left: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.digital-clock-widget_block {
  position: relative;
  padding: 5px 10px;
  border-radius: 2px;
  background-color: #2a3035;
  font-size: 28px;
  color: #f1af0f;
  overflow: hidden;
}
.digital-clock-widget_block:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.digital-clock-widget_spacer {
  position: relative;
  padding: 0 4px;
  font-size: 18px;
  color: #939ba0;
  line-height: 15px;
  margin-top: -10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.digital-clock-widget_spacer:before, .digital-clock-widget_spacer:after {
  content: ".";
}
.digital-clock-widget_meridiem {
  color: #939ba0;
  font-size: 13px;
  margin: 10px 0 0 5px;
}
.digital-clock-widget_time {
  text-align: center;
}
.digital-clock-widget--light .digital-clock-widget_header {
  border-color: #e0e0e0;
}
.digital-clock-widget--light .digital-clock-widget_title {
  color: #545b62;
}
.digital-clock-widget--light .digital-clock-widget_block {
  background-color: #f7f7f7;
  color: #297ac5;
}
.digital-clock-widget--light .digital-clock-widget_block:after {
  background-color: rgba(0, 0, 0, 0.05);
}
