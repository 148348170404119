/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.targeting-page_saved-search-item {
  padding: 4px 0;
  cursor: pointer;
}
.targeting-page_saved-search-detail {
  display: block;
  line-height: 16px;
}
.targeting-page_saved-search-detail--name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.targeting-page_saved-search-detail--date {
  color: #939ba0;
}
