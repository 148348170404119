@import '../../../../../resources/styles/variables';
@import '../../../../../resources/styles/mixins/all';
@import '../../../../../resources/styles/utils';


.eventsTranscripts-eventtype-filter {
  .value-eventTypes {
    position: absolute;
  }
  .placeholder-eventTypes {
    color: $silver;
  }
  .field.field--grouped:not(:last-of-type) {
    margin-bottom: gux(2);
  }
}

.eventsTranscripts-date-filter {
  .asterisk {
    color: $spice;
  }
}
