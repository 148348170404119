/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.report-snapshot {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  position: relative;
  width: 100%;
  transition: width 0.3s ease;
}
.report-snapshot_results-label, .report-snapshot_results-count {
  vertical-align: middle;
}
.report-snapshot_results-count {
  margin-left: 10px;
}
.report-snapshot_grid {
  height: calc(100% - 70px);
  background-color: #ffffff;
}
.report-snapshot_grid:empty:before {
  content: "";
  display: block;
  height: 50px;
  background-color: #428BCF;
}
.report-snapshot .section-header {
  animation: none;
}
