/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.stock-chart {
  height: 100%;
  color: #ffffff;
  overflow: hidden;
  font-size: 13px;
}
.stock-chart, .stock-chart .sq-header, .stock-chart_peer-container, .stock-chart_peer-symbol, .stock-chart_footer, .stock-chart_autocomplete .autocomplete_input, .stock-chart_autocomplete .autocomplete_value {
  transition: 0.3s ease;
}
.stock-chart_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-left: 10px;
}
.stock-chart_container > div:first-child {
  width: 100%;
}
.stock-chart_container--narrow > div:first-child {
  width: calc(100% - 145px);
}
.stock-chart_wrapper {
  height: calc(100% - 50px - 25px - 35px);
}
.stock-chart_ticker {
  position: absolute;
  right: 148px;
  top: 58px;
}
.stock-chart_ticker--full {
  right: 15px;
}
.stock-chart_ticker:before {
  content: "";
  display: inline-block;
  width: 11px;
  height: 11px;
  background-color: #3498db;
  border-radius: 6px;
  margin-right: 7px;
}
.stock-chart_title {
  font-size: 13px;
  color: #f1af0f;
  padding: 5px 0;
  text-transform: uppercase;
}
.stock-chart_peer-container {
  width: 130px;
  height: calc(100% + 20px);
  margin: 10px 0 0 5px;
  padding: 5px 5px 5px 10px;
  border-radius: 2px;
  background-color: #2a3035;
}
.stock-chart_peer-item {
  display: block;
  margin-bottom: 2px;
}
.stock-chart_peer-label {
  display: inline-block;
  width: calc(100% - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #939ba0;
}
.stock-chart_peer-symbol {
  color: #ffffff;
}
.stock-chart_peer-exchange {
  margin-left: 8px;
}
.stock-chart_checkbox .checkbox_label:before {
  content: "";
}
.stock-chart_index {
  padding: 0 10px 5px;
  height: 35px;
  display: flex;
}
.stock-chart_chips, .stock-chart_autocomplete {
  vertical-align: top;
}
.stock-chart_autocomplete {
  margin-right: 3px;
}
.stock-chart_autocomplete .select__control {
  min-width: 128px;
  border-radius: 0 0 3px 3px;
}
.stock-chart_autocomplete .select__control--is-disabled .select__placeholder {
  color: #ec6a4c;
}
.stock-chart_autocomplete .select__control .select__input {
  height: inherit;
  line-height: inherit;
}
.stock-chart_autocomplete .autocomplete_icon {
  display: none;
}
.stock-chart_autocomplete .autocomplete_input, .stock-chart_autocomplete .autocomplete_value {
  padding: 0 10px !important;
  border-radius: 3px;
}
.stock-chart_autocomplete.autocomplete--disabled .autocomplete_input {
  color: #ec6a4c;
}
.stock-chart_autocomplete.autocomplete--disabled .autocomplete_input::placeholder {
  color: #ec6a4c;
}
.stock-chart_autocomplete.autocomplete--is-open .autocomplete_input, .stock-chart_autocomplete.autocomplete--is-open .autocomplete_value {
  border-radius: 0 0 3px 3px;
}
.stock-chart_chips {
  display: flex;
  flex: 1;
  padding-right: 135px;
  max-width: calc(100% - 130px);
}
.stock-chart_chips .chips_chip {
  flex: 1;
  background-color: #2a3035;
  max-width: 33%;
}
.stock-chart_chips .chips_label {
  padding: 0 5px;
  background-color: transparent;
  width: calc(100% - 17px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stock-chart_chips .chips_close {
  width: auto;
  padding: 0 5px 0 0;
  background-color: transparent;
  color: #939ba0;
}
.stock-chart_chips .chips_close:hover {
  color: rgba(147, 155, 160, 0.3);
}
.stock-chart_chips.chips--light .chips_chip {
  background-color: #f1f1f1;
}
.stock-chart g.highcharts-tooltip {
  display: none;
}
.stock-chart .highcharts-tooltip_wrapper {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;
}
.stock-chart .highcharts-tooltip_wrapper h2 {
  font-size: 13px;
  color: #297ac5;
}
.stock-chart .highcharts-tooltip_wrapper h3 {
  font-size: 13px;
  line-height: 18px;
}
.stock-chart .highcharts-tooltip_circle, .stock-chart .highcharts-tooltip_name {
  margin-right: 3px;
}
.stock-chart .highcharts-tooltip_circle {
  font-size: 16px;
  line-height: 18px;
}
.stock-chart .highcharts-tooltip_name {
  color: #939ba0;
}
.stock-chart .highcharts-tooltip_value {
  color: #545b62;
}
.stock-chart_footer {
  font-size: 11px;
  line-height: 25px;
  height: 25px;
  color: #939ba0;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
}
.stock-chart--light {
  color: #545b62;
}
.stock-chart--light .stock-chart_title {
  color: #297ac5;
}
.stock-chart--light .stock-chart_peer-container {
  background-color: #f7f7f7;
  border: none;
}
.stock-chart--light .stock-chart_peer-symbol {
  color: #545b62;
}
.stock-chart--light .stock-chart_footer {
  border-color: #e0e0e0;
}
.stock-chart--light .highcharts-tooltip_wrapper {
  background-color: #f7f7f7;
}
