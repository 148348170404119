@import '../../resources/styles/variables';
@import '../../resources/styles/icons/variables';
@import '../../resources/styles/mixins/all';
@import '../../resources/styles/utils';

.q4-wrapper {

  @mixin tagsTheme($color, $backgroundColor, $hoverColor, $hoverBackgroundColor) {

    .tags {

      &-header {
        background-color: $backgroundColor;
      }

      &-item {
        color: $color;
        background-color: $backgroundColor;

        &_delete {
          color: $color;
        }

        &:hover {
          color: $hoverColor;
          background-color: $hoverBackgroundColor;

          .tags-item_delete {
            color: $hoverColor;
          }
        }
      }
    }
  }

  .tags {
    box-shadow: none;
    display: flex;
    align-items: center;
    height: 48px;

    &-header {
      cursor: default;
      margin-right: gux(.25);
      padding: 0 gux(1);
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: $letter-spacing-small;
      border-radius: $border-radius-small 0 0 $border-radius-small
    }

    &-item {
      cursor: pointer;
      position: relative;
      margin-right: gux(0.25);
      height: 32px;
      line-height: 30px;
      border-radius: 0;
      transition: background-color $transition-duration--default $transition-timing-function--default;
      font-family: $font-family-base;
      font-size: $font-size-base;

      &:first-child {
        border-radius: $border-radius-small 0 0 $border-radius-small;
      }

      &:last-child {
        margin-right: 0;
        border-radius: 0 $border-radius-small $border-radius-small 0;
      }

      &:only-child {
        margin-right: 0;
        border-radius: $border-radius-small;
      }

      &:active {
        box-shadow: none;
      }

      > span {
        padding: 0 gux();
        max-width: 98px;
      }

      &_label {
        @include truncate();
      }

      &_delete {
        margin: 0;
        height: 30px;
        width: 20px;
        line-height: 32px;
        font-size: $font-size-xsmall;
      }

      &--more {
        width: 30px;

        > span {
          padding: 0;
          line-height: 1;
        }

        .tags-item_icon {
          font-size: $font-size-large;
        }
      }
    }

    &--thin {
      height: 32px;
    }

    &--wide {
      .tags-item > span {
        max-width: 160px;
      }
    }

    &--in-component {

      .tags-item {
        border-radius: 0;

        &:last-child {
          margin-right: gux(0.25);
        }
      }
    }

    &--soft-grey {
      @include tagsTheme($dark-slate, $soft-grey, $dark-slate, $ash);
    }

    &--light {
      @include tagsTheme($light-slate, $light-grey, $light-slate, $soft-grey);
    }

    &--dark {
      @include tagsTheme($citrus, $dark-slate, $dark-slate, $citrus);
    }
  }
}

