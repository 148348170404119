/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.activity-detail_overview {
  padding: 0 30px;
  min-width: 1230px;
  color: #ffffff;
}
.activity-detail_overview_columns {
  padding: 0 0 30px 70px;
  display: flex;
}
.activity-detail_overview_columns--left {
  padding: 0 30px 0 0;
  width: 340px;
}
.activity-detail_overview_columns--right {
  flex: 1;
  max-width: 735px;
}
.activity-detail_overview_columns--full {
  max-width: 735px;
}
.activity-detail_overview_section:not(:first-of-type) {
  padding-top: 30px;
}
.activity-detail_overview_subtitle {
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #939ba0;
}
.activity-detail_overview_link {
  color: #f1af0f;
}
.activity-detail_overview_participant {
  line-height: 1.5;
}
.activity-detail_overview_participant--deleted {
  padding-left: 10px;
  color: #939ba0;
}
.activity-detail_overview_location {
  line-height: 1.5;
}
.activity-detail_overview_audit-trail p {
  font-size: 11px;
  color: #939ba0;
}
.activity-detail_overview_audit-trail p span {
  display: inline-block;
  min-width: 55px;
}
