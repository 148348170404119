.report-limit + .report-limit {
  margin-top: 16px;
}
.report-limit_label {
  display: block;
  margin-bottom: 4px;
  line-height: 22px;
  text-transform: capitalize;
}
.report-limit_inner {
  display: flex;
  align-items: center;
}
.report-limit_input {
  margin-left: 16px;
}
.report-limit .field_input {
  padding: 0 12px;
}
.report-limit_slider {
  flex: 1;
}
.report-limit--disabled {
  opacity: 0.5;
  pointer-events: none;
}
