.render-html {
  line-height: 22px;
}
.render-html + .render-html {
  padding-top: 4px;
}
.render-html > * {
  max-width: 100%;
  overflow: hidden;
}
.render-html > *:last-child {
  margin-bottom: 0;
}
.render-html p {
  margin-bottom: 8px;
}
.render-html p strong {
  font-weight: bold;
}
.render-html p em, .render-html p em strong {
  font-style: italic;
}
.render-html ol, .render-html ul {
  margin: 0 0 4px;
  padding: 0 0 0 16px;
}
.render-html ol li, .render-html ul li {
  list-style: inherit;
}
.render-html ol {
  overflow: visible;
}
.render-html ul {
  list-style: disc;
}
.render-html--dark {
  color: #ffffff;
}
.render-html--light {
  color: #545b62;
}
