/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.institution-web-analytics-container {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.institution-web-analytics-container, .institution-web-analytics-container_header, .institution-web-analytics-container_footer, .institution-web-analytics-container_footer a {
  transition: 0.3s ease;
}
.institution-web-analytics-container_header {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.institution-web-analytics-container_title {
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.institution-web-analytics-container_body {
  height: calc(100% - 35px - 25px);
}
.institution-web-analytics-container_chart {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.institution-web-analytics-container_chart > div {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding-bottom: 10px;
}
.institution-web-analytics-container_company {
  min-width: 70px;
  text-align: right;
  color: #939ba0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.institution-web-analytics-container_footer {
  font-size: 11px;
  height: 25px;
  color: #939ba0;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.institution-web-analytics-container_footer p {
  padding-right: 5px;
}
.institution-web-analytics-container_footer-detail {
  min-width: 80px;
  font-size: 13px;
}
.institution-web-analytics-container_footer a {
  color: #f1af0f;
}
.institution-web-analytics-container_footer a:hover {
  color: #dc9e27;
}
.institution-web-analytics-container--light {
  color: #545b62;
}
.institution-web-analytics-container--light .institution-web-analytics-container_header {
  border-bottom: 1px solid #e0e0e0;
}
.institution-web-analytics-container--light .institution-web-analytics-container_footer {
  border-top: 1px solid #e0e0e0;
}
.institution-web-analytics-container--light .institution-web-analytics-container_footer a {
  color: #297ac5;
}
.institution-web-analytics-container--light .institution-web-analytics-container_footer a:hover {
  color: #0f5ca3;
}
