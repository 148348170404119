.report-indices_body {
  margin-top: 8px;
}
.report-indices_label-symbol {
  margin-right: 4px;
}
.report-indices_label-seperator, .report-indices_label-name {
  color: #939ba0;
}
.report-indices_label-name {
  margin-left: 4px;
}
.report-indices_label--search .report-indices_label-symbol, .report-indices_label--search .report-indices_label-seperator, .report-indices_label--search .report-indices_label-name {
  color: #ffffff;
}
