/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.top-managers .data-table_head {
  background-color: transparent;
}
.top-managers .data-table_row {
  height: 40px;
}
.top-managers .data-table_row:last-child .data-table_cell {
  border-bottom: none;
}
.top-managers .data-table_cell {
  color: #545b62;
}
.top-managers .data-table_cell--head {
  color: #939ba0;
}
.top-managers .peer-holdings-percent--increase {
  color: #1abc9c;
}
.top-managers .peer-holdings-percent--decrease {
  color: #e74c3c;
}
