/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.targeting-page-old_tabs_tab--all {
  display: flex;
}
.targeting-page-old_tabs_tab--all .targeting-page-tab_body {
  flex: 1;
  position: relative;
  min-height: 600px;
  height: calc(100vh - 80px - 70px);
  background-color: #ffffff;
  overflow: hidden;
}
.targeting-page-old_tabs_tab--all .targeting-page-tab_body .ag-theme-bootstrap.ag-grid-root {
  height: calc(100% - 70px);
}
.targeting-page-old_tabs_tab--all .targeting-page-tab_body .pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.targeting-page-old_tabs_tab--all .targeting-page-tab_body .spinner, .targeting-page-old_tabs_tab--all .targeting-page-tab_body .spinner_mask {
  z-index: 10;
}

.targeting-page_tabs_tab--flyout-open .pagination {
  min-width: 310px;
}
