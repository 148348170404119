.estimates-broker-details_modal {
  position: relative;
  height: 360px;
}
.estimates-broker-details_modal .react-scrollbar {
  height: calc(100% - 50px) !important;
}
.estimates-broker-details_modal .data-table td.data-table_cell {
  background-color: #f1f1f1;
}
.estimates-broker-details_modal .data-table td.data-table_cell:nth-child(even) {
  background-color: #eaeaea;
}
