@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/generic';
@import '../../../../resources/styles/utils';

$height: 32px;

.security-stock-overview_index {
  padding-top: gux();
  display: flex;

  &-select {
    width: 200px;
  }

  &-item {
    margin-left: gux();
    height: $height;
    max-width: 160px;
    background-color: $gunpowder;
    border-radius: $border-radius-small;
    display: flex;
    align-items: center;

    &_label {
      padding: 0 gux();
      @include truncate();
    }

    &_delete {
      cursor: pointer;
      width: $height;
      font-size: $font-size-table;
      text-align: center;

      &:before {
        line-height: $height;
      }
    }
  }
}
