.flyout {
  position: fixed;
  right: 0;
  top: 80px;
  background-color: #ffffff;
  height: calc(100% - 80px);
  width: 100%;
  transition: 0.2s ease;
  transition-property: width, min-width;
  z-index: 10;
}
.flyout_inner {
  transition: opacity 0.2s ease;
}
.flyout:before {
  content: "";
  opacity: 1;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  width: 10px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.2), transparent);
  transition: opacity 0.2s ease;
}
.flyout--collapsed .flyout_inner, .flyout--collapsed:before {
  opacity: 0;
}
