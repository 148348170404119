@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pop-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pop-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes loading-pulse {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.banner {
  min-height: 130px;
  background-color: #2a3035;
  color: #ffffff;
  animation: fade-in 0.3s ease;
}
.banner_inner {
  position: relative;
  padding: 0 32px;
}
.banner_elements {
  display: flex;
  justify-content: space-between;
}
.banner_header {
  display: flex;
  flex: 1;
}
.banner_badge {
  width: 50px;
  height: 70px;
  margin-right: 20px;
  background-color: #22272b;
  border-radius: 0 0 25px 25px;
  text-align: center;
}
.banner_badge:before {
  font-size: 24px;
  line-height: 70px;
}
.banner_badge--fund {
  background-color: #1abc9c;
  color: #ffffff;
}
.banner_badge--institution {
  background-color: #ec6a4c;
  color: #ffffff;
}
.banner_badge--contact {
  background-color: #297ac5;
  color: #ffffff;
}
.banner_information {
  padding-top: 20px;
}
.banner_title {
  margin-right: 16px;
  display: inline-block;
  font-size: 20px;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner_disclosure {
  margin-left: 16px;
  padding: 0 8px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  vertical-align: bottom;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 3px;
  cursor: pointer;
}
.banner_disclosure-icon {
  display: inline-block;
  margin-left: 16px;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
}
.banner_disclosure-icon:before {
  font-size: 10px;
  line-height: 19px;
}
.banner_disclosure--cherry {
  color: #ffffff;
  background-color: #e74c3c;
}
.banner_disclosure--cherry .banner_disclosure-icon {
  color: #e74c3c;
  background-color: #ffffff;
}
.banner .button--info {
  margin-top: 4px;
  width: 20px;
  height: 20px;
  vertical-align: top;
}
.banner .button--info:before {
  line-height: 20px;
}
.banner_details > a {
  cursor: pointer;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner_details > a:hover {
  color: #ffffff;
}
.banner_details > a.reverse {
  display: block;
  color: #939ba0;
}
.banner_details > a.reverse:hover {
  color: #e0e0e0;
}
.banner_details > span {
  color: #939ba0;
}
.banner_details > span > span {
  color: #ffffff;
}
.banner_details > span.divider:before {
  padding: 0 4px;
  content: "|";
  position: relative;
  top: -1px;
  font-size: 12px;
}
.banner_details > span + span:not(.divider) {
  margin-left: 8px;
}
.banner_details > br + span {
  display: inline-block;
  margin-top: 4px;
}
.banner_controls {
  padding-top: 20px;
  display: flex;
}
.banner_controls-score:not(:only-child) {
  padding-right: 8px;
  height: 32px;
}
.banner_component {
  margin: 4px 0 0 70px;
}
.banner_component--badge-aligned {
  margin: 20px 0 0;
}
.banner--thin .banner_title {
  font-size: 16px;
}
.banner--small {
  min-height: auto;
  height: 80px;
}
.banner--medium {
  min-height: auto;
  height: 100px;
}
.banner--thin {
  min-height: auto;
  height: 80px;
  overflow: hidden;
}
.banner--thin .banner_inner {
  height: 100%;
}
.banner--thin .banner_header {
  align-items: center;
}
.banner--thin .banner_badge {
  width: auto;
  height: auto;
  background-color: transparent;
}
.banner--thin .banner_badge:before {
  font-size: 35px;
  line-height: 80px;
}
.banner--thin .banner_information {
  padding: 0;
}
.banner--thin .banner_controls {
  padding: 20px 0;
}
.banner--slate {
  background-color: #2a3035;
  color: #ffffff;
}
.banner--slate .banner_title {
  font-size: 20px;
}
.banner--slate .banner_details {
  color: #939ba0;
}
.banner--slate .banner_details > a {
  color: #ffffff;
}
.banner--slate .banner_details > a:hover {
  color: #ffffff;
}
.banner--slate .banner_badge {
  color: #ffffff;
  background-color: #22272b;
}
.banner--white, .banner--light {
  background-color: #ffffff;
  color: #545b62;
}
.banner--white .banner_title, .banner--light .banner_title {
  font-size: 20px;
}
.banner--white .banner_details, .banner--light .banner_details {
  color: #939ba0;
}
.banner--white .banner_details > a, .banner--light .banner_details > a {
  color: #545b62;
}
.banner--white .banner_details > a:hover, .banner--light .banner_details > a:hover {
  color: #545b62;
}
.banner--white .banner_badge, .banner--light .banner_badge {
  color: #545b62;
  background-color: #eeeeee;
}
