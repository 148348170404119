.radio-button_label {
  display: inline-block;
  line-height: 22px;
  user-select: none;
}
.radio-button_label:before, .radio-button_label:after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border: 3px solid #e0e0e0;
  border-radius: 50%;
  vertical-align: text-bottom;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}
.radio-button_label--right:before {
  margin-right: 5px;
}
.radio-button_label--right:after {
  display: none;
}
.radio-button_label--left:before {
  display: none;
}
.radio-button_label--left:after {
  margin-left: 8px;
}
.radio-button_input {
  display: none;
}
.radio-button--selected .radio-button_label:before, .radio-button--selected .radio-button_label:after {
  background-color: #297ac5;
}
.radio-button--sky .radio-button_label:before, .radio-button--sky .radio-button_label:after {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.radio-button--sky.radio-button--selected .radio-button_label:before, .radio-button--sky.radio-button--selected .radio-button_label:after {
  background-color: #3498db;
}
.radio-button--rain .radio-button_label:before, .radio-button--rain .radio-button_label:after {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.radio-button--rain.radio-button--selected .radio-button_label:before, .radio-button--rain.radio-button--selected .radio-button_label:after {
  background-color: #297ac5;
}
.radio-button--ash-rain .radio-button_label:before, .radio-button--ash-rain .radio-button_label:after {
  background-color: #ffffff;
  border-color: #cccccc;
}
.radio-button--ash-rain.radio-button--selected .radio-button_label:before, .radio-button--ash-rain.radio-button--selected .radio-button_label:after {
  background-color: #297ac5;
}
.radio-button--teal .radio-button_label:before, .radio-button--teal .radio-button_label:after {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.radio-button--teal.radio-button--selected .radio-button_label:before, .radio-button--teal.radio-button--selected .radio-button_label:after {
  background-color: #1abc9c;
}
.radio-button--citrus .radio-button_label:before, .radio-button--citrus .radio-button_label:after {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.radio-button--citrus.radio-button--selected .radio-button_label:before, .radio-button--citrus.radio-button--selected .radio-button_label:after {
  background-color: #f1af0f;
}
.radio-button--spice .radio-button_label:before, .radio-button--spice .radio-button_label:after {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.radio-button--spice.radio-button--selected .radio-button_label:before, .radio-button--spice.radio-button--selected .radio-button_label:after {
  background-color: #ec6a4c;
}
.radio-button--eggplant .radio-button_label:before, .radio-button--eggplant .radio-button_label:after {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.radio-button--eggplant.radio-button--selected .radio-button_label:before, .radio-button--eggplant.radio-button--selected .radio-button_label:after {
  background-color: #6a3476;
}
.radio-button--tangerine .radio-button_label:before, .radio-button--tangerine .radio-button_label:after {
  background-color: #ffffff;
  border-color: #eeeeee;
}
.radio-button--tangerine.radio-button--selected .radio-button_label:before, .radio-button--tangerine.radio-button--selected .radio-button_label:after {
  background-color: #fc7e26;
}
.radio-button--transparent .radio-button_label:before, .radio-button--transparent .radio-button_label:after {
  background-color: transparent;
  border-color: transparent;
}
.radio-button--transparent.radio-button--selected .radio-button_label:before, .radio-button--transparent.radio-button--selected .radio-button_label:after {
  background-color: transparent;
}
.radio-button--custom-color.radio-button--selected .radio-button_label:before, .radio-button--custom-color.radio-button--selected .radio-button_label:after {
  color: inherit;
  background-color: currentColor;
}
.radio-button--disabled {
  opacity: 0.3;
  pointer-events: none;
}
