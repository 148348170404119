.estimates-broker-details_list {
  position: relative;
}
.estimates-broker-details_edit {
  cursor: pointer;
}
.estimates-broker-details_edit--disabled {
  pointer-events: none;
}
.estimates-broker-details_edit--disabled {
  color: #e0e0e0;
}
.estimates-broker-details_edit--saved {
  color: #2ecc71;
}
.estimates-broker-details .estimates_toolbar {
  padding: 16px 0;
}
.estimates-broker-details_research {
  position: relative;
  line-height: 18px;
}
.estimates-broker-details_research i {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin: 1px 0 1px 5px;
  background-color: #297ac5;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  font-size: 8px;
  transform: translateY(-2px);
}
.estimates-broker-details_research .tooltip {
  display: none;
}
.estimates-broker-details_research:hover .tooltip {
  display: block;
}
.estimates-broker-details_research-link {
  display: inline-block;
  font-size: 16px;
  text-align: center;
}
.estimates-broker-details_research-link, .estimates-broker-details_research-link:hover, .estimates-broker-details_research-link:visited {
  color: #545b62;
}
.estimates-broker-details_value i {
  margin-right: 2px;
  font-size: 11px;
}
.estimates-broker-details_value--up {
  color: #1abc9c;
}
.estimates-broker-details_value--down {
  color: #ec6a4c;
}
.estimates-broker-details_dropdown--metric {
  width: 180px;
  max-width: 180px;
}
.estimates-broker-details_dropdown--date {
  width: 140px;
}
.estimates-broker-details_dropdown--date .select-component,
.estimates-broker-details_dropdown--date .select__control {
  min-width: 140px;
}
.estimates-broker-details .data-table th.data-table_cell:nth-child(1n+3) {
  text-align: center;
}
.estimates-broker-details .data-table th.data-table_cell:nth-child(2n+1):not(:first-child) {
  background-color: #2f89c5;
}
.estimates-broker-details .data-table td.data-table_cell:nth-child(2n+1):not(:first-child) {
  background-color: #f7f7f7;
}
