.email-preference-modal {
    outline: 0;
    .message-root .message_content {
        padding-bottom: 32px;
    }
    h1.message_title {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 20px;
        line-height: 24px;
        color: #2A3035;
        i.icon {
            font-size: 32px;
            margin-right: 8px;
        }
        > div {
            display: flex;
            align-items: center;
        }
    }
    .message-text {
        padding-top: 8px;
    }
    .message_text p {
        text-align: left;
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 8px;
        color: #545B62;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .message_text ul {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        text-align: left;
        list-style-type: disc;
        // * Left align bulleted list
        list-style-position: inside;
        text-indent: -1.4em;
        padding-left: 1.4em;
        // *
    }
    footer.message_footer {
        justify-content: flex-end;
    }
    button.button.button--wide.button--tall.button--dark-slate {
        color: white;
    }

    .new-badge {
        background-color: white;
        border: 1px solid #0F5CA3;
        border-radius: 16px;
        width: 32px;
        height: 14px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #0F5CA3;
        margin-left: 8px;
    }
}
