@import '../../resources/styles/variables';
@import '../../resources/styles/mixins/generic';
@import '../../resources/styles/utils';

.entity-search {
  width: 100%;

  &_controls {
    display: flex;
    align-items: center;
    padding-bottom: gux();

    > :last-child:not(:only-child) {
      padding-left: gux(2);
    }
  }

  &_suggestion {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-content {
      flex: 1 1 auto;
    }

    &-icon {
      padding-left: gux(2);
      width: 40px;
      height: 50px;

      &:before {
        font-size: $font-size-medium;
        line-height: 48px;
        color: $silver;
      }
    }

    &-title {
      @include truncate();
    }

    &-detail {
      color: $silver;
    }
  }

  &_pagination {
    margin-top: gux();
  }

  &_entity {
    display: flex;

    &-title, &-icon:before, &-content {
      line-height: $line-height-medium;
    }

    &-icon {
      padding-right: gux();
      font-size: $font-size-medium;

      &--institution {
        color: $spice;
      }

      &--contact {
        color: $olympic;
      }

      &--fund {
        color: $teal;
      }
    }

    &-content {
      padding-top: gux();
    }
  }
}
