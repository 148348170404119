@import '../../../../../resources/styles/variables';
@import '../../../../../resources/styles/mixins/generic';
@import '../../../../../resources/styles/utils';

.tearsheet_tags {
  text-align: right;

  &-label,
  &-tag {
    display: inline-block;
    vertical-align: top;
  }

  &-label {
    padding-right: gux();
    color: $silver;
  }

  &-tag {
    padding-right: gux(0.75);
    max-width: 220px;
    @include truncate();
  }
}
