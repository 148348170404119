/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.login-page_form {
  position: relative;
  width: 290px;
}
.login-page_form .formik-text-input_field {
  border-radius: 3px;
  margin-bottom: 8px;
}
.login-page_form .button--q4-blue {
  width: 100%;
}

.login-error {
  width: 364px;
  position: relative;
  text-align: center;
  color: #ec6a4c;
  padding-bottom: 20px;
}
.login-error .contact-support-message {
  padding-top: 8px;
  color: #ffffff;
}
.login-error .contact-support-message a {
  color: #f1af0f;
}
