@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';

.page-message {
  margin: 0 auto;
  padding: gux(20) 0 0 gux(6);

  &-inner {
    width: 660px;
    padding: 0 0 gux(6) 0;
    font-size: $font-size-xlarge;
    font-weight: $font-weight-light;
    line-height: $line-height-xlarge;
    color: $white;
  }
}