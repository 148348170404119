/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.news-widget {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.news-widget, .news-widget_header, .news-widget_footer, .news-widget_footer a, .news-widget_tab, .news-widget_item, .news-widget_icon {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.news-widget_header {
  height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
}
.news-widget_title {
  cursor: pointer;
  display: inline-block;
  margin-right: auto;
  line-height: 13px;
  font-size: 13px;
  text-transform: uppercase;
}
.news-widget_input {
  float: right;
  margin-right: 15px;
  line-height: 13px;
}
.news-widget_tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.news-widget_tab {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  border: 1px solid #1f2327;
  color: #939ba0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.news-widget_tab--active {
  pointer-events: none;
  background-color: #1f2327;
  color: #f1af0f;
  cursor: default;
}
.news-widget_tab:first-child {
  border-radius: 3px 0 0 3px;
}
.news-widget_tab:last-child {
  border-radius: 0 3px 3px 0;
}
.news-widget_items {
  position: relative;
  height: calc(100% - 50px - 10px);
}
.news-widget_items--tall {
  height: calc(100% - 70px - 10px);
}
.news-widget_item {
  padding: 7px 10px;
  border-bottom: 1px solid #2a3035;
}
.news-widget_item:last-child {
  border: none;
}
.news-widget_headline {
  cursor: pointer;
}
.news-widget_company {
  color: #939ba0;
}
.news-widget_company i {
  padding-right: 5px;
}
.news-widget_company--active {
  color: #f1af0f;
}
.news-widget_date {
  color: #939ba0;
}
.news-widget_footer {
  height: 10px;
  line-height: 10px;
  border-top: 1px solid #2a3035;
}
.news-widget_footer a {
  color: #f1af0f;
}
.news-widget_footer a:hover {
  color: #dc9e27;
}
.news-widget_modal-headline {
  margin-bottom: 20px;
  font-size: 18px;
  color: #1a1d21;
}
.news-widget_modal-subtitle > span {
  margin-right: 20px;
}
.news-widget_modal-subtitle i {
  margin-right: 5px;
}
.news-widget_modal-body {
  overflow: hidden;
}
.news-widget_modal-body .qmnews_headline {
  display: none;
}
.news-widget_modal-body h2:not(:last-child), .news-widget_modal-body p:not(:last-child), .news-widget_modal-body li:not(:last-child), .news-widget_modal-body a:not(:last-child), .news-widget_modal-body table:not(:last-child) {
  margin-bottom: 14px;
}
.news-widget_modal-body strong, .news-widget_modal-body b {
  font-weight: 400;
  color: #1a1d21;
}
.news-widget_modal-body table {
  width: 100%;
  font-size: 12px;
}
.news-widget_modal-body img {
  max-width: 100%;
}
.news-widget_modal-body a {
  color: #297ac5;
}
.news-widget:not(.news-widget--narrow) .news-widget_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.news-widget--narrow .news-widget_header {
  height: 70px;
  padding: 10px;
}
.news-widget--narrow .news-widget_tab {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.news-widget--narrow .news-widget_input {
  margin-right: 0;
}
.news-widget--narrow .news-widget_tabs {
  margin-top: 6px;
}
.news-widget--light {
  color: #545b62;
}
.news-widget--light .news-widget_header, .news-widget--light .news-widget_item {
  border-bottom: 1px solid #e0e0e0;
}
.news-widget--light .news-widget_company--active {
  color: #297ac5;
}
.news-widget--light .news-widget_item:last-child {
  border: none;
}
.news-widget--light .news-widget_footer {
  border-top: 1px solid #e0e0e0;
}
.news-widget--light .news-widget_footer a {
  color: #297ac5;
}
.news-widget--light .news-widget_footer a:hover {
  color: #0f5ca3;
}
.news-widget--light .news-widget_tab {
  color: #545b62;
  border: 1px solid #eeeeee;
}
.news-widget--light .news-widget_tab--active {
  background-color: #eeeeee;
  color: #297ac5;
}
