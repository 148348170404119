/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.popover-root .popover {
  font-size: 13px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
  width: 234px;
  overflow: visible;
}
.popover-root .popover--empty {
  display: none;
}
.popover-root .popover_item {
  cursor: pointer;
  background-color: transparent;
  font-size: 13px;
  text-align: left;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popover-root .popover_item-detail {
  padding: 0 10px 0 14px;
  flex: 1;
  max-width: 100%;
  line-height: 49px;
}
.popover-root .popover_item-detail--deletable {
  width: calc(100% - 24px);
}
.popover-root .popover_item-icon, .popover-root .popover_item-label {
  display: inline-block;
  vertical-align: middle;
}
.popover-root .popover_item-icon {
  margin-right: 10px;
}
.popover-root .popover_item-icon:before {
  font-size: 20px;
}
.popover-root .popover_item-label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popover-root .popover_item-label--has-icon {
  max-width: calc(100% - 30px);
}
.popover-root .popover_item-delete {
  opacity: 0;
  padding: 0 10px 0 0;
  width: 24px;
  font-size: 11px;
  line-height: 49px;
  text-align: right;
  transition: opacity 0.2s ease;
}
.popover-root .popover_item:hover:first-child {
  border-radius: 3px 3px 0 0;
}
.popover-root .popover_item:hover:last-child {
  border-radius: 0 0 3px 3px;
}
.popover-root .popover_item:hover .popover_item-delete {
  opacity: 1;
}
.popover-root .popover_item--thin {
  height: 40px;
}
.popover-root .popover_item--thin .popover_item-detail,
.popover-root .popover_item--thin .popover_item-delete {
  line-height: 39px;
}
.popover-root .popover_item-expandable {
  margin: 0;
  box-shadow: none;
}
.popover-root .popover_item-expandable > div {
  height: 50px;
  padding: 0;
}
.popover-root .popover_item-expandable_button {
  padding: 0;
  align-items: center;
}
.popover-root .popover_item-expandable_button > :last-child {
  padding-right: 0;
}
.popover-root .popover_item-expandable_button--open, .popover-root .popover_item-expandable_button--open > div {
  margin: 0;
}
.popover-root .popover_item-expandable_button--open .popover-item-expandable_caret {
  transform: rotate(-180deg);
}
.popover-root .popover_item-expandable_label {
  flex: 1;
}
.popover-root .popover_item-expandable_caret {
  transition: transform 0.2s;
}
.popover-root .popover_item-expandable_content {
  padding-bottom: 14px;
}
.popover-root .popover_item-expandable_content .radio-button + .radio-button {
  margin-top: 10px;
}
.popover-root .popover--hiding {
  pointer-events: none;
}
.popover-root .popover--margin--bottom-right, .popover-root .popover--margin--top-right {
  margin-left: 16px;
}
.popover-root .popover--margin--bottom-left, .popover-root .popover--margin--top-left {
  margin-left: -16px;
}
.popover-root .popover--margin--bottom-right, .popover-root .popover--margin--bottom-left {
  margin-top: 16px;
}
.popover-root .popover--margin--top-right, .popover-root .popover--margin--top-left {
  margin-top: -16px;
}
.popover-root .popover--white {
  background-color: #ffffff;
  color: #545b62;
}
.popover-root .popover--white, .popover-root .popover--white--top, .popover-root .popover--white--top-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-left-radius: 0;
}
.popover-root .popover--white:before, .popover-root .popover--white--top:before, .popover-root .popover--white--top-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--white:before, .popover-root .popover--white--top:before, .popover-root .popover--white--top-left:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #e0e0e0;
  left: 0;
  border-right-color: #e0e0e0;
}
.popover-root .popover--white--top-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-right-radius: 0;
}
.popover-root .popover--white--top-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--white--top-right:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #e0e0e0;
  right: 0;
  border-left-color: #e0e0e0;
}
.popover-root .popover--white--bottom-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
}
.popover-root .popover--white--bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--white--bottom-left:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #e0e0e0;
  left: 0;
  border-right-color: #e0e0e0;
}
.popover-root .popover--white--bottom, .popover-root .popover--white--bottom-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
}
.popover-root .popover--white--bottom:before, .popover-root .popover--white--bottom-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--white--bottom:before, .popover-root .popover--white--bottom-right:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #e0e0e0;
  right: 0;
  border-left-color: #e0e0e0;
}
.popover-root .popover--white--right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.popover-root .popover--white--right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--white--right:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #e0e0e0;
}
.popover-root .popover--white--left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.popover-root .popover--white--left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--white--left:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  left: 100%;
  border-left-color: #e0e0e0;
}
.popover-root .popover--white--right, .popover-root .popover--white--left {
  white-space: nowrap;
}
.popover-root .popover--white .popover_item {
  color: #2a3035;
}
.popover-root .popover--white .popover_item:not(:first-child) {
  border-top: 1px solid #eeeeee;
}
.popover-root .popover--white .popover_item:hover {
  background-color: #eeeeee;
}
.popover-root .popover--light, .popover-root .popover--light-grey {
  background-color: #eeeeee;
  color: #545b62;
}
.popover-root .popover--light, .popover-root .popover--light--top, .popover-root .popover--light--top-left, .popover-root .popover--light-grey, .popover-root .popover--light-grey--top, .popover-root .popover--light-grey--top-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-left-radius: 0;
}
.popover-root .popover--light:before, .popover-root .popover--light--top:before, .popover-root .popover--light--top-left:before, .popover-root .popover--light-grey:before, .popover-root .popover--light-grey--top:before, .popover-root .popover--light-grey--top-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--light:before, .popover-root .popover--light--top:before, .popover-root .popover--light--top-left:before, .popover-root .popover--light-grey:before, .popover-root .popover--light-grey--top:before, .popover-root .popover--light-grey--top-left:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #dddddd;
  left: 0;
  border-right-color: #dddddd;
}
.popover-root .popover--light--top-right, .popover-root .popover--light-grey--top-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-right-radius: 0;
}
.popover-root .popover--light--top-right:before, .popover-root .popover--light-grey--top-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--light--top-right:before, .popover-root .popover--light-grey--top-right:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #dddddd;
  right: 0;
  border-left-color: #dddddd;
}
.popover-root .popover--light--bottom-left, .popover-root .popover--light-grey--bottom-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
}
.popover-root .popover--light--bottom-left:before, .popover-root .popover--light-grey--bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--light--bottom-left:before, .popover-root .popover--light-grey--bottom-left:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #dddddd;
  left: 0;
  border-right-color: #dddddd;
}
.popover-root .popover--light--bottom, .popover-root .popover--light--bottom-right, .popover-root .popover--light-grey--bottom, .popover-root .popover--light-grey--bottom-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
}
.popover-root .popover--light--bottom:before, .popover-root .popover--light--bottom-right:before, .popover-root .popover--light-grey--bottom:before, .popover-root .popover--light-grey--bottom-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--light--bottom:before, .popover-root .popover--light--bottom-right:before, .popover-root .popover--light-grey--bottom:before, .popover-root .popover--light-grey--bottom-right:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #dddddd;
  right: 0;
  border-left-color: #dddddd;
}
.popover-root .popover--light--right, .popover-root .popover--light-grey--right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.popover-root .popover--light--right:before, .popover-root .popover--light-grey--right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--light--right:before, .popover-root .popover--light-grey--right:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #dddddd;
}
.popover-root .popover--light--left, .popover-root .popover--light-grey--left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.popover-root .popover--light--left:before, .popover-root .popover--light-grey--left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--light--left:before, .popover-root .popover--light-grey--left:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  left: 100%;
  border-left-color: #dddddd;
}
.popover-root .popover--light--right, .popover-root .popover--light--left, .popover-root .popover--light-grey--right, .popover-root .popover--light-grey--left {
  white-space: nowrap;
}
.popover-root .popover--light .popover_item, .popover-root .popover--light-grey .popover_item {
  color: #2a3035;
}
.popover-root .popover--light .popover_item:not(:first-child), .popover-root .popover--light-grey .popover_item:not(:first-child) {
  border-top: 1px solid #e0e0e0;
}
.popover-root .popover--light .popover_item:hover, .popover-root .popover--light-grey .popover_item:hover {
  background-color: #e0e0e0;
}
.popover-root .popover--rain {
  background-color: #297ac5;
  color: #ffffff;
}
.popover-root .popover--rain, .popover-root .popover--rain--top, .popover-root .popover--rain--top-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-left-radius: 0;
}
.popover-root .popover--rain:before, .popover-root .popover--rain--top:before, .popover-root .popover--rain--top-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--rain:before, .popover-root .popover--rain--top:before, .popover-root .popover--rain--top-left:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #297ac5;
  left: 0;
  border-right-color: #297ac5;
}
.popover-root .popover--rain--top-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-right-radius: 0;
}
.popover-root .popover--rain--top-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--rain--top-right:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #297ac5;
  right: 0;
  border-left-color: #297ac5;
}
.popover-root .popover--rain--bottom-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
}
.popover-root .popover--rain--bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--rain--bottom-left:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #297ac5;
  left: 0;
  border-right-color: #297ac5;
}
.popover-root .popover--rain--bottom, .popover-root .popover--rain--bottom-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
}
.popover-root .popover--rain--bottom:before, .popover-root .popover--rain--bottom-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--rain--bottom:before, .popover-root .popover--rain--bottom-right:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #297ac5;
  right: 0;
  border-left-color: #297ac5;
}
.popover-root .popover--rain--right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.popover-root .popover--rain--right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--rain--right:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #297ac5;
}
.popover-root .popover--rain--left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.popover-root .popover--rain--left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--rain--left:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  left: 100%;
  border-left-color: #297ac5;
}
.popover-root .popover--rain--right, .popover-root .popover--rain--left {
  white-space: nowrap;
}
.popover-root .popover--rain .popover_item {
  color: #ffffff;
}
.popover-root .popover--rain .popover_item:not(:first-child) {
  border-top: 1px solid #1f6bb0;
}
.popover-root .popover--rain .popover_item:hover {
  background-color: #1f6bb0;
}
.popover-root .popover--dark, .popover-root .popover--citrus {
  background-color: #f1af0f;
  color: #22272b;
}
.popover-root .popover--dark, .popover-root .popover--dark--top, .popover-root .popover--dark--top-left, .popover-root .popover--citrus, .popover-root .popover--citrus--top, .popover-root .popover--citrus--top-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-left-radius: 0;
}
.popover-root .popover--dark:before, .popover-root .popover--dark--top:before, .popover-root .popover--dark--top-left:before, .popover-root .popover--citrus:before, .popover-root .popover--citrus--top:before, .popover-root .popover--citrus--top-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--dark:before, .popover-root .popover--dark--top:before, .popover-root .popover--dark--top-left:before, .popover-root .popover--citrus:before, .popover-root .popover--citrus--top:before, .popover-root .popover--citrus--top-left:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #dc9e27;
  left: 0;
  border-right-color: #dc9e27;
}
.popover-root .popover--dark--top-right, .popover-root .popover--citrus--top-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-right-radius: 0;
}
.popover-root .popover--dark--top-right:before, .popover-root .popover--citrus--top-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--dark--top-right:before, .popover-root .popover--citrus--top-right:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #dc9e27;
  right: 0;
  border-left-color: #dc9e27;
}
.popover-root .popover--dark--bottom-left, .popover-root .popover--citrus--bottom-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
}
.popover-root .popover--dark--bottom-left:before, .popover-root .popover--citrus--bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--dark--bottom-left:before, .popover-root .popover--citrus--bottom-left:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #dc9e27;
  left: 0;
  border-right-color: #dc9e27;
}
.popover-root .popover--dark--bottom, .popover-root .popover--dark--bottom-right, .popover-root .popover--citrus--bottom, .popover-root .popover--citrus--bottom-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
}
.popover-root .popover--dark--bottom:before, .popover-root .popover--dark--bottom-right:before, .popover-root .popover--citrus--bottom:before, .popover-root .popover--citrus--bottom-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--dark--bottom:before, .popover-root .popover--dark--bottom-right:before, .popover-root .popover--citrus--bottom:before, .popover-root .popover--citrus--bottom-right:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #dc9e27;
  right: 0;
  border-left-color: #dc9e27;
}
.popover-root .popover--dark--right, .popover-root .popover--citrus--right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.popover-root .popover--dark--right:before, .popover-root .popover--citrus--right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--dark--right:before, .popover-root .popover--citrus--right:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #dc9e27;
}
.popover-root .popover--dark--left, .popover-root .popover--citrus--left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.popover-root .popover--dark--left:before, .popover-root .popover--citrus--left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.popover-root .popover--dark--left:before, .popover-root .popover--citrus--left:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  left: 100%;
  border-left-color: #dc9e27;
}
.popover-root .popover--dark--right, .popover-root .popover--dark--left, .popover-root .popover--citrus--right, .popover-root .popover--citrus--left {
  white-space: nowrap;
}
.popover-root .popover--dark .popover_item, .popover-root .popover--citrus .popover_item {
  color: #2a3035;
}
.popover-root .popover--dark .popover_item:not(:first-child), .popover-root .popover--citrus .popover_item:not(:first-child) {
  border-top: 1px solid #dc9e27;
}
.popover-root .popover--dark .popover_item:hover, .popover-root .popover--citrus .popover_item:hover {
  background-color: #dc9e27;
}
