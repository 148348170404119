.report-builder-grid.ag-theme-bootstrap.ag-grid-root {
  height: 100%;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-group-cell, .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-cell, .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-cell {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-pinned-left-header .ag-header-group-cell:last-child, .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-pinned-left-header .ag-header-cell:last-child {
  border-right: none;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-row:nth-child(2):not(:last-child), .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-row:last-child:not(:first-child) {
  color: #545b62;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-row:nth-child(2):not(:last-child) {
  background-color: #e0e0e0;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-row:last-child:not(:first-child) {
  background-color: #eeeeee;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-row:not(:last-child) {
  pointer-events: none;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-cell--first, .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-column--first {
  padding-left: 20px;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-header-cell--centered .ag-header-cell-text {
  text-align: center;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-row-odd, .report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-row:not(.ag-row-even) {
  background-color: #fafafa;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-row-last .ag-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-column {
  background-color: transparent;
}
.report-builder-grid.ag-theme-bootstrap.ag-grid-root .ag-cell {
  border-top: 0;
}
