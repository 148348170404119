@import '../../../resources/styles/variables';
@import '../../../resources/styles/mixins/generic';
@import '../../../resources/styles/utils';

$content-height: 172px;

.security-recent-update {
  .card_children {
    padding: 0;
    height: 212px;
  }

  &_no-date {
    padding: gux(2);
  }

  &_content {
    height: $content-height;
  }

  &_item {
    padding: 0 gux(2);
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid $titanium;
    cursor: pointer;
  }

  &_icon {
    padding: 0 gux() 0 0;
    font-size: $font-size-icon;
    color: $citrus;
  }

  &_title {
    color: $white;
    @include truncate();
  }

  &_date {
    padding: 0 0 0 gux();
    color: $silver;
    white-space: nowrap;
  }

  &_footer {
    padding: gux(1.5) gux(2);
    height: 40px;
    background-color: $gunpowder;

    &-link {
      text-transform: uppercase;
      color: $citrus;

      &:hover {
        color: $mustard;
      }
    }
  }
}
