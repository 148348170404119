/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.toast {
  position: fixed;
  z-index: 70;
  top: 20px;
  right: 20px;
}
.toast-item {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  margin-bottom: 4px;
  width: 420px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.toast-item_content {
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
}
.toast-item_icon {
  padding: 20px 0;
  width: 50px;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 3px 0 0 3px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.toast-item_icon--info {
  color: #2a3035;
  background-color: #f1af0f;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.toast-item_icon--info:before {
  font-family: "iris";
  content: "\e9bb";
}
.toast-item_icon--warning {
  background-color: #e74c3c;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.toast-item_icon--warning:before {
  font-family: "iris";
  content: "\e980";
}
.toast-item_icon--success {
  background-color: #1abc9c;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.toast-item_icon--success:before {
  font-family: "iris";
  content: "\e984";
}
.toast-item_icon--error {
  background-color: #e74c3c;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.toast-item_icon--error:before {
  font-family: "iris";
  content: "\ea35";
}
.toast-item_message {
  padding: 20px 0 20px 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.toast-item_close {
  cursor: pointer;
  padding: 10px;
  height: 33px;
}
