/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.peer-movers-widget {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.peer-movers-widget, .peer-movers-widget_header, .peer-movers-widget_footer, .peer-movers-widget_item, .peer-movers-widget_icon, .peer-movers-widget_link {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.peer-movers-widget_header {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.peer-movers-widget_company-switch .switch {
  margin-left: 8px;
}
.peer-movers-widget_title {
  display: inline;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.peer-movers-widget_details {
  text-transform: capitalize;
  color: #939ba0;
}
.peer-movers-widget_items {
  height: calc(100% - 35px - 25px);
}
.peer-movers-widget_item {
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  border-bottom: 1px solid #2a3035;
  cursor: pointer;
}
.peer-movers-widget_item:last-child {
  border-bottom: none;
}
.peer-movers-widget_item:not(.peer-movers-widget_item--narrow) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.peer-movers-widget_item:not(.peer-movers-widget_item--narrow) .peer-movers-widget_icon {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 22px;
  padding-left: 4px;
  text-align: center;
}
.peer-movers-widget_item:not(.peer-movers-widget_item--narrow) .peer-movers-widget_change {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 100px;
  text-align: right;
}
.peer-movers-widget_item:not(.peer-movers-widget_item--narrow) .peer-movers-widget_name {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -moz-box-flex: 2;
  -moz-flex: 2;
  -ms-flex: 2;
  flex: 2;
}
.peer-movers-widget_item--narrow {
  position: relative;
  height: auto;
  padding: 4px 10px;
  line-height: 1.3;
}
.peer-movers-widget_item--narrow .peer-movers-widget_icon {
  position: absolute;
  top: 4px;
  left: 10px;
  width: 24px;
  height: 34px;
  line-height: 34px;
  border-radius: 3px;
  background-color: #22272b;
  text-align: center;
}
.peer-movers-widget_item--narrow .peer-movers-widget_icon i {
  font-size: 14px;
}
.peer-movers-widget_item--narrow .peer-movers-widget_change, .peer-movers-widget_item--narrow .peer-movers-widget_name {
  display: block;
  padding-left: 34px;
}
.peer-movers-widget_item--narrow .peer-movers-widget_change {
  font-size: 18px;
  line-height: 18px;
}
.peer-movers-widget_item--narrow .peer-movers-widget_name {
  line-height: 16px;
}
.peer-movers-widget_item--tall {
  height: 41px;
  line-height: 20px;
  padding: 10px;
}
.peer-movers-widget_item--narrow.peer-movers-widget_item--tall {
  height: auto;
}
.peer-movers-widget_item--narrow.peer-movers-widget_item--tall .peer-movers-widget_icon {
  top: 12px;
}
.peer-movers-widget_item--person {
  cursor: default;
  pointer-events: none;
}
.peer-movers-widget_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.peer-movers-widget_icon--up, .peer-movers-widget_change--up {
  color: #1abc9c;
}
.peer-movers-widget_icon--down, .peer-movers-widget_change--down {
  color: #ec6a4c;
}
.peer-movers-widget_icon--empty, .peer-movers-widget_change--empty {
  color: #939ba0;
}
.peer-movers-widget_icon--empty {
  visibility: hidden;
}
.peer-movers-widget_footer {
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  border-top: 1px solid #2a3035;
  font-size: 11px;
  color: #939ba0;
}
.peer-movers-widget_link {
  float: right;
  font-size: 13px;
  text-transform: uppercase;
  color: #f1af0f;
  cursor: pointer;
}
.peer-movers-widget_link:hover {
  color: #dc9e27;
}
.peer-movers-widget--light {
  color: #545b62;
}
.peer-movers-widget--light .peer-movers-widget_header {
  border-bottom: 1px solid #e0e0e0;
}
.peer-movers-widget--light .peer-movers-widget_item {
  border-bottom: 1px solid #e0e0e0;
}
.peer-movers-widget--light .peer-movers-widget_item:last-child {
  border-bottom: none;
}
.peer-movers-widget--light .peer-movers-widget_item--narrow .peer-movers-widget_icon {
  background-color: #f1f1f1;
}
.peer-movers-widget--light .peer-movers-widget_footer {
  border-top: 1px solid #e0e0e0;
}
.peer-movers-widget--light .peer-movers-widget_link {
  color: #297ac5;
}
.peer-movers-widget--light .peer-movers-widget_link:hover {
  color: #0f5ca3;
}
