@import '../../../resources/styles/variables';
@import '../../../resources/styles/mixins/generic';
@import '../../../resources/styles/utils';

$size: 32px;

@mixin rangeTabTheme(
  $color,
  $backgroundColor,
  $borderColor,
  $hoverColor,
  $hoverBackgroundColor,
  $selectedColor,
  $selectedBackgroundColor,
  $counterColor
) {
  .range-tab {
    &_button {
      color: $color;
      border-color: $borderColor;
      background-color: $backgroundColor;

      &--active {
        color: $selectedColor;
        background-color: $selectedBackgroundColor;
        border-color: $selectedBackgroundColor;
      }

      &--disabled {
        color: rgba($color, .3);;
      }

      &:hover:not(.range-tab_button--active):not(.range-tab_button--disabled) {
        color: $hoverColor;
        background-color: $hoverBackgroundColor;
        border-color: $hoverBackgroundColor;
      }
    }

    &_counter {
      background-color: $counterColor;
    }
  }
}

.range-tab {
  display: inline-block;

  &_button, &_counter {
    transition: 0.3s ease;
  }

  &_button {
    height: $size;
    margin: 0 0 0 -1px;
    padding: 0 gux(2);
    vertical-align: top;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;

    &:first-child {
      margin: 0;
      border-radius: $border-radius-small 0 0 $border-radius-small;
    }

    &:last-child {
      border-radius: 0 $border-radius-small $border-radius-small 0;
    }

    &--disabled {
      cursor: $cursor-disabled;
    }

    &--empty {
      padding: 0 gux();

      .range-tab {
        &_icon {
          margin: 0;
          padding: 0;
        }

        &_label {
          display: none;
        }
      }
    }
  }

  &_icon,
  &_label {
    display: inline-block;
  }

  &_icon {
    padding-right: gux();
  }

  &_label {
    max-width: 150px;
    line-height: $size - 2px;
    text-transform: capitalize;
    @include truncate();
  }

  &_counter {
    display: inline-block;
    margin-left: gux(0.75);
    padding: 0 gux(0.75);
    font-size: $font-size-table;
    line-height: 17px;
    border-radius: $border-radius-xlarge;
  }

  // themes
  &--light {
    @include rangeTabTheme($light-slate, transparent, $light-grey, $light-slate, $light-grey, $white, $rain, $steel);
  }

  &--soft-grey {
    @include rangeTabTheme($light-slate, transparent, $soft-grey, $rain, $soft-grey, $rain, $soft-grey, $silver);
  }

  &--rain {
    @include rangeTabTheme($white, transparent, $rain, $white, $steel, $white, $rain, $steel);
  }

  &--q4-blue {
    @include rangeTabTheme($white, transparent, $q4-blue, $white, $ink, $white, $q4-blue, $ink);
  }

  &--steel {
    @include rangeTabTheme($white, transparent, $steel, $white, $q4-blue, $white, $steel, $q4-blue);
  }

  &--ink {
    @include rangeTabTheme($white, transparent, $ink, $white, $deep-blue, $white, $ink, $deep-blue);
  }

  &--dark {
    @include rangeTabTheme($white, mix(white, $gunpowder, 5%), $gunpowder, $citrus, $gunpowder, $citrus, $gunpowder, $black-smoke);
  }
}
