.report-peers {
  color: #545b62;
}
.report-peers_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-peers_title {
  font-size: 16px;
}
.report-peers_subtitle {
  margin-top: 4px;
  color: #939ba0;
}
.report-peers_action {
  cursor: pointer;
}
.report-peers_action + .report-peers_action {
  margin-left: 16px;
}
.report-peers_search {
  margin-top: 16px;
}
.report-peers_item-symbol, .report-peers_item-exchange {
  margin-right: 4px;
}
.report-peers_item-exchange, .report-peers_item-seperator, .report-peers_item-name {
  color: #939ba0;
}
.report-peers_item-name {
  margin-left: 4px;
}
.report-peers_item--search .report-peers_item-symbol, .report-peers_item--search .report-peers_item-exchange, .report-peers_item--search .report-peers_item-seperator, .report-peers_item--search .report-peers_item-name {
  color: #ffffff;
}
