.stock-quote-container {
  height: 100%;
  padding: 40px 15px;
  font-size: 13px;
  color: #ffffff;
  cursor: pointer;
}
.stock-quote-container, .stock-quote-container_item {
  transition: 0.3s ease;
}
.stock-quote-container--light {
  color: #545b62;
}
.stock-quote-container--light .stock-quote_item--neutral {
  color: #545b62;
}
