@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/generic';
@import '../../../../resources/styles/utils';

.ag-grid-root {

  .cell,
  .expandable-cell {
    display: flex;

    &_label {
      max-width: 90%;
      @include truncate();
    }
  }

  .expandable-cell {
    &_count {
      padding-left: gux(0.5);
      color: $surf;
    }

    &--clickable:hover {
      color: $surf;
      cursor: pointer;
    }
  }
}
