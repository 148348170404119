@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';
@import '../../../resources/styles/mixins/all';

.search-page_list {
  position: relative;
  z-index: $z-index--base;
  min-height: 180px;
  background-color: $white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow--light;

  &--loading {
    min-height: 480px;
  }

  .item {
    padding: gux(3);
    display: flex;
    cursor: pointer;
    color: inherit;

    &:not(:first-of-type) {
      border-top: 1px solid $light-grey;
    }

    &_icon {
      padding-top: gux(0.5);
      width: 50px;
      font-size: $font-size-large;

      &-contact {
        color: $rain;
      }

      &-institution {
        color: $spice;
      }

      &-fund {
        color: $teal;
      }
    }

    &_content {
      flex: 1;

      &-title {
        font-size: $font-size-large;
        line-height: $line-height-large;
      }

      &-subtitle {
        font-size: $font-size-medium;
        line-height: $line-height-large;

        span {
          color: $silver;
        }

        &--small {
          padding-top: gux(0.5);
          font-size: $font-size-base;
          color: $silver;
        }

        .pipe:before {
          padding: 0 gux(0.5);
          content: '|';
        }
      }

      .email {
        color: $light-slate;
      }

      &-body {
        padding-top: gux(2);
      }

      &-detail {
        display: flex;
        line-height: $line-height-medium;

        &:not(:first-child) {
          padding-top: gux();
        }

        em {
          color: $rain;
          font-style: initial;
        }
      }

      &-label {
        padding-right: gux();
        text-transform: uppercase;
        color: $silver;

        &--ownership {
          text-transform: capitalize;
        }
      }
    }

    &_actions {
      height: 30px;
      margin-left: gux(2);
    }
  }

  &:not(.search-page_list--loading):not(.search-page_list--few) {
    .spinner {
      padding-top: calc((480px - 25px) / 2);
      justify-content: flex-start;
    }
  }
}
