@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/utils';

.security-movers {

  .card_children {
    height: 340px;
  }

  &_title {
    display: flex;
    padding-bottom: gux(1.5);
    text-transform: uppercase;
    color: $white;

    &-type {
      flex: 1;
    }

    &-metric {
      justify-content: flex-end;
    }
  }

  &_section {
    min-height: 160px;

    &:not(:first-child) {
      border-top: 1px solid $titanium;
    }
  }
}
