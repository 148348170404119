.scroll-up-button {
  position: fixed;
  bottom: -40px;
  right: 35px;
  float: right;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  background-color: #0f5ca3;
  color: #fff;
  transition: 0.3s ease;
  transition-property: bottom, background-color, color;
  cursor: pointer;
  z-index: 10;
}
.scroll-up-button:hover {
  background-color: #0A457C;
}
.scroll-up-button--visible {
  bottom: 20px;
}
.scroll-up-button--sticky {
  position: relative;
  top: 10px;
  right: 0;
}
