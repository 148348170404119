@import '../../../resources/styles/variables';
@import '../../../resources/styles/mixins/generic';
@import '../../../resources/styles/utils';

.security-page {

  .security-banner-title {
    display: flex;
    align-items: center;

    &_name {
      flex: 1;
      @include truncate();
    }

    &_ticker {
      padding-left: gux(2);

      &--exchange {
        padding-left: gux();
        color: $silver;
      }
    }
  }
}
