/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.dropdown-toggle {
  background-color: #f1f1f1;
  color: #545b62;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0;
  cursor: pointer;
  transition-property: color, background-color;
}
.dropdown-toggle, .dropdown-toggle_toggler {
  transition: 0.3s ease;
}
.dropdown-toggle_toggler {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 14px;
  max-width: 34px;
  height: 100%;
  transition-property: transform;
  z-index: 5;
}
.dropdown-toggle_toggler i {
  font-size: inherit;
}
.dropdown-toggle_value {
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
}
.dropdown-toggle_value-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px 0 14px;
}
.dropdown-toggle input,
.dropdown-toggle button {
  background: transparent;
  color: inherit;
  padding: 0 30px 0 14px;
  height: 100%;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.dropdown-toggle input::placeholder {
  font-family: "Open Sans", sans-serif;
  color: #939ba0;
}
.dropdown-toggle input {
  cursor: auto;
}
.dropdown-toggle .prepend {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}
.dropdown-toggle .prepend span {
  position: relative;
  left: 10px;
}
.dropdown-toggle--whites {
  background-color: #ffffff;
  color: #545b62;
}
.dropdown-toggle--slate {
  background-color: #2a3035;
  color: #ffffff;
}
.dropdown-toggle--dark-slate {
  background-color: #22272b;
  color: #ffffff;
}
.dropdown-toggle--pale-grey {
  background-color: #f1f1f1;
  color: #545b62;
}
.dropdown-toggle--steel {
  background-color: #1f6bb0;
  color: #ffffff;
}
.dropdown-toggle--rain {
  background-color: #297ac5;
  color: #ffffff;
}
.dropdown-toggle--q4-blue {
  background-color: #0f5ca3;
  color: #ffffff;
}
.dropdown-toggle--ink {
  background-color: #10528f;
  color: #ffffff;
}
.dropdown-toggle--silver {
  background-color: #939ba0;
  color: #ffffff;
}
.dropdown-toggle--citrus {
  background-color: #f1af0f;
  color: #22272b;
}
