/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.estimates-consensus_list {
  position: relative;
}
.estimates-consensus .data-table_cell--broker-details-available {
  color: #3498db;
  cursor: pointer;
  transition: color 0.3s;
}
.estimates-consensus .data-table_cell--broker-details-available:hover {
  color: #0f5ca3;
}
.estimates-consensus .data-table th.data-table_cell[class*=fy] {
  background-color: #5dade2;
  color: #ffffff;
}
