.activity-form__activity-dates .activity-date_container {
  display: flex;
  padding-bottom: 16px;
}
.activity-form__activity-dates .activity-date {
  position: relative;
  z-index: 8;
}
.activity-form__activity-dates .activity-date_pickers {
  display: flex;
}
.activity-form__activity-dates .activity-date_pickers .select-component {
  margin-left: 8px;
}
.activity-form__activity-dates .activity-date_pickers .select-component .select__control {
  min-width: 140px;
}
.activity-form__activity-dates .activity-date_field--timezone {
  padding-top: 35px;
}
.activity-form__activity-dates .activity-date_fields {
  display: flex;
  flex-wrap: wrap;
  max-width: 660px;
}
.activity-form__activity-dates .activity-date_fields .activity-date_field {
  padding-right: 16px;
}
.activity-form__activity-dates .activity-date_fields .activity-date_field:nth-child(n+3) {
  padding-top: 24px;
}
.activity-form__activity-dates .activity-date_fields .timezone-picker {
  width: 340px;
}
.activity-form__activity-dates .activity-date .date-picker, .activity-form__activity-dates .activity-date .dropdown {
  display: inline-block;
  vertical-align: top;
}
.activity-form__activity-dates .select_menu.select__menu {
  z-index: 10;
}
.activity-form__activity-dates .date-picker {
  width: 140px;
}
.activity-form__activity-dates .date-picker + .dropdown {
  margin-left: 10px;
}
