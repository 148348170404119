/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.nav-tooltip .nav-tooltip_item {
  margin: 0;
  padding: 0 14px;
  font-size: 13px;
  line-height: 30px;
  text-transform: uppercase;
  color: #22272b;
  background-color: #f1af0f;
  border-radius: 0 3px 3px 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
  pointer-events: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.nav-tooltip .nav-tooltip_item:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.nav-tooltip .nav-tooltip_item:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #dc9e27;
}
.nav-tooltip .nav-tooltip_item--new:after {
  content: "NEW";
  margin-left: 10px;
  padding: 0 4px;
  line-height: 14px;
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 0.5px;
  color: #ffffff;
  background-color: #22272b;
  border-radius: 2px;
}
