.widget-card {
  position: relative;
  margin: 0 20px 10px;
  padding: 30px;
  border-radius: 5px;
  background-color: #1a1d21;
}
.widget-card:last-child {
  margin-bottom: 15px;
}
.widget-card_title {
  margin-bottom: 5px;
  font-size: 20px;
  color: #f1af0f;
}
.widget-card_indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  padding: 6px 0 0 4px;
  border-top-right-radius: 5px;
  background-color: #f1af0f;
  color: #22272b;
}
.widget-card_indicator:before, .widget-card_indicator:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 25px 0;
  border-color: transparent #f1af0f transparent transparent;
}
.widget-card_indicator:before {
  top: 0;
  left: -25px;
}
.widget-card_indicator:after {
  top: 25px;
  right: 0;
}
.widget-card_description {
  line-height: 1.5;
}
.widget-card_description .widget-card_link {
  margin-left: 5px;
}
.widget-card_link {
  cursor: pointer;
  color: #f1af0f;
}
.widget-card_link:hover {
  color: #dc9e27;
}
.widget-card_actions {
  margin-top: 15px;
}
.widget-card--locked .widget-card_indicator {
  background-color: #ec6a4c;
  color: #ffffff;
}
.widget-card--locked .widget-card_indicator:before, .widget-card--locked .widget-card_indicator:after {
  border-color: transparent #ec6a4c transparent transparent;
}
.widget-card--too-large {
  opacity: 0.5;
  pointer-events: none;
}
