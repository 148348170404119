.widget-library {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #ffffff;
  overflow: hidden;
  z-index: 50;
}
.widget-library, .widget-library_categories, .widget-library_selection, .widget-library_mask, .widget-library_list-item, .widget-library_list-icon, .widget-library_list-label, .widget-library_back {
  transition: 0.3s ease;
}
.widget-library_categories, .widget-library_selection, .widget-library_mask {
  position: absolute;
  top: 0;
}
.widget-library_categories, .widget-library_selection {
  height: 100%;
  background-color: #2a3035;
  overflow: hidden;
}
.widget-library_categories {
  left: 0;
  width: 300px;
  padding-bottom: 90px;
  border-right: 1px solid #1a1d21;
  z-index: 52;
}
.widget-library_categories--selected .widget-library_back {
  opacity: 0;
  pointer-events: none;
}
.widget-library_title {
  margin: 25px 0 0;
  padding: 0 20px;
  font-size: 16px;
  line-height: 30px;
}
.widget-library_title:first-child {
  margin: 15px 0;
}
.widget-library_title--unavailable:first-child {
  margin: 15px 0 0;
}
.widget-library_list {
  list-style: none;
  border-top: 1px solid #1a1d21;
}
.widget-library_list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid #1a1d21;
  cursor: pointer;
}
.widget-library_list-item--active, .widget-library_list-item:hover {
  background-color: #f1af0f;
}
.widget-library_list-item--active .widget-library_list-icon, .widget-library_list-item--active .widget-library_list-label, .widget-library_list-item:hover .widget-library_list-icon, .widget-library_list-item:hover .widget-library_list-label {
  color: #2a3035;
}
.widget-library_list-icon, .widget-library_list-arrow {
  width: 40px;
}
.widget-library_list-icon i, .widget-library_list-arrow i {
  line-height: 60px;
}
.widget-library_list-icon {
  font-size: 26px;
  text-align: left;
  color: #f1af0f;
}
.widget-library_list-label {
  display: inline-block;
  width: calc(100% - 80px);
  line-height: 60px;
  text-transform: uppercase;
  color: #ffffff;
}
.widget-library_list-arrow {
  font-size: 18px;
  text-align: right;
  color: #2a3035;
}
.widget-library_suggestion {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px 20px;
}
.widget-library_suggestion .button {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.widget-library_selection {
  display: block;
  width: 450px;
  left: -450px;
  z-index: 51;
}
.widget-library_selection--open {
  left: 300px;
}
.widget-library_message {
  padding: 0 20px;
  margin-bottom: 20px;
  line-height: 1.5;
}
.widget-library_disclaimer {
  display: block;
  margin: 15px 0 20px;
  padding-left: 22px;
  font-size: 11px;
  line-height: 13px;
}
.widget-library_disclaimer i {
  margin-right: 8px;
  font-size: 13px;
}
.widget-library_back {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  opacity: 1;
  font-size: 16px;
  color: #f1af0f;
  text-align: right;
  cursor: pointer;
  z-index: 52;
}
.widget-library_back--pushed {
  left: 710px;
}
.widget-library_mask {
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  z-index: 50;
}
.widget-library--hidden {
  width: 0;
}
.widget-library--hidden .widget-library_categories {
  left: -300px;
}
.widget-library--hidden .widget-library_mask {
  opacity: 0;
}
