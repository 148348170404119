/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.message-root {
  z-index: 55 !important;
}
.message-root .message {
  width: 600px;
  max-height: 95vh;
  margin: 8px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
}
.message-root .message_content {
  min-height: 160px;
  padding: 32px 32px 24px;
  text-align: center;
  background-color: #ffffff;
}
.message-root .message_exit {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s ease;
}
.message-root .message_exit:hover {
  color: #dddddd;
}
.message-root .message_title {
  padding-bottom: 24px;
  font-size: 30px;
}
.message-root .message_text {
  font-size: 16px;
  line-height: 22px;
}
.message-root .message_list {
  margin-top: 24px;
}
.message-root .message_list-row {
  padding: 0 8px;
  height: 32px;
  line-height: 31px;
  color: #545b62;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}
.message-root .message_list-row--header {
  line-height: 32px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #0f5ca3;
  border-right: 1px solid #0f5ca3;
  border-left: 1px solid #0f5ca3;
  border-radius: 3px 3px 0 0;
  border-bottom: none;
}
.message-root .message_list-row:last-child {
  border-radius: 0 0 3px 3px;
}
.message-root .message_list--no-data {
  color: #939ba0;
}
.message-root .message_footer {
  padding: 16px 32px;
  background-color: #2a3035;
  display: flex;
  justify-content: center;
}
.message-root .message--warning .message_title, .message-root .message--error .message_title {
  color: #ec6a4c;
}
.message-root .message--success .message_title {
  color: #1abc9c;
}
.message-root .message--info .message_content {
  padding-top: 24px;
  text-align: left;
}
.message-root .message--info .message_title {
  color: #2a3035;
  font-size: 20px;
}
.message-root .message--info .message_text {
  font-size: 13px;
  color: #545b62;
}
.message-root .message--info .message_text p + p {
  margin-top: 8px;
}
.message-root .message--info .message_text strong {
  font-weight: 400;
  color: #121517;
}
.message-root .message--info .message_text i {
  margin: 0 2px;
  color: #297ac5;
  font-size: 16px;
  vertical-align: text-bottom;
}
.message-root .message--info .message_footer {
  justify-content: flex-end;
}
