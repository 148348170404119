.mini-calendar {
  width: 100%;
  background: transparent;
  border: none;
  font-family: "Open Sans", sans-serif;
}
.mini-calendar .react-calendar, .mini-calendar .react-calendar__tile, .mini-calendar .react-calendar__navigation, .mini-calendar .react-calendar__navigation__label, .mini-calendar .react-calendar__navigation__arrow:before {
  transition: 0.3s ease;
}
.mini-calendar .react-calendar__navigation {
  height: 35px;
  line-height: 35px;
  margin: 0;
  padding: 0 10px;
  align-items: center;
}
.mini-calendar .react-calendar__navigation__label {
  padding: 0;
  text-align: left;
  color: #2a3035;
  pointer-events: none;
}
.mini-calendar .react-calendar__navigation__prev-button, .mini-calendar .react-calendar__navigation__next-button {
  min-width: 24px;
  padding: 0;
  font-size: 12px;
  color: #2a3035;
  background-color: transparent;
  border-radius: 3px;
  height: 24px;
  padding: 4px 4px;
}
.mini-calendar .react-calendar__navigation__prev-button {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  order: 2;
}
.mini-calendar .react-calendar__navigation__prev-button:before {
  font-family: "iris";
  content: "\e997";
}
.mini-calendar .react-calendar__navigation__next-button {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  order: 3;
}
.mini-calendar .react-calendar__navigation__next-button:before {
  font-family: "iris";
  content: "\e9a1";
}
.mini-calendar .react-calendar__navigation__prev2-button, .mini-calendar .react-calendar__navigation__next2-button {
  display: none;
}
.mini-calendar .react-calendar__month-view {
  padding: 4px 0;
}
.mini-calendar .react-calendar__tile {
  font-size: 13px;
  height: 30px;
  padding: 0;
  color: #2a3035;
  text-align: center;
  border-radius: 3px;
}
.mini-calendar .react-calendar__tile:hover {
  background: #eeeeee;
}
.mini-calendar .react-calendar__tile--active, .mini-calendar .react-calendar__tile--active:hover, .mini-calendar .react-calendar__tile--active:focus, .mini-calendar .react-calendar__tile--active:enabled:hover, .mini-calendar .react-calendar__tile--active:enabled:focus {
  background: #297ac5;
  color: #ffffff;
}
.mini-calendar .react-calendar__tile div {
  font-size: 26px;
  line-height: 3px;
  color: #939ba0;
}
.mini-calendar .react-calendar__month-view__weekdays__weekday {
  height: 24px;
  line-height: 24px;
  padding: 0;
  font-weight: 400;
  font-size: 11px;
  color: #2a3035;
}
.mini-calendar .react-calendar__month-view__days__day--neighboringMonth:not(.react-calendar__tile--now) {
  color: #939ba0 !important;
}
