.estimates-page {
  background-color: #ffffff;
}
.estimates-page_peer-label {
  cursor: pointer;
}
.estimates-page_peer-label--exchange {
  color: #939ba0;
}
.estimates-page_peer-label--symbol {
  margin-right: 5px;
  color: #297ac5;
}
.estimates-page .estimates_toolbar {
  display: flex;
  justify-content: space-between;
}
.estimates-page_company-details {
  transition: color 0.3s;
  cursor: pointer;
}
.estimates-page_company-details span + span {
  margin-left: 3px;
}
.estimates-page_company-details--banner {
  color: #f1af0f;
}
.estimates-page_company-details--banner:hover {
  color: #dc9e27;
}
.estimates-page_company-details--section-header:hover {
  color: #297ac5;
}
.estimates-page_annotation {
  padding: 20px 20px 30px;
  color: #939ba0;
}
