.address-book-page_body {
  display: flex;
  min-height: 600px;
  background-color: #ffffff;
}
.address-book-page_sidebar {
  width: 264px;
}
.address-book-page_grid {
  flex: 1;
}
.address-book-page_grid-table {
  position: relative;
  min-height: calc(100vh - 80px - 130px - 70px);
  z-index: 0;
}
