@import '../../resources/styles/variables';
@import '../../resources/styles/utils';

.search-page {
  display: flex;
  padding: gux(4) gux(18);
  min-height: calc(100vh - #{$app-header-height});
  background-color: $muted-grey;

  &_result {
    flex: 1;

    &-pagination {
      padding: gux(4) 0 0;
    }
  }
}