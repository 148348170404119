/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.activity-form__activity-editor .editor-fontsize {
  width: 100px;
  height: 30px;
  border-radius: 3px;
  background: #f1f1f1;
  cursor: pointer;
  z-index: 1;
}
.activity-form__activity-editor .editor-fontsize[aria-expanded=true] {
  border-radius: 3px 3px 0 0;
}
.activity-form__activity-editor .editor-fontsize .rdw-dropdown-selectedtext, .activity-form__activity-editor .editor-fontsize .rdw-dropdown-optionwrapper {
  width: 100%;
  color: #545b62;
}
.activity-form__activity-editor .editor-fontsize .rdw-dropdown-selectedtext {
  height: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.activity-form__activity-editor .editor-fontsize .rdw-dropdown-selectedtext:after {
  font-family: "iris";
  content: "\e995";
}
.activity-form__activity-editor .editor-fontsize .rdw-dropdown-selectedtext:after {
  color: #0f5ca3;
}
.activity-form__activity-editor .editor-fontsize .rdw-dropdown-optionwrapper {
  height: 150px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.activity-form__activity-editor .editor-fontsize .rdw-dropdown-optionwrapper li {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  background: #eeeeee;
  border-top: 1px solid #e0e0e0;
}
.activity-form__activity-editor .editor-fontsize .rdw-dropdown-optionwrapper li:hover {
  background: #e0e0e0;
}
.activity-form__activity-editor .editor_design {
  z-index: 0;
}
