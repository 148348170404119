/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.data-table {
  display: table;
  width: 100%;
  background-color: #ffffff;
  border-spacing: 0;
  border-collapse: collapse;
}
.data-table-root {
  position: relative;
}
.data-table_action-toolbar {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  width: 100%;
  height: 50px;
  background-color: #1f6bb0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.data-table_action-toolbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-right: 1px solid #10528f;
  background-color: #0f5ca3;
  color: #ffffff;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.data-table_action-toolbar-item:first-child {
  justify-content: flex-start;
  width: 70px;
  padding-left: 30px;
  background-color: transparent;
}
.data-table_action-toolbar-item:not(:first-child):hover {
  background-color: #10528f;
}
.data-table_action-toolbar-item:first-child, .data-table_action-toolbar-item:last-child {
  border: none;
}
.data-table_action-toolbar-item i {
  font-size: 20px;
}
.data-table_action-toolbar--visible {
  opacity: 1;
  pointer-events: initial;
}
.data-table_head {
  background-color: #3498db;
}
.data-table_footer .data-table_cell {
  padding: 30px 0;
  border-bottom: none;
}
.data-table_row--clickable, .data-table_cell--clickable {
  cursor: pointer;
}
.data-table_row {
  height: 50px;
}
.data-table_row--child + .data-table_row--parent .data-table_cell {
  border-top: 1px solid #e0e0e0;
}
.data-table_row--parent .data-table_cell[rowspan] {
  vertical-align: top;
}
.data-table_row--no-results .data-table_cell {
  padding: 60px 30px;
}
.data-table_row--expanded {
  background: #f1f1f1;
}
.data-table_cell {
  min-width: 40px;
  padding: 4px 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
  color: #545b62;
}
.data-table_cell:first-child:last-child {
  width: 100%;
}
.data-table_cell--head {
  border-bottom: none;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
}
.data-table_cell--head[aria-sort]:after {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.data-table_cell--head[aria-sort=ascending]:after {
  font-family: "iris";
  content: "\e9a2";
}
.data-table_cell--head[aria-sort=descending]:after {
  font-family: "iris";
  content: "\e995";
}
.data-table_cell--head[aria-sort]:after {
  margin-left: 10px;
}
.data-table_cell--head.data-table_cell--alternate {
  background-color: #2f89c5;
}
.data-table_cell--head.data-table_cell--highlighted {
  background-color: #f1af0f;
  color: #22272b;
}
.data-table_cell--body.data-table_cell--alternate {
  background-color: #f7f7f7;
}
.data-table_cell--sortable {
  cursor: pointer;
}
.data-table_cell--auto {
  width: auto;
}
.data-table_cell--widest {
  width: 300px;
}
.data-table_cell--wider {
  width: 240px;
}
.data-table_cell--wide {
  width: 160px;
}
.data-table_cell--default {
  width: 120px;
}
.data-table_cell--narrow {
  width: 100px;
}
.data-table_cell--narrower {
  width: 80px;
}
.data-table_cell--checkbox {
  width: 70px;
}
.data-table_cell--icon {
  width: 50px;
}
.data-table_cell--dense:first-child {
  padding-left: 32px;
}
.data-table_cell--dense:last-child {
  padding-right: 30px;
}
.data-table_cell--narrower:first-child, .data-table_cell--icon:first-child {
  padding-left: 10px;
}
.data-table_cell--narrower:last-child, .data-table_cell--icon:last-child {
  padding-right: 10px;
}
.data-table_cell--left {
  text-align: left;
}
.data-table_cell--right {
  text-align: right;
}
.data-table_cell--center {
  text-align: center;
}
.data-table_cell--hidden {
  display: none;
}
.data-table_value--up {
  color: #1abc9c;
}
.data-table_value--down {
  color: #ec6a4c;
}
.data-table_value--indented {
  margin-left: 20px;
}
.data-table_value--truncate {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-table_footer tr {
  height: auto;
}
.data-table--alternating .data-table_cell--head:not(:first-child) {
  text-align: center;
}
.data-table--fixed {
  table-layout: fixed;
}
.data-table--small .data-table_row {
  height: 40px;
}
.data-table--xsmall .data-table_row {
  height: 30px;
}
