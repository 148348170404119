@import '../../../resources/styles/variables';
@import '../../../resources/styles/mixins/icons';
@import '../../../resources/styles/icons/variables';
@import '../../../resources/styles/utils';

.security-attribute {

  .card_children {
    height: 212px;
  }

  &_stock {
    display: flex;
    align-items: center;
    font-size: $font-size-medium;

    &-arrow {
      padding: 0 gux(0.5) 0 gux(0.75);
      font-size: calc(#{$font-size-base} + 1px);

      &--positive {
        color: $teal;
        @include q4-icon($q4i-arrow-up-4pt);
      }

      &--negative {
        color: $spice;
        @include q4-icon($q4i-arrow-down-4pt);
      }
    }

    &-change {
      color: $silver;
    }
  }
}
