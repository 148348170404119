@import '../../../resources/styles/variables';
@import '../../../resources/styles/mixins/icons';
@import '../../../resources/styles/icons/variables';
@import '../../../resources/styles/mixins/all';
@import '../../../resources/styles/utils';


@mixin textAreaTheme($textColor, $backgroundColor, $placeholderColor, $errorColor, $disabledBackgroundColor) {
  .text-area_label {
    color: $textColor;
  }

  .text-area_field {
    color: $textColor;
    background-color: $backgroundColor;
    @include placeholder((color: $placeholderColor));
  }

  &.text-area--error {
    .text-area_field {
      background-color: lighten($errorColor, 30);
      color: $errorColor;
      @include placeholder((color: lighten($errorColor, 15)));
    }
  }

  &.text-area--disabled {
    .text-area_field {
      background-color: $disabledBackgroundColor;
    }
  }
}

.text-area {

  &_label {
    display: block;
    margin-bottom: gux();
    user-select: none;

    &--required {
      font-size: $font-size-table;
      color: $silver;
    }
  }

  &_field {
    padding: gux();
    width: 100%;
    border-radius: $border-radius-small;
    resize: none;
  }

  // themes
  &--light {
    @include textAreaTheme($light-slate, $light-grey, $silver, $spice, $light-grey);
  }

  &--dark {
    //@include textAreaTheme();
  }

  &--white {
    @include textAreaTheme($light-slate, $white, $silver, $spice, $pale-grey);
  }
}
