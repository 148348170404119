.checkbox-field_label {
  display: inline-block;
  line-height: 22px;
  user-select: none;
  white-space: nowrap;
}
.checkbox-field_label:before, .checkbox-field_label:after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  padding-top: 1px;
  line-height: 18px;
  border-radius: 3px;
  font-size: 11px;
  text-align: center;
  vertical-align: text-bottom;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}
.checkbox-field_label--right {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
}
.checkbox-field_label--right:before {
  font-family: "iris";
  content: "\e984";
}
.checkbox-field_label--right:before {
  margin-right: 8px;
}
.checkbox-field_label--right:after {
  display: none;
}
.checkbox-field_label--left {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
}
.checkbox-field_label--left:after {
  font-family: "iris";
  content: "\e984";
}
.checkbox-field_label--left:before {
  display: none;
}
.checkbox-field_label--left:after {
  margin-left: 8px;
}
.checkbox-field_input {
  display: none;
}
.checkbox-field--inline {
  display: inline-block;
}
.checkbox-field--inline:not(:first-child) {
  margin-left: 10px;
}
.checkbox-field--dark .checkbox-field_label:before, .checkbox-field--dark .checkbox-field_label:after {
  background-color: #22272b;
  color: transparent;
}
.checkbox-field--dark.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--dark.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--dark.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--dark.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #f1af0f;
  color: #22272b;
}
.checkbox-field--light .checkbox-field_label:before, .checkbox-field--light .checkbox-field_label:after {
  background-color: #eeeeee;
  color: transparent;
}
.checkbox-field--light.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--light.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--light.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--light.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #297ac5;
  color: #ffffff;
}
.checkbox-field--light-slate .checkbox-field_label:before, .checkbox-field--light-slate .checkbox-field_label:after {
  background-color: #545b62;
  color: transparent;
}
.checkbox-field--light-slate.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--light-slate.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--light-slate.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--light-slate.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #f1af0f;
  color: #ffffff;
}
.checkbox-field--soft-grey .checkbox-field_label:before, .checkbox-field--soft-grey .checkbox-field_label:after {
  background-color: #e0e0e0;
  color: transparent;
}
.checkbox-field--soft-grey.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--soft-grey.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--soft-grey.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--soft-grey.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #297ac5;
  color: #ffffff;
}
.checkbox-field--teal .checkbox-field_label:before, .checkbox-field--teal .checkbox-field_label:after {
  background-color: #eeeeee;
  color: transparent;
}
.checkbox-field--teal.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--teal.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--teal.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--teal.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #1abc9c;
  color: #ffffff;
}
.checkbox-field--soft-teal .checkbox-field_label:before, .checkbox-field--soft-teal .checkbox-field_label:after {
  background-color: #e0e0e0;
  color: transparent;
}
.checkbox-field--soft-teal.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--soft-teal.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--soft-teal.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--soft-teal.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #1abc9c;
  color: #ffffff;
}
.checkbox-field--q4-blue-teal .checkbox-field_label:before, .checkbox-field--q4-blue-teal .checkbox-field_label:after {
  background-color: #0f5ca3;
  color: transparent;
}
.checkbox-field--q4-blue-teal.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--q4-blue-teal.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--q4-blue-teal.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--q4-blue-teal.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #1abc9c;
  color: #ffffff;
}
.checkbox-field--q4-blue-white .checkbox-field_label:before, .checkbox-field--q4-blue-white .checkbox-field_label:after {
  background-color: #0f5ca3;
  color: transparent;
}
.checkbox-field--q4-blue-white.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--q4-blue-white.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--q4-blue-white.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--q4-blue-white.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #ffffff;
  color: #545b62;
}
.checkbox-field--white-rain .checkbox-field_label:before, .checkbox-field--white-rain .checkbox-field_label:after {
  background-color: #ffffff;
  color: transparent;
}
.checkbox-field--white-rain.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--white-rain.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--white-rain.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--white-rain.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #297ac5;
  color: #ffffff;
}
.checkbox-field--white-steel .checkbox-field_label:before, .checkbox-field--white-steel .checkbox-field_label:after {
  background-color: #ffffff;
  color: transparent;
}
.checkbox-field--white-steel.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--white-steel.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--white-steel.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--white-steel.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #1f6bb0;
  color: #ffffff;
}
.checkbox-field--steel-teal .checkbox-field_label:before, .checkbox-field--steel-teal .checkbox-field_label:after {
  background-color: #1f6bb0;
  color: transparent;
}
.checkbox-field--steel-teal.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--steel-teal.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--steel-teal.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--steel-teal.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #1abc9c;
  color: #ffffff;
}
.checkbox-field--spice .checkbox-field_label:before, .checkbox-field--spice .checkbox-field_label:after {
  background-color: #eeeeee;
  color: transparent;
}
.checkbox-field--spice.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--spice.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--spice.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--spice.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #ec6a4c;
  color: #ffffff;
}
.checkbox-field--rain .checkbox-field_label:before, .checkbox-field--rain .checkbox-field_label:after {
  background-color: #eeeeee;
  color: transparent;
}
.checkbox-field--rain.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--rain.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--rain.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--rain.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #297ac5;
  color: #ffffff;
}
.checkbox-field--steel .checkbox-field_label:before, .checkbox-field--steel .checkbox-field_label:after {
  background-color: #1f6bb0;
  color: transparent;
}
.checkbox-field--steel.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--steel.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--steel.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--steel.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #2a3035;
  color: #ffffff;
}
.checkbox-field--q4-blue .checkbox-field_label:before, .checkbox-field--q4-blue .checkbox-field_label:after {
  background-color: #0f5ca3;
  color: transparent;
}
.checkbox-field--q4-blue.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--q4-blue.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--q4-blue.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--q4-blue.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #2a3035;
  color: #ffffff;
}
.checkbox-field--ink .checkbox-field_label:before, .checkbox-field--ink .checkbox-field_label:after {
  background-color: #10528f;
  color: transparent;
}
.checkbox-field--ink.checkbox-field--checked .checkbox-field_label:before,
.checkbox-field--ink.checkbox-field--checked .checkbox-field_label:after, .checkbox-field--ink.checkbox-field--indeterminate .checkbox-field_label:before,
.checkbox-field--ink.checkbox-field--indeterminate .checkbox-field_label:after {
  background-color: #2a3035;
  color: #ffffff;
}
.checkbox-field--box-only .checkbox-field_label:before, .checkbox-field--box-only .checkbox-field_label:after {
  margin: 0;
}
.checkbox-field--custom-color.checkbox-field--checked .checkbox-field_label:before, .checkbox-field--custom-color.checkbox-field--checked .checkbox-field_label:after {
  color: inherit;
  background-color: currentColor;
}
.checkbox-field--small .checkbox-field_label:before, .checkbox-field--small .checkbox-field_label:after, .checkbox-field--xsmall .checkbox-field_label:before, .checkbox-field--xsmall .checkbox-field_label:after {
  padding-top: 0;
  font-size: 8px;
  vertical-align: top;
}
.checkbox-field--small .checkbox-field_label--right:before, .checkbox-field--xsmall .checkbox-field_label--right:before {
  margin-right: 5px;
}
.checkbox-field--small .checkbox-field_label--left:after, .checkbox-field--xsmall .checkbox-field_label--left:after {
  margin-left: 5px;
}
.checkbox-field--small .checkbox-field_label {
  line-height: 13px;
}
.checkbox-field--small .checkbox-field_label:before, .checkbox-field--small .checkbox-field_label:after {
  width: 13px;
  height: 13px;
  line-height: 13px;
}
.checkbox-field--xsmall .checkbox-field_label {
  line-height: 11px;
}
.checkbox-field--xsmall .checkbox-field_label:before, .checkbox-field--xsmall .checkbox-field_label:after {
  width: 11px;
  height: 11px;
  border-radius: 2px;
  line-height: 11px;
}
.checkbox-field--disabled {
  opacity: 0.3;
  pointer-events: none;
}

.checkbox-three-state .checkbox-field_label:before, .checkbox-three-state .checkbox-field_label:after {
  transition: background-color 0.3s ease;
}

.checkbox-field--indeterminate .checkbox-field_label:before, .checkbox-field--indeterminate .checkbox-field_label:after {
  content: "";
}
.checkbox-field--indeterminate .checkbox-field_label--right {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
}
.checkbox-field--indeterminate .checkbox-field_label--right:before {
  font-family: "iris";
  content: "\e983";
}
.checkbox-field--indeterminate .checkbox-field_label--right:after {
  display: none;
}
