.switch-root {
  display: inline-block;
  cursor: pointer;
}
.switch-root .switch {
  height: 100%;
}
.switch-root .switch_label, .switch-root .switch_icon {
  transition: 0.3s ease;
}
.switch-root .switch_label + .switch_input, .switch-root .switch_input + .switch_label {
  margin-left: 8px;
}
.switch-root .switch_label, .switch-root .switch_input {
  display: inline-block;
}
.switch-root .switch_label--active {
  color: #297ac5;
}
.switch-root .switch_input {
  position: relative;
  top: -2px;
}
.switch-root .switch_icon {
  position: absolute;
  top: -2px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #297ac5;
  border-radius: 50%;
}
.switch-root .switch_icon--checked {
  left: calc(100% - 10px);
}
.switch-root .switch_bar {
  width: 22px;
  height: 6px;
  background: #e0e0e0;
  border-radius: 3px;
}
.switch-root .switch--light .switch_label {
  color: #545b62;
}
.switch-root .switch--light .switch_label--active {
  color: #297ac5;
}
.switch-root .switch--light .switch_bar {
  background: #939ba0;
}
.switch-root .switch--dark .switch_label {
  color: #ffffff;
}
.switch-root .switch--dark .switch_label--active {
  color: #f1af0f;
}
.switch-root .switch--dark .switch_icon {
  background-color: #f1af0f;
}
.switch-root .switch--dark .switch_bar {
  background: #ffffff;
}
.switch-root .switch--teal .switch_icon {
  background-color: #ec6a4c;
}
.switch-root .switch--teal .switch_icon--checked {
  background-color: #1abc9c;
}
