@import '../../../../../resources/styles/variables';
@import '../../../../../resources/styles/mixins/generic';
@import '../../../../../resources/styles/utils';

.tearsheet_activity {

  &:not(:first-child) {
    .tearsheet_activity_header {
      margin-top: gux();
      padding-top: gux(2);
      border-top: 1px solid $light-grey;
    }
  }

  &_content {
    display: flex;
    align-items: center;

    &--top-aligned {
      align-items: flex-start;
    }
  }

  &-category {
    padding-right: gux();
    font-size: $font-size-medium;
    color: $q4-blue;
  }

  &-title {
    font-weight: $font-weight-bold;
    color: $charcoal;
    @include truncate();
  }

  &-date {
    padding-right: gux();

    &--double-spaced {
      padding-right: gux(1.5);
    }
  }

  &-attendees {
    width: calc(100% - 74px);
  }

  &-attendee {
    display: flex;
    align-items: center;

    &-icon {
      padding-right: gux();
      color: $q4-blue;

      &--fund {
        color: $teal;
      }

      &--institution {
        color: $spice;
      }
    }

    &-name {
      width: calc(100% - 22px);
      @include truncate();
    }
  }

  &-participants {
    width: calc(100% - 148px);
  }

  &-participant {

    &-name {
      width: 100%;
      @include truncate();
    }
  }

  &-tags {
    width: calc(100% - 39px);
  }

  &-tag {
    padding-right: gux(0.75);
  }

  &-label {
    padding-right: gux();
    color: $silver;
  }

  &-more {
    font-size: $font-size-table;
    color: $q4-blue;
  }
}
