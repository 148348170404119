.trading-analytics-container {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.trading-analytics-container i {
  color: #ffffff;
}
.trading-analytics-container_wrapper {
  height: calc(100% - 35px - 10px);
}
.trading-analytics-container_commentary {
  padding: 0 20px 10px;
  text-align: center;
  line-height: 20px;
}
.trading-analytics-container, .trading-analytics-container_header, .trading-analytics-container_footer, .trading-analytics-container_footer a {
  transition: 0.3s ease;
}
.trading-analytics-container_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #2a3035;
}
.trading-analytics-container_title {
  font-size: 13px;
  text-transform: uppercase;
}
.trading-analytics-container_footer {
  height: 10px;
  line-height: 10px;
  border-top: 1px solid #2a3035;
}
.trading-analytics-container_company {
  margin-right: 5px;
  color: #939ba0;
}
.trading-analytics-container_items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 20px;
  height: 100%;
}
.trading-analytics-container_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
.trading-analytics-container_item .item-wrapper {
  padding-left: 20px;
  cursor: pointer;
}
.trading-analytics-container_item .highcharts-container i {
  font-size: 25px;
  position: absolute;
  left: -13px;
  top: -7px;
}
.trading-analytics-container_item .trading-value {
  margin-top: 10px;
  font-size: 32px;
  vertical-align: middle;
}
.trading-analytics-container_item .trading-title {
  font-size: 13px;
}
.trading-analytics-container_item .trading-icon {
  font-size: 22px;
  vertical-align: middle;
}
.trading-analytics-container--narrow .trading-analytics-container_items {
  padding: 0;
}
.trading-analytics-container--narrow .trading-analytics-container_items--tall > div {
  text-align: center;
}
.trading-analytics-container--narrow .trading-analytics-container_items--tall--1x2 > div, .trading-analytics-container--narrow .trading-analytics-container_items--tall--1x3 > div, .trading-analytics-container--narrow .trading-analytics-container_items--tall--1x4 > div {
  text-align: left;
}
.trading-analytics-container--narrow .trading-analytics-container_items--tall--1x2 {
  padding-top: 10px;
}
.trading-analytics-container--narrow .trading-analytics-container_items--tall--1x3 {
  padding-top: 30px;
}
.trading-analytics-container--narrow .trading-analytics-container_items--tall--1x4 {
  padding-top: 100px;
}
.trading-analytics-container--narrow .trading-analytics-container_item {
  padding-top: 10px;
}
.trading-analytics-container--narrow .trading-analytics-container_item .item-wrapper {
  padding-left: 0;
}
.trading-analytics-container--narrow .trading-analytics-container_item .trading-value {
  margin-top: 0;
}
.trading-analytics-container_items--tall--1x2, .trading-analytics-container_items--tall--1x3, .trading-analytics-container_items--tall--1x4 {
  height: 95%;
}
.trading-analytics-container_items--tall--1x2 .trading-analytics-container_item, .trading-analytics-container_items--tall--1x3 .trading-analytics-container_item, .trading-analytics-container_items--tall--1x4 .trading-analytics-container_item {
  width: 75%;
  padding-top: 0;
  height: 85px;
}
.trading-analytics-container_items--tall--1x2 .trading-analytics-container_item .trading-value, .trading-analytics-container_items--tall--1x3 .trading-analytics-container_item .trading-value, .trading-analytics-container_items--tall--1x4 .trading-analytics-container_item .trading-value {
  font-size: 38px;
}
.trading-analytics-container_items--tall--1x3, .trading-analytics-container_items--tall--1x4 {
  height: 70%;
}
.trading-analytics-container_items--2x2, .trading-analytics-container_items--2x3 {
  padding: 25px 43px 0;
}
.trading-analytics-container_items--2x2 .trading-analytics-container_item, .trading-analytics-container_items--2x3 .trading-analytics-container_item {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 33%;
  height: 200px;
  padding-top: 0;
  display: block;
}
.trading-analytics-container_items--2x2 .trading-analytics-container_item > div, .trading-analytics-container_items--2x3 .trading-analytics-container_item > div {
  text-align: center;
}
.trading-analytics-container_items--2x2 .trading-analytics-container_item .highcharts-container, .trading-analytics-container_items--2x3 .trading-analytics-container_item .highcharts-container {
  margin: auto;
}
.trading-analytics-container_items--2x2 .trading-analytics-container_item .highcharts-container i, .trading-analytics-container_items--2x3 .trading-analytics-container_item .highcharts-container i {
  font-size: 32px;
  left: -17px;
  top: -10px;
}
.trading-analytics-container_items--2x2 .trading-analytics-container_item .item-wrapper, .trading-analytics-container_items--2x3 .trading-analytics-container_item .item-wrapper {
  padding: 0;
}
.trading-analytics-container_items--2x2 .trading-analytics-container_item .trading-value, .trading-analytics-container_items--2x3 .trading-analytics-container_item .trading-value {
  font-size: 38px;
}
.trading-analytics-container_items--2x3 {
  padding-top: 100px;
}
.trading-analytics-container--light {
  color: #545b62;
}
.trading-analytics-container--light i {
  color: #545b62;
}
.trading-analytics-container--light .trading-analytics-container_header, .trading-analytics-container--light .trading-analytics-container_footer {
  border-color: #e0e0e0;
}
