@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';

.bulk-action-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 1;
  width: 100%;
  height: 50px;
  background-color: $steel;
  transition: opacity $transition-duration--default $transition-timing-function--default;

  &_checkbox {
    padding-left: gux(4);
    padding-right: gux(2);
  }

  &_actions {
    height: 100%;
    list-style: none;

    &-item {
      display: inline-block;
      min-width: 60px;
      height: 50px;
      line-height: 50px;
      padding: 0 gux();
      border-right: 1px solid $deep-blue;
      text-align: center;
      color: $white;
      transition: background-color $transition-duration--default $transition-timing-function--default;
      vertical-align: top;
      cursor: pointer;

      &:hover {
        background-color: $q4-blue;
      }

      &:first-child {
        border-left: 1px solid $deep-blue;
      }
    }

    &-item &-icon, &-label {
      line-height: 50px;
      vertical-align: top;
    }

    &-icon {
      font-size: $font-size-icon;
    }

    &-icon + &-label {
      margin-left: gux();
    }
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
}
