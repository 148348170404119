/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.card {
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: default;
}
.card_spinner {
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.card_spinner--enter, .card_spinner--appear {
  opacity: 0;
}
.card_spinner--enter-active, .card_spinner--appear-active {
  opacity: 1;
  transition-property: opacity;
}
.card_spinner--exit {
  opacity: 1;
}
.card_spinner--exit-active {
  opacity: 0;
  transition-property: opacity;
}
.card_header, .card_children {
  padding: 16px;
}
.card_header {
  height: 57px;
  text-transform: uppercase;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_header-note {
  padding: 0 8px;
  font-size: 12px;
  text-transform: none;
  color: #939ba0;
}
.card_actions {
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: none;
  width: 32.5%;
}
.card_actions > div.card_actions--checkbox {
  padding-right: 32px;
}
.card_children {
  padding: 16px;
  position: relative;
  min-height: 140px;
}
.card_children-link {
  display: block;
  cursor: pointer;
}
.card_children-chin:after {
  content: "";
  position: absolute;
  right: 6px;
  bottom: 0;
  left: 0;
  height: 20px;
  border-radius: 0 0 3px 3px;
}
.card--medium .card_children {
  min-height: 150px;
}
.card--large .card_children {
  min-height: 378px;
}
.card_section:not(:first-of-type) {
  padding-top: 24px;
}
.card_section--grouped {
  padding-top: 16px;
}
.card_section-title {
  font-size: 20px;
}
.card_section-subtitle {
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.card_section-detail {
  word-break: break-word;
}
.card_section-detail-text {
  line-height: 22px;
}
.card_section-detail--neutral {
  padding-left: 6px;
}
.card_section-link {
  cursor: pointer;
}
.card_list {
  display: flex;
  justify-content: space-between;
  line-height: 22px;
}
.card_list-icon {
  padding-right: 8px;
}
.card_list-label {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card_list-value {
  width: 16%;
  text-align: right;
}
.card_list-value--positive {
  color: #1abc9c;
}
.card_list-value--negative {
  color: #ec6a4c;
}
.card_list-value--neutral {
  text-transform: none;
}
.card_list--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.card_footer--justified {
  display: flex;
  justify-content: space-between;
}
.card--dark {
  background-color: #22272b;
}
.card--dark .card_header {
  color: #ffffff;
  border-color: #545b5f;
}
.card--dark .card_children-chin:after {
  background: #22272b;
}
.card--dark .card_children-link {
  color: #ffffff;
}
.card--dark .card_children .react-scrollbar_thumb {
  background-color: rgba(255, 255, 255, 0.1);
}
.card--dark .card_section-title, .card--dark .card_section-subtitle {
  color: #939ba0;
}
.card--dark .card_section-detail {
  color: #ffffff;
}
.card--dark .card_section-detail-text {
  color: #ffffff;
}
.card--dark .card_section-detail-placeholder, .card--dark .card_section-detail--neutral {
  color: #939ba0;
}
.card--dark .card_section-link {
  color: #f1af0f;
}
.card--dark .card_section--reverse .card_section-title,
.card--dark .card_section--reverse .card_section-subtitle {
  color: #ffffff;
}
.card--dark .card_section--reverse .card_section-detail {
  color: #939ba0;
}
.card--dark .card_section--theme .card_section-title,
.card--dark .card_section--theme .card_section-subtitle {
  color: #f1af0f;
}
.card--dark .card_list {
  color: #ffffff;
}
.card--dark .card_list-icon {
  color: #939ba0;
}
.card--dark .card_list-value--neutral {
  color: #939ba0;
}
.card--light {
  background-color: #ffffff;
}
.card--light .card_header {
  color: #545b62;
  border-color: #eeeeee;
}
.card--light .card_children-chin:after {
  background: #ffffff;
}
.card--light .card_children-link {
  color: #545b62;
}
.card--light .card_children .react-scrollbar_thumb {
  background-color: rgba(0, 0, 0, 0.1);
}
.card--light .card_section-title, .card--light .card_section-subtitle {
  color: #939ba0;
}
.card--light .card_section-detail {
  color: #545b62;
}
.card--light .card_section-detail-text {
  color: #545b62;
}
.card--light .card_section-detail-placeholder, .card--light .card_section-detail--neutral {
  color: #939ba0;
}
.card--light .card_section-link {
  color: #4696E0;
}
.card--light .card_section--reverse .card_section-title,
.card--light .card_section--reverse .card_section-subtitle {
  color: #545b62;
}
.card--light .card_section--reverse .card_section-detail {
  color: #939ba0;
}
.card--light .card_section--theme .card_section-title,
.card--light .card_section--theme .card_section-subtitle {
  color: #4696E0;
}
.card--light .card_list {
  color: #545b62;
}
.card--light .card_list-icon {
  color: #939ba0;
}
.card--light .card_list-value--neutral {
  color: #939ba0;
}
