.badge {
  display: flex;
  align-items: center;
}
.badge_item {
  cursor: default;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.badge_item:not(:last-child) {
  margin-right: 8px;
}
.badge_icon {
  width: 32px;
  height: 32px;
  border-radius: 3px 0 0 3px;
  text-align: center;
}
.badge_icon:before {
  font-size: 18px;
  line-height: 32px;
}
.badge_label {
  padding: 0 8px;
}
.badge--dark .badge_item {
  border-color: #297ac5;
}
.badge--dark .badge_icon {
  background-color: #297ac5;
  color: #ffffff;
}
.badge--dark .badge_label {
  color: #ffffff;
}
.badge--light .badge_item {
  border-color: #297ac5;
}
.badge--light .badge_icon {
  background-color: #297ac5;
  color: #ffffff;
}
.badge--light .badge_label {
  color: #545b62;
}
