@import '../../../resources/styles/variables';
@import '../../../resources/styles/icons/variables';
@import '../../../resources/styles/mixins/all';
@import '../../../resources/styles/utils';

.notification-mask {

  .notification {

    @mixin barTheme($backgroundColor, $progressColor) {
      background-color: $backgroundColor;

        .MuiLinearProgress-barColorPrimary {
          background-color: $progressColor;
        }
    }

    top: $app-header-height !important; // mui override
    background-color: $white;
    width: 370px;
    min-height: 88px;
    max-height: 362px;
    padding: 0;
    border-radius: 0;

    ul {
      padding: 0; // mui override
    }

    &_header {
      padding: 0 $padding-horizontal-medium;
      line-height: 42px;
      text-transform: uppercase;
      color: $white;
      background-color: $dark-slate;
      outline: none;
    }

    &_container {
      max-height: 362px - 42px;
      overflow-y: auto;
    }

    &_no-data {
      padding: $padding-vertical-small $padding-horizontal-medium;
      font-size: $font-size-base;
    }

    &_item {
      cursor: default;
      padding: 0;
      height: auto;
      min-height: 45px;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: $light-slate;
      border-bottom: 1px solid $soft-grey;
      display: flex;
      align-items: stretch;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: transparent;
      }

      .link {
        cursor: pointer;
        color: $sky;
      }
    }

    &_col {
      &--icon {
        width: 40px;
        background-color: $light-grey;
        color: $rain;
        text-align: center;
        padding: $padding-vertical-small 0 ($padding-vertical-small - 1px);

        &--download {
          cursor: pointer;
        }
      }

      &--message {
        width: 370px - 40px;
        padding: 8px $padding-horizontal-xsmall 7px;
      }
    }

    &_message, &_icon {
      display: inline-block;
      vertical-align: top;

      .email-link {
        text-decoration: underline;
      }
    }

    &_message {
      padding: $padding-vertical-xxsmall $padding-horizontal-small $padding-vertical-xxsmall 0;
      width: calc(100% - 30px);
      white-space: normal;

      &--error,
      &--success,
      &--info {
        padding-right: $padding-horizontal-xxsmall;
        width: 100%;
      }
    }

    &_progress {
      display: flex;
      justify-content: space-between;

      &-message {
        margin-bottom: gux(0.5);

        &--link {
          white-space: normal;
          color: $sky;
          cursor: pointer;
        }
      }

      &-bar {
        border-radius: $border-radius-small;

        &--rain {
          @include barTheme($soft-grey, $rain)
        }
      }
    }

    &_icon {
      width: 30px;
      color: $dark-slate;
      background-color: $citrus;
      border-radius: $border-radius-small;
      text-align: center;
      line-height: 30px;

      &:hover {
        cursor: pointer;
        background-color: $mustard;
      }
    }
  }
}
