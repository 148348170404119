/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.report-data-editor {
  height: 100%;
}
.report-data-editor_visualizer {
  height: calc(100% - 140px);
}
.report-data-editor_builder {
  display: flex;
  position: relative;
  height: calc(100vh - 80px);
  min-height: 575px;
}
.report-data-editor_snapshot {
  flex: 1;
  position: relative;
  max-width: calc(100% - 40px);
  transition: width 0.3s ease;
}
.report-data-editor_snapshot:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent);
  z-index: 5;
}
