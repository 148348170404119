@import '../../resources/styles/variables';
@import '../../resources/styles/utils';

$height: 32px;

@mixin tagInputTheme($color, $backgroundColor, $hoverColor, $hoverBackgroundColor) {
  .tag-input {

    &_header, &_add {
      color: $color;
      background-color: $backgroundColor;
    }

    &_add:hover {
      color: $hoverColor;
      background-color: $hoverBackgroundColor;
    }
  }
}


.tag-input {
  display: flex;
  align-items: center;

  &_header, &_add {
    height: $height;
  }

  &_header {
    cursor: default;
    margin-right: gux(0.25);
    padding: 0 gux();
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-small;
    border-radius: $border-radius-small 0 0 $border-radius-small;
    display: flex;
    align-items: center;
  }

  &_add.button--square {
    cursor: pointer;
    width: $height;
    border-radius: 0 $border-radius-small $border-radius-small 0;
    transition: background-color $transition-duration--default $transition-timing-function--default;
  }

  .tag-input_add--hidden {
    display: none;
  }

  // select overrides
  .tag-input_search {
    width: 0;
    transition: width $transition-duration--fast $transition-timing-function--default;

    .select-component {

      .select__control {
        width: 100%;
        min-width: 0;
        border-radius: 0 $border-radius-small $border-radius-small 0;

        &--menu-is-open {
          border-radius: 0 $border-radius-small 0 0;
        }
      }

      .select__value-container {
        padding: 0;
      }

      .select__placeholder {
        opacity: 0;
      }
    }

    &--expanded {
      width: 260px;

      .select-component {

        .select__value-container {
          padding: 0 gux(0.75);
        }

        .select__placeholder {
          opacity: 1;
          transition: opacity $transition-duration--medium $transition-timing-function--default;
        }
      }
    }
  }

  &--light {
    @include tagInputTheme($light-slate, $light-grey, $light-grey, $rain);
  }

  &--dark {
    @include tagInputTheme($citrus, $dark-slate, $dark-slate, $citrus);
  }
}
