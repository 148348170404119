/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.app-header .autosuggest_input {
  margin: 10px 10px;
  width: 630px;
  height: 60px;
  padding: 0 14px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
}
.app-header .autosuggest_input:before {
  font-family: "iris";
  content: "\ea3b";
}
.app-header .autosuggest_input input {
  font-size: 20px;
}
.app-header .autosuggest_input:before {
  margin-right: 14px;
  font-size: 24px;
  color: #ffffff;
}
.app-header .autosuggest_results {
  width: 630px;
  position: absolute;
  left: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}
.app-header .autosuggest_results-item {
  padding: 0;
  border: none;
  line-height: 40px;
}
.app-header .autosuggest_results-item .link {
  padding: 0 24px;
  display: flex;
  align-items: center;
  color: #545b62;
}
.app-header .autosuggest_results-item .link-icon {
  padding-right: 14px;
  font-size: 16px;
}
.app-header .autosuggest_results-item .link-icon--contact {
  color: #297ac5;
}
.app-header .autosuggest_results-item .link-icon--institution {
  color: #ec6a4c;
}
.app-header .autosuggest_results-item .link-icon--fund {
  color: #1abc9c;
}
.app-header .autosuggest_results-item .link-icon--security {
  color: #f1af0f;
}
.app-header .autosuggest_results-item .link-name {
  padding-right: 20px;
  font-weight: 400;
}
.app-header .autosuggest_results-item .link-detail {
  font-size: 12px;
  color: #939ba0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
