/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.ag-theme-bootstrap {
  background-color: #ffffff;
  color: #545b62;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
}
.ag-theme-bootstrap .ag-body-viewport {
  background-color: #f6f6f6;
}
.ag-theme-bootstrap .ag-overlay-loading-center, .ag-theme-bootstrap .ag-select-agg-func-popup, .ag-theme-bootstrap .ag-cell-inline-editing, .ag-theme-bootstrap .ag-popup-editor, .ag-theme-bootstrap .ag-theme-bootstrap.ag-dnd-ghost, .ag-theme-bootstrap .ag-menu {
  background-color: #f6f6f6;
  border: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-tab-header .ag-tab {
  border: 1px solid transparent;
  border-bottom-width: 0;
  display: inline-block;
  margin: 4px;
  margin-bottom: 0;
  padding: 4px 8px;
}
.ag-theme-bootstrap .ag-tab-header .ag-tab.ag-tab-selected {
  background-color: #f6f6f6;
  border-bottom: 2px solid #f6f6f6 !important;
  border-color: #e0e0e0;
}
.ag-theme-bootstrap *:focus {
  outline: none;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-1 {
  padding-left: 17px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-1 {
  padding-right: 17px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-1 {
  padding-left: 8px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-1 {
  padding-right: 8px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-2 {
  padding-left: 34px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-2 {
  padding-right: 34px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-2 {
  padding-left: 16px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-2 {
  padding-right: 16px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-3 {
  padding-left: 51px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-3 {
  padding-right: 51px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-3 {
  padding-left: 24px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-3 {
  padding-right: 24px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-4 {
  padding-left: 68px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-4 {
  padding-right: 68px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-4 {
  padding-left: 32px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-4 {
  padding-right: 32px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-5 {
  padding-left: 85px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-5 {
  padding-right: 85px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-5 {
  padding-left: 40px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-5 {
  padding-right: 40px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-6 {
  padding-left: 102px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-6 {
  padding-right: 102px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-6 {
  padding-left: 48px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-6 {
  padding-right: 48px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-7 {
  padding-left: 119px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-7 {
  padding-right: 119px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-7 {
  padding-left: 56px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-7 {
  padding-right: 56px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-8 {
  padding-left: 136px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-8 {
  padding-right: 136px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-8 {
  padding-left: 64px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-8 {
  padding-right: 64px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-9 {
  padding-left: 153px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-9 {
  padding-right: 153px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-9 {
  padding-left: 72px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-9 {
  padding-right: 72px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-10 {
  padding-left: 170px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-10 {
  padding-right: 170px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-10 {
  padding-left: 80px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-10 {
  padding-right: 80px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-11 {
  padding-left: 187px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-11 {
  padding-right: 187px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-11 {
  padding-left: 88px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-11 {
  padding-right: 88px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-12 {
  padding-left: 204px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-12 {
  padding-right: 204px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-12 {
  padding-left: 96px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-12 {
  padding-right: 96px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-13 {
  padding-left: 221px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-13 {
  padding-right: 221px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-13 {
  padding-left: 104px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-13 {
  padding-right: 104px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-14 {
  padding-left: 238px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-14 {
  padding-right: 238px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-14 {
  padding-left: 112px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-14 {
  padding-right: 112px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-15 {
  padding-left: 255px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-15 {
  padding-right: 255px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-15 {
  padding-left: 120px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-15 {
  padding-right: 120px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-16 {
  padding-left: 272px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-16 {
  padding-right: 272px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-16 {
  padding-left: 128px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-16 {
  padding-right: 128px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-17 {
  padding-left: 289px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-17 {
  padding-right: 289px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-17 {
  padding-left: 136px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-17 {
  padding-right: 136px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-18 {
  padding-left: 306px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-18 {
  padding-right: 306px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-18 {
  padding-left: 144px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-18 {
  padding-right: 144px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-19 {
  padding-left: 323px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-19 {
  padding-right: 323px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-19 {
  padding-left: 152px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-19 {
  padding-right: 152px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-20 {
  padding-left: 340px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-20 {
  padding-right: 340px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-20 {
  padding-left: 160px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-20 {
  padding-right: 160px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-21 {
  padding-left: 357px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-21 {
  padding-right: 357px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-21 {
  padding-left: 168px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-21 {
  padding-right: 168px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-22 {
  padding-left: 374px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-22 {
  padding-right: 374px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-22 {
  padding-left: 176px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-22 {
  padding-right: 176px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-23 {
  padding-left: 391px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-23 {
  padding-right: 391px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-23 {
  padding-left: 184px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-23 {
  padding-right: 184px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-24 {
  padding-left: 408px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-24 {
  padding-right: 408px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-24 {
  padding-left: 192px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-24 {
  padding-right: 192px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-25 {
  padding-left: 425px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-25 {
  padding-right: 425px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-25 {
  padding-left: 200px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-25 {
  padding-right: 200px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-26 {
  padding-left: 442px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-26 {
  padding-right: 442px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-26 {
  padding-left: 208px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-26 {
  padding-right: 208px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-27 {
  padding-left: 459px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-27 {
  padding-right: 459px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-27 {
  padding-left: 216px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-27 {
  padding-right: 216px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-28 {
  padding-left: 476px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-28 {
  padding-right: 476px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-28 {
  padding-left: 224px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-28 {
  padding-right: 224px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-29 {
  padding-left: 493px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-29 {
  padding-right: 493px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-29 {
  padding-left: 232px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-29 {
  padding-right: 232px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-30 {
  padding-left: 510px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-30 {
  padding-right: 510px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-30 {
  padding-left: 240px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-30 {
  padding-right: 240px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-31 {
  padding-left: 527px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-31 {
  padding-right: 527px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-31 {
  padding-left: 248px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-31 {
  padding-right: 248px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-32 {
  padding-left: 544px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-32 {
  padding-right: 544px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-32 {
  padding-left: 256px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-32 {
  padding-right: 256px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-33 {
  padding-left: 561px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-33 {
  padding-right: 561px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-33 {
  padding-left: 264px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-33 {
  padding-right: 264px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-34 {
  padding-left: 578px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-34 {
  padding-right: 578px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-34 {
  padding-left: 272px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-34 {
  padding-right: 272px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-35 {
  padding-left: 595px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-35 {
  padding-right: 595px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-35 {
  padding-left: 280px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-35 {
  padding-right: 280px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-36 {
  padding-left: 612px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-36 {
  padding-right: 612px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-36 {
  padding-left: 288px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-36 {
  padding-right: 288px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-37 {
  padding-left: 629px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-37 {
  padding-right: 629px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-37 {
  padding-left: 296px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-37 {
  padding-right: 296px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-38 {
  padding-left: 646px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-38 {
  padding-right: 646px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-38 {
  padding-left: 304px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-38 {
  padding-right: 304px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-39 {
  padding-left: 663px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-39 {
  padding-right: 663px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-39 {
  padding-left: 312px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-39 {
  padding-right: 312px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-40 {
  padding-left: 680px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-40 {
  padding-right: 680px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-40 {
  padding-left: 320px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-40 {
  padding-right: 320px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-41 {
  padding-left: 697px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-41 {
  padding-right: 697px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-41 {
  padding-left: 328px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-41 {
  padding-right: 328px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-42 {
  padding-left: 714px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-42 {
  padding-right: 714px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-42 {
  padding-left: 336px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-42 {
  padding-right: 336px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-43 {
  padding-left: 731px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-43 {
  padding-right: 731px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-43 {
  padding-left: 344px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-43 {
  padding-right: 344px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-44 {
  padding-left: 748px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-44 {
  padding-right: 748px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-44 {
  padding-left: 352px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-44 {
  padding-right: 352px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-45 {
  padding-left: 765px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-45 {
  padding-right: 765px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-45 {
  padding-left: 360px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-45 {
  padding-right: 360px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-46 {
  padding-left: 782px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-46 {
  padding-right: 782px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-46 {
  padding-left: 368px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-46 {
  padding-right: 368px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-47 {
  padding-left: 799px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-47 {
  padding-right: 799px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-47 {
  padding-left: 376px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-47 {
  padding-right: 376px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-48 {
  padding-left: 816px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-48 {
  padding-right: 816px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-48 {
  padding-left: 384px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-48 {
  padding-right: 384px;
}
.ag-theme-bootstrap .ag-ltr .ag-toolpanel-indent-49 {
  padding-left: 833px;
}
.ag-theme-bootstrap .ag-rtl .ag-toolpanel-indent-49 {
  padding-right: 833px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-indent-49 {
  padding-left: 392px;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-indent-49 {
  padding-right: 392px;
}
.ag-theme-bootstrap .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 25px;
}
.ag-theme-bootstrap .ag-ltr .ag-cell {
  border-right: 0;
}
.ag-theme-bootstrap .ag-ltr .ag-row.ag-cell-first-right-pinned, .ag-theme-bootstrap .ag-ltr .ag-cell:not(.ag-cell-focus).ag-cell-first-right-pinned {
  border-left: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-ltr .ag-row.ag-cell-last-left-pinned, .ag-theme-bootstrap .ag-ltr .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-rtl .ag-row-group-leaf-indent {
  margin-right: 25px;
}
.ag-theme-bootstrap .ag-rtl .ag-cell {
  border-left: 0;
}
.ag-theme-bootstrap .ag-rtl .ag-row.ag-cell-first-right-pinned, .ag-theme-bootstrap .ag-rtl .ag-cell:not(.ag-cell-focus).ag-cell-first-right-pinned {
  border-left: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-rtl .ag-row.ag-cell-last-left-pinned, .ag-theme-bootstrap .ag-rtl .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-value-change-delta {
  padding-right: 2px;
}
.ag-theme-bootstrap .ag-value-change-delta-increase {
  color: #1abc9c;
}
.ag-theme-bootstrap .ag-value-change-delta-decrease {
  color: #e74c3c;
}
.ag-theme-bootstrap .ag-value-change-value {
  background-color: transparent;
  border-radius: 1px;
  padding-left: 1px;
  padding-right: 1px;
  transition: background-color 1s;
}
.ag-theme-bootstrap .ag-value-change-value-highlight {
  background-color: rgba(41, 122, 197, 0.09);
  transition: background-color 0.1s;
}
.ag-theme-bootstrap .ag-header {
  background-color: #4696E0;
  background-image: none;
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  border-bottom: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-pinned-right-header {
  border-left: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-pinned-left-header {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-header-row {
  border: none;
}
.ag-theme-bootstrap .ag-row {
  border-style: solid;
  border-color: #e0e0e0;
  border-width: 0;
}
.ag-theme-bootstrap .ag-row:not(.ag-row-first) {
  border-width: 0 0 0;
}
.ag-theme-bootstrap .ag-row.ag-row-last {
  border-bottom-width: 0;
}
.ag-theme-bootstrap .ag-row-odd {
  background-color: #ffffff;
}
.ag-theme-bootstrap .ag-row-even {
  background-color: #ffffff;
}
.ag-theme-bootstrap .ag-horizontal-left-spacer {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-horizontal-left-spacer.ag-scroller-corner {
  border-right: none;
}
.ag-theme-bootstrap .ag-horizontal-right-spacer {
  border-left: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-horizontal-right-spacer.ag-scroller-corner {
  border-left: none;
}
.ag-theme-bootstrap .ag-row-hover {
  background-color: transparent;
}
.ag-theme-bootstrap .ag-numeric-cell {
  text-align: right;
}
.ag-theme-bootstrap .ag-header-cell-label {
  display: flex;
  height: 100%;
}
.ag-theme-bootstrap .ag-header-cell-label > span {
  float: left;
}
.ag-theme-bootstrap .ag-header-cell-label .ag-header-icon {
  background-position-y: 10px;
  background-size: 16px 16px;
  height: 100%;
  margin: 0;
  margin-left: 4px;
  opacity: 1;
}
.ag-theme-bootstrap .ag-header-cell-label .ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ag-theme-bootstrap .ag-numeric-header .ag-header-cell-label {
  flex-direction: row-reverse;
  float: right;
}
.ag-theme-bootstrap .ag-numeric-header .ag-header-cell-label > span {
  float: right;
}
.ag-theme-bootstrap .ag-numeric-header .ag-header-cell-menu-button {
  float: left;
}
.ag-theme-bootstrap .ag-header-group-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ag-theme-bootstrap .ag-header-cell,
.ag-theme-bootstrap .ag-header-group-cell {
  border-style: solid;
  border-color: #e0e0e0;
  line-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  border-width: 0;
}
.ag-theme-bootstrap .ag-header-cell.ag-header-cell-moving,
.ag-theme-bootstrap .ag-header-group-cell.ag-header-cell-moving {
  background-color: rgb(190, 190, 190);
}
.ag-theme-bootstrap .ag-header-cell:not(.ag-header-group-cell-no-group),
.ag-theme-bootstrap .ag-header-group-cell:not(.ag-header-group-cell-no-group) {
  border-top-width: 1px;
}
.ag-theme-bootstrap .ag-header-row:first-child .ag-header-cell, .ag-theme-bootstrap .ag-header-row:first-child .ag-header-group-cell {
  border-top-width: 0;
}
.ag-theme-bootstrap .ag-cell {
  line-height: 46px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid transparent;
  padding-left: 7px;
  padding-right: 7px;
}
.ag-theme-bootstrap .ag-row-drag {
  float: left;
  height: 100%;
  width: 25px;
  cursor: grab;
}
.ag-theme-bootstrap .ag-row-drag .ag-icon-row-drag {
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/grip.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  background-position-x: left;
  background-position-y: 4px;
}
.ag-theme-bootstrap .ag-row-dragging, .ag-theme-bootstrap .ag-row-dragging .ag-row-drag {
  cursor: move;
}
.ag-theme-bootstrap .ag-rtl .ag-row-drag {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-row-drag .ag-icon-row-drag {
  background-position-x: right;
}
.ag-theme-bootstrap .ag-column-drag {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/grip.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  background-position-x: left;
  background-position-y: 4px !important;
  height: 100%;
  min-width: 17px;
  cursor: grab;
}
.ag-theme-bootstrap .ag-row-dragging {
  opacity: 0.5;
  z-index: 10000;
}
.ag-theme-bootstrap .ag-ltr .ag-has-focus .ag-cell-focus, .ag-theme-bootstrap .ag-rtl .ag-has-focus .ag-cell-focus {
  border: 1px solid black;
  outline: initial;
}
.ag-theme-bootstrap .ag-header-cell-resize {
  width: 8px;
}
.ag-theme-bootstrap .ag-icon-aggregation {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/aggregation.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-arrows {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/arrows.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-asc {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/asc.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-checkbox-checked-readonly {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/checkbox-checked-readonly.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-checkbox-checked {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/checkbox-checked.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-checkbox-indeterminate-readonly {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/checkbox-indeterminate-readonly.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-checkbox-indeterminate {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/checkbox-indeterminate.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-checkbox-unchecked-readonly {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/checkbox-unchecked-readonly.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-checkbox-unchecked {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/checkbox-unchecked.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-column {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/column.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-columns {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/columns.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-contracted {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/contracted.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-copy {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/copy.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-cut {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/cut.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-desc {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/desc.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-expanded {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/expanded.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-eye-slash {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/eye-slash.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-eye {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/eye.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-filter {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/filter.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-group {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/group.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-indeterminate {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/indeterminate.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-left {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/left.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-loading {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/loading.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-menu {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/menu.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-minus {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/minus.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-none {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/none.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-not-allowed {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/not-allowed.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-paste {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/paste.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-pin {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/pin.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-pivot {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/pivot.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-plus {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/plus.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-right {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/right.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-small-left {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/small-left.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-small-right {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/small-right.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-small-up {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/small-up.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-small-down {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/small-down.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-tick {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/tick.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-cross {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/cross.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-tree-open {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/tree-open.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-tree-closed {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/tree-closed.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-icon-tree-indeterminate {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/tree-indeterminate.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  display: inline-block;
}
.ag-theme-bootstrap .ag-header-cell-menu-button .ag-icon-menu {
  display: block;
  height: 48px;
}
.ag-theme-bootstrap .ag-icon-checkbox-checked:empty {
  background-image: url("./styles/ag-theme-base/icons/checkbox-checked.svg?color1=%23297AC5&color2=%23FFFFFF");
}
.ag-theme-bootstrap .ag-menu {
  background: #297ac5;
  border-radius: 0;
  box-shadow: none;
  padding: 4px;
  padding: 0;
  z-index: 5;
}
.ag-theme-bootstrap .ag-menu .ag-menu-list {
  cursor: default;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 100%;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option {
  line-height: 16px;
  padding-left: 8px;
  padding-right: 8px;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option > span {
  display: table-cell;
  vertical-align: middle;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-active {
  background-color: transparent;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-disabled {
  opacity: 0.5;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-icon {
  padding-left: 4px;
  padding-right: 4px;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-icon span {
  height: 13px;
  line-height: 0;
  margin-top: 4px;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-shortcut {
  padding-left: 8px;
}
.ag-theme-bootstrap .ag-menu .ag-menu-separator {
  margin-left: -4px;
}
.ag-theme-bootstrap .ag-menu .ag-menu-separator > span {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D'1'%20height%3D'8px'%20viewBox%3D'0%200%201%208px'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cline%20x1%3D'0'%20y1%3D'4px'%20x2%3D'1'%20y2%3D'4px'%20stroke-width%3D'1'%20stroke%3D'%23E0E0E0'%2F%3E%3C%2Fsvg%3E");
  height: 8px;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-popup-pointer {
  width: 21px;
}
.ag-theme-bootstrap.ag-dnd-ghost {
  background: #297ac5;
  border-radius: 0;
  box-shadow: none;
  padding: 4px;
  border: 1px solid #e0e0e0;
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  height: 48px !important;
  line-height: 48px;
  margin: 0;
  padding: 0 8px;
  transform: translateY(8px);
  z-index: 10000;
}
.ag-theme-bootstrap.ag-dnd-ghost span,
.ag-theme-bootstrap.ag-dnd-ghost div {
  float: left;
  height: 100%;
  margin: 0;
  padding: 0;
}
.ag-theme-bootstrap.ag-dnd-ghost .ag-dnd-ghost-icon {
  margin-right: 4px;
  opacity: 1;
}
.ag-theme-bootstrap .ag-tab-header {
  background: #f6f6f6;
  min-width: 220px;
  width: 100%;
}
.ag-theme-bootstrap .ag-tab-header .ag-tab {
  border-bottom: 2px solid transparent;
  height: 16px;
  text-align: center;
  vertical-align: middle;
}
.ag-theme-bootstrap .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-filter {
  background-image: url("./styles/ag-theme-base/icons/filter.svg?color1=%23000000&color2=%23FFFFFF");
  display: inline-block;
}
.ag-theme-bootstrap .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-columns {
  background-image: url("./styles/ag-theme-base/icons/columns.svg?color1=%23000000&color2=%23FFFFFF");
  display: inline-block;
}
.ag-theme-bootstrap .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-menu {
  background-image: url("./styles/ag-theme-base/icons/menu.svg?color1=%23000000&color2=%23FFFFFF");
  display: inline-block;
}
.ag-theme-bootstrap .ag-tab-body {
  padding: 4px 0;
}
.ag-theme-bootstrap .ag-tab-body .ag-filter-select {
  margin: 4px;
  width: calc(100% - 8px);
}
.ag-theme-bootstrap .ag-tab-body .ag-menu-list {
  margin-bottom: 0;
  margin-top: 0;
}
.ag-theme-bootstrap .ag-tab-body .ag-menu-list > div:first-child > span {
  padding-top: 0;
}
.ag-theme-bootstrap .ag-tab-body .ag-menu-list > div:last-child > span {
  padding-bottom: 0;
}
.ag-theme-bootstrap .ag-tab-body .ag-menu-list > div:last-child > .ag-menu-option-popup-pointer {
  background-position-y: 0;
}
.ag-theme-bootstrap .ag-filter-select {
  margin: 4px;
  width: calc(100% - 8px);
}
.ag-theme-bootstrap .ag-filter input[type=radio] {
  margin: 0 3px 0 6px;
  width: 12px;
  height: 17px;
  vertical-align: top;
}
.ag-theme-bootstrap .ag-filter input[type=text],
.ag-theme-bootstrap .ag-filter input[type=date] {
  padding-left: 4px;
}
.ag-theme-bootstrap .ag-filter input[type=text]:disabled,
.ag-theme-bootstrap .ag-filter input[type=date]:disabled {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebebeb;
}
.ag-theme-bootstrap .ag-filter label {
  display: block;
  padding-left: 4px;
}
.ag-theme-bootstrap .ag-filter .ag-set-filter-list {
  height: 130px;
  padding-top: 4px;
}
.ag-theme-bootstrap .ag-filter .ag-filter-header-container {
  height: 20px;
}
.ag-theme-bootstrap .ag-filter .ag-filter-header-container:nth-child(2) {
  border-bottom: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-filter .ag-filter-checkbox {
  float: left;
  height: 20px;
  margin-right: 4px;
  padding-top: 2px;
}
.ag-theme-bootstrap .ag-filter .ag-filter-value {
  height: 20px;
  line-height: 14px;
}
.ag-theme-bootstrap .ag-filter .ag-filter-apply-panel {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  padding-top: 8px;
}
.ag-theme-bootstrap .ag-filter .ag-filter-apply-panel button + button {
  margin-left: 8px;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column-group,
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column {
  height: 16px;
  line-height: 16px;
  margin-left: 0;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column-group span,
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column span {
  float: left;
  height: 100%;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column-group .ag-column-select-indent,
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column .ag-column-select-indent {
  width: 8px;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column-group .ag-column-select-checkbox,
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column-group .ag-column-group-icons,
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column .ag-column-select-checkbox,
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column .ag-column-group-icons {
  margin-left: 4px;
  margin-right: 4px;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-primary-cols-list-panel {
  padding-top: 4px;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-primary-cols-list-panel > div {
  cursor: pointer;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column.ag-toolpanel-add-group-indent {
  margin-left: 21px;
}
.ag-theme-bootstrap .ag-filter-filter {
  margin-bottom: 4px;
}
.ag-theme-bootstrap .ag-primary-cols-header-panel {
  border-bottom: 1px solid #e0e0e0;
  height: 48px;
  padding-top: 4px;
}
.ag-theme-bootstrap .ag-primary-cols-header-panel > div {
  cursor: pointer;
  margin: 0 4px;
  padding-top: 2px;
}
.ag-theme-bootstrap .ag-primary-cols-header-panel .ag-filter-body {
  margin-left: 4px;
  margin-right: 4px;
}
.ag-theme-bootstrap .ag-group-child-count::before {
  content: " ";
}
.ag-theme-bootstrap .ag-tool-panel-wrapper {
  border-right: 0;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-filter-panel {
  width: 100%;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-filter-panel .ag-filter-toolpanel-instance {
  color: #ffffff;
  font-weight: 600;
  flex: auto;
  flex-direction: column;
  flex-wrap: nowrap;
  display: flex;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-filter-panel .ag-filter-toolpanel-instance .ag-filter-toolpanel-header {
  padding: 5px 0 5px 5px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-filter-panel .ag-filter-toolpanel-instance .ag-filter-toolpanel-header > div {
  margin: auto 0;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-filter-panel .ag-filter-body-wrapper {
  padding-top: 5px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-filter-panel .ag-filter-air {
  border: 1px solid #e0e0e0;
  border-left: 0;
  border-right: 0;
  padding: 4px 0;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-pivot-mode-panel {
  border-bottom: 1px solid #e0e0e0;
  height: 48px;
  line-height: 48px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-pivot-mode-panel span {
  float: left;
  height: 100%;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-pivot-mode-panel .ag-pivot-mode-select {
  margin-left: 4px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-pivot-mode-panel .ag-pivot-mode-select .ag-checkbox-label {
  margin-left: 4px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-select-panel {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 3px;
  padding-top: 0;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-drop {
  border-bottom: 1px solid #e0e0e0;
  clear: both;
  overflow: auto;
  padding: 4px 0;
  padding-bottom: 8px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-drop .ag-icon {
  float: left;
  height: 20px;
  margin: 0 4px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-drop .ag-column-drop-title {
  clear: right;
  float: left;
  height: 20px;
  line-height: 20px;
  width: calc(100% - 21px);
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-drop .ag-column-drop-empty-message {
  clear: both;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  line-height: 8px;
  padding-left: 16px;
  padding-right: 4px;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-drop:last-child {
  border-bottom: 0;
}
.ag-theme-bootstrap .ag-filter-icon:empty {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/filter.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-sort-ascending-icon:empty {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/asc.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-sort-descending-icon:empty {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/desc.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-sort-none-icon:empty {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/none.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-numeric-header .ag-header-cell-label .ag-header-icon {
  margin-left: 0;
  margin-right: 4px;
}
.ag-theme-bootstrap .ag-paging-panel {
  align-items: center;
  border-top: 1px solid #e0e0e0;
  color: #ffffff;
  display: flex;
  height: 48px;
  justify-content: flex-end;
  padding: 0 8px;
}
.ag-theme-bootstrap .ag-paging-panel > span {
  margin-left: 16px;
}
.ag-theme-bootstrap .ag-row-selected {
  background-color: #bde2e5;
}
.ag-theme-bootstrap .ag-cell-range-selected:not(.ag-cell-focus) {
  background-color: rgba(120, 120, 120, 0.4);
}
.ag-theme-bootstrap .ag-cell-range-selected-1:not(.ag-cell-focus) {
  background-color: #ffffff;
}
.ag-theme-bootstrap .ag-cell-range-selected-2:not(.ag-cell-focus) {
  background-color: rgba(80, 80, 80, 0.4);
}
.ag-theme-bootstrap .ag-cell-range-selected-3:not(.ag-cell-focus) {
  background-color: rgba(40, 40, 40, 0.4);
}
.ag-theme-bootstrap .ag-cell-range-selected-4:not(.ag-cell-focus) {
  background-color: rgba(0, 0, 0, 0.4);
}
.ag-theme-bootstrap .ag-cell-inline-editing {
  padding: 8px;
  height: 60px;
  z-index: 2;
}
.ag-theme-bootstrap .ag-cell-inline-editing, .ag-theme-bootstrap .ag-popup-editor {
  background: #297ac5;
  border-radius: 0;
  box-shadow: none;
  padding: 4px;
}
.ag-theme-bootstrap .ag-cell-inline-editing input[type=text]:disabled,
.ag-theme-bootstrap .ag-cell-inline-editing input[type=tel]:disabled,
.ag-theme-bootstrap .ag-cell-inline-editing input[type=date]:disabled,
.ag-theme-bootstrap .ag-cell-inline-editing input[type=datetime-local]:disabled, .ag-theme-bootstrap .ag-popup-editor input[type=text]:disabled,
.ag-theme-bootstrap .ag-popup-editor input[type=tel]:disabled,
.ag-theme-bootstrap .ag-popup-editor input[type=date]:disabled,
.ag-theme-bootstrap .ag-popup-editor input[type=datetime-local]:disabled {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebebeb;
}
.ag-theme-bootstrap .ag-cell-inline-editing select, .ag-theme-bootstrap .ag-popup-editor select {
  height: auto;
}
.ag-theme-bootstrap .ag-popup-editor {
  z-index: 1;
  padding: 0;
}
.ag-theme-bootstrap .ag-popup-editor .ag-large-textarea textarea {
  height: auto;
  padding: 8px;
}
.ag-theme-bootstrap .ag-popup-editor .ag-large-textarea textarea:disabled {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebebeb;
}
.ag-theme-bootstrap .ag-rich-select {
  background-image: url("./styles/ag-theme-base/icons/tree-open.svg?color1=%23333333");
  background-position-x: calc(100% - 4px);
  background-position-y: 8px;
  background-repeat: no-repeat;
}
.ag-theme-bootstrap .ag-rich-select .ag-rich-select-list {
  height: 312px;
}
.ag-theme-bootstrap .ag-rich-select .ag-rich-select-value {
  height: 48px;
  line-height: 48px;
  padding-left: 8px;
}
.ag-theme-bootstrap .ag-rich-select .ag-virtual-list-item {
  cursor: default;
  height: 48px;
  line-height: 48px;
}
.ag-theme-bootstrap .ag-rich-select .ag-virtual-list-item:hover {
  background-color: transparent;
}
.ag-theme-bootstrap .ag-rich-select .ag-rich-select-row {
  padding-left: 8px;
}
.ag-theme-bootstrap .ag-rich-select .ag-rich-select-row-selected {
  background-color: #bde2e5;
}
.ag-theme-bootstrap .ag-floating-filter-body {
  float: left;
  height: 100%;
  margin-right: 0;
  width: calc(100% - 21px);
}
.ag-theme-bootstrap .ag-floating-filter-body input:disabled {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebebeb;
}
.ag-theme-bootstrap .ag-floating-filter-full-body input:disabled {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebebeb;
}
.ag-theme-bootstrap .ag-floating-filter-button {
  float: right;
  line-height: 13px;
  margin-top: 10px;
}
.ag-theme-bootstrap .ag-floating-filter-button button {
  appearance: none;
  background: transparent;
  border: 0;
  height: 13px;
  padding: 0;
  width: 13px;
}
.ag-theme-bootstrap .ag-cell-label-container {
  height: 100%;
}
.ag-theme-bootstrap .ag-tooltip {
  background-color: #535353;
  color: white;
  border-radius: 2px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #ebebeb;
  transition: opacity 1s;
}
.ag-theme-bootstrap .ag-tooltip.ag-tooltip-hiding {
  opacity: 0;
}
.ag-theme-bootstrap .ag-header-group-cell-label {
  height: 100%;
}
.ag-theme-bootstrap .ag-header-group-cell-label span {
  float: left;
  height: 100%;
}
.ag-theme-bootstrap .ag-header-select-all {
  height: 100%;
}
.ag-theme-bootstrap .ag-header-select-all span {
  height: 100%;
}
.ag-theme-bootstrap .ag-header-select-all:not(.ag-hidden) + .ag-cell-label-container {
  float: left;
  width: calc(100% - 13px - 8px);
}
.ag-theme-bootstrap .ag-selection-checkbox ~ .ag-cell-value:not(:empty), .ag-theme-bootstrap .ag-selection-checkbox + .ag-group-value:not(:empty),
.ag-theme-bootstrap .ag-group-checkbox:not(.ag-invisible) ~ .ag-cell-value:not(:empty),
.ag-theme-bootstrap .ag-group-checkbox:not(.ag-invisible) + .ag-group-value:not(:empty),
.ag-theme-bootstrap .ag-group-expanded:not(.ag-hidden) ~ .ag-cell-value:not(:empty),
.ag-theme-bootstrap .ag-group-expanded:not(.ag-hidden) + .ag-group-value:not(:empty),
.ag-theme-bootstrap .ag-group-contracted:not(.ag-hidden) ~ .ag-cell-value:not(:empty),
.ag-theme-bootstrap .ag-group-contracted:not(.ag-hidden) + .ag-group-value:not(:empty) {
  display: inline-block;
  margin-left: 8px;
}
.ag-theme-bootstrap .ag-selection-checkbox ~ .ag-group-checkbox,
.ag-theme-bootstrap .ag-group-checkbox:not(.ag-invisible) ~ .ag-group-checkbox,
.ag-theme-bootstrap .ag-group-expanded:not(.ag-hidden) ~ .ag-group-checkbox,
.ag-theme-bootstrap .ag-group-contracted:not(.ag-hidden) ~ .ag-group-checkbox {
  margin-left: 8px;
}
.ag-theme-bootstrap .ag-selection-checkbox span {
  position: relative;
  top: 2px;
}
.ag-theme-bootstrap .ag-group-expanded .ag-icon-contracted:empty {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/tree-open.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-column-drop-horizontal {
  background-color: #f6f6f6;
  height: 48px;
  line-height: 16px;
  padding-left: 8px;
}
.ag-theme-bootstrap .ag-column-drop-horizontal.ag-width-half {
  margin-bottom: -5px;
}
.ag-theme-bootstrap .ag-column-drop-horizontal span {
  float: left;
  height: 100%;
}
.ag-theme-bootstrap .ag-column-drop-horizontal > div:first-child {
  float: left;
  height: 100%;
}
.ag-theme-bootstrap .ag-column-drop-horizontal .ag-icon-group,
.ag-theme-bootstrap .ag-column-drop-horizontal .ag-icon-pivot {
  margin-right: 8px;
}
.ag-theme-bootstrap .ag-column-drop-horizontal .ag-right-arrow {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/right.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-column-drop-horizontal .ag-left-arrow {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/left.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-column-drop-horizontal .ag-left-arrow,
.ag-theme-bootstrap .ag-column-drop-horizontal .ag-right-arrow {
  overflow: hidden;
  text-indent: 100%;
  height: 100%;
  margin: 0 4px;
  opacity: 1;
}
.ag-theme-bootstrap .ag-column-drop-horizontal .ag-column-drop-empty-message {
  height: 100%;
  line-height: 48px;
  opacity: 0.5;
}
.ag-theme-bootstrap .ag-column-drop-cell {
  background: #ecf0f1;
  border-radius: 16px;
  height: 16px !important;
  margin-top: 4px;
  padding: 0 2px;
}
.ag-theme-bootstrap .ag-column-drop-cell .ag-column-drop-cell-text {
  height: 100%;
  line-height: 16px;
  margin: 0 4px;
}
.ag-theme-bootstrap .ag-column-drop-cell .ag-column-drop-cell-button {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/cancel.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  overflow: hidden;
  text-indent: 100%;
  min-width: 16px;
  height: 100%;
  margin: 0 2px;
  opacity: 1;
}
.ag-theme-bootstrap .ag-column-drop-cell .ag-column-drop-cell-button:hover {
  opacity: 1;
}
.ag-theme-bootstrap .ag-column-drop-cell .ag-column-drag {
  margin-left: 8px;
  margin-top: 2px;
  width: 13px;
}
.ag-theme-bootstrap .ag-select-agg-func-popup {
  background: #297ac5;
  border-radius: 0;
  box-shadow: none;
  padding: 4px;
  background: #ffffff;
  height: 70px;
  padding: 0;
}
.ag-theme-bootstrap .ag-select-agg-func-popup .ag-virtual-list-item {
  cursor: default;
  line-height: 20px;
  padding-left: 8px;
}
.ag-theme-bootstrap .ag-select-agg-func-popup .ag-virtual-list-item:hover {
  background-color: #bde2e5;
}
.ag-theme-bootstrap .ag-set-filter-list,
.ag-theme-bootstrap .ag-menu-column-select-wrapper {
  width: auto;
}
.ag-theme-bootstrap .ag-column-drop-vertical > .ag-column-drop-cell {
  float: left;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-top: 0;
}
.ag-theme-bootstrap .ag-cell-data-changed {
  background-color: rgba(41, 122, 197, 0.09) !important;
}
.ag-theme-bootstrap .ag-cell-data-changed-animation {
  background-color: transparent;
  transition: background-color 1s;
}
.ag-theme-bootstrap .ag-row-stub {
  background-color: #f0f0f0;
}
.ag-theme-bootstrap .ag-stub-cell {
  padding-left: 8px;
  padding-top: 4px;
}
.ag-theme-bootstrap .ag-stub-cell .ag-loading-icon {
  float: left;
  height: 100%;
}
.ag-theme-bootstrap .ag-stub-cell .ag-loading-text {
  float: left;
  height: 100%;
  margin-left: 4px;
  margin-top: 4px;
}
.ag-theme-bootstrap .ag-floating-top {
  border-bottom: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-floating-bottom {
  border-top: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-floating-top, .ag-theme-bootstrap .ag-floating-bottom {
  background-color: #f0f0f0;
}
.ag-theme-bootstrap .ag-floating-top .ag-row, .ag-theme-bootstrap .ag-floating-bottom .ag-row {
  background-color: #f0f0f0;
}
.ag-theme-bootstrap .ag-rtl {
  text-align: right;
}
.ag-theme-bootstrap .ag-rtl .ag-numeric-cell {
  text-align: left;
}
.ag-theme-bootstrap .ag-rtl .ag-header-cell-menu-button {
  float: left;
}
.ag-theme-bootstrap .ag-rtl .ag-header-cell-label > span {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-header-cell-label .ag-header-icon {
  margin-top: 2px;
}
.ag-theme-bootstrap .ag-rtl .ag-numeric-header .ag-header-cell-menu-button {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-numeric-header .ag-header-cell-label {
  float: left;
}
.ag-theme-bootstrap .ag-rtl .ag-numeric-header .ag-header-cell-label > span {
  float: left;
}
.ag-theme-bootstrap .ag-rtl .ag-tool-panel-wrapper .ag-pivot-mode-panel span {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-tool-panel-wrapper .ag-pivot-mode-panel .ag-pivot-mode-select {
  margin-right: 4px;
}
.ag-theme-bootstrap .ag-rtl .ag-tool-panel-wrapper .ag-pivot-mode-panel .ag-pivot-mode-select .ag-checkbox-label {
  margin-right: 4px;
}
.ag-theme-bootstrap .ag-rtl .ag-tool-panel-wrapper .ag-column-drop .ag-icon {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-tool-panel-wrapper .ag-column-drop .ag-column-drop-title {
  clear: left;
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-tool-panel-wrapper .ag-column-drop .ag-column-drop-empty-message {
  padding-left: 4px;
  padding-right: 16px;
}
.ag-theme-bootstrap .ag-rtl .ag-filter-checkbox {
  float: right;
  margin-left: 4px;
}
.ag-theme-bootstrap .ag-rtl .ag-column-select-panel .ag-column-tool-panel-column-group span,
.ag-theme-bootstrap .ag-rtl .ag-column-select-panel .ag-column-tool-panel-column span {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-column-select-panel .ag-column-tool-panel-column-group .ag-column-select-checkbox,
.ag-theme-bootstrap .ag-rtl .ag-column-select-panel .ag-column-tool-panel-column-group .ag-column-group-icons,
.ag-theme-bootstrap .ag-rtl .ag-column-select-panel .ag-column-tool-panel-column .ag-column-select-checkbox,
.ag-theme-bootstrap .ag-rtl .ag-column-select-panel .ag-column-tool-panel-column .ag-column-group-icons {
  margin-left: 4px;
  margin-right: 4px;
}
.ag-theme-bootstrap .ag-rtl .ag-column-select-panel .ag-column-tool-panel-column.ag-toolpanel-add-group-indent {
  margin-left: 0;
  margin-right: 21px;
}
.ag-theme-bootstrap .ag-rtl .ag-icon-tree-closed {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/contracted.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-rtl .ag-header-group-cell-label {
  height: 100%;
}
.ag-theme-bootstrap .ag-rtl .ag-header-group-cell-label span {
  float: right;
  height: 100%;
}
.ag-theme-bootstrap .ag-rtl .ag-header-select-all:not(.ag-hidden) + .ag-cell-label-container {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-header-select-all {
  margin-left: 8px;
  margin-right: 0;
}
.ag-theme-bootstrap .ag-rtl .ag-selection-checkbox + .ag-cell-value:not(:empty), .ag-theme-bootstrap .ag-rtl .ag-selection-checkbox + .ag-group-value:not(:empty),
.ag-theme-bootstrap .ag-rtl .ag-group-checkbox + .ag-cell-value:not(:empty),
.ag-theme-bootstrap .ag-rtl .ag-group-checkbox + .ag-group-value:not(:empty),
.ag-theme-bootstrap .ag-rtl .ag-group-expanded + .ag-cell-value:not(:empty),
.ag-theme-bootstrap .ag-rtl .ag-group-expanded + .ag-group-value:not(:empty),
.ag-theme-bootstrap .ag-rtl .ag-group-contracted + .ag-cell-value:not(:empty),
.ag-theme-bootstrap .ag-rtl .ag-group-contracted + .ag-group-value:not(:empty) {
  display: inline-block;
  margin-right: 8px;
  margin-left: initial;
}
.ag-theme-bootstrap .ag-rtl .ag-selection-checkbox + .ag-group-checkbox,
.ag-theme-bootstrap .ag-rtl .ag-group-checkbox + .ag-group-checkbox,
.ag-theme-bootstrap .ag-rtl .ag-group-expanded + .ag-group-checkbox,
.ag-theme-bootstrap .ag-rtl .ag-group-contracted + .ag-group-checkbox {
  margin-right: 8px;
  margin-left: initial;
}
.ag-theme-bootstrap .ag-rtl .ag-column-drop-horizontal {
  padding-right: 8px;
}
.ag-theme-bootstrap .ag-rtl .ag-column-drop-horizontal span {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-column-drop-horizontal > div:first-child {
  float: right;
}
.ag-theme-bootstrap .ag-rtl .ag-column-drop-horizontal .ag-icon-group,
.ag-theme-bootstrap .ag-rtl .ag-column-drop-horizontal .ag-icon-pivot {
  margin-left: 8px;
  margin-right: 0;
}
.ag-theme-bootstrap .ag-rtl .ag-column-drop-horizontal .ag-right-arrow {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/right.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  height: 100%;
}
.ag-theme-bootstrap .ag-rtl .ag-column-drop-horizontal .ag-left-arrow {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/left.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
  height: 100%;
}
.ag-theme-bootstrap .ag-rtl .ag-floating-filter-body {
  float: right;
  margin-left: 0;
}
.ag-theme-bootstrap .ag-rtl .ag-floating-filter-button {
  float: left;
}
.ag-theme-bootstrap .ag-rtl .ag-header .ag-header-cell-resize::after {
  border-left: 1px solid #e0e0e0;
  border-right: 0;
}
.ag-theme-bootstrap .ag-rtl .ag-column-drag {
  background-position-x: right;
}
.ag-theme-bootstrap .ag-status-bar {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: 0;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  padding-right: 16px;
  padding-left: 16px;
}
.ag-theme-bootstrap .ag-name-value-value {
  color: #545b62;
}
.ag-theme-bootstrap .ag-status-bar-center {
  text-align: center;
}
.ag-theme-bootstrap .ag-name-value {
  margin-left: 4px;
  margin-right: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.ag-theme-bootstrap .ag-details-row {
  padding: 20px;
}
.ag-theme-bootstrap .ag-overlay-loading-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}
.ag-theme-bootstrap .ag-overlay-loading-center {
  background: #297ac5;
  border-radius: 0;
  box-shadow: none;
  padding: 4px;
}
.ag-theme-bootstrap .ag-side-bar {
  background-color: #f6f6f6;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  position: relative;
}
.ag-theme-bootstrap .ag-side-bar .ag-side-buttons {
  padding-top: 16px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}
.ag-theme-bootstrap .ag-side-bar .ag-side-buttons .ag-side-button button {
  background: transparent;
  border: 0;
  color: #545b62;
  padding: 8px 0 8px 0;
  width: 100%;
  margin: 0;
  min-height: 72px;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: transparent;
  background-position-y: 4px;
  background-position-x: center;
  background-repeat: no-repeat;
}
.ag-theme-bootstrap .ag-side-bar .ag-side-buttons .ag-selected button {
  background-color: #f6f6f6;
  margin-left: -1px;
  padding-left: 1px;
  width: calc(100% + 1px);
  border-color: #e0e0e0;
}
.ag-theme-bootstrap .ag-side-bar .ag-panel-container {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-side-bar.full-width .ag-panel-container {
  border-right: 0;
}
.ag-theme-bootstrap .ag-side-bar .ag-column-drop {
  min-height: 50px;
}
.ag-theme-bootstrap .ag-rtl .ag-side-bar .ag-panel-container {
  border-left: 1px solid #e0e0e0;
  border-right: 0;
}
.ag-theme-bootstrap .ag-rtl .ag-side-bar.full-width .ag-panel-container {
  border-left: 0;
}
.ag-theme-bootstrap .ag-primary-cols-filter {
  width: 100%;
}
.ag-theme-bootstrap .ag-primary-cols-filter:disabled {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebebeb;
}
.ag-theme-bootstrap .ag-primary-cols-filter-wrapper {
  margin-left: 4px;
  margin-right: 4px;
}
.ag-theme-bootstrap .sass-variables::after {
  content: '{ "autoSizePadding": "12px", "headerHeight": "48px", "groupPaddingSize": "25px", "footerPaddingAddition": "16px", "virtualItemHeight": "20px", "aggFuncPopupHeight": "70px", "checkboxIndentWidth": "17px", "leafNodePadding": "12px", "rowHeight": "48px", "gridSize": "4px", "iconSize": "13px" }';
  display: none;
}
.ag-theme-bootstrap .ag-tab-header {
  background-color: #e6e6e6;
}
.ag-theme-bootstrap .ag-faded {
  opacity: 0.3;
}
.ag-theme-bootstrap .ag-column-drop-horizontal.ag-column-drop {
  border: 1px solid #e0e0e0;
  border-bottom: 0;
}
.ag-theme-bootstrap .ag-column-drop-horizontal.ag-column-drop:last-child {
  border-left: 0;
}
.ag-theme-bootstrap .ag-header-cell-resize::after {
  height: 48px;
  margin-top: 0;
}
.ag-theme-bootstrap .ag-header-cell {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-header-group-cell {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-root {
  border: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper {
  border-right: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-drop .ag-column-drop-empty-message {
  line-height: 48px;
}
.ag-theme-bootstrap .ag-floating-filter-button {
  margin-top: 8px;
}
.ag-theme-bootstrap .ag-filter .ag-filter-apply-panel {
  border-top: 1px solid #e0e0e0;
  justify-content: flex-start;
}
.ag-theme-bootstrap .ag-cell-focus {
  border: 1px solid #297ac5;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-active {
  background-color: #bde2e5;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option {
  line-height: 24px;
}
.ag-theme-bootstrap .ag-column-drop-cell {
  background: #ecf0f1;
  background-image: none;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  height: 18px !important;
}
.ag-theme-bootstrap .ag-column-drop-cell .ag-column-drop-cell-button {
  height: calc(100% - 4px);
  margin-bottom: 2px;
  margin-top: 2px;
}
.ag-theme-bootstrap .ag-column-drop-cell .ag-column-drop-cell-button:hover {
  border: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-cell-highlight {
  background-color: #ffffff;
  border-bottom: 2px solid #297ac5;
}
.ag-theme-bootstrap .ag-cell-highlight-animation {
  transition: all 1s;
}
.ag-theme-bootstrap .ag-group-expanded .ag-icon-contracted:empty {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/contracted.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-rtl .ag-icon-tree-closed {
  background-color: transparent;
  background-image: url("./styles/ag-theme-base/icons/small-left.svg?color1=%23333333&color2=%23FFFFFF");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 13px;
  opacity: 1;
  width: 13px;
}
.ag-theme-bootstrap .ag-cell-inline-editing {
  height: 48px;
  padding: 0;
}
.ag-theme-bootstrap .ag-tool-panel-wrapper .ag-column-drop .ag-column-drop-title {
  float: none;
}
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column-group,
.ag-theme-bootstrap .ag-column-select-panel .ag-column-tool-panel-column {
  height: 20px;
  line-height: 20px;
}
.ag-theme-bootstrap .ag-filter .ag-filter-header-container {
  height: 24px;
}
.ag-theme-bootstrap .ag-tab {
  box-sizing: initial;
}
.ag-theme-bootstrap .ag-filter .ag-filter-value {
  line-height: 17px;
}
.ag-theme-bootstrap .ag-selection-checkbox span {
  position: relative;
  top: 0;
}
.ag-theme-bootstrap .ag-rich-select-value {
  border-bottom: 1px solid #e0e0e0;
}
.ag-theme-bootstrap .ag-header-cell-moving .ag-header-cell-label {
  opacity: 0.5;
}
.ag-theme-bootstrap .ag-header-cell-moving {
  background-color: rgb(190, 190, 190);
}
.ag-theme-bootstrap .ag-overlay-loading-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}
.ag-theme-bootstrap .ag-overlay-loading-center {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  color: black;
  padding: 10px;
}
.ag-theme-bootstrap .ag-column-name-filter {
  height: 16px;
}
.ag-theme-bootstrap .ag-column-drop-cell .ag-column-drag {
  background-position-y: 0 !important;
}
.ag-theme-bootstrap .ag-menu, .ag-theme-bootstrap .ag-theme-bootstrap.ag-dnd-ghost, .ag-theme-bootstrap .ag-cell-inline-editing, .ag-theme-bootstrap .ag-popup-editor, .ag-theme-bootstrap .ag-select-agg-func-popup, .ag-theme-bootstrap .ag-overlay-loading-center {
  border: 1px solid #ccc;
}

.ag-theme-bootstrap.ag-grid-root {
  position: relative;
  height: calc(100vh - 210px);
  max-height: 100%;
}
.ag-theme-bootstrap.ag-grid-root .ag-root, .ag-theme-bootstrap.ag-grid-root .ag-header {
  border: 0;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell--first, .ag-theme-bootstrap.ag-grid-root .ag-column--first, .ag-theme-bootstrap.ag-grid-root .ag-header-cell[col-id*=AutoColumn]:first-child, .ag-theme-bootstrap.ag-grid-root .ag-cell[col-id*=AutoColumn]:first-child {
  padding-left: 32px;
  border-left: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-group-cell, .ag-theme-bootstrap.ag-grid-root .ag-header-cell {
  border: none;
  text-transform: uppercase;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-group-cell .ag-header-group-cell-label {
  justify-content: center;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell-label {
  width: 100%;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell-sortable .ag-header-cell-label {
  cursor: pointer;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell-sortable .ag-header-icon {
  display: flex;
  align-items: center;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell-moving {
  background-color: #297ac5;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell--centered .ag-header-cell-label {
  justify-content: center;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell-menu-button + .ag-header-cell-label {
  width: calc(100% - 16px8px);
  justify-content: flex-start;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-cell--even {
  background-color: #428BCF;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-icon {
  margin-left: 8px;
}
.ag-theme-bootstrap.ag-grid-root .ag-numeric-header .ag-header-cell-text {
  text-align: right;
}
.ag-theme-bootstrap.ag-grid-root .ag-numeric-header .ag-header-icon {
  margin-left: 0;
  margin-right: 8px;
}
.ag-theme-bootstrap.ag-grid-root .ag-body {
  background-color: #ffffff;
}
.ag-theme-bootstrap.ag-grid-root .ag-row-first .ag-cell {
  border-top: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-row-last .ag-cell {
  border-bottom: 1px solid #e0e0e0;
}
.ag-theme-bootstrap.ag-grid-root .ag-row--clickable {
  cursor: pointer;
}
.ag-theme-bootstrap.ag-grid-root .ag-row-drag {
  width: 81px;
}
.ag-theme-bootstrap.ag-grid-root .ag-row-drag .ag-icon-grip {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  line-height: 48px;
}
.ag-theme-bootstrap.ag-grid-root .ag-row-drag .ag-icon-grip:before {
  font-family: "iris";
  content: "\ea38";
}
.ag-theme-bootstrap.ag-grid-root .ag-column {
  background-color: #ffffff;
}
.ag-theme-bootstrap.ag-grid-root .ag-column--centered {
  text-align: center;
}
.ag-theme-bootstrap.ag-grid-root .ag-column--number {
  text-align: right;
}
.ag-theme-bootstrap.ag-grid-root .ag-column--text, .ag-theme-bootstrap.ag-grid-root .ag-column--text > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-theme-bootstrap.ag-grid-root .ag-column--even {
  background-color: #eeeeee;
}
.ag-theme-bootstrap.ag-grid-root .ag-column--odd {
  background-color: #ffffff;
}
.ag-theme-bootstrap.ag-grid-root .ag-column--flexed > span {
  display: flex;
  align-items: center;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell {
  border-top: 1px solid #e0e0e0;
}
.ag-theme-bootstrap.ag-grid-root .ag-pinned-left-header {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
.ag-theme-bootstrap.ag-grid-root .ag-pinned-left-header .ag-header-cell, .ag-theme-bootstrap.ag-grid-root .ag-pinned-left-header .ag-header-group-cell {
  border: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-pinned-left-cols-container .ag-row--expanded .ag-column {
  background-color: #eeeeee;
}
.ag-theme-bootstrap.ag-grid-root .ag-pinned-left-cols-container .ag-column[col-id=ag-Grid-AutoColumn] {
  text-overflow: unset;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell:focus {
  outline: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-value {
  vertical-align: middle;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-value--increase {
  color: #1abc9c;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-value--decrease {
  color: #e74c3c;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-focus {
  border: 2px solid #297ac5 !important;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-focus:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 8px 8px;
  border-color: transparent transparent #297ac5 transparent;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-highlight {
  border-bottom: 2px solid #297ac5;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-last-left-pinned {
  border-right: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-cell-range-selected:not(.ag-cell-focus):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: rgba(41, 122, 197, 0.09);
}
.ag-theme-bootstrap.ag-grid-root .ag-row-group-leaf-indent {
  margin-left: 0;
}
.ag-theme-bootstrap.ag-grid-root .ag-group-value {
  max-width: calc(100% - 8px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-theme-bootstrap.ag-grid-root .ag-group-checkbox:empty {
  display: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-group-checkbox:empty + .ag-group-value {
  margin: 0;
}
.ag-theme-bootstrap.ag-grid-root .ag-group-expanded, .ag-theme-bootstrap.ag-grid-root .ag-group-contracted {
  position: relative;
  float: right;
}
.ag-theme-bootstrap.ag-grid-root .ag-group-expanded i, .ag-theme-bootstrap.ag-grid-root .ag-group-contracted i {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.ag-theme-bootstrap.ag-grid-root .ag-group-expanded i:hover, .ag-theme-bootstrap.ag-grid-root .ag-group-contracted i:hover {
  background-color: #eeeeee;
}
.ag-theme-bootstrap.ag-grid-root .ag-group-expanded:not(.ag-hidden) ~ .ag-group-value, .ag-theme-bootstrap.ag-grid-root .ag-group-contracted:not(.ag-hidden) ~ .ag-group-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
}
.ag-theme-bootstrap.ag-grid-root .ag-sort-order {
  display: none;
  color: rgba(255, 255, 255, 0.3);
}
.ag-theme-bootstrap.ag-grid-root .ag-sort-order:before {
  content: "(";
}
.ag-theme-bootstrap.ag-grid-root .ag-sort-order:after {
  content: ")";
}
.ag-theme-bootstrap.ag-grid-root .ag-header-select-all .ag-wrapper, .ag-theme-bootstrap.ag-grid-root .ag-selection-checkbox .ag-wrapper {
  margin: 16px 24px 16px 0;
  height: 16px;
  width: 16px;
  line-height: 16px;
  vertical-align: top;
  text-align: center;
  color: #ffffff;
  border-radius: 3px;
  display: inline-block;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-select-all .ag-wrapper .ag-checkbox-input, .ag-theme-bootstrap.ag-grid-root .ag-selection-checkbox .ag-wrapper .ag-checkbox-input {
  margin: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
  cursor: pointer;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-select-all ~ .ag-cell-value:not(:empty), .ag-theme-bootstrap.ag-grid-root .ag-selection-checkbox ~ .ag-cell-value:not(:empty) {
  display: inline-block;
  margin-left: 0;
  width: calc(100% - 40px);
}
.ag-theme-bootstrap.ag-grid-root .ag-header-select-all .ag-wrapper {
  background-color: #297ac5;
}
.ag-theme-bootstrap.ag-grid-root .ag-header-select-all:not(.ag-hidden) + .ag-cell-label-container {
  float: right;
  width: calc(100% - 16px32px);
}
.ag-theme-bootstrap.ag-grid-root .ag-selection-checkbox .ag-wrapper {
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;
}
.ag-theme-bootstrap.ag-grid-root .ag-selection-checkbox .ag-wrapper.ag-checked {
  background-color: #1f6bb0;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ag-theme-bootstrap.ag-grid-root .ag-selection-checkbox .ag-wrapper.ag-checked:before {
  font-family: "iris";
  content: "\e984";
}
.ag-theme-bootstrap.ag-grid-root .ag-selection-checkbox .ag-wrapper.ag-checked:before {
  position: absolute;
  width: 16px;
  height: 16px;
  line-height: calc(16px + 2px);
  font-size: 11px;
}
.ag-theme-bootstrap.ag-grid-root .ag-body-viewport {
  background-color: #ffffff;
}
.ag-theme-bootstrap.ag-grid-root .ag-overlay {
  z-index: 1;
}
.ag-theme-bootstrap.ag-grid-root .ag-overlay-panel {
  pointer-events: initial;
}
.ag-theme-bootstrap.ag-grid-root .ag-overlay-wrapper {
  flex-flow: column;
  height: 100%;
  padding-top: 48px;
  background-color: #ffffff;
  background-clip: content-box;
}
.ag-theme-bootstrap.ag-grid-root .ag-overlay-wrapper .ag-react-container:not(:first-child) {
  display: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-overlay-message {
  font-size: 16px;
  margin-bottom: 16px;
}
.ag-theme-bootstrap.ag-grid-root .ag-floating-top, .ag-theme-bootstrap.ag-grid-root .ag-floating-bottom {
  border: none;
}
.ag-theme-bootstrap.ag-grid-root .ag-side-bar, .ag-theme-bootstrap.ag-grid-root .ag-status-bar, .ag-theme-bootstrap.ag-grid-root .ag-paging-panel {
  display: none;
}
.ag-theme-bootstrap.ag-grid-root .pagination {
  height: 100px;
  padding: 32px 0;
}
.ag-theme-bootstrap.ag-grid-root--auto-height {
  height: auto;
}
.ag-theme-bootstrap.ag-grid-root--no-data .ag-body-viewport {
  min-height: 150px;
}
.ag-theme-bootstrap.ag-grid-root--paginated > div {
  height: calc(100% - 100px) !important;
}
.ag-theme-bootstrap.ag-grid-root--comfy .ag-cell {
  line-height: 38px;
}
.ag-theme-bootstrap.ag-grid-root--compact .ag-header-cell--first, .ag-theme-bootstrap.ag-grid-root--compact .ag-column--first, .ag-theme-bootstrap.ag-grid-root--compact .ag-header-cell[col-id*=AutoColumn]:first-child, .ag-theme-bootstrap.ag-grid-root--compact .ag-cell[col-id*=AutoColumn]:first-child {
  padding-left: 16px;
}
.ag-theme-bootstrap.ag-grid-root--compact .ag-cell {
  line-height: 24px;
}
.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header, .ag-theme-bootstrap.ag-grid-root--pinned .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned {
  border-right: none;
}
.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header {
  position: relative;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-color: transparent rgba(0, 0, 0, 0.1) transparent transparent;
  border-style: solid;
  border-width: 0 32px 32px 0;
}
.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header:before {
  font-family: "iris";
  content: "\e90d";
}
.ag-theme-bootstrap.ag-grid-root--pinned .ag-pinned-left-header:before {
  z-index: 1;
  position: absolute;
  right: 2px;
  top: 4px;
  font-size: 11px;
  color: #ffffff;
}
.ag-theme-bootstrap.ag-grid-root--pinned .ag-header-viewport, .ag-theme-bootstrap.ag-grid-root--pinned .ag-center-cols-clipper {
  position: relative;
}
.ag-theme-bootstrap.ag-grid-root--pinned .ag-header-viewport:before, .ag-theme-bootstrap.ag-grid-root--pinned .ag-center-cols-clipper:before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  opacity: 1;
  background: transparent;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent);
  animation: fade-in 0.3s ease;
}
.ag-theme-bootstrap.ag-grid-root .bulk-action-bar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.ag-theme-bootstrap .ag-menu {
  background: #297ac5;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.ag-theme-bootstrap .ag-menu .ag-menu-list {
  margin: 0;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option {
  line-height: 30px;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-active {
  background: #1f6bb0;
}
.ag-theme-bootstrap .ag-menu .ag-icon-copy {
  display: none;
}
.ag-theme-bootstrap.ag-dnd-ghost {
  display: none;
}
.ag-theme-bootstrap .ag-row-dragging {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
