/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.dropdown .dropdown-toggle {
  border-radius: 3px;
}
.dropdown-portal {
  position: relative;
  z-index: 55;
}
.dropdown-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: #545b62;
}
.dropdown-loading_spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border: 2px solid #939ba0;
  border-radius: 50%;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  z-index: 51;
}
.dropdown--expanded .dropdown-toggle {
  border-radius: 3px 3px 0 0;
}
.dropdown--disabled .dropdown-toggle {
  opacity: 0.3;
}
.dropdown--disabled .dropdown-toggle input, .dropdown--disabled .dropdown-toggle input::placeholder {
  opacity: 0.3;
}

.dropdown_mask {
  background-color: #ffffff;
  opacity: 0;
}
