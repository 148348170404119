/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.address-book-grid .cell {
  display: flex;
  align-items: center;
}
.address-book-grid .cell_detail {
  flex: 1;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address-book-grid .cell_detail-name, .address-book-grid .cell_detail-position {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address-book-grid .cell_detail-position {
  color: #939ba0;
}
.address-book-grid .cell_icon {
  margin-left: 8px;
  font-size: 16px;
}
.address-book-grid .cell_icon--target {
  color: #1abc9c;
}
.address-book-grid .cell_icon--deal {
  color: #939ba0;
}
.address-book-grid .cell--email {
  justify-content: center;
}
.address-book-grid .cell--email a {
  color: inherit;
}
.address-book-grid .cell--email .cell_icon {
  font-size: 16px;
}
