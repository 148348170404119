/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.drawer-library-card {
  position: relative;
  min-height: 70px;
  margin: 0 0 10px;
  padding: 20px 30px;
  border-radius: 5px;
  background-color: #22272b;
  animation: fade-in 0.3s;
}
.drawer-library-card:last-child {
  margin-bottom: 14px;
}
.drawer-library-card_title, .drawer-library-card_description {
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.drawer-library-card_title {
  font-size: 20px;
  color: #f1af0f;
}
.drawer-library-card_indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  padding: 6px 0 0 4px;
  border-top-right-radius: 5px;
  background-color: #f1af0f;
  color: #22272b;
}
.drawer-library-card_indicator:before, .drawer-library-card_indicator:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 25px 0;
  border-color: transparent #f1af0f transparent transparent;
}
.drawer-library-card_indicator:before {
  top: 0;
  left: -25px;
}
.drawer-library-card_indicator:after {
  top: 25px;
  right: 0;
}
.drawer-library-card_description {
  margin-top: 10px;
  line-height: 1.5;
}
.drawer-library-card_action {
  position: absolute;
  top: 20px;
  right: 30px;
}
.drawer-library-card--locked .drawer-library-card_indicator {
  background-color: #ec6a4c;
  color: #ffffff;
}
.drawer-library-card--locked .drawer-library-card_indicator:before, .drawer-library-card--locked .drawer-library-card_indicator:after {
  border-color: transparent #ec6a4c transparent transparent;
}
.drawer-library-card--disabled {
  opacity: 0.5;
  pointer-events: none;
}
