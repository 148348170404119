/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.highlights {
  display: flex;
  padding-bottom: 8px;
}
.highlights:before {
  content: "-";
}
.highlights > p span {
  padding: 0 5px;
}
.highlights_change-ptc--increase {
  color: #1abc9c;
}
.highlights_change-ptc--decrease {
  color: #e74c3c;
}
