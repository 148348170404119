.profile-menu-mask .profile-menu {
  top: 80px !important;
  background-color: #22272b;
  width: 250px;
  padding: 20px 20px;
  border-radius: 0;
}
.profile-menu-mask .profile-menu ul {
  padding: 0;
}
.profile-menu-mask .profile-menu_profile {
  margin-bottom: 10px;
  padding: 0 10px 20px;
  color: #ffffff;
  border-bottom: 1px solid #14171a;
  outline: none;
}
.profile-menu-mask .profile-menu_profile_title {
  margin-top: 4px;
  color: #858e93;
}
.profile-menu-mask .profile-menu_item {
  padding: 0 10px;
  color: #ffffff;
  font-size: 13px;
  height: 40px;
  transition: all 0.15s ease;
}
.profile-menu-mask .profile-menu_item .icon {
  margin-right: 14px;
  font-size: 16px;
}
.profile-menu-mask .profile-menu_item:hover {
  color: #f1af0f;
  background-color: #14171a;
  border-radius: 3px;
}
