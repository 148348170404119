.report-chart-type-list {
  list-style: none;
}
.report-chart-type-list_item {
  display: inline-block;
  margin-bottom: 14px;
  cursor: pointer;
}
.report-chart-type-list_item:not(:nth-child(3n)) {
  margin-right: 10px;
  margin-bottom: 0;
}
.report-chart-type-list_item--selected {
  color: #297ac5;
  pointer-events: none;
}
.report-chart-type-list_item--selected .report-chart-type-list_block {
  border: 2px solid #297ac5;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.report-chart-type-list_item--selected .report-chart-type-list_block i {
  line-height: 89.3333333333px;
}
.report-chart-type-list_item--selected .report-chart-type-list_label {
  color: #297ac5;
}
.report-chart-type-list_item--disabled {
  opacity: 0.3;
  pointer-events: none;
}
.report-chart-type-list_item--bar .report-chart-type-list_block i {
  display: inline-block;
  transform: rotate(90deg) scale(-1, 1);
}
.report-chart-type-list_block {
  width: 93.3333333333px;
  height: 93.3333333333px;
  margin-bottom: 6px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  text-align: center;
  transition: box-shadow 0.3s ease;
}
.report-chart-type-list_block i {
  font-size: 30px;
  line-height: 91.3333333333px;
}
.report-chart-type-list_label {
  color: #2a3035;
  transition: color 0.3s ease;
}
.report-chart-type-list:not(.report-chart-type-list--selected) .report-chart-type-list_block:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
