@import '../../../resources/styles/variables';
@import '../../../resources/styles/icons/variables';
@import '../../../resources/styles/utils';

@mixin radioTheme($textColor, $backgroundColor, $borderColor) {
  .radio {
    &_label {
      color: $textColor;

      &:before {
        background-color: $backgroundColor;
        border-color: $borderColor;
      }
    }
  }
}

@mixin radioButtonTheme($checkedBackgroundColor) {
  &.radio--selected .radio {
    &_label:before {
      background-color: $checkedBackgroundColor;
    }
  }
}

.radio {
  padding: gux(0.5) 0;
  line-height: $line-height-medium;

  &_label {
    display: inline-block;
    user-select: none;

    &:before {
      content: '';
      display: inline-block;
      margin-right: gux();
      width: 16px;
      height: 16px;
      border-width: 3px;
      border-style: solid;
      border-radius: $border-radius-circle;
      vertical-align: text-bottom;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      cursor: pointer;
    }
  }

  &_field {
    display: none;
  }

  // themes
  &--light,
  &--white {
    @include radioTheme($light-slate, $white, $soft-grey);
  }

  &--dark {
    @include radioTheme($white, $white, $black-smoke);
  }

  // radio button color
  &--rain {
    @include radioButtonTheme($rain);
  }

  &--teal {
    @include radioButtonTheme($teal);
  }

  &--citrus {
    @include radioButtonTheme($citrus);
  }

  &--cherry {
    @include radioButtonTheme($cherry);
  }

  &--apple {
    @include radioButtonTheme($apple);
  }

  &--eggplant {
    @include radioButtonTheme($eggplant);
  }

  &--walnut {
    @include radioButtonTheme($walnut);
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
