.footer {
  min-height: 150px;
  background-color: #1a1d21;
}
.footer_inner {
  padding: 24px 16px;
}
.footer p {
  font-size: 11px;
  line-height: 22px;
  color: #939ba0;
}
.footer p span {
  margin-right: 16px;
}
