@import '../../resources/styles/variables';
@import '../../resources/styles/utils';

$height: 32px;

@mixin peerInputTheme($color, $backgroundColor, $hoverColor, $hoverBackgroundColor) {
  .peer-input {

    &_add {
      color: $color;
      background-color: $backgroundColor;
    }

    &_add:hover {
      color: $hoverColor;
      background-color: $hoverBackgroundColor;
    }
  }
}


.peer-input {
  display: flex;
  align-items: center;

  &_add {
    height: $height;
  }

  &_add.button--square {
    cursor: pointer;
    width: $height;
    border-radius: $border-radius-small;
    transition: background-color $transition-duration--default $transition-timing-function--default;
  }

  .peer-input_add--hidden {
    display: none;
  }

  // select overrides
  .peer-input_search {
    width: 0;
    transition: width $transition-duration--fast $transition-timing-function--default;

    .select-component {

      .select__control {
        width: 100%;
        min-width: 0;
        border-radius: 0 $border-radius-small $border-radius-small 0;
        background-color: $dark-slate;

        &--menu-is-open {
          border-radius: $border-radius-small;
        }
      }

      .select__value-container {
        padding: 0;

        & .select__input {
          color: $white;
        }
      }

      .select__clear-indicator {
        color: $silver;
      }

      .select__indicator-loading-spinner {
        border: 2px solid $white;
      }

      .select__placeholder {
        opacity: 0;
      }

      .select_menu--white .select__option.select__option--is-disabled {
        background-color: $white
      }
      
      .select_menu--dark .select__option.select__option--is-disabled {
        background-color: $gunpowder
      }

      .select__option {
        height: auto;

        &.select__option--is-disabled {
          cursor: default;
        }

        .peer-option__container {
          padding: 10px 4px;
          display: flex;
          justify-content: space-between;

          .peer-option__indicator {
            width: 15px;
            height: 15px;
            font-size: 8px;
            margin-right: 3px;
            text-align: center;
            display: inline-block;
            border-radius: 50%;

            &.q4i-checkmark-2pt {
              color: $light-slate;
              background-color: $pale-grey;
              line-height: 15px;
            }

            &.q4i-add-4pt {
              color: $white;
              background-color: $teal;
              line-height: 15px;
            }
          }

          .peer-option__group {
            line-height: normal;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
  
            .peer-option__symbol {
              font-weight: $font-weight-bold;
              padding-right: 5px;
            }
            .peer-option__exchange {
              font-weight: $font-weight-bold;
              color: $silver;
            }
            .peer-option__companyName {
              color: $silver;
            }
          }
        }
      }
    }

    &--expanded {
      width: 260px;

      .select-component {

        .select__value-container {
          padding: 0 gux(0.75);
        }

        .select__placeholder {
          opacity: 1;
          transition: opacity $transition-duration--medium $transition-timing-function--default;
        }
      }
    }
  }

  &--light {
    @include peerInputTheme($light-slate, $light-grey, $light-grey, $rain);
  }

  &--dark {
    @include peerInputTheme($citrus, $dark-slate, $dark-slate, $citrus);
  }
}
