.report-builder-list_table {
  position: relative;
  min-height: calc(100vh - 80px - 130px - 140px);
  background-color: #ffffff;
}
.report-builder-list_table--empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
