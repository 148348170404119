/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.select-list {
  background-color: #297ac5;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 3px 3px;
  z-index: 5;
}
.select-list .select-list__item {
  color: #ffffff;
  border-top: 1px solid #1f6bb0;
  background-color: #297ac5;
}
.select-list .select-list__item--highlighted {
  background-color: #1f6bb0;
}
.select-list .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #1f6bb0;
}
.select-list .react-scrollbar_scrollbar:before {
  background-color: #1f6bb0;
}
.select-list--whites {
  background-color: #ffffff;
}
.select-list--whites .select-list__item {
  color: #545b62;
  border-top: 1px solid #eeeeee;
  background-color: #ffffff;
}
.select-list--whites .select-list__item--highlighted {
  background-color: #f7f7f7;
}
.select-list--whites .select-list__item--disabled {
  color: rgba(84, 91, 98, 0.3);
}
.select-list--whites:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #f7f7f7;
}
.select-list--whites .react-scrollbar_scrollbar:before {
  background-color: #eeeeee;
}
.select-list--slate {
  background-color: #2a3035;
}
.select-list--slate .select-list__item {
  color: #ffffff;
  border-top: 1px solid #22272b;
  background-color: #2a3035;
}
.select-list--slate .select-list__item--highlighted {
  background-color: #545b62;
}
.select-list--slate .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list--slate:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #545b62;
}
.select-list--slate .react-scrollbar_scrollbar:before {
  background-color: #22272b;
}
.select-list--dark-slate {
  background-color: #22272b;
}
.select-list--dark-slate .select-list__item {
  color: #ffffff;
  border-top: 1px solid #1a1d21;
  background-color: #22272b;
}
.select-list--dark-slate .select-list__item--highlighted {
  background-color: #1f2327;
}
.select-list--dark-slate .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list--dark-slate:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #1f2327;
}
.select-list--dark-slate .react-scrollbar_scrollbar:before {
  background-color: #1a1d21;
}
.select-list--pale-grey {
  background-color: #f1f1f1;
}
.select-list--pale-grey .select-list__item {
  color: #545b62;
  border-top: 1px solid #e0e0e0;
  background-color: #f1f1f1;
}
.select-list--pale-grey .select-list__item--highlighted {
  background-color: #e0e0e0;
}
.select-list--pale-grey .select-list__item--disabled {
  color: rgba(84, 91, 98, 0.3);
}
.select-list--pale-grey:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #e0e0e0;
}
.select-list--pale-grey .react-scrollbar_scrollbar:before {
  background-color: #e0e0e0;
}
.select-list--steel {
  background-color: #1f6bb0;
}
.select-list--steel .select-list__item {
  color: #ffffff;
  border-top: 1px solid #10528f;
  background-color: #1f6bb0;
}
.select-list--steel .select-list__item--highlighted {
  background-color: #10528f;
}
.select-list--steel .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list--steel:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #10528f;
}
.select-list--steel .react-scrollbar_scrollbar:before {
  background-color: #10528f;
}
.select-list--rain {
  background-color: #297ac5;
}
.select-list--rain .select-list__item {
  color: #ffffff;
  border-top: 1px solid #1f6bb0;
  background-color: #297ac5;
}
.select-list--rain .select-list__item--highlighted {
  background-color: #1f6bb0;
}
.select-list--rain .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list--rain:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #1f6bb0;
}
.select-list--rain .react-scrollbar_scrollbar:before {
  background-color: #1f6bb0;
}
.select-list--q4-blue {
  background-color: #0f5ca3;
}
.select-list--q4-blue .select-list__item {
  color: #ffffff;
  border-top: 1px solid #0A457C;
  background-color: #0f5ca3;
}
.select-list--q4-blue .select-list__item--highlighted {
  background-color: #0A457C;
}
.select-list--q4-blue .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list--q4-blue:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #0A457C;
}
.select-list--q4-blue .react-scrollbar_scrollbar:before {
  background-color: #0A457C;
}
.select-list--ink {
  background-color: #10528f;
}
.select-list--ink .select-list__item {
  color: #ffffff;
  border-top: 1px solid #0A457C;
  background-color: #10528f;
}
.select-list--ink .select-list__item--highlighted {
  background-color: #0A457C;
}
.select-list--ink .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list--ink:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #0A457C;
}
.select-list--ink .react-scrollbar_scrollbar:before {
  background-color: #0A457C;
}
.select-list--silver {
  background-color: #939ba0;
}
.select-list--silver .select-list__item {
  color: #ffffff;
  border-top: 1px solid #858e93;
  background-color: #939ba0;
}
.select-list--silver .select-list__item--highlighted {
  background-color: #646d75;
}
.select-list--silver .select-list__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.select-list--silver:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #646d75;
}
.select-list--silver .react-scrollbar_scrollbar:before {
  background-color: #858e93;
}
.select-list--citrus {
  background-color: #f1af0f;
}
.select-list--citrus .select-list__item {
  color: #22272b;
  border-top: 1px solid #939ba0;
  background-color: #f1af0f;
}
.select-list--citrus .select-list__item--highlighted {
  background-color: #dc9e27;
}
.select-list--citrus .select-list__item--disabled {
  color: rgba(34, 39, 43, 0.3);
}
.select-list--citrus:not(.select-list--keyboard-navigated) .select-list__item:hover {
  background-color: #dc9e27;
}
.select-list--citrus .react-scrollbar_scrollbar:before {
  background-color: #939ba0;
}
.select-list_scrollbar > div:first-child:empty {
  height: 0;
}
.select-list__empty {
  padding: 10px 14px 14px;
  color: #ffffff;
}

.select-list__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding: 14px;
  user-select: none;
  cursor: pointer;
}
