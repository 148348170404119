@import '../../../../../resources/styles/variables';
@import '../../../../../resources/styles/utils';
@import '../../../../../resources/styles/mixins/all';

.event-transcript-grid_table .event-transcript-grid {
  .cell {
    .event-icon {
      margin-right: gux(2);
      font-size: $font-size-medium;
      display: block;
    }
    .q4i-map-marker-4pt {
      color: $avocado;
    }
    .q4i-releases-4pt {
      color: $midnight;
    }
    .q4i-phone-4pt {
      color: $plum;
    }
    .q4i-webcast-4pt, .q4i-transcript-4pt {
      color: $light-slate;
    }
    .date {
      margin-right: gux(2);
    }

    &_content {
      display: flex;
      align-items: center;

      a {
        margin-right: gux(2);
        .event-icon {
          padding: $padding-horizontal-xxsmall;
        }
        .event-icon, &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.event_transcript-tooltip {
  .event_transcript-tooltip_item {
    margin: 0;
    margin-top: $padding-horizontal-xsmall;
    padding: 0 $padding-horizontal-small;
    font-size: $font-size-base;
    line-height: 30px;
    text-transform: uppercase;
    color: $white;
    background-color: $dark-slate;
    border-radius: $border-radius-small;
    box-shadow: rgba(0, 0, 0, .2) 0 0 20px;
    pointer-events: none;

    @include flexbox();
    @include align-items(center);
  }

  .event_transcript-tooltip_arrow {
    color: $dark-slate;
  }
}