/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.drawer-library-category-list {
  list-style: none;
}
.drawer-library-category-list_item, .drawer-library-category-list_icon, .drawer-library-category-list_label {
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.drawer-library-category-list_item {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid #121517;
  transition-property: background-color;
  cursor: pointer;
}
.drawer-library-category-list_item--selected, .drawer-library-category-list_item:hover {
  background-color: #f1af0f;
}
.drawer-library-category-list_item--selected .drawer-library-category-list_icon, .drawer-library-category-list_item--selected .drawer-library-category-list_label, .drawer-library-category-list_item:hover .drawer-library-category-list_icon, .drawer-library-category-list_item:hover .drawer-library-category-list_label {
  color: #2a3035;
}
.drawer-library-category-list_icon, .drawer-library-category-list_label {
  vertical-align: top;
  transition-property: color;
}
.drawer-library-category-list_icon {
  font-size: 26px;
  margin-right: 20px;
  color: #f1af0f;
}
.drawer-library-category-list_icon i {
  vertical-align: middle;
}
.drawer-library-category-list_label {
  text-transform: uppercase;
  color: #ffffff;
}
.drawer-library-category-list_arrow {
  float: right;
  font-size: 18px;
  color: #2a3035;
}
