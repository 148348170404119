/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.combobox--pale-grey .dropdown-toggle_value input {
  color: #545b62;
  background-color: #f1f1f1;
}
.combobox--pale-grey .dropdown-toggle_value input::placeholder {
  color: #939ba0;
}
.combobox--pale-grey .dropdown-toggle_value input::-webkit-input-placeholder {
  color: #939ba0;
}
.combobox--pale-grey .dropdown-toggle_value input::-moz-placeholder {
  color: #939ba0;
}
.combobox--pale-grey .dropdown-toggle_value input:-moz-placeholder {
  color: #939ba0;
}
.combobox--pale-grey .dropdown-toggle_value input:-ms-input-placeholder {
  color: #939ba0;
}
.combobox--pale-grey .dropdown-toggle_value input::-ms-input-placeholder {
  color: #939ba0;
}
.combobox--white .dropdown-toggle_value input {
  color: #545b62;
  background-color: #ffffff;
}
.combobox--white .dropdown-toggle_value input::placeholder {
  color: #939ba0;
}
.combobox--white .dropdown-toggle_value input::-webkit-input-placeholder {
  color: #939ba0;
}
.combobox--white .dropdown-toggle_value input::-moz-placeholder {
  color: #939ba0;
}
.combobox--white .dropdown-toggle_value input:-moz-placeholder {
  color: #939ba0;
}
.combobox--white .dropdown-toggle_value input:-ms-input-placeholder {
  color: #939ba0;
}
.combobox--white .dropdown-toggle_value input::-ms-input-placeholder {
  color: #939ba0;
}
