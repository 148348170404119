.card--entity-summary .card_children {
  padding: 0;
  height: 140px;
}
.card--entity-summary .comment-body {
  padding: 20px;
  color: #ffffff;
}
.card--entity-summary .comment-body .placeholder {
  color: #545b62;
}
