.peer-config {
  padding-top: 40px;
  color: #545b62;
}
.peer-config_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.peer-config_title {
  font-size: 20px;
  font-weight: 400;
}
.peer-config_subtitle {
  margin-top: 4px;
  color: #939ba0;
}
.peer-config_subtitle-error {
  display: flex;
  color: #ec6a4c;
}
.peer-config_action {
  cursor: pointer;
}
.peer-config_action:hover {
  color: rgba(0, 0, 0, 0.8);
}
.peer-config_action + .peer-config_action {
  margin-left: 16px;
}
.peer-config_search {
  margin-top: 16px;
}
.peer-config_body {
  margin-top: 8px;
}
.peer-config_item-symbol, .peer-config_item-exchange {
  margin-right: 4px;
}
.peer-config_item-exchange, .peer-config_item-seperator, .peer-config_item-name {
  color: #939ba0;
}
.peer-config_item-name {
  margin-left: 4px;
}
.peer-config-modal .modal_header, .peer-config-modal .modal_footer {
  padding: 32px;
}
.peer-config-modal .modal_content {
  background-color: #eeeeee;
}
.peer-config-modal .modal_content .modify-peers_header {
  border-bottom: 1px solid #e0e0e0;
}
.peer-config-modal .modal_content .peer-config--detailed {
  padding: 32px;
}
