.placeholder-message {
  height: 100%;
  padding: 24px;
  animation: fade-in 0.3s ease;
}
.placeholder-message, .placeholder-message_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.placeholder-message_inner, .placeholder-message_icon {
  transition-property: background-color, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.placeholder-message_inner {
  flex-direction: column;
  width: 400px;
  padding: 80px 40px;
  border-radius: 8px;
  background-color: #eeeeee;
  text-align: center;
  color: #545b62;
}
.placeholder-message_image, .placeholder-message_icon {
  display: inline-block;
}
.placeholder-message_image {
  width: 190px;
}
.placeholder-message_icon {
  margin-bottom: 40px;
  font-size: 80px;
  color: #297ac5;
}
.placeholder-message_title {
  font-size: 20px;
  line-height: 1.5;
}
.placeholder-message_message {
  line-height: 22px;
}
.placeholder-message_actions {
  margin-top: 16px;
}
.placeholder-message--light .placeholder-message_inner {
  background-color: #ffffff;
  color: #545b62;
}
.placeholder-message--light .placeholder-message_icon {
  color: #297ac5;
}
.placeholder-message--dark .placeholder-message_inner {
  background-color: #22272b;
  color: #ffffff;
}
.placeholder-message--dark .placeholder-message_icon {
  color: #f1af0f;
}
.placeholder-message--transparent .placeholder-message_inner {
  background-color: transparent;
  color: #545b62;
}
.placeholder-message--transparent .placeholder-message_icon {
  color: #297ac5;
}
