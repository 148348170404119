/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.text-area--error .text-area_field::placeholder {
  color: #ec6a4c;
}
.text-area--error .text-area_field::-webkit-input-placeholder {
  color: #ec6a4c;
}
.text-area--error .text-area_field::-moz-placeholder {
  color: #ec6a4c;
}
.text-area--error .text-area_field:-moz-placeholder {
  color: #ec6a4c;
}
.text-area--error .text-area_field:-ms-input-placeholder {
  color: #ec6a4c;
}
.text-area--error .text-area_field::-ms-input-placeholder {
  color: #ec6a4c;
}
.text-area_field {
  padding: 10px 10px;
  width: 100%;
  background-color: #f1f1f1;
  resize: none;
}
.text-area_field::placeholder {
  color: #939ba0;
}
.text-area_field::-webkit-input-placeholder {
  color: #939ba0;
}
.text-area_field::-moz-placeholder {
  color: #939ba0;
}
.text-area_field:-moz-placeholder {
  color: #939ba0;
}
.text-area_field:-ms-input-placeholder {
  color: #939ba0;
}
.text-area_field::-ms-input-placeholder {
  color: #939ba0;
}
