@import '../../../../../../resources/styles/variables';

.holder-peer-analysis-grid_table {

  .ag-header {
    border-bottom: 1px solid $light-grey !important;
    .ag-header-cell-text {
      display: flex;
      flex: 1;
      justify-content: center;
    }
    .ag-header-cell--first {
      .ag-header-cell-text {
        justify-content: flex-start;
      }
    }
  }

  .ag-theme-bootstrap.ag-grid-root .ag-pinned-left-header .ag-header-cell {
    &:last-child {
      background-color: $citrus;
      color: $slate;
    }
  }
}
