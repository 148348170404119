@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';

.deal-card {

  &_progress {
    position: relative;
    margin-top: gux();
    width: 100%;
    height: 16px;
    background-color: $gunpowder;
    border-radius: $border-radius-large;

    &-stage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      min-width: 20%;
      max-width: 100%;
      height: 16px;
      background-color: $teal;
      border-radius: $border-radius-large;
    }
  }

  &_pipeline > .select-component {
    padding-top: gux(2);
  }

  .select-component {
    width: 100%;
  }
}
