/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.page-editor {
  position: relative;
  width: 100%;
  height: 100%;
}
.page-editor_expansion-toggle i {
  display: inline-block;
  transition: transform 0.3s ease;
}
.page-editor_body {
  height: calc(100% - 70px);
}
.page-editor_paper-wrap {
  position: relative;
  height: 100%;
  padding: 30px 30px;
  overflow: hidden;
}
.page-editor_paper-wrap:hover .react-grid-item:before {
  opacity: 1;
}
.page-editor_paper-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1280px;
  height: 720px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}
.page-editor .react-grid {
  position: relative;
}
.page-editor .react-grid-item, .page-editor .react-grid-placeholder {
  transition-duration: 0.1s;
}
.page-editor .react-grid-item {
  position: absolute;
  transition-property: width, height, transform;
  z-index: 6;
}
.page-editor .react-grid-item:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  content: "";
  opacity: 0;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  z-index: 6;
  pointer-events: none;
  transition: 0.3s ease;
}
.page-editor .react-grid-item:not(.react-grid-item--selected):hover:before {
  border: 1px solid #e0e0e0;
}
.page-editor .react-grid-item:hover .react-resizable-handle, .page-editor .react-grid-item--selected .react-resizable-handle {
  opacity: 1;
}
.page-editor .react-grid-item--selected:before {
  opacity: 1;
  border: 2px solid #297ac5;
}
.page-editor .react-grid-placeholder {
  opacity: 0.5;
  border-radius: 3px;
  background-color: #eeeeee;
  transition-property: transform;
  z-index: 5;
}
.page-editor .react-grid-item--selected,
.page-editor .react-resizable.resizing {
  z-index: 7;
}
.page-editor .react-draggable-dragging {
  z-index: 8;
}
.page-editor .react-draggable-dragging,
.page-editor .react-resizable.resizing {
  transition: none;
}
.page-editor .react-draggable:hover {
  cursor: move;
  cursor: -webkit-grab;
}
.page-editor .react-resizable-handle {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  padding: 6px;
  border-radius: 3px;
  background-color: #297ac5;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  color: #ffffff;
  cursor: nwse-resize;
  transition-property: background-color, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  z-index: 6;
}
.page-editor .react-resizable-handle:before {
  font-family: "iris";
  content: "\e91c";
}
.page-editor .react-resizable-handle:hover {
  background-color: #256eb1;
}
