@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/generic';
@import '../../../../resources/styles/utils';

.comment_edit-modal {

  .subtext {
    padding-bottom: gux(3);
    color: $light-slate;
  }

  .entity {
    padding-bottom: gux(2);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_icon {
      padding-right: gux();
      font-size: $font-size-icon;
      color: $rain;

      &--fund {
        color: $teal;
      }

      &--institution {
        color: $spice;
      }
    }

    &_content {
      flex: 1;
      color: $light-slate;
      @include truncate();

      &-name,
      &-detail {
        max-width: 98%;
        @include truncate();
      }

      &-detail {
        color: $silver;
      }
    }
  }
}
