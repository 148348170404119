/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.targeting-page_tabs_tab--saved {
  position: relative;
}
.targeting-page_tabs_tab--saved .targeting-page-tab_body {
  flex: 1;
  position: relative;
  min-height: calc(100vh - 80px - 133px);
  background-color: #ffffff;
  overflow: hidden;
}
.targeting-page_tabs_tab--saved .targeting-page-tab_body .spinner, .targeting-page_tabs_tab--saved .targeting-page-tab_body .spinner_mask {
  z-index: 10;
}
