.combo-box_list .removable-list {
  padding-top: 8px;
  transition: padding 0.3s ease;
}
.combo-box_list .removable-list-item_label {
  padding-left: 10px;
}
.combo-box_list .removable-list:empty {
  padding-top: 0;
}
.combo-box .field-error_icon {
  min-width: 14px;
  min-height: 14px;
}
.combo-box .field-error_message {
  white-space: pre-wrap;
}
