.report-series-config_details {
  animation: fade-in 0.3s ease;
}
.report-series-config_swatch {
  display: block;
  margin: 10px 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}
.report-series-config_colorpicker .select__control {
  min-width: 70px;
}
.report-series-config_colorpicker .select__option {
  padding: 0 12px;
}
