.report-text-edit .editor {
  height: 300px;
}
.report-text-edit .editor_toolbar {
  align-items: center;
}
.report-text-edit .editor .rdw-list-wrapper,
.report-text-edit .editor .rdw-link-wrapper,
.report-text-edit .editor .rdw-inline-wrapper,
.report-text-edit .editor .rdw-text-align-wrapper {
  margin: 0 10px;
}
.report-text-edit .editor .rdw-option-wrapper {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 0 4px;
}
.report-text-edit .editor .rdw-option-wrapper img {
  display: none;
}
.report-text-edit .editor .rdw-option-active {
  color: black;
}
.report-text-edit .editor-button--bold {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--bold:before {
  font-family: "iris";
  content: "\e963";
}
.report-text-edit .editor-button--italic {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--italic:before {
  font-family: "iris";
  content: "\e976";
}
.report-text-edit .editor-button--underline {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--underline:before {
  font-family: "iris";
  content: "\e973";
}
.report-text-edit .editor-button--bullet {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--bullet:before {
  font-family: "iris";
  content: "\e96a";
}
.report-text-edit .editor-button--numbered {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--numbered:before {
  font-family: "iris";
  content: "\e971";
}
.report-text-edit .editor-button--left {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--left:before {
  font-family: "iris";
  content: "\e95e";
}
.report-text-edit .editor-button--center {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--center:before {
  font-family: "iris";
  content: "\e95d";
}
.report-text-edit .editor-button--right {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--right:before {
  font-family: "iris";
  content: "\e962";
}
.report-text-edit .editor-button--link {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor-button--link:before {
  font-family: "iris";
  content: "\e968";
}
.report-text-edit .editor-button.end {
  margin-right: 14px;
}
.report-text-edit .editor .rdw-link-wrapper .rdw-option-wrapper {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-text-edit .editor .rdw-link-wrapper .rdw-option-wrapper:before {
  font-family: "iris";
  content: "\e968";
}
.report-text-edit .dropdown {
  margin-right: 4px;
}
.report-text-edit .dropdown_inner {
  width: 100%;
}
