/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.dashboard {
  background-color: #2a3035;
}
.dashboard, .dashboard_widget {
  transition-property: background-color, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.dashboard_contain {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 20px;
}
.dashboard_contain:before, .dashboard_contain:after {
  content: " ";
  display: table;
}
.dashboard_contain:after {
  clear: both;
}
.dashboard_inner {
  position: relative;
  min-height: calc(100vh - 80px - 150px);
  padding: 0 20px 20px;
}
.dashboard_inner--empty {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.dashboard_header {
  height: 50px;
  padding: 10px 0;
  line-height: 30px;
}
.dashboard_header .range-tab_button:first-child {
  margin-left: 0;
  border-radius: 3px 0 0 3px;
}
.dashboard_header .range-tab_button:last-child {
  border-radius: 0 3px 3px 0;
}
.dashboard_header .range-tab_button--empty {
  padding: 0 2px;
}
.dashboard_header .range-tab_icon {
  font-size: 34px;
  line-height: 30px;
}
.dashboard_edit {
  height: 80px;
  padding: 20px 0;
  background-color: #1f2327;
  line-height: 40px;
}
.dashboard_widget {
  background-color: #121517;
}
.dashboard_widget--edited .widget-config {
  background-color: transparent;
}
.dashboard_widget--edited .widget-config:hover {
  box-shadow: none;
}
.dashboard_widget--edited .widget-config_details {
  display: none;
}
.dashboard_widget--empty {
  opacity: 0.3;
}
.dashboard_actions {
  position: relative;
}
.dashboard_actions--left {
  float: left;
}
.dashboard_actions--left > * {
  vertical-align: top;
}
.dashboard_actions--right {
  float: right;
}
.dashboard_actions--right .dashboard_action > i {
  font-size: 18px;
  line-height: 30px;
}
.dashboard_action {
  display: inline-block;
  height: 30px;
  margin-right: 20px;
  color: #f1af0f;
  vertical-align: top;
}
.dashboard_action i {
  cursor: pointer;
}
.dashboard_action:hover {
  color: #dc9e27;
}
.dashboard_action:last-child {
  margin-right: 0;
}
.dashboard_divider {
  display: inline-block;
  width: 1px;
  height: 40px;
  margin: 0 20px;
  background-color: #2a3035;
}
.dashboard_title-edit {
  display: inline-block;
  width: 300px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s ease;
}
.dashboard_title-edit .field_input {
  width: 300px;
}
.dashboard_title-edit--hidden {
  width: 0;
  margin-right: 0;
}
.dashboard_unavailable {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  color: #f1af0f;
}
.dashboard_unavailable i {
  font-size: 30px;
}
.dashboard_add {
  margin-bottom: 20px;
  width: 40px;
}
.dashboard_add .tooltip {
  transition: 0.3s ease;
}
.dashboard_add--disabled .tooltip {
  opacity: 0;
}
.dashboard_add--disabled:hover .tooltip {
  opacity: 1;
}
.dashboard_add .button--disabled {
  color: #545b62;
}
.dashboard_add .button--square .tooltip {
  display: block;
}
.dashboard_footer p {
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 15px;
}
.dashboard_footer i {
  position: relative;
  margin-left: 3px;
  font-size: 16px;
}
.dashboard_footer i, .dashboard_footer i:before {
  line-height: 18px;
}
.dashboard_footer i:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 90%;
  height: 5%;
  margin-top: 2px;
  background-color: #f1af0f;
}
.dashboard .react-swipeable-view-container:not([style*="0%"]) .react-grid-item {
  pointer-events: none;
}
.dashboard .react-grid {
  position: relative;
}
.dashboard .react-grid-item {
  border-radius: 3px;
  overflow: hidden;
  z-index: 6;
}
.dashboard .react-grid-item:hover .react-resizable-handle {
  opacity: 1;
  visibility: visible;
}
.dashboard .react-grid-item.resizing {
  z-index: 7;
}
.dashboard .react-grid-item.resizing .react-resizable-handle {
  background-color: #f1af0f;
  color: #22272b;
}
.dashboard .react-grid-placeholder {
  background-color: rgba(18, 21, 23, 0.6);
  z-index: 5;
}
.dashboard .react-draggable-dragging {
  z-index: 7;
}
.dashboard .react-resizable-handle {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  padding: 6px;
  background-color: #22272b;
  border-radius: 3px;
  font-size: 13px;
  color: #f1af0f;
  z-index: 9;
  cursor: nwse-resize;
  visibility: hidden;
  transition: 0.3s ease;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dashboard .react-resizable-handle:before {
  font-family: "iris";
  content: "\e91c";
}
.dashboard .react-resizable-handle:hover {
  background-color: #1a1d21;
}
.dashboard--locked {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}
.dashboard--edit .dashboard_header {
  height: 80px;
  background-color: #1f2327;
  line-height: 80px;
}
.dashboard--edit .dashboard_inner {
  min-height: calc(100vh - 80px - 150px);
  padding: 20px;
}
.dashboard--edit .dashboard_action {
  height: 40px;
  margin-right: 5px;
}
.dashboard--edit .dashboard_action .button {
  vertical-align: top;
}
.dashboard--edit .dashboard_action:last-child {
  margin-right: 0;
}
.dashboard--edit .dashboard_widget:hover {
  box-shadow: 0 0 15px 5px rgba(18, 21, 23, 0.75);
}
.dashboard--edit .dashboard_widget > *:not(.widget-config):not(.react-resizable-handle) {
  opacity: 0.3;
}
.dashboard--edit .react-grid-item {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.dashboard--edit .react-grid-item:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.dashboard--edit.dashboard--light .dashboard_widget:hover {
  box-shadow: 0 0 15px 5px rgba(18, 21, 23, 0.125);
}
.dashboard--edit.dashboard--light .dashboard_widget > *:not(.widget-config):not(.react-resizable-handle) {
  opacity: 0.6;
}
.dashboard--light {
  background: #eeeeee;
}
.dashboard--light .dashboard_widget, .dashboard--light .dashboard_edit {
  background-color: #ffffff;
}
.dashboard--light .dashboard_action {
  color: #297ac5;
}
.dashboard--light .dashboard_action:hover {
  color: #0f5ca3;
}
.dashboard--light .dashboard_theme-label {
  color: #545b62;
}
.dashboard--light .dashboard_theme-label--active {
  color: #297ac5;
}
.dashboard--light .dashboard_divider {
  background-color: #e0e0e0;
}
.dashboard--light .dashboard_unavailable {
  color: #297ac5;
}
.dashboard--light .dashboard_add .button--disabled {
  background-color: #e0e0e0;
  color: #939ba0;
}
.dashboard--light .react-grid-item.resizing .react-resizable-handle {
  background-color: #297ac5;
  color: #ffffff;
}
.dashboard--light .react-grid-placeholder {
  background-color: rgba(255, 255, 255, 0.6);
}
.dashboard--light .react-resizable-handle {
  background-color: #eeeeee;
  color: #297ac5;
}
.dashboard--light .react-resizable-handle:hover {
  background-color: #e0e0e0;
}
.dashboard--light .button--cancel:hover {
  color: #297ac5;
}
