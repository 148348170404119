.nui-swapable {
  transition: height 0.3s ease;
}
.nui-swapable--transition {
  position: relative;
  overflow: hidden;
}
.nui-swapable--transition > .nui-swapable_layer {
  position: absolute;
  width: 100%;
}
