@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/utils';

.entity-ownership {

  &_view {
    position: relative;
    height: 308px;
  }

  &--headless {
    height: 316px;
    box-shadow: none;

    .card_children {
      padding: 0 gux() 0 0;
    }
  }
}
