@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';

.field-error {
  padding-top: gux(0.5);
  display: flex;
  align-items: center;

  &_icon {
    width: 14px;
    height: 14px;
    text-align: center;
    color: $white;
    background-color: $spice;
    border-radius: $border-radius-circle;

    &:before {
      font-size: $font-size-small;
      line-height: 15px;
    }
  }

  &_message {
    padding-left: gux(0.75);
    font-size: $font-size-small;
    color: $spice;
  }
}
