.holding-grid {
  position: relative;
}
.holding-grid_toolbar-sectors {
  width: 195px;
}
.holding-grid_toolbar-marketcaps .select__control {
  min-width: 125px;
  width: 125px;
}
.holding-grid_toolbar-regions .select__control {
  min-width: 135px;
  width: 135px;
}
.holding-grid_toolbar-search {
  flex: 1;
  width: auto;
  min-width: 170px;
  max-width: 200px;
}
