.upcoming-activities-widget {
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.upcoming-activities-widget_header, .upcoming-activities-widget_calendar, .upcoming-activities-widget_footer, .upcoming-activities-widget_footer a {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.upcoming-activities-widget_header {
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
}
.upcoming-activities-widget_heading, .upcoming-activities-widget_footer {
  font-size: 13px;
  text-transform: uppercase;
}
.upcoming-activities-widget_inner {
  height: calc(100% - 25px);
  overflow: hidden;
}
.upcoming-activities-widget_calendar {
  border-right: 1px solid #2a3035;
}
.upcoming-activities-widget_agenda--narrow {
  height: calc(100% - 260px);
}
.upcoming-activities-widget_agenda .upcoming-activities_items {
  height: calc(100% - 35px);
}
.upcoming-activities-widget_footer {
  padding: 0 10px;
  line-height: 25px;
  height: 25px;
  border-top: 1px solid #2a3035;
  text-align: right;
}
.upcoming-activities-widget_footer a {
  color: #f1af0f;
}
.upcoming-activities-widget_footer a:hover {
  color: #dc9e27;
}
.upcoming-activities-widget--short .upcoming-activities-widget_agenda {
  display: none;
}
.upcoming-activities-widget:not(.upcoming-activities-widget--short) .upcoming-activities-widget_inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.upcoming-activities-widget:not(.upcoming-activities-widget--break) .spinner_pulse {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(75% - 25px);
}
.upcoming-activities-widget:not(.upcoming-activities-widget--break) .upcoming-activities-widget_calendar, .upcoming-activities-widget:not(.upcoming-activities-widget--break) .upcoming-activities-widget_agenda {
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}
.upcoming-activities-widget--break .spinner_pulse {
  position: absolute;
  top: calc(75% - 25px);
  left: calc(50% - 25px);
}
.upcoming-activities-widget--break .upcoming-activities-widget_inner {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.upcoming-activities-widget--break .upcoming-activities-widget_calendar {
  padding-bottom: 10px;
  border-right: none;
  border-bottom: 1px solid #2a3035;
}
.upcoming-activities-widget--break .upcoming-activities-widget_agenda {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.upcoming-activities-widget--light .upcoming-activities-widget_calendar {
  border-right: 1px solid #e0e0e0;
}
.upcoming-activities-widget--light .upcoming-activities-widget_header {
  border-bottom: 1px solid #e0e0e0;
  color: #545b62;
}
.upcoming-activities-widget--light .upcoming-activities-widget_footer {
  border-top: 1px solid #e0e0e0;
}
.upcoming-activities-widget--light .upcoming-activities-widget_footer a {
  color: #297ac5;
}
.upcoming-activities-widget--light .upcoming-activities-widget_footer a:hover {
  color: #0f5ca3;
}
.upcoming-activities-widget--light.upcoming-activities-widget--break .upcoming-activities-widget_calendar {
  border-bottom: 1px solid #e0e0e0;
  border-right: 0;
}
.upcoming-activities-widget .react-calendar {
  width: 100%;
  background: transparent;
  border: none;
  font-family: "Open Sans", sans-serif;
}
.upcoming-activities-widget .react-calendar, .upcoming-activities-widget .react-calendar__tile, .upcoming-activities-widget .react-calendar__navigation, .upcoming-activities-widget .react-calendar__navigation__label, .upcoming-activities-widget .react-calendar__navigation__arrow:before {
  transition: 0.3s ease;
}
.upcoming-activities-widget .react-calendar__navigation {
  height: 35px;
  line-height: 35px;
  margin: 0;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
}
.upcoming-activities-widget .react-calendar__navigation button:hover, .upcoming-activities-widget .react-calendar__navigation button:enabled:hover, .upcoming-activities-widget .react-calendar__navigation button:enabled:focus {
  background: transparent !important;
}
.upcoming-activities-widget .react-calendar__navigation__label {
  padding: 0;
  text-align: left;
  color: #ffffff;
  pointer-events: none;
}
.upcoming-activities-widget .react-calendar__navigation__prev-button, .upcoming-activities-widget .react-calendar__navigation__next-button {
  min-width: 24px;
  padding: 0;
  font-size: 11px;
  color: #f1af0f;
}
.upcoming-activities-widget .react-calendar__navigation__prev-button:before, .upcoming-activities-widget .react-calendar__navigation__next-button:before {
  padding: 6px;
  border-radius: 3px;
}
.upcoming-activities-widget .react-calendar__navigation__prev-button:hover:before, .upcoming-activities-widget .react-calendar__navigation__next-button:hover:before {
  background-color: #2a3035;
}
.upcoming-activities-widget .react-calendar__navigation__prev-button {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.upcoming-activities-widget .react-calendar__navigation__prev-button:before {
  font-family: "iris";
  content: "\e997";
}
.upcoming-activities-widget .react-calendar__navigation__next-button {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -moz-order: 3;
  -ms-flex-order: 3;
  order: 3;
}
.upcoming-activities-widget .react-calendar__navigation__next-button:before {
  font-family: "iris";
  content: "\e9a1";
}
.upcoming-activities-widget .react-calendar__navigation__prev2-button, .upcoming-activities-widget .react-calendar__navigation__next2-button {
  display: none;
}
.upcoming-activities-widget .react-calendar__month-view {
  padding: 5px 10px 0;
}
.upcoming-activities-widget .react-calendar__tile {
  height: 30px;
  padding: 0;
  color: #ffffff;
  text-align: center;
}
.upcoming-activities-widget .react-calendar__tile--now {
  background: #f1af0f !important;
  color: #22272b !important;
}
.upcoming-activities-widget .react-calendar__tile--now div {
  color: #22272b !important;
}
.upcoming-activities-widget .react-calendar__tile--active:not(.react-calendar__tile--now), .upcoming-activities-widget .react-calendar__tile:hover, .upcoming-activities-widget .react-calendar__tile:enabled:hover, .upcoming-activities-widget .react-calendar__tile:enabled:focus, .upcoming-activities-widget .react-calendar__tile:active, .upcoming-activities-widget .react-calendar__tile:focus {
  background: #2a3035 !important;
  color: #ffffff !important;
}
.upcoming-activities-widget .react-calendar__tile div {
  font-size: 26px;
  line-height: 3px;
  color: #939ba0;
}
.upcoming-activities-widget .react-calendar__month-view__weekdays__weekday {
  height: 24px;
  line-height: 24px;
  padding: 0;
  font-weight: 400;
  font-size: 11px;
}
.upcoming-activities-widget .react-calendar__month-view__days__day--neighboringMonth:not(.react-calendar__tile--now) {
  color: #939ba0 !important;
}
.upcoming-activities-widget .react-calendar--light {
  color: #545b62;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__navigation {
  border-bottom: 1px solid #e0e0e0;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__navigation__label {
  color: #545b62;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__navigation__prev-button, .upcoming-activities-widget .react-calendar--light .react-calendar__navigation__next-button {
  color: #297ac5;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__navigation__prev-button:hover:before, .upcoming-activities-widget .react-calendar--light .react-calendar__navigation__next-button:hover:before {
  background-color: #f1f1f1;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__tile {
  color: #545b62;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__tile--now {
  background: #297ac5 !important;
  color: #ffffff !important;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__tile--active div {
  color: #ffffff !important;
}
.upcoming-activities-widget .react-calendar--light .react-calendar__tile--active:not(.react-calendar__tile--now), .upcoming-activities-widget .react-calendar--light .react-calendar__tile:hover, .upcoming-activities-widget .react-calendar--light .react-calendar__tile:enabled:hover, .upcoming-activities-widget .react-calendar--light .react-calendar__tile:enabled:focus, .upcoming-activities-widget .react-calendar--light .react-calendar__tile:active, .upcoming-activities-widget .react-calendar--light .react-calendar__tile:focus {
  background: #f1f1f1 !important;
  color: #545b62 !important;
}
