.progress-dots {
  padding: 30px 0;
  text-align: center;
  line-height: 8px;
}
.progress-dots_wrap {
  display: inline-block;
  padding: 4px;
  border-radius: 8px;
  background-color: #eeeeee;
}
.progress-dots_inner {
  position: relative;
}
.progress-dots_node, .progress-dots_space, .progress-dots_bar {
  height: 8px;
}
.progress-dots_node, .progress-dots_space {
  display: inline-block;
  width: 8px;
  vertical-align: top;
}
.progress-dots_node {
  border-radius: 50%;
  background-color: #939ba0;
}
.progress-dots_bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 8px;
  max-width: 100%;
  background-color: #297ac5;
  border-radius: 8px;
  transition: width 0.3s ease;
}
.progress-dots--interactive .progress-dots_node {
  cursor: pointer;
}
.progress-dots--teal .progress-dots_wrap {
  background-color: #eeeeee;
}
.progress-dots--teal .progress-dots_node {
  background-color: #939ba0;
}
.progress-dots--teal .progress-dots_bar {
  background-color: #1abc9c;
}
