/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.address-book-page_sidebar-content {
  padding: 0 0 24px;
}
.address-book-page_sidebar-content--masked:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  cursor: default;
}
.address-book-page_sidebar .list-header {
  font-size: 20px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.address-book-page_sidebar .lists_item {
  cursor: pointer;
  position: relative;
  height: 40px;
  line-height: 40px;
}
.address-book-page_sidebar .lists_item-detail {
  padding: 0 16px;
  max-width: 100%;
  display: flex;
  align-items: center;
}
.address-book-page_sidebar .lists_item-actions {
  display: none;
  position: absolute;
  top: 0;
  right: 16px;
  width: 54px;
  text-align: right;
  background: #eeeeee;
  background: linear-gradient(270deg, #eeeeee 0%, #eeeeee 54%, rgba(238, 238, 238, 0.5) 100%);
}
.address-book-page_sidebar .lists_item--selected {
  background-color: #e0e0e0;
}
.address-book-page_sidebar .lists_item--selected .lists_item-actions {
  background: #e0e0e0;
  background: linear-gradient(270deg, #e0e0e0 0%, #e0e0e0 54%, rgba(224, 224, 224, 0.5) 100%);
}
.address-book-page_sidebar .lists_item:hover .lists_item-actions {
  display: block;
}
.address-book-page_sidebar .lists_label {
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address-book-page_sidebar .lists_count {
  padding-left: 8px;
  color: #939ba0;
}
.address-book-page_sidebar .lists_icon {
  padding-left: 8px;
  color: #545b62;
}
.address-book-page_sidebar .lists_icon:hover {
  color: #22272b;
}
.address-book-page_sidebar .field_label--standalone {
  margin-bottom: 20px;
}
.address-book-page_sidebar .field .select__control .select__value-container {
  color: #939ba0;
}
.address-book-page_sidebar .section-tabs button {
  width: 50%;
  border-right-width: 0px;
}
.address-book-page_sidebar .section-tabs button .label {
  text-align: center;
  width: 100%;
}
