.page-manager {
  position: relative;
  width: 250px;
  height: 100%;
  background-color: #eeeeee;
  transition: width 0.3s ease;
}
.page-manager_header {
  position: relative;
  width: calc(100% - 40px);
  height: 70px;
  margin: 0 20px;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  transition: height 0.3s ease;
}
.page-manager_add-button, .page-manager_collapse-toggle {
  position: absolute;
  top: 20px;
  transition: 0.3s ease;
}
.page-manager_add-button {
  top: 20px;
  left: 0;
}
.page-manager_add-button .button {
  width: 95px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-manager_collapse-toggle {
  right: 0;
}
.page-manager_collapse-toggle i {
  display: inline-block;
  transition: transform 0.3s ease;
}
.page-manager_body {
  height: calc(100% - 70px);
  transition: height 0.3s ease;
}
.page-manager_list {
  padding: 14px 20px 20px;
}
.page-manager + .page-editor {
  width: calc(100% - 250px);
  transition: width 0.3s ease;
}
.page-manager--portrait {
  width: 210px;
}
.page-manager--portrait + .page-editor {
  width: calc(100% - 210px);
}
.page-manager--collapsed {
  width: 70px;
}
.page-manager--collapsed .page-manager_header {
  height: 110px;
}
.page-manager--collapsed .page-manager_add-button {
  top: 60px;
}
.page-manager--collapsed .page-manager_add-button .button {
  width: 30px;
}
.page-manager--collapsed .page-manager_body {
  height: calc(100% - 110px);
}
.page-manager--collapsed + .page-editor {
  width: calc(100% - 70px);
}
