.no-subscription-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 360px);
  background-color: #2a3035;
  animation: fade-in 0.3s ease;
}
.no-subscription-message_details {
  padding-left: 50px;
}
.no-subscription-message_title {
  margin-bottom: 40px;
  font-size: 26px;
  font-weight: 300;
  color: #ffffff;
}
.no-subscription-message_snapshot {
  position: relative;
  width: 550px;
  height: 356px;
}
.no-subscription-message_macbook, .no-subscription-message_image {
  position: absolute;
}
.no-subscription-message_macbook {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../resources/images/macbook.png") no-repeat;
  background-size: cover;
  z-index: 6;
}
.no-subscription-message_image {
  top: 8%;
  right: 0;
  left: 21%;
  bottom: 16%;
  background-size: cover;
  z-index: 5;
}
