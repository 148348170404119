/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.ownership-filter_item {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ownership-filter_item-exchange, .ownership-filter_item-name {
  color: #939ba0;
}
.ownership-filter_item-symbol {
  margin-right: 4px;
}
.ownership-filter_item-separator {
  margin: 0 4px;
}
