.widget-config {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(84, 91, 98, 0.6);
  border-radius: 3px;
  transition: 0.3s ease;
  z-index: 8;
}
.widget-config_actions, .widget-config_details {
  position: absolute;
  top: 10px;
}
.widget-config_actions {
  right: 10px;
  border-radius: 3px;
  background-color: #f1af0f;
  cursor: default;
}
.widget-config_action {
  display: inline-block;
  margin: 0 4px;
  padding: 10px 3px;
  font-size: 11px;
  color: #22272b;
  cursor: pointer;
}
.widget-config_action:first-child {
  margin-left: 0;
  padding-left: 15px;
}
.widget-config_action:last-child {
  margin-right: 0;
  padding-right: 15px;
}
.widget-config_action:first-child:last-child {
  padding: 10px;
}
.widget-config_action:hover {
  opacity: 0.3;
}
.widget-config_details {
  left: 10px;
  width: 20px;
  height: 20px;
  opacity: 0;
  border-radius: 50%;
  background-color: #22272b;
  font-size: 11px;
  line-height: 20px;
  text-align: center;
  color: #f1af0f;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.widget-config_details i {
  margin-left: 1px;
}
.widget-config:hover .widget-config_details {
  opacity: 1;
}
.widget-config--light {
  background-color: rgba(255, 255, 255, 0.6);
}
.widget-config--light .widget-config_actions {
  background-color: #297ac5;
}
.widget-config--light .widget-config_action {
  color: #ffffff;
}
.widget-config--light .widget-config_details {
  background-color: #939ba0;
  color: #ffffff;
}
