.nlg_performance {
  margin-top: 10px;
}
.nlg-item {
  width: 100%;
}
.nlg-item:nth-child(2) {
  padding-left: 14px;
}
.nlg-item_title {
  color: #ffffff;
  font-size: 13px;
  margin: 5px 0;
  padding: 0;
  text-transform: uppercase;
}
.nlg-item_wrapper {
  padding: 4px 10px 10px;
  background-color: #1f2327;
  border-radius: 4px;
}
.nlg-item_change {
  font-size: 13px;
  min-height: 32px;
  padding: 7px 0;
}
.nlg-item--price .nlg-item_change {
  font-size: 25px;
}
.nlg-item--price .nlg-item_change::after {
  content: "% change";
  font-size: 13px;
  margin-left: 10px;
  color: #939ba0;
}
.nlg--light .nlg-item--price .nlg-item_change::after {
  color: #545b62;
}
.nlg--light .nlg-item_title {
  color: #2a3035;
}
.nlg--light .nlg-item_wrapper {
  background-color: #eeeeee;
}
