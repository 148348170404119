.clock-widget {
  height: 100%;
}
.clock-widget .react-clock {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -110px;
  margin-top: -110px;
}
.clock-widget .react-clock__face {
  border: 1px solid transparent;
}
.clock-widget .react-clock__mark__body {
  background-color: #939ba0;
}
.clock-widget .react-clock__hand__body {
  background-color: #f1af0f;
}
.clock-widget .react-clock__second-hand__body {
  background-color: #ffffff;
}
.clock-widget--light .react-clock__hand__body {
  background-color: #297ac5;
}
.clock-widget--light .react-clock__second-hand__body {
  background-color: #2a3035;
}
