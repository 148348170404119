.peer-list-grid_table .peer-list-grid .cell {
  height: 70px;
}
.peer-list-grid_table .peer-list-grid .cell_content.stacked {
  text-align: right;
  line-height: 18px;
}
.peer-list-grid_table .peer-list-grid .cell_content.no-data {
  text-align: center;
}
.peer-list-grid_table .peer-list-grid .cell_content-indicator {
  color: #fff;
  width: 12px;
  height: 12px;
  font-size: 8px;
  margin-right: 3px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
}
.peer-list-grid_table .peer-list-grid .cell_content-indicator.change-up {
  background-color: #1abc9c;
  line-height: 12px;
}
.peer-list-grid_table .peer-list-grid .cell_content-indicator.change-down {
  line-height: 13px;
  background-color: #ec6a4c;
}
