/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.report-config {
  position: relative;
  color: #545b62;
  overflow: hidden;
  transition: width 0.3s ease;
}
.report-config, .report-config_inner {
  width: 340px;
  height: 100%;
}
.report-config:before, .report-config_collapse-toggle {
  position: absolute;
  top: 0;
  background-color: #297ac5;
}
.report-config:before {
  content: "";
  bottom: 0;
  left: 0;
  width: 0;
  transition: width 0.3s ease;
  z-index: 6;
}
.report-config_collapse-toggle {
  right: 0;
  width: 40px;
  height: 50px;
  background-color: #297ac5;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  z-index: 7;
}
.report-config_collapse-toggle:hover {
  background-color: #1f6bb0;
}
.report-config_collapse-toggle i {
  display: inline-block;
  transition: transform 0.3s ease;
}
.report-config_header {
  background-color: #297ac5;
}
.report-config_title {
  font-size: 16px;
}
.report-config_body {
  position: relative;
  height: calc(100% - 50px);
  background-color: #eeeeee;
}
.report-config_pane-tabs {
  display: flex;
  width: calc(100% - 40px);
}
.report-config_pane-tab {
  flex-grow: 1;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-right: 1px solid #1f6bb0;
  background-color: #297ac5;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: background-color, ease, color, border-color;
  cursor: pointer;
}
.report-config_pane-tab:hover:not(.report-config_pane-tab--selected) {
  background-color: #1f6bb0;
}
.report-config_pane-tab--selected {
  background-color: #eeeeee;
  color: #545b62;
}
.report-config_pane-tab--selected:last-child {
  border-color: #eeeeee;
}
.report-config_pane-tab--disabled:not(.report-config_pane-tab--selected) {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.3);
}
.report-config_pane-item {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
}
.report-config_pane-item--active {
  opacity: 1;
  height: auto;
  overflow: initial;
  pointer-events: initial;
  z-index: 2;
}
.report-config_pane-item--locked {
  pointer-events: none;
  opacity: 0.5;
}
.report-config_pane-header {
  display: flex;
  line-height: 20px;
}
.report-config_pane-body {
  margin-top: 16px;
}
.report-config_pane-keyline {
  height: 1px;
  margin: 20px 0;
  background-color: #e0e0e0;
}
.report-config--collapsed {
  width: 40px;
}
.report-config--collapsed:before {
  width: 40px;
}
.report-config .locked-template-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  padding: 24px;
  width: 290px;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
  z-index: 5;
  background-color: #ffffff;
  text-align: center;
}
.report-config .locked-template-popup_title {
  height: 20px;
  font-size: 16px;
  color: #22272b;
  margin-bottom: 10px;
}
.report-config .locked-template-popup_description {
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 24px;
}
