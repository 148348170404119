.pie-config-item + .pie-config-item {
  margin-top: 20px;
}
.pie-config-item_label {
  margin-bottom: 4px;
}
.pie-config-item--card .pie-config-item_value {
  background-color: #ffffff;
  padding: 14px;
  border-radius: 3px;
}
.pie-config-item--card .pie-config-item_value > *:not(:last-child) {
  margin-bottom: 4px;
}
