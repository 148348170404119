/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.pipeline_section-tab.section-tabs--compact .section-tabs_item {
  min-height: 48px;
  height: 48px;
}
.pipeline_section-tab.section-tabs--compact .section-tabs_item:first-child {
  padding-left: 0;
}
.pipeline_section-tab.section-tabs--compact .section-tabs_item > span > span {
  border-bottom: none;
  padding-bottom: 0;
}
.pipeline, .pipeline .pipeline_board {
  height: auto;
  background-color: #2a3035;
  font: inherit;
  color: inherit;
  overflow: visible;
  position: relative;
}
.pipeline .pipeline_board {
  padding: 0 30px 20px;
  max-width: 1920px;
}
.pipeline .pipeline_board .react_trello_dragClass {
  transform: rotate(0deg);
  pointer-events: auto;
}
.pipeline .pipeline_board .smooth-dnd-container.horizontal {
  display: flex;
  align-items: stretch;
  margin-left: 0;
  width: 100%;
}
.pipeline .pipeline_board .smooth-dnd-container.horizontal > span:not(:first-of-type):not(:last-of-type) {
  flex: 1;
}
.pipeline .pipeline_board .smooth-dnd-container.horizontal > span:only-child {
  flex: 1;
  width: 362.2px;
}
.pipeline .pipeline_board .smooth-dnd-container.horizontal > span:only-child .vertical > span {
  display: flex;
}
.pipeline .pipeline_board .smooth-dnd-container.vertical {
  height: 100%;
}
.pipeline .pipeline_board .smooth-dnd-container.vertical .smooth-dnd-draggable-wrapper {
  display: flex;
  overflow: visible;
}
.pipeline .pipeline_board section {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  width: calc(100% - 4px);
  max-width: 362.2px;
  padding: 0 0 60px;
  background-color: #121517;
  border-radius: 3px;
  margin: 0 4px 0 0;
}
.pipeline .pipeline_board section > div {
  margin: 0;
  padding: 0;
  min-width: 230px;
  min-height: 50vh;
  max-height: calc(100% - 48px);
  height: 100%;
  border-radius: 3px;
  border-bottom: none;
  overflow: visible;
}
.pipeline .pipeline_board section > div article {
  margin-bottom: 0 4px 4px;
  flex: 1;
  min-width: calc(230px - 8px);
  max-width: calc(362.2px - 8px);
  border-bottom: none;
  background-color: #ffffff;
}
.pipeline .pipeline_board section > div article:focus {
  outline: none;
}
.pipeline .pipeline_board section[type=target] {
  transition: background 0.75s cubic-bezier(0.1, 0.1, 0.25, 0.9) 0.15s, width 0.25s cubic-bezier(0.1, 0.1, 0.25, 0.9) 0.15s, height 0s 0s;
}
.pipeline .pipeline_board section[type=target] article {
  animation: fadein-opacity 1.5s;
  background-color: #f1f1f1;
}
.pipeline .pipeline_board--open section[label="Objective Not Met"] {
  height: auto;
  max-height: 100%;
  width: 230px;
  position: absolute;
  right: 0;
  top: -65px;
  opacity: 0;
  margin-bottom: 25px;
}
.pipeline .pipeline_board--open section[label="Objective Not Met"] > div {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: -50px;
  height: 124px;
  min-height: 124px;
  width: 230px;
}
.pipeline .pipeline_board--open section[label="Objective Not Met"] .lane-header {
  padding: 0 14px 0 10px;
  height: 40px;
  background-color: #2a3035;
  border: 2px dashed #646d75;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.pipeline .pipeline_board--open section[label="Objective Not Met"] .lane-header_row--add-deal {
  display: none;
}
.pipeline .pipeline_board--open section[label="Objective Not Met"] .lane-header_row--market-info {
  display: none;
}
.pipeline .pipeline_board--open section[label="Objective Not Met"] .lane-header_count {
  display: none;
}
.pipeline .pipeline_board section[label="Objective Not Met"] .lane-header_icon--lost {
  display: none;
}
.pipeline .pipeline_board--drag-target section[label="Objective Not Met"] {
  padding: 0;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.pipeline .pipeline_board--drag-target section[label="Objective Not Met"] .lane-header_icon--lost {
  display: inline-block;
}
.pipeline .pipeline_board--target-collapsed section[type=target] {
  width: 45px;
  background-color: transparent;
  transition-delay: 0.1s, 0.15s, 0.15s;
}
.pipeline .pipeline_board--target-collapsed section[type=target] article {
  display: none;
}
.pipeline .pipeline_board--target-collapsed section[type=target] .lane-header_expand-button {
  cursor: pointer;
  background-color: #f1af0f;
}
.pipeline .pipeline_board--target-collapsed section[type=target] .lane-header_target-count {
  background-color: #dc9e27;
}
.pipeline .pipeline_board--target-collapsed section[type=target] .lane-header_icon--target {
  color: #22272b;
}
.pipeline .pipeline_board--target-collapsed section[type=target] .lane-header_icon--collapse, .pipeline .pipeline_board--target-collapsed section[type=target] .lane-header_target-title--collapse {
  display: none;
}
