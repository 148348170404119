.report-category-list {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  height: 100%;
  padding: 40px 45px 0;
}
.report-category-list_item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  border-radius: 8px;
  background-color: #eeeeee;
  text-align: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.report-category-list_item:not(:last-child) {
  margin-bottom: 10px;
}
.report-category-list_item:hover, .report-category-list_item--selected {
  background-color: #297ac5;
}
.report-category-list_item:hover, .report-category-list_item:hover .report-category-list_icon, .report-category-list_item:hover .report-category-list_title, .report-category-list_item--selected, .report-category-list_item--selected .report-category-list_icon, .report-category-list_item--selected .report-category-list_title {
  color: #ffffff;
}
.report-category-list_icon, .report-category-list_title {
  line-height: 40px;
  transition: color 0.3s ease;
}
.report-category-list_icon {
  margin-right: 10px;
  font-size: 40px;
  color: #297ac5;
}
.report-category-list_title {
  color: #545b62;
}
