.broker-details-widget {
  width: 100%;
  height: 100%;
  color: #eeeeee;
}
.broker-details-widget, .broker-details-widget_title, .broker-details-widget_header, .broker-details-widget_footer, .broker-details-widget_footer a {
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.broker-details-widget_header {
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.broker-details-widget_title {
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}
.broker-details-widget_dropdown {
  vertical-align: middle;
}
.broker-details-widget_dropdown:first-child {
  margin-right: 10px;
}
.broker-details-widget_dropdown--metric {
  text-transform: uppercase;
}
.broker-details-widget_body {
  height: calc(100% - 50px - 35px);
  padding: 10px 10px;
}
.broker-details-widget_body div[data-highcharts-chart] {
  height: 100%;
}
.broker-details-widget_body div[data-highcharts-chart] tspan {
  stroke: none;
}
.broker-details-widget_footer {
  line-height: 35px;
  height: 35px;
  padding: 0 10px;
  border-top: 1px solid #2a3035;
}
.broker-details-widget_footer a {
  float: right;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 13px;
  color: #f1af0f;
}
.broker-details-widget_footer a:hover {
  color: #dc9e27;
}
.broker-details-widget_footer-label {
  width: 100%;
  color: #939ba0;
  font-size: 11px;
}
.broker-details-widget--light {
  color: #545b62;
}
.broker-details-widget--light .broker-details-widget {
  padding-top: 20px;
}
.broker-details-widget--light .broker-details-widget_header {
  border-bottom: 1px solid #e0e0e0;
}
.broker-details-widget--light .broker-details-widget_title {
  color: #545b62;
}
.broker-details-widget--light .broker-details-widget_footer {
  border-top: 1px solid #e0e0e0;
}
.broker-details-widget--light .broker-details-widget_footer a {
  color: #297ac5;
}
.broker-details-widget--light .broker-details-widget_footer a:hover {
  color: #0f5ca3;
}
