/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.peer-analysis_table .ag-row-first .ag-cell {
  background-color: #eeeeee;
}
.peer-analysis_table .ag-row-first .ag-column--even {
  background-color: #e0e0e0;
}
.peer-analysis_table .cell {
  line-height: 18px;
  display: flex;
  align-items: center;
  height: 50px;
}
.peer-analysis_table .cell_icon {
  font-size: 18px;
  color: #0f5ca3;
}
.peer-analysis_table .cell_title {
  margin-left: 14px;
}
.peer-analysis_table .cell_title--name {
  display: inline-block;
  color: #545b62;
  vertical-align: middle;
}
.peer-analysis_table .cell_title--detail {
  color: #939ba0;
}
.peer-analysis_table--increase {
  color: #1abc9c;
}
.peer-analysis_table--decrease {
  color: #e74c3c;
}
