@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';

@mixin radioGroupTheme($textColor) {
  .radio-group_label {
    color: $textColor;
  }
}

.radio-group {

  &_label {
    display: block;
    user-select: none;
  }

  &--inline {
    .radio {
      display: inline-block;
      padding: gux(0.5) gux(1.5) 0 0;
    }
  }

  // themes
  &--light,
  &--white {
    @include radioGroupTheme($light-slate);
  }

  &--dark {
    @include radioGroupTheme($white);
  }
}
