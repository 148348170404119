.q4-wrapper .section-tabs {
  min-height: 30px;
}
.q4-wrapper .section-tabs_item {
  opacity: 1;
  min-width: 34px;
  height: 64px;
  padding: 0 32px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
  border-right-width: 1px;
  border-right-style: solid;
  transition-property: background-color, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.q4-wrapper .section-tabs_item > span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.q4-wrapper .section-tabs_item > span .icon {
  margin: 0 8px 0 0;
  font-size: 16px;
}
.q4-wrapper .section-tabs_indicator {
  background-color: transparent;
}
.q4-wrapper .section-tabs--compact .section-tabs_item {
  min-height: 56px;
  height: 56px;
  padding: 0 16px;
  border-right: none;
}
.q4-wrapper .section-tabs--compact .section-tabs_item:first-child {
  padding-left: 0;
}
.q4-wrapper .section-tabs--compact .section-tabs_item > span {
  height: 100%;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}
.q4-wrapper .section-tabs--white {
  background-color: #ffffff;
}
.q4-wrapper .section-tabs--white .section-tabs_item {
  color: #545b62;
  border-right-color: #eeeeee;
}
.q4-wrapper .section-tabs--white .section-tabs_item:hover {
  background-color: #eeeeee;
}
.q4-wrapper .section-tabs--white .section-tabs_item--active {
  color: #545b62;
  background-color: #eeeeee;
  border-right-color: #eeeeee;
}
.q4-wrapper .section-tabs--white .section-tabs_item--active:hover {
  background-color: #eeeeee;
}
.q4-wrapper .section-tabs--white.section-tabs--compact .section-tabs_item:hover {
  background-color: #ffffff;
}
.q4-wrapper .section-tabs--white.section-tabs--compact .section-tabs_item--active, .q4-wrapper .section-tabs--white.section-tabs--compact .section-tabs_item--active:hover {
  background-color: #ffffff;
}
.q4-wrapper .section-tabs--white.section-tabs--compact .section-tabs_item--active > span, .q4-wrapper .section-tabs--white.section-tabs--compact .section-tabs_item--active:hover > span {
  border-bottom-color: #eeeeee;
}
.q4-wrapper .section-tabs--rain {
  background-color: #297ac5;
}
.q4-wrapper .section-tabs--rain .section-tabs_item {
  color: #ffffff;
  border-right-color: #1f6bb0;
}
.q4-wrapper .section-tabs--rain .section-tabs_item:hover {
  background-color: #0f5ca3;
}
.q4-wrapper .section-tabs--rain .section-tabs_item--active {
  color: #ffffff;
  background-color: #1f6bb0;
  border-right-color: #1f6bb0;
}
.q4-wrapper .section-tabs--rain .section-tabs_item--active:hover {
  background-color: #1f6bb0;
}
.q4-wrapper .section-tabs--rain.section-tabs--compact .section-tabs_item:hover {
  background-color: #297ac5;
}
.q4-wrapper .section-tabs--rain.section-tabs--compact .section-tabs_item--active, .q4-wrapper .section-tabs--rain.section-tabs--compact .section-tabs_item--active:hover {
  background-color: #297ac5;
}
.q4-wrapper .section-tabs--rain.section-tabs--compact .section-tabs_item--active > span, .q4-wrapper .section-tabs--rain.section-tabs--compact .section-tabs_item--active:hover > span {
  border-bottom-color: #1f6bb0;
}
.q4-wrapper .section-tabs--steel {
  background-color: #1f6bb0;
}
.q4-wrapper .section-tabs--steel .section-tabs_item {
  color: #ffffff;
  border-right-color: #0f5ca3;
}
.q4-wrapper .section-tabs--steel .section-tabs_item:hover {
  background-color: #10528f;
}
.q4-wrapper .section-tabs--steel .section-tabs_item--active {
  color: #ffffff;
  background-color: #0f5ca3;
  border-right-color: #0f5ca3;
}
.q4-wrapper .section-tabs--steel .section-tabs_item--active:hover {
  background-color: #0f5ca3;
}
.q4-wrapper .section-tabs--steel.section-tabs--compact .section-tabs_item:hover {
  background-color: #1f6bb0;
}
.q4-wrapper .section-tabs--steel.section-tabs--compact .section-tabs_item--active, .q4-wrapper .section-tabs--steel.section-tabs--compact .section-tabs_item--active:hover {
  background-color: #1f6bb0;
}
.q4-wrapper .section-tabs--steel.section-tabs--compact .section-tabs_item--active > span, .q4-wrapper .section-tabs--steel.section-tabs--compact .section-tabs_item--active:hover > span {
  border-bottom-color: #0f5ca3;
}
.q4-wrapper .section-tabs--slate {
  background-color: #2a3035;
}
.q4-wrapper .section-tabs--slate .section-tabs_item {
  color: #ffffff;
  border-right-color: #1a1d21;
}
.q4-wrapper .section-tabs--slate .section-tabs_item:hover {
  background-color: #1f2327;
}
.q4-wrapper .section-tabs--slate .section-tabs_item--active {
  color: #f1af0f;
  background-color: #1a1d21;
  border-right-color: #f1af0f;
}
.q4-wrapper .section-tabs--slate .section-tabs_item--active:hover {
  background-color: #1a1d21;
}
.q4-wrapper .section-tabs--slate.section-tabs--compact .section-tabs_item:hover {
  background-color: #2a3035;
}
.q4-wrapper .section-tabs--slate.section-tabs--compact .section-tabs_item--active, .q4-wrapper .section-tabs--slate.section-tabs--compact .section-tabs_item--active:hover {
  background-color: #2a3035;
}
.q4-wrapper .section-tabs--slate.section-tabs--compact .section-tabs_item--active > span, .q4-wrapper .section-tabs--slate.section-tabs--compact .section-tabs_item--active:hover > span {
  border-bottom-color: #f1af0f;
}
.q4-wrapper .section-tabs--dark {
  background-color: #22272b;
}
.q4-wrapper .section-tabs--dark .section-tabs_item {
  color: #ffffff;
  border-right-color: #22272b;
}
.q4-wrapper .section-tabs--dark .section-tabs_item:hover {
  background-color: #22272b;
}
.q4-wrapper .section-tabs--dark .section-tabs_item--active {
  color: #ffffff;
  background-color: #22272b;
  border-right-color: #f1af0f;
}
.q4-wrapper .section-tabs--dark .section-tabs_item--active:hover {
  background-color: #22272b;
}
.q4-wrapper .section-tabs--dark.section-tabs--compact .section-tabs_item:hover {
  background-color: #22272b;
}
.q4-wrapper .section-tabs--dark.section-tabs--compact .section-tabs_item--active, .q4-wrapper .section-tabs--dark.section-tabs--compact .section-tabs_item--active:hover {
  background-color: #22272b;
}
.q4-wrapper .section-tabs--dark.section-tabs--compact .section-tabs_item--active > span, .q4-wrapper .section-tabs--dark.section-tabs--compact .section-tabs_item--active:hover > span {
  border-bottom-color: #f1af0f;
}
