@import '../../../../../resources/styles/variables';
@import '../../../../../resources/styles/mixins/generic';
@import '../../../../../resources/styles/utils';

.entity-ownership_list {
  height: 308px;
  display: flex;
  justify-content: space-between;
  color: $white;
  background-color: $dark-slate;

  visibility: hidden;
  position: absolute;
  right: 0;
  left: 0;

  &--active {
    visibility: visible;
  }

  &-column {
    width: 120px;

    &:first-child {
      flex: 1;

      .entity-ownership_list-row {
        padding-left: gux(2);
      }
    }

    &:not(:first-child) {
      text-align: right;
    }
  }

  &-row {
    padding: 0 gux();
    height: 40px;
    line-height: 39px;
    border-bottom: 1px solid darken($silver, 25);

    &--header {
      line-height: 40px;
      background-color: $gunpowder;
      border-radius: $border-radius-small $border-radius-small 0 0;
      border-bottom: none;
      @include truncate();
    }

    &-divider {
      @include divider();
    }
  }
}
