.nui-solid--done {
  opacity: 1;
}

.nui-ghost--done {
  opacity: 0;
  visibility: hidden;
}

.nui-solid--transition,
.nui-ghost--transition {
  transition: opacity 0.3s ease;
}
.nui-solid--transition-1x-delay,
.nui-ghost--transition-1x-delay {
  transition-delay: 0.3s;
}
.nui-solid--transition-2x-delay,
.nui-ghost--transition-2x-delay {
  transition-delay: 0.6s;
}
.nui-solid--transition-3x-delay,
.nui-ghost--transition-3x-delay {
  transition-delay: 0.9s;
}
.nui-solid--transition-4x-delay,
.nui-ghost--transition-4x-delay {
  transition-delay: 1.2s;
}
.nui-solid--transition--fast-speed,
.nui-ghost--transition--fast-speed {
  transition-duration: 0.2s;
}
.nui-solid--transition--slow-speed,
.nui-ghost--transition--slow-speed {
  transition-duration: 1s;
}

.nui-solid--transition {
  opacity: 1;
  visibility: visible;
}

.nui-ghost--transition {
  opacity: 0;
}
