@font-face {
  font-family: "Open Sans";
  src: url("../../../resources/fonts/openSans/OpenSans-Light-webfont.eot");
  src: url("../../../resources/fonts/openSans/OpenSans-Light-webfont.eot?#iefix") format("embedded-opentype"), url("../../../resources/fonts/openSans/OpenSans-Light-webfont.woff") format("woff"), url("../../../resources/fonts/openSans/OpenSans-Light-webfont.ttf") format("truetype"), url("../../../resources/fonts/openSans/OpenSans-Light-webfont.svg#open_sanslight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../../resources/fonts/openSans/OpenSans-Regular-webfont.eot");
  src: url("../../../resources/fonts/openSans/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../resources/fonts/openSans/OpenSans-Regular-webfont.woff") format("woff"), url("../../../resources/fonts/openSans/OpenSans-Regular-webfont.ttf") format("truetype"), url("../../../resources/fonts/openSans/OpenSans-Regular-webfont.svg#open_sansregular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../../resources/fonts/openSans/OpenSans-Italic-webfont.eot");
  src: url("../../../resources/fonts/openSans/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), url("../../../resources/fonts/openSans/OpenSans-Italic-webfont.woff") format("woff"), url("../../../resources/fonts/openSans/OpenSans-Italic-webfont.ttf") format("truetype"), url("../../../resources/fonts/openSans/OpenSans-Italic-webfont.svg#open_sansitalic") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../../resources/fonts/openSans/OpenSans-SemiboldItalic-webfont.eot");
  src: url("../../../resources/fonts/openSans/OpenSans-SemiboldItalic-webfont.eot?#iefix") format("embedded-opentype"), url("../../../resources/fonts/openSans/OpenSans-SemiboldItalic-webfont.woff") format("woff"), url("../../../resources/fonts/openSans/OpenSans-SemiboldItalic-webfont.ttf") format("truetype"), url("../../../resources/fonts/openSans/OpenSans-SemiboldItalic-webfont.svg#open_sansitalic") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../../resources/fonts/openSans/OpenSans-Semibold-webfont.eot");
  src: url("../../../resources/fonts/openSans/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../../../resources/fonts/openSans/OpenSans-Semibold-webfont.woff") format("woff"), url("../../../resources/fonts/openSans/OpenSans-Semibold-webfont.ttf") format("truetype"), url("../../../resources/fonts/openSans/OpenSans-Semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../../resources/fonts/openSans/OpenSans-Bold-webfont.eot");
  src: url("../../../resources/fonts/openSans/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../../resources/fonts/openSans/OpenSans-Bold-webfont.woff") format("woff"), url("../../../resources/fonts/openSans/OpenSans-Bold-webfont.ttf") format("truetype"), url("../../../resources/fonts/openSans/OpenSans-Bold-webfont.svg#open_sansbold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "iris";
  src: url("../../../resources/fonts/icons/iris.woff2?h6xksg") format("woff2"), url("../../../resources/fonts/icons/iris.ttf?h6xksg") format("truetype"), url("../../../resources/fonts/icons/iris.woff?h6xksg") format("woff"), url("../../../resources/fonts/icons/iris.svg?h6xksg#iris") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=q4i-], [class*=" q4i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iris" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.q4i-insider-institution-2pt:before {
  content: "\e9a4";
}

.q4i-insider-institution-4pt:before {
  content: "\e9a9";
}

.q4i-arrow-down-4pt:before {
  content: "\e995";
}

.q4i-arrow-left-4pt:before {
  content: "\e997";
}

.q4i-arrow-right-4pt:before {
  content: "\e9a1";
}

.q4i-arrow-up-4pt:before {
  content: "\e9a2";
}

.q4i-insight:before {
  content: "\e994";
}

.q4i-essential:before {
  content: "\e996";
}

.q4i-website-analytics-4pt:before {
  content: "\e993";
}

.q4i-contact-list-4pt:before {
  content: "\e992";
}

.q4i-sync-or-fetch-2pt:before {
  content: "\e93f";
}

.q4i-sync-or-fetch-4pt:before {
  content: "\e991";
}

.q4i-savedtargets-2pt:before {
  content: "\e98f";
}

.q4i-savedtargets-4pt:before {
  content: "\e990";
}

.q4i-combo-chart-2pt:before {
  content: "\e98d";
}

.q4i-combo-chart-4pt:before {
  content: "\e98e";
}

.q4i-pivot-4pt:before {
  content: "\e986";
}

.q4i-pivot-2pt:before {
  content: "\e98c";
}

.q4i-clone-duplicate-2pt:before {
  content: "\e97a";
}

.q4i-clone-duplicate-4pt:before {
  content: "\e97c";
}

.q4i-stock-2pt:before {
  content: "\e977";
}

.q4i-stock-4pt:before {
  content: "\e979";
}

.q4i-editor-italic-4pt:before {
  content: "\e976";
}

.q4i-editor-align-center-4pt:before {
  content: "\e95d";
}

.q4i-editor-align-left-4pt:before {
  content: "\e95e";
}

.q4i-editor-align-right-4pt:before {
  content: "\e962";
}

.q4i-editor-bold-4pt:before {
  content: "\e963";
}

.q4i-editor-indent-in-4pt:before {
  content: "\e964";
}

.q4i-editor-indent-out-4pt:before {
  content: "\e966";
}

.q4i-editor-link2-4pt:before {
  content: "\e968";
}

.q4i-editor-list-bullet-4pt:before {
  content: "\e96a";
}

.q4i-editor-list-number-4pt:before {
  content: "\e971";
}

.q4i-editor-underline-4pt:before {
  content: "\e973";
}

.q4i-display-density-2pt:before {
  content: "\e959";
}

.q4i-display-density-4pt:before {
  content: "\e95a";
}

.q4i-earnings-2pt:before {
  content: "\e947";
}

.q4i-earnings-4pt:before {
  content: "\e951";
}

.q4i-hamburger-q4inc-4pt:before {
  content: "\e936";
}

.q4i-report-blank-1pt:before {
  content: "\e937";
}

.q4i-report-blank-2pt:before {
  content: "\e938";
}

.q4i-report-blank-4pt:before {
  content: "\e939";
}

.q4i-ownership-1pt:before {
  content: "\e93a";
}

.q4i-filter-4pt:before {
  content: "\e935";
}

.q4i-presentation-4pt:before {
  content: "\e933";
}

.q4i-webcast-4pt:before {
  content: "\e934";
}

.q4i-xls-1pt:before {
  content: "\e930";
}

.q4i-xls-2pt:before {
  content: "\e931";
}

.q4i-xls-4pt:before {
  content: "\e932";
}

.q4i-funnel-filter-1pt:before {
  content: "\e92d";
}

.q4i-funnel-filter-2pt:before {
  content: "\e92e";
}

.q4i-funnel-filter-4pt:before {
  content: "\e92f";
}

.q4i-search-1pt:before {
  content: "\e92b";
}

.q4i-search-4pt:before {
  content: "\e92c";
}

.q4i-pdf-1pt:before {
  content: "\e926";
}

.q4i-pdf-2pt:before {
  content: "\e927";
}

.q4i-pdf-4pt:before {
  content: "\e928";
}

.q4i-table-1pt:before {
  content: "\e929";
}

.q4i-table-4pt:before {
  content: "\e92a";
}

.q4i-activity-1pt:before {
  content: "\e924";
}

.q4i-notifications-1pt:before {
  content: "\e925";
}

.q4i-generate-1pt:before {
  content: "\e900";
}

.q4i-generate-2pt:before {
  content: "\e901";
}

.q4i-generate-4pt:before {
  content: "\e902";
}

.q4i-request-2pt:before {
  content: "\e903";
}

.q4i-request-4pt:before {
  content: "\e904";
}

.q4i-position-1pt:before {
  content: "\e905";
}

.q4i-position-2pt:before {
  content: "\e906";
}

.q4i-position-4pt:before {
  content: "\e907";
}

.q4i-custom-4pt:before {
  content: "\e908";
}

.q4i-target-1pt:before {
  content: "\e909";
}

.q4i-target-2pt:before {
  content: "\e90a";
}

.q4i-target-4pt:before {
  content: "\e90b";
}

.q4i-targeting-4pt:before {
  content: "\e90c";
}

.q4i-lock-4pt:before {
  content: "\e90d";
}

.q4i-nodata-4pt:before {
  content: "\e90e";
}

.q4i-notifications-4pt:before {
  content: "\e90f";
}

.q4i-releases-4pt:before {
  content: "\e910";
}

.q4i-stage-1pt:before {
  content: "\e911";
}

.q4i-stage-2pt:before {
  content: "\e912";
}

.q4i-stage-4pt:before {
  content: "\e913";
}

.q4i-transcript-4pt:before {
  content: "\e914";
}

.q4i-insider-4pt:before {
  content: "\e915";
}

.q4i-press-releases-4pt:before {
  content: "\e916";
}

.q4i-iris-logo:before {
  content: "\e917";
}

.q4i-swap-2pt:before {
  content: "\e918";
}

.q4i-swap-4pt:before {
  content: "\e919";
}

.q4i-scale-1pt:before {
  content: "\e91a";
}

.q4i-scale-2pt:before {
  content: "\e91b";
}

.q4i-scale-4pt:before {
  content: "\e91c";
}

.q4i-fullscreen-exit-1pt:before {
  content: "\e91d";
}

.q4i-fullscreen-exit-2pt:before {
  content: "\e91e";
}

.q4i-fullscreen-exit-4pt:before {
  content: "\e91f";
}

.q4i-volatility-4pt:before {
  content: "\e920";
}

.q4i-sentiment-4pt:before {
  content: "\e921";
}

.q4i-question-mark-4pt:before {
  content: "\e922";
}

.q4i-star-2pt:before {
  content: "\e9ab";
}

.q4i-star-fill:before {
  content: "\e923";
}

.q4i-security-mapping-2pt:before {
  content: "\e93b";
}

.q4i-move-to-list-4pt:before {
  content: "\e93c";
}

.q4i-book-2pt:before {
  content: "\e93d";
}

.q4i-mobile-1pt:before {
  content: "\e93e";
}

.q4i-rss-4pt:before {
  content: "\e940";
}

.q4i-feedback-2pt:before {
  content: "\e941";
}

.q4i-price-4pt:before {
  content: "\e942";
}

.q4i-rating-4pt:before {
  content: "\e943";
}

.q4i-price-2pt:before {
  content: "\e944";
}

.q4i-rating-2pt:before {
  content: "\e945";
}

.q4i-insider-2pt:before {
  content: "\e946";
}

.q4i-buyers-2pt:before {
  content: "\e948";
}

.q4i-sellers-2pt:before {
  content: "\e949";
}

.q4i-reportclosed-4pt:before {
  content: "\e94a";
}

.q4i-reportno-4pt:before {
  content: "\e94b";
}

.q4i-reportopen-4pt:before {
  content: "\e94c";
}

.q4i-research-2pt:before {
  content: "\e94d";
}

.q4i-arrowdouble-sm-left-4pt:before {
  content: "\e94e";
}

.q4i-arrowdouble-sm-right-4pt:before {
  content: "\e94f";
}

.q4i-sync-2pt:before {
  content: "\e950";
}

.q4i-sync-4pt:before {
  content: "\e952";
}

.q4i-mail-4pt:before {
  content: "\e953";
}

.q4i-phone-4pt:before {
  content: "\e954";
}

.q4i-edit-4pt:before {
  content: "\e955";
}

.q4i-desktop-1pt:before {
  content: "\e956";
}

.q4i-tablet-2pt:before {
  content: "\e957";
}

.q4i-tablet-1pt:before {
  content: "\e958";
}

.q4i-session-2pt:before {
  content: "\e95b";
}

.q4i-session-1pt:before {
  content: "\e95c";
}

.q4i-rate-2pt:before {
  content: "\e95f";
}

.q4i-rate-1pt:before {
  content: "\e960";
}

.q4i-pages-session-2pt:before {
  content: "\e961";
}

.q4i-pages-session-1pt:before {
  content: "\e965";
}

.q4i-new-user-2pt:before {
  content: "\e967";
}

.q4i-new-user-1pt:before {
  content: "\e969";
}

.q4i-new-session-2pt:before {
  content: "\e96b";
}

.q4i-new-session-1pt:before {
  content: "\e96c";
}

.q4i-contact-1pt:before {
  content: "\e96d";
}

.q4i-savedsearches-2pt:before {
  content: "\e96e";
}

.q4i-targeting-2pt:before {
  content: "\e96f";
}

.q4i-stop-watch-4pt:before {
  content: "\e970";
}

.q4i-longarrow-down-2pt:before {
  content: "\e972";
}

.q4i-longarrow-up-2pt:before {
  content: "\e974";
}

.q4i-info-fill:before {
  content: "\e975";
}

.q4i-noresults-2pt:before {
  content: "\e978";
}

.q4i-noresults-4pt:before {
  content: "\e97b";
}

.q4i-nodata-2pt:before {
  content: "\e97e";
}

.q4i-trashbin-4pt:before {
  content: "\e980";
}

.q4i-flexible-2pt:before {
  content: "\e981";
}

.q4i-plus-4pt:before {
  content: "\e982";
}

.q4i-minus-4pt:before {
  content: "\e983";
}

.q4i-checkmark-4pt:before {
  content: "\e984";
}

.q4i-surveillance-2pt:before {
  content: "\e985";
}

.q4i-activist-4pt:before {
  content: "\e987";
}

.q4i-ownership-2pt:before {
  content: "\e988";
}

.q4i-watchlist-2pt:before {
  content: "\e989";
}

.q4i-support-2pt:before {
  content: "\e98a";
}

.q4i-presentation-2pt:before {
  content: "\e98b";
}

.q4i-bookmark-2pt:before {
  content: "\e998";
}

.q4i-export-4pt:before {
  content: "\e999";
}

.q4i-logout-2pt:before {
  content: "\e99a";
}

.q4i-download-2pt:before {
  content: "\e99b";
}

.q4i-download-4pt:before {
  content: "\e99c";
}

.q4i-more-4pt:before {
  content: "\e99d";
}

.q4i-time-1pt:before {
  content: "\e99e";
}

.q4i-time-2pt:before {
  content: "\e99f";
}

.q4i-book-4pt:before {
  content: "\e9a0";
}

.q4i-roadshow-2pt:before {
  content: "\e9a3";
}

.q4i-collection-2pt:before {
  content: "\e9a5";
}

.q4i-collection-4pt:before {
  content: "\e9a6";
}

.q4i-userprofile-2pt:before {
  content: "\e9a7";
}

.q4i-ticker-2pt:before {
  content: "\e9a8";
}

.q4i-add-to-list-4pt:before {
  content: "\e9aa";
}

.q4i-review-undomove-4pt:before {
  content: "\e9ac";
}

.q4i-review-archive-4pt:before {
  content: "\e9ad";
}

.q4i-caret-sm-left-2pt:before {
  content: "\e9af";
}

.q4i-custom-2pt:before {
  content: "\e9b0";
}

.q4i-add-to-calendar-4pt:before {
  content: "\e9b3";
}

.q4i-filter-2pt:before {
  content: "\e9b4";
}

.q4i-conference-2pt:before {
  content: "\e9b6";
}

.q4i-venue-2pt:before {
  content: "\e9b7";
}

.q4i-undo-4pt:before {
  content: "\e9b9";
}

.q4i-map-marker-4pt:before {
  content: "\e9ba";
}

.q4i-warning-4pt:before {
  content: "\e9bb";
}

.q4i-security-4pt:before {
  content: "\e9be";
}

.q4i-ownership-4pt:before {
  content: "\e9bf";
}

.q4i-shareholder-id-2pt:before {
  content: "\e9c2";
}

.q4i-activity-4pt:before {
  content: "\e9c4";
}

.q4i-conference-4pt:before {
  content: "\e9c5";
}

.q4i-meeting-4pt:before {
  content: "\e9c6";
}

.q4i-note-4pt:before {
  content: "\e9c7";
}

.q4i-roadshow-4pt:before {
  content: "\e9c8";
}

.q4i-itinerary-1pt:before {
  content: "\e9c9";
}

.q4i-itinerary-2pt:before {
  content: "\e9ca";
}

.q4i-itinerary-4pt:before {
  content: "\e9cb";
}

.q4i-contact-4pt:before {
  content: "\e9cd";
}

.q4i-fund-4pt:before {
  content: "\e9ce";
}

.q4i-deal-1pt:before {
  content: "\e9cf";
}

.q4i-deal-2pt:before {
  content: "\e9d0";
}

.q4i-deal-4pt:before {
  content: "\e9d1";
}

.q4i-institution-4pt:before {
  content: "\e9d2";
}

.q4i-activity-calendar-2pt:before {
  content: "\e9d3";
}

.q4i-website-2pt:before {
  content: "\e9d4";
}

.q4i-cog-4pt:before {
  content: "\e9d5";
}

.q4i-trashbin-2pt:before {
  content: "\e9d6";
}

.q4i-suggest-an-edit-2pt:before {
  content: "\e9d7";
}

.q4i-utility-2pt:before {
  content: "\e9d8";
}

.q4i-contact-list-2pt:before {
  content: "\e9d9";
}

.q4i-morning-star-data-2pt:before {
  content: "\e9da";
}

.q4i-eod-reports-2pt:before {
  content: "\e9db";
}

.q4i-link-4pt:before {
  content: "\e9dc";
}

.q4i-html-2pt:before {
  content: "\e9dd";
}

.q4i-table-2pt:before {
  content: "\e9de";
}

.q4i-mobile-2pt:before {
  content: "\e9df";
}

.q4i-estimates-research-2pt:before {
  content: "\e9e0";
}

.q4i-print-2pt:before {
  content: "\e9e1";
}

.q4i-piechart-2pt:before {
  content: "\e9e2";
}

.q4i-lightbulb-1pt:before {
  content: "\e9e3";
}

.q4i-free-1pt:before {
  content: "\e9e4";
}

.q4i-data-2pt:before {
  content: "\e9e5";
}

.q4i-data-1pt:before {
  content: "\e9e6";
}

.q4i-customer-1pt:before {
  content: "\e9e7";
}

.q4i-closed-1pt:before {
  content: "\e9e8";
}

.q4i-ai-2pt:before {
  content: "\e9e9";
}

.q4i-ai-1pt:before {
  content: "\e9ea";
}

.q4i-volatility-2pt:before {
  content: "\e9eb";
}

.q4i-upcoming-events-2pt:before {
  content: "\e9ec";
}

.q4i-relative-performance-2pt:before {
  content: "\e9ed";
}

.q4i-past-events-2pt:before {
  content: "\e9ee";
}

.q4i-notifications-2pt:before {
  content: "\e9ef";
}

.q4i-note-2pt:before {
  content: "\e9f0";
}

.q4i-map-marker-2pt:before {
  content: "\e9f1";
}

.q4i-expected-trading-range-2pt:before {
  content: "\e9f2";
}

.q4i-admin-2pt:before {
  content: "\e9f3";
}

.q4i-activist-2pt:before {
  content: "\e9f4";
}

.q4i-lock-2pt:before {
  content: "\e9f5";
}

.q4i-fullscreen-4pt:before {
  content: "\e9f6";
}

.q4i-calendar-4pt:before {
  content: "\e9f7";
}

.q4i-add-4pt:before {
  content: "\e9f8";
}

.q4i-add-2pt:before {
  content: "\e9f9";
}

.q4i-time-4pt:before {
  content: "\e9fa";
}

.q4i-edit-2pt:before {
  content: "\e9fb";
}

.q4i-cog-2pt:before {
  content: "\e9fc";
}

.q4i-question-mark-2pt:before {
  content: "\e9fd";
}

.q4i-sec-2pt:before {
  content: "\e9fe";
}

.q4i-fund-2pt:before {
  content: "\e9ff";
}

.q4i-institution-2pt:before {
  content: "\ea00";
}

.q4i-press-releases-2pt:before {
  content: "\ea01";
}

.q4i-releases-2pt:before {
  content: "\ea02";
}

.q4i-transcript-2pt:before {
  content: "\ea03";
}

.q4i-caret-sm-left-4pt:before {
  content: "\ea04";
}

.q4i-caret-sm-down-4pt:before {
  content: "\ea05";
}

.q4i-caret-sm-right-4pt:before {
  content: "\ea06";
}

.q4i-caret-sm-up-4pt:before {
  content: "\ea07";
}

.q4i-mail-2pt:before {
  content: "\ea08";
}

.q4i-meeting-2pt:before {
  content: "\ea09";
}

.q4i-chat-2pt:before {
  content: "\ea0a";
}

.q4i-phone-2pt:before {
  content: "\ea0b";
}

.q4i-hamburger-desktop-2pt:before {
  content: "\ea0c";
}

.q4i-chart-4pt:before {
  content: "\e97d";
}

.q4i-chart-2pt:before {
  content: "\ea0d";
}

.q4i-list-2pt:before {
  content: "\ea0e";
}

.q4i-checklist-2pt:before {
  content: "\ea0f";
}

.q4i-team-2pt:before {
  content: "\ea10";
}

.q4i-sentiment-2pt:before {
  content: "\ea11";
}

.q4i-webcast-2pt:before {
  content: "\ea12";
}

.q4i-website-analytics-2pt:before {
  content: "\ea13";
}

.q4i-activity-2pt:before {
  content: "\ea14";
}

.q4i-security-2pt:before {
  content: "\ea15";
}

.q4i-contact-2pt:before {
  content: "\ea16";
}

.q4i-dashboard-2pt:before {
  content: "\ea17";
}

.q4i-events-transcripts-2pt:before {
  content: "\ea18";
}

.q4i-reports-4pt:before {
  content: "\e97f";
}

.q4i-reports-2pt:before {
  content: "\ea19";
}

.q4i-calculator-2pt:before {
  content: "\ea1a";
}

.q4i-stop-watch-2pt:before {
  content: "\ea1b";
}

.q4i-lightbulb-2pt:before {
  content: "\ea1c";
}

.q4i-free-2pt:before {
  content: "\ea1d";
}

.q4i-postchat-2pt:before {
  content: "\ea1e";
}

.q4i-sort-2pt:before {
  content: "\ea1f";
}

.q4i-coffee-2pt:before {
  content: "\ea20";
}

.q4i-growth-2pt:before {
  content: "\ea21";
}

.q4i-open-1pt:before {
  content: "\ea22";
}

.q4i-resolved-1pt:before {
  content: "\ea23";
}

.q4i-total-tickets-1pt:before {
  content: "\ea24";
}

.q4i-desktop-2pt:before {
  content: "\ea25";
}

.q4i-customer-2pt:before {
  content: "\ea26";
}

.q4i-employee-2pt:before {
  content: "\ea27";
}

.q4i-blog-2pt:before {
  content: "\ea28";
}

.q4i-blog-fill:before {
  content: "\ea29";
}

.q4i-moustache-2pt:before {
  content: "\ea2a";
}

.q4i-checkmark-2pt:before {
  content: "\ea2b";
}

.q4i-arrow-down-2pt:before {
  content: "\ea2c";
}

.q4i-arrow-left-2pt:before {
  content: "\ea2d";
}

.q4i-arrow-right-2pt:before {
  content: "\ea2e";
}

.q4i-arrow-up-2pt:before {
  content: "\ea33";
}

.q4i-close-2pt:before {
  content: "\ea34";
}

.q4i-close-4pt:before {
  content: "\ea35";
}

.q4i-link-2pt:before {
  content: "\ea36";
}

.q4i-play-2pt:before {
  content: "\ea37";
}

.q4i-drag-drop-2pt:before {
  content: "\ea38";
}

.q4i-hamburger-q4inc-2pt:before {
  content: "\ea39";
}

.q4i-print-4pt:before {
  content: "\ea3a";
}

.q4i-search-2pt:before {
  content: "\ea3b";
}

.q4i-logo:before {
  content: "\ea3c";
}

.q4i-nrhwnjehsd:before {
  content: "\e9ae";
}

.q4i-utility-4pt:before {
  content: "\ebbd";
}

/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.itinerary-page {
  overflow: hidden;
  width: 8.5in;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #22272b;
  background-color: #ffffff;
  zoom: 1.13;
}
@media screen {
  .itinerary-page {
    margin: 0 auto;
    width: 1085px;
    overflow: visible;
  }
  .itinerary-page .itinerary_item, .itinerary-page .itinerary_table {
    page-break-inside: avoid;
  }
}
@media print {
  .itinerary-page html, .itinerary-page body, .itinerary-page .page {
    float: none;
  }
  .itinerary-page .page {
    page-break-after: always;
  }
  .itinerary-page .itinerary_item, .itinerary-page .itinerary_table {
    page-break-inside: unset;
  }
}
.itinerary-page .page {
  position: relative;
  width: 1085px;
  padding: 0 48px 44px 48px;
  background-color: #ffffff;
}
.itinerary-page .page_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 4px;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 30px;
  color: #0f5ca3;
}
.itinerary-page .page_header-icon {
  padding-right: 8px;
}
.itinerary-page .page_header-title {
  font-weight: normal;
}
.itinerary-page .page_body {
  margin-left: -20px;
}
.itinerary-page .page_body:before, .itinerary-page .page_body:after {
  content: " ";
  display: table;
}
.itinerary-page .page_body:after {
  clear: both;
}
.itinerary-page .page_footer {
  position: relative;
  page-break-inside: avoid;
}
.itinerary-page .page_footer i, .itinerary-page .page_footer span {
  display: inline-block;
  vertical-align: middle;
}
.itinerary-page .page_footer i {
  margin-right: 16px;
  font-size: 20px;
  color: #0f5ca3;
}
.itinerary-page .pane {
  float: left;
  padding-left: 20px;
}
.itinerary-page .pane:before, .itinerary-page .pane:after {
  content: " ";
  display: table;
}
.itinerary-page .pane:after {
  clear: both;
}
.itinerary-page .pane--left {
  width: 66.666666%;
}
.itinerary-page .pane--right {
  width: 33.333333%;
}
.itinerary-page .pane--bottom {
  float: none;
  clear: both;
  width: 100%;
}
.itinerary-page .data {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0 0 16px 0;
}
.itinerary-page .data--clean {
  list-style: none;
}
.itinerary-page .data_item--header {
  font-size: 15px;
  font-weight: 600;
  color: #1a1d21;
}
.itinerary-page .data_item--bordered {
  border-bottom: 1px solid #eeeeee;
  line-height: 17px;
}
.itinerary-page .data_title {
  display: block;
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 600;
  color: #1a1d21;
}
.itinerary-page .data_text {
  margin: 0;
}
.itinerary-page .data_text--multi {
  line-height: 1.3;
}
.itinerary-page .data_key {
  margin-right: 2px;
}
.itinerary-page .data_value, .itinerary-page .data_icon {
  color: #1a1d21;
}
.itinerary-page .data_icon {
  margin-right: 4px;
}
.itinerary-page .data_name {
  display: block;
  border-bottom: 1px solid #eeeeee;
  text-transform: uppercase;
  line-height: 17px;
  color: #1a1d21;
}
.itinerary-page .data_name--initial {
  text-transform: none;
}
.itinerary-page .data_block {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.itinerary-page .data_block--no-icon {
  margin-left: 16px;
  padding-right: 16px;
}
.itinerary-page .data--list {
  list-style: none;
}
.itinerary-page .data--list .data_item:first-child:not(.data_item--header) {
  padding-top: 18px;
}
.itinerary-page .itinerary_item:not(:last-child) {
  margin-bottom: 20px;
}
.itinerary-page .itinerary_calendar-day {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.itinerary-page .itinerary_table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
.itinerary-page .itinerary_table th, .itinerary-page .itinerary_table td {
  padding: 8px;
}
.itinerary-page .itinerary_table th {
  font-weight: 400;
  text-align: left;
}
.itinerary-page .itinerary_table td {
  vertical-align: top;
}
.itinerary-page .itinerary_table-header {
  background-color: #297ac5;
  color: #ffffff;
}
.itinerary-page .itinerary_table-subheader {
  background-color: #eeeeee;
  text-transform: uppercase;
}
.itinerary-page .itinerary_table-item {
  border-bottom: 1px solid #eeeeee;
  page-break-inside: avoid;
}
.itinerary-page .itinerary_table-column--truncate .itinerary_label {
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.itinerary-page .itinerary_table-column--truncate .itinerary_label--small {
  max-width: 130px;
}
.itinerary-page .itinerary_table-column--center {
  text-align: center;
}
.itinerary-page .itinerary_table-column--right {
  text-align: right;
}
.itinerary-page .itinerary_label {
  display: block;
}
.itinerary-page .itinerary_label--black {
  color: #1a1d21;
}
.itinerary-page .itinerary_label span:not(:last-child):after {
  content: ", ";
}
.itinerary-page .itinerary_attendee {
  width: calc(210px - 16px);
}
.itinerary-page .itinerary_attendee:not(:first-child) {
  margin-top: 18px;
}
.itinerary-page .itinerary_icon {
  font-size: 18px;
  color: #297ac5;
}
.itinerary-page .itinerary_notes {
  display: inline;
}
.itinerary-page .itinerary_notes p, .itinerary-page .itinerary_notes ul, .itinerary-page .itinerary_notes ol, .itinerary-page .itinerary_notes li, .itinerary-page .itinerary_notes span, .itinerary-page .itinerary_notes div {
  display: inline !important;
}
.itinerary-page .itinerary_notes p, .itinerary-page .itinerary_notes span, .itinerary-page .itinerary_notes ul, .itinerary-page .itinerary_notes ol {
  margin: 0;
  padding: 0;
}
.itinerary-page .itinerary_notes p, .itinerary-page .itinerary_notes span, .itinerary-page .itinerary_notes a {
  background: none;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: inherit;
  text-align: right;
  letter-spacing: inherit;
}
.itinerary-page .itinerary_notes p, .itinerary-page .itinerary_notes span {
  color: #22272b;
}
.itinerary-page .itinerary_notes a {
  color: #0f5ca3;
  text-decoration: none;
}
.itinerary-page .itinerary_notes p:after {
  content: " ";
}
.itinerary-page .itinerary_notes li:not(:last-child):after {
  content: ", ";
}
.itinerary-page .itinerary_notes li:last-child:after {
  content: ". ";
}
