/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.modal-root--trial-agreement .modal_header {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  height: 135px;
}
.modal-root--trial-agreement .modal_subtitle, .modal-root--trial-agreement .modal_title-container {
  text-align: center;
}
.modal-root--trial-agreement .modal_title-container {
  width: 400px;
}
.modal-root--trial-agreement .modal_title {
  font-size: 30px;
  padding: 10px;
  color: #1a1d21;
}
.modal-root--trial-agreement .modal_exit {
  display: none;
}
.modal-root--trial-agreement .modal_subtitle {
  font-size: 16px;
  margin-bottom: 10px;
}
.modal-root--trial-agreement .modal_component {
  padding: 30px 0 40px;
}
.modal-root--trial-agreement .modal_component h2, .modal-root--trial-agreement .modal_component h3, .modal-root--trial-agreement .modal_component h4, .modal-root--trial-agreement .modal_component p, .modal-root--trial-agreement .modal_component li {
  margin-bottom: 30px;
}
.modal-root--trial-agreement .modal_component h2, .modal-root--trial-agreement .modal_component h4, .modal-root--trial-agreement .modal_component strong {
  color: #1a1d21;
}
.modal-root--trial-agreement .modal_component h2 {
  font-size: 16px;
}
.modal-root--trial-agreement .modal_component h2 span {
  display: inline-block;
}
.modal-root--trial-agreement .modal_component h4 {
  font-size: 20px;
}
.modal-root--trial-agreement .modal_component ol li {
  padding-left: 5px;
  list-style: outside lower-alpha;
}
.modal-root--trial-agreement .modal_component ol li ol li {
  list-style-type: lower-roman;
}
.modal-root--trial-agreement .modal_actions {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
