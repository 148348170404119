@import '../../resources/styles/variables';

.route-link {

  &--disabled {
    opacity: $opacity--medium;
    pointer-events: none;
  }

}
