@import '../../resources/styles/variables';
@import '../../resources/styles/utils';
@import '../../resources/styles/mixins/all';

.modal-root {
  z-index: $z-index--modal-level-one !important;

  .undelivered {
    &_title {
      padding: gux(1);
    }

    &_icon, &_title { 
      font-size: $font-size-large;
    }

    &_subheading {
      font-size: $font-size-table;
    }

    &_addresses {
      font-size: $font-size-small;
    }
  }
}
