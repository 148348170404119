@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pop-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pop-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes loading-pulse {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.report-groups {
  color: #545b62;
  overflow: hidden;
  transition: height 0.3s ease;
}
.report-groups_dropdown--secondary {
  margin-top: 5px;
}
.report-groups_dropdown--secondary .select__control {
  width: 160px;
}
.report-groups_item {
  height: 30px;
  line-height: 30px;
  padding: 0 10px 0 14px;
  border-radius: 3px;
  background-color: #ffffff;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: height;
}
.report-groups_item-wrap:hover > .report-groups_item-action--close {
  opacity: 1;
}
.report-groups_item-label {
  text-transform: uppercase;
}
.report-groups_item-icon {
  font-size: 16px;
  margin-right: 8px;
}
.report-groups_item-icon--institution, .report-groups_item-icon--shareholderid, .report-groups_item-icon--institutionSurveillance {
  color: #ec6a4c;
}
.report-groups_item-icon--fundShareholderid, .report-groups_item-icon--fundSurveillance, .report-groups_item-icon--fund {
  color: #1abc9c;
}
.report-groups_item-icon--contact {
  color: #3498db;
}
.report-groups_item-icon--activity {
  color: #aa2d40;
}
.report-groups_item-icon--stock {
  color: #6a3476;
}
.report-groups_item-action {
  opacity: 0;
  float: right;
  font-size: 8px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s ease, color 0.3s ease;
}
.report-groups_item-action i {
  vertical-align: middle;
}
.report-groups_item-action:hover {
  color: #939ba0;
}
.report-groups_item-action--active {
  color: #297ac5;
}
.report-groups_item--expanded {
  height: 70px;
}
.report-groups_item--dropdown {
  margin-bottom: 2px;
  background-color: #eeeeee;
  transition-property: background-color, height;
}
.report-groups_item--dropdown:hover {
  background-color: #e0e0e0;
}
.report-groups_children {
  padding-bottom: 10px;
}
.report-groups_child:before, .report-groups_child .report-groups_item {
  display: inline-block;
  vertical-align: top;
}
.report-groups_child:before {
  content: "";
  width: 12px;
  height: 12px;
  margin: 4px 8px 14px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-color: #dddddd;
}
.report-groups_child .report-groups_item {
  width: calc(100% - 12px - 16px);
  background-color: #eeeeee;
}
.report-groups--empty, .report-groups--once-full {
  height: 30px;
}
.report-groups--half-full {
  height: 65px;
}
.report-groups--twice-full {
  height: 70px;
}
