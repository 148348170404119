.information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.information_item {
  flex-basis: calc(100% / 2 - 10px);
}
.information_item--label {
  text-transform: uppercase;
  color: #939ba0;
}
.information_item:nth-child(n+3) {
  margin-top: 10px;
}
