/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.sidebar {
  position: relative;
  width: 340px;
  transition: width 0.3s ease;
  white-space: nowrap;
}
.sidebar:after {
  content: "";
  opacity: 1;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 10px;
  background: transparent;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.2), transparent);
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.2), transparent);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent);
  transition: opacity 0.2s ease;
}
.sidebar_inner {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar_inner .sidebar_inner-section {
  margin: 0 16px;
}
.sidebar_inner .sidebar_inner-section:not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.sidebar_inner section {
  margin: 0 16px;
  padding: 24px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.sidebar_inner section:first-of-type {
  padding: 0 0 24px;
}
.sidebar_inner section:last-of-type {
  border-bottom: none;
}
.sidebar_header, .sidebar_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.sidebar_header {
  white-space: nowrap;
}
.sidebar_header-title {
  max-width: 96%;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar_body {
  flex: 1;
}
.sidebar_footer {
  border-top: 1px solid #e0e0e0;
}
.sidebar_footer .button {
  height: 40px;
}
.sidebar_footer .button + .button {
  margin-left: 8px;
}
.sidebar--collapsed {
  width: 0;
}
.sidebar--collapsed:after {
  opacity: 0;
}
.sidebar--tabs .sidebar_header {
  padding: 0;
}
.sidebar--tabs .sidebar_header .section-tabs {
  width: 100%;
}
.sidebar--tabs .sidebar_header .section-tabs_item {
  height: 50px;
  padding: 0 16px;
  letter-spacing: 0.5px;
}
.sidebar--tabs .sidebar_header .section-tabs_reset {
  height: 100%;
  border-radius: 0;
}
.sidebar--tabs .sidebar_body {
  padding-top: 24px;
}
.sidebar--light-grey {
  background-color: #eeeeee;
}
.sidebar--light-grey .sidebar_inner section,
.sidebar--light-grey .sidebar_inner .sidebar_inner-section {
  border-bottom-color: #e0e0e0;
}
.sidebar--light-grey .sidebar_header .section-tabs .section-tabs_item--active,
.sidebar--light-grey .sidebar_header .section-tabs .section-tabs_item--active:hover {
  color: #545b62;
  background-color: #eeeeee;
}
.sidebar--light-grey .sidebar--tabs .sidebar_header {
  background-color: #eeeeee;
}
.sidebar--light-grey .sidebar_footer {
  border-top-color: #e0e0e0;
}
