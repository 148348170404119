.suggested-contacts {
  color: #545b62;
}
.suggested-contacts .card_header {
  padding: 16px;
}
.suggested-contacts .card_children {
  padding: 0;
  min-height: 300px;
}
.suggested-contacts .placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.suggested-contacts .placeholder_text {
  width: 50%;
  text-align: center;
  color: #939ba0;
  line-height: 22px;
}
.suggested-contacts .contact-list_item {
  height: 60px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background-color: transparent;
  transition: background-color 0.1s ease-in;
}
.suggested-contacts .contact-list_item_actions {
  display: none;
}
.suggested-contacts .contact-list_item_detail {
  padding-right: 8px;
  overflow: hidden;
}
.suggested-contacts .contact-list_item_detail_title, .suggested-contacts .contact-list_item_detail_subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
}
.suggested-contacts .contact-list_item_detail_subtitle {
  color: #939ba0;
  font-size: 12px;
  line-height: 22px;
}
.suggested-contacts .contact-list_item:hover {
  background-color: #eeeeee;
}
.suggested-contacts .contact-list_item:hover .contact-list_item_actions {
  animation: fade-in 0.2s ease;
  display: block;
}
.suggested-contacts .contact-list_item:nth-child(5) {
  border-bottom: none;
}
.suggested-contacts .preview {
  padding: 16px;
}
.suggested-contacts .preview_detail {
  min-height: 215px;
  color: #545b62;
  padding: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 24px;
}
.suggested-contacts .preview_detail_title {
  font-size: 20px;
}
.suggested-contacts .preview_detail_subtitle {
  color: #939ba0;
  font-size: 12px;
}
.suggested-contacts .preview_detail_label {
  color: #939ba0;
  text-transform: uppercase;
}
.suggested-contacts .preview_detail_value {
  text-overflow: ellipsis;
  overflow: hidden;
}
.suggested-contacts .preview_detail_grid {
  margin-top: 16px;
}
.suggested-contacts .preview_actions {
  text-align: right;
}
