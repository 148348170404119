@import '../../resources/styles/variables';
@import '../../resources/styles/utils';

.q4-wrapper {

  @mixin sliderTheme($railColor, $trackColor, $thumbColor, $thumbHoverColor, $thumbActiveColor) {
    color: $trackColor;

    .slider {
      &_rail {
        background-color: $railColor;
      }

      &_track {
        background-color: $trackColor;
      }

      &_thumb {
        &:hover:not(&--active) {
          background-color: $thumbHoverColor;
        }

        &--active {
          background-color: $thumbActiveColor;
        }
      }
    }
  }

  .slider {
    height: 4px;
    padding: gux(2) 0;

    &_rail,
    &_track {
      height: 4px;
      opacity: 1;
      border-radius: $border-radius-xsmall;
    }

    &_thumb {
      width: 16px;
      height: 16px;
      margin: -#{gux(0.75)} 0 0 -#{gux()};
      transition: background-color $transition-duration--default $transition-timing-function--default;

      &:hover,
      &:focus,
      &--active {
        box-shadow: none;
      }
    }

    // themes
    &--rain {
      @include sliderTheme($soft-grey, $rain, $rain, $olympic, $steel);
    }

    &--light {
      @include sliderTheme($light-grey, $rain, $rain, $olympic, $steel);
    }

    &--dark {

    }
  }
}
