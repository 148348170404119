.series-list .removable-list-item {
  overflow: visible;
}
.series-list .button {
  width: 100%;
  text-align: left;
  margin-bottom: 3px;
  color: #939ba0;
  text-transform: none;
}
.series-list .button:hover, .series-list .button:focus {
  background-color: #ffffff;
}
.series-list-item_subtitle {
  margin-bottom: 10px;
}
.series-list-item_group {
  position: relative;
}
.series-list-item_group .select-component {
  position: relative;
  margin-left: 24px;
  margin-bottom: 3px;
}
.series-list-item_group .select-component:before {
  top: 50%;
  left: -23px;
  width: 10px;
  height: 1px;
}
.series-list-item_group:before, .series-list-item_group .select-component:before {
  content: "";
  position: absolute;
  background-color: #dddddd;
}
.series-list-item_group:before {
  top: 5%;
  width: 1px;
  height: calc(95% - 14px);
}
