/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.formik-text-input {
  position: relative;
}
.formik-text-input:not(:last-child) {
  margin-bottom: 20px;
}
.formik-text-input--condensed:not(:last-child) {
  margin-bottom: 1px;
}
.formik-text-input--not-labeled:not(:last-child) {
  margin-bottom: 10px;
}
.formik-text-input--error {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.formik-text-input--error:after {
  font-family: "iris";
  content: "\e9bb";
}
.formik-text-input--error:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  background-color: #ec6a4c;
}
.formik-text-input_field {
  padding: 0 10px;
  width: 100%;
  line-height: 40px;
  min-height: 40px;
  background-color: #f1f1f1;
}
.formik-text-input_field::placeholder {
  color: #939ba0;
}
.formik-text-input_field::-webkit-input-placeholder {
  color: #939ba0;
}
.formik-text-input_field::-moz-placeholder {
  color: #939ba0;
}
.formik-text-input_field:-moz-placeholder {
  color: #939ba0;
}
.formik-text-input_field:-ms-input-placeholder {
  color: #939ba0;
}
.formik-text-input_field::-ms-input-placeholder {
  color: #939ba0;
}
.formik-text-input_field--dark-slate {
  color: #ffffff;
  background-color: #22272b;
}
.formik-text-input_field--dark-slate::placeholder {
  color: #646d75;
}
.formik-text-input_field--dark-slate::-webkit-input-placeholder {
  color: #646d75;
}
.formik-text-input_field--dark-slate::-moz-placeholder {
  color: #646d75;
}
.formik-text-input_field--dark-slate:-moz-placeholder {
  color: #646d75;
}
.formik-text-input_field--dark-slate:-ms-input-placeholder {
  color: #646d75;
}
.formik-text-input_field--dark-slate::-ms-input-placeholder {
  color: #646d75;
}
