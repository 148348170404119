@import '../../resources/styles/variables';
@import '../../resources/styles/utils';
@import '../../resources/styles/mixins/all';


.event-root {
  z-index: $z-index--modal-level-one !important;

  .event {
    width: 600px;
    max-height: 95vh;
    margin: gux(1);
    background-color: transparent;
    border-radius: $border-radius-medium;
    overflow: hidden;
    
    &_banner {
      display: flex;
      padding: gux(4) gux(4) gux(3);
      text-align: left;
      background-color: $white;
    }

    &_icon {
      font-size: $font-size-large;
      padding: gux(1) gux(2) 0 0;
    }
    
    &_title {
      font-size: $font-size-medium;
    }

    &_company {
      font-size: $font-size-small;
      margin-top: gux(1);
    }

    &_detail {
      padding: gux(4) gux(4) gux(3);
      text-align: left;
      background-color: $light-grey;

      &-date-container {
        display: flex;
        justify-content: space-between;
      }

      &-date {
        flex: 1.25 1;
      }

      &-time {
        flex: 1 1;
      }

      &-phone-container {
        display: flex;
        justify-content: space-between;
      }

      &-phone {
        flex: 1.25 1;
      }

      &-passcode {
        flex: 1 1;
      }

      &-phone-number {
        margin-left: gux(1.5);
      }

      &-icon {
        margin: gux(1);
      }

      &-heading{
        font-size: $font-size-medium;
      }
    }

    &_exit {
      position: absolute;
      top: 12px;
      right: 12px;
      font-size: 12px;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: $ghost;
      }
    }
  }

}