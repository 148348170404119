@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/mixins/all';
@import '../../../../resources/styles/utils';

$targeting-toolbar-height: gux(8.75);
$targeting-banner-height: gux(16.5);

.targeting-page_tabs_tab--saved {
  position: relative;

  .targeting-page-tab_body {
    flex: 1;
    position: relative;
    min-height: calc(100vh - #{$app-header-height} - #{$targeting-banner-height});
    background-color: $white;
    overflow: hidden;
    .spinner {
      &, &_mask {
        z-index: $z-index--level-two;
      }
    }
  }
}
