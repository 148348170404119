.report-builder {
  background-color: #2a3035;
  animation: fade-in 0.3s ease;
  overflow: hidden;
}
.report-builder_body {
  position: relative;
  display: flex;
  height: calc(100vh - 80px - 80px);
  min-height: 575px;
  background-color: #ffffff;
  transition: height 0.3s ease;
}
.report-builder .banner {
  transition: margin 0.3s ease;
}
.report-builder--no-banner .report-builder_body, .report-builder--fullscreen .report-builder_body {
  height: calc(100vh - 80px);
}
.report-builder--no-banner .banner {
  margin-top: -80px;
}
.report-builder--no-banner.report-builder--fullscreen .report-builder_body {
  height: 100vh;
}
