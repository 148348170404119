/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  padding: 16px 32px;
  background-color: #ffffff;
  animation: fade-in 0.3s ease;
}
.section-header_title {
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-header_annotation {
  margin-top: 4px;
  color: #858e93;
}
.section-header_annotation i {
  margin-right: 4px;
  font-size: 10px;
}
.section-header_annotation span + span {
  margin-left: 10px;
}
.section-header_component {
  display: flex;
  align-items: center;
}
.section-header_component > * {
  margin-left: 10px;
}
.section-header--bordered {
  border-top: 1px solid #e0e0e0;
}
.section-header--thin {
  height: 64px;
}
.section-header--rain {
  background-color: #297ac5;
}
.section-header--rain .section-header_title {
  color: #ffffff;
}
.section-header--rain .section-header_annotation {
  color: #ffffff;
}
