@font-face {
  font-family: "Open Sans";
  src: url("../fonts/openSans/OpenSans-Light-webfont.eot");
  src: url("../fonts/openSans/OpenSans-Light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/openSans/OpenSans-Light-webfont.woff") format("woff"), url("../fonts/openSans/OpenSans-Light-webfont.ttf") format("truetype"), url("../fonts/openSans/OpenSans-Light-webfont.svg#open_sanslight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/openSans/OpenSans-Regular-webfont.eot");
  src: url("../fonts/openSans/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/openSans/OpenSans-Regular-webfont.woff") format("woff"), url("../fonts/openSans/OpenSans-Regular-webfont.ttf") format("truetype"), url("../fonts/openSans/OpenSans-Regular-webfont.svg#open_sansregular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/openSans/OpenSans-Italic-webfont.eot");
  src: url("../fonts/openSans/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/openSans/OpenSans-Italic-webfont.woff") format("woff"), url("../fonts/openSans/OpenSans-Italic-webfont.ttf") format("truetype"), url("../fonts/openSans/OpenSans-Italic-webfont.svg#open_sansitalic") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/openSans/OpenSans-SemiboldItalic-webfont.eot");
  src: url("../fonts/openSans/OpenSans-SemiboldItalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/openSans/OpenSans-SemiboldItalic-webfont.woff") format("woff"), url("../fonts/openSans/OpenSans-SemiboldItalic-webfont.ttf") format("truetype"), url("../fonts/openSans/OpenSans-SemiboldItalic-webfont.svg#open_sansitalic") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/openSans/OpenSans-Semibold-webfont.eot");
  src: url("../fonts/openSans/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/openSans/OpenSans-Semibold-webfont.woff") format("woff"), url("../fonts/openSans/OpenSans-Semibold-webfont.ttf") format("truetype"), url("../fonts/openSans/OpenSans-Semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/openSans/OpenSans-Bold-webfont.eot");
  src: url("../fonts/openSans/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/openSans/OpenSans-Bold-webfont.woff") format("woff"), url("../fonts/openSans/OpenSans-Bold-webfont.ttf") format("truetype"), url("../fonts/openSans/OpenSans-Bold-webfont.svg#open_sansbold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "iris";
  src: url("../fonts/icons/iris.woff2?h6xksg") format("woff2"), url("../fonts/icons/iris.ttf?h6xksg") format("truetype"), url("../fonts/icons/iris.woff?h6xksg") format("woff"), url("../fonts/icons/iris.svg?h6xksg#iris") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=q4i-], [class*=" q4i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iris" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.q4i-insider-institution-2pt:before {
  content: "\e9a4";
}

.q4i-insider-institution-4pt:before {
  content: "\e9a9";
}

.q4i-arrow-down-4pt:before {
  content: "\e995";
}

.q4i-arrow-left-4pt:before {
  content: "\e997";
}

.q4i-arrow-right-4pt:before {
  content: "\e9a1";
}

.q4i-arrow-up-4pt:before {
  content: "\e9a2";
}

.q4i-insight:before {
  content: "\e994";
}

.q4i-essential:before {
  content: "\e996";
}

.q4i-website-analytics-4pt:before {
  content: "\e993";
}

.q4i-contact-list-4pt:before {
  content: "\e992";
}

.q4i-sync-or-fetch-2pt:before {
  content: "\e93f";
}

.q4i-sync-or-fetch-4pt:before {
  content: "\e991";
}

.q4i-savedtargets-2pt:before {
  content: "\e98f";
}

.q4i-savedtargets-4pt:before {
  content: "\e990";
}

.q4i-combo-chart-2pt:before {
  content: "\e98d";
}

.q4i-combo-chart-4pt:before {
  content: "\e98e";
}

.q4i-pivot-4pt:before {
  content: "\e986";
}

.q4i-pivot-2pt:before {
  content: "\e98c";
}

.q4i-clone-duplicate-2pt:before {
  content: "\e97a";
}

.q4i-clone-duplicate-4pt:before {
  content: "\e97c";
}

.q4i-stock-2pt:before {
  content: "\e977";
}

.q4i-stock-4pt:before {
  content: "\e979";
}

.q4i-editor-italic-4pt:before {
  content: "\e976";
}

.q4i-editor-align-center-4pt:before {
  content: "\e95d";
}

.q4i-editor-align-left-4pt:before {
  content: "\e95e";
}

.q4i-editor-align-right-4pt:before {
  content: "\e962";
}

.q4i-editor-bold-4pt:before {
  content: "\e963";
}

.q4i-editor-indent-in-4pt:before {
  content: "\e964";
}

.q4i-editor-indent-out-4pt:before {
  content: "\e966";
}

.q4i-editor-link2-4pt:before {
  content: "\e968";
}

.q4i-editor-list-bullet-4pt:before {
  content: "\e96a";
}

.q4i-editor-list-number-4pt:before {
  content: "\e971";
}

.q4i-editor-underline-4pt:before {
  content: "\e973";
}

.q4i-display-density-2pt:before {
  content: "\e959";
}

.q4i-display-density-4pt:before {
  content: "\e95a";
}

.q4i-earnings-2pt:before {
  content: "\e947";
}

.q4i-earnings-4pt:before {
  content: "\e951";
}

.q4i-hamburger-q4inc-4pt:before {
  content: "\e936";
}

.q4i-report-blank-1pt:before {
  content: "\e937";
}

.q4i-report-blank-2pt:before {
  content: "\e938";
}

.q4i-report-blank-4pt:before {
  content: "\e939";
}

.q4i-ownership-1pt:before {
  content: "\e93a";
}

.q4i-filter-4pt:before {
  content: "\e935";
}

.q4i-presentation-4pt:before {
  content: "\e933";
}

.q4i-webcast-4pt:before {
  content: "\e934";
}

.q4i-xls-1pt:before {
  content: "\e930";
}

.q4i-xls-2pt:before {
  content: "\e931";
}

.q4i-xls-4pt:before {
  content: "\e932";
}

.q4i-funnel-filter-1pt:before {
  content: "\e92d";
}

.q4i-funnel-filter-2pt:before {
  content: "\e92e";
}

.q4i-funnel-filter-4pt:before {
  content: "\e92f";
}

.q4i-search-1pt:before {
  content: "\e92b";
}

.q4i-search-4pt:before {
  content: "\e92c";
}

.q4i-pdf-1pt:before {
  content: "\e926";
}

.q4i-pdf-2pt:before {
  content: "\e927";
}

.q4i-pdf-4pt:before {
  content: "\e928";
}

.q4i-table-1pt:before {
  content: "\e929";
}

.q4i-table-4pt:before {
  content: "\e92a";
}

.q4i-activity-1pt:before {
  content: "\e924";
}

.q4i-notifications-1pt:before {
  content: "\e925";
}

.q4i-generate-1pt:before {
  content: "\e900";
}

.q4i-generate-2pt:before {
  content: "\e901";
}

.q4i-generate-4pt:before {
  content: "\e902";
}

.q4i-request-2pt:before {
  content: "\e903";
}

.q4i-request-4pt:before {
  content: "\e904";
}

.q4i-position-1pt:before {
  content: "\e905";
}

.q4i-position-2pt:before {
  content: "\e906";
}

.q4i-position-4pt:before {
  content: "\e907";
}

.q4i-custom-4pt:before {
  content: "\e908";
}

.q4i-target-1pt:before {
  content: "\e909";
}

.q4i-target-2pt:before {
  content: "\e90a";
}

.q4i-target-4pt:before {
  content: "\e90b";
}

.q4i-targeting-4pt:before {
  content: "\e90c";
}

.q4i-lock-4pt:before {
  content: "\e90d";
}

.q4i-nodata-4pt:before {
  content: "\e90e";
}

.q4i-notifications-4pt:before {
  content: "\e90f";
}

.q4i-releases-4pt:before {
  content: "\e910";
}

.q4i-stage-1pt:before {
  content: "\e911";
}

.q4i-stage-2pt:before {
  content: "\e912";
}

.q4i-stage-4pt:before {
  content: "\e913";
}

.q4i-transcript-4pt:before {
  content: "\e914";
}

.q4i-insider-4pt:before {
  content: "\e915";
}

.q4i-press-releases-4pt:before {
  content: "\e916";
}

.q4i-iris-logo:before {
  content: "\e917";
}

.q4i-swap-2pt:before {
  content: "\e918";
}

.q4i-swap-4pt:before {
  content: "\e919";
}

.q4i-scale-1pt:before {
  content: "\e91a";
}

.q4i-scale-2pt:before {
  content: "\e91b";
}

.q4i-scale-4pt:before {
  content: "\e91c";
}

.q4i-fullscreen-exit-1pt:before {
  content: "\e91d";
}

.q4i-fullscreen-exit-2pt:before {
  content: "\e91e";
}

.q4i-fullscreen-exit-4pt:before {
  content: "\e91f";
}

.q4i-volatility-4pt:before {
  content: "\e920";
}

.q4i-sentiment-4pt:before {
  content: "\e921";
}

.q4i-question-mark-4pt:before {
  content: "\e922";
}

.q4i-star-2pt:before {
  content: "\e9ab";
}

.q4i-star-fill:before {
  content: "\e923";
}

.q4i-security-mapping-2pt:before {
  content: "\e93b";
}

.q4i-move-to-list-4pt:before {
  content: "\e93c";
}

.q4i-book-2pt:before {
  content: "\e93d";
}

.q4i-mobile-1pt:before {
  content: "\e93e";
}

.q4i-rss-4pt:before {
  content: "\e940";
}

.q4i-feedback-2pt:before {
  content: "\e941";
}

.q4i-price-4pt:before {
  content: "\e942";
}

.q4i-rating-4pt:before {
  content: "\e943";
}

.q4i-price-2pt:before {
  content: "\e944";
}

.q4i-rating-2pt:before {
  content: "\e945";
}

.q4i-insider-2pt:before {
  content: "\e946";
}

.q4i-buyers-2pt:before {
  content: "\e948";
}

.q4i-sellers-2pt:before {
  content: "\e949";
}

.q4i-reportclosed-4pt:before {
  content: "\e94a";
}

.q4i-reportno-4pt:before {
  content: "\e94b";
}

.q4i-reportopen-4pt:before {
  content: "\e94c";
}

.q4i-research-2pt:before {
  content: "\e94d";
}

.q4i-arrowdouble-sm-left-4pt:before {
  content: "\e94e";
}

.q4i-arrowdouble-sm-right-4pt:before {
  content: "\e94f";
}

.q4i-sync-2pt:before {
  content: "\e950";
}

.q4i-sync-4pt:before {
  content: "\e952";
}

.q4i-mail-4pt:before {
  content: "\e953";
}

.q4i-phone-4pt:before {
  content: "\e954";
}

.q4i-edit-4pt:before {
  content: "\e955";
}

.q4i-desktop-1pt:before {
  content: "\e956";
}

.q4i-tablet-2pt:before {
  content: "\e957";
}

.q4i-tablet-1pt:before {
  content: "\e958";
}

.q4i-session-2pt:before {
  content: "\e95b";
}

.q4i-session-1pt:before {
  content: "\e95c";
}

.q4i-rate-2pt:before {
  content: "\e95f";
}

.q4i-rate-1pt:before {
  content: "\e960";
}

.q4i-pages-session-2pt:before {
  content: "\e961";
}

.q4i-pages-session-1pt:before {
  content: "\e965";
}

.q4i-new-user-2pt:before {
  content: "\e967";
}

.q4i-new-user-1pt:before {
  content: "\e969";
}

.q4i-new-session-2pt:before {
  content: "\e96b";
}

.q4i-new-session-1pt:before {
  content: "\e96c";
}

.q4i-contact-1pt:before {
  content: "\e96d";
}

.q4i-savedsearches-2pt:before {
  content: "\e96e";
}

.q4i-targeting-2pt:before {
  content: "\e96f";
}

.q4i-stop-watch-4pt:before {
  content: "\e970";
}

.q4i-longarrow-down-2pt:before {
  content: "\e972";
}

.q4i-longarrow-up-2pt:before {
  content: "\e974";
}

.q4i-info-fill:before {
  content: "\e975";
}

.q4i-noresults-2pt:before {
  content: "\e978";
}

.q4i-noresults-4pt:before {
  content: "\e97b";
}

.q4i-nodata-2pt:before {
  content: "\e97e";
}

.q4i-trashbin-4pt:before {
  content: "\e980";
}

.q4i-flexible-2pt:before {
  content: "\e981";
}

.q4i-plus-4pt:before {
  content: "\e982";
}

.q4i-minus-4pt:before {
  content: "\e983";
}

.q4i-checkmark-4pt:before {
  content: "\e984";
}

.q4i-surveillance-2pt:before {
  content: "\e985";
}

.q4i-activist-4pt:before {
  content: "\e987";
}

.q4i-ownership-2pt:before {
  content: "\e988";
}

.q4i-watchlist-2pt:before {
  content: "\e989";
}

.q4i-support-2pt:before {
  content: "\e98a";
}

.q4i-presentation-2pt:before {
  content: "\e98b";
}

.q4i-bookmark-2pt:before {
  content: "\e998";
}

.q4i-export-4pt:before {
  content: "\e999";
}

.q4i-logout-2pt:before {
  content: "\e99a";
}

.q4i-download-2pt:before {
  content: "\e99b";
}

.q4i-download-4pt:before {
  content: "\e99c";
}

.q4i-more-4pt:before {
  content: "\e99d";
}

.q4i-time-1pt:before {
  content: "\e99e";
}

.q4i-time-2pt:before {
  content: "\e99f";
}

.q4i-book-4pt:before {
  content: "\e9a0";
}

.q4i-roadshow-2pt:before {
  content: "\e9a3";
}

.q4i-collection-2pt:before {
  content: "\e9a5";
}

.q4i-collection-4pt:before {
  content: "\e9a6";
}

.q4i-userprofile-2pt:before {
  content: "\e9a7";
}

.q4i-ticker-2pt:before {
  content: "\e9a8";
}

.q4i-add-to-list-4pt:before {
  content: "\e9aa";
}

.q4i-review-undomove-4pt:before {
  content: "\e9ac";
}

.q4i-review-archive-4pt:before {
  content: "\e9ad";
}

.q4i-caret-sm-left-2pt:before {
  content: "\e9af";
}

.q4i-custom-2pt:before {
  content: "\e9b0";
}

.q4i-add-to-calendar-4pt:before {
  content: "\e9b3";
}

.q4i-filter-2pt:before {
  content: "\e9b4";
}

.q4i-conference-2pt:before {
  content: "\e9b6";
}

.q4i-venue-2pt:before {
  content: "\e9b7";
}

.q4i-undo-4pt:before {
  content: "\e9b9";
}

.q4i-map-marker-4pt:before {
  content: "\e9ba";
}

.q4i-warning-4pt:before {
  content: "\e9bb";
}

.q4i-security-4pt:before {
  content: "\e9be";
}

.q4i-ownership-4pt:before {
  content: "\e9bf";
}

.q4i-shareholder-id-2pt:before {
  content: "\e9c2";
}

.q4i-activity-4pt:before {
  content: "\e9c4";
}

.q4i-conference-4pt:before {
  content: "\e9c5";
}

.q4i-meeting-4pt:before {
  content: "\e9c6";
}

.q4i-note-4pt:before {
  content: "\e9c7";
}

.q4i-roadshow-4pt:before {
  content: "\e9c8";
}

.q4i-itinerary-1pt:before {
  content: "\e9c9";
}

.q4i-itinerary-2pt:before {
  content: "\e9ca";
}

.q4i-itinerary-4pt:before {
  content: "\e9cb";
}

.q4i-contact-4pt:before {
  content: "\e9cd";
}

.q4i-fund-4pt:before {
  content: "\e9ce";
}

.q4i-deal-1pt:before {
  content: "\e9cf";
}

.q4i-deal-2pt:before {
  content: "\e9d0";
}

.q4i-deal-4pt:before {
  content: "\e9d1";
}

.q4i-institution-4pt:before {
  content: "\e9d2";
}

.q4i-activity-calendar-2pt:before {
  content: "\e9d3";
}

.q4i-website-2pt:before {
  content: "\e9d4";
}

.q4i-cog-4pt:before {
  content: "\e9d5";
}

.q4i-trashbin-2pt:before {
  content: "\e9d6";
}

.q4i-suggest-an-edit-2pt:before {
  content: "\e9d7";
}

.q4i-utility-2pt:before {
  content: "\e9d8";
}

.q4i-contact-list-2pt:before {
  content: "\e9d9";
}

.q4i-morning-star-data-2pt:before {
  content: "\e9da";
}

.q4i-eod-reports-2pt:before {
  content: "\e9db";
}

.q4i-link-4pt:before {
  content: "\e9dc";
}

.q4i-html-2pt:before {
  content: "\e9dd";
}

.q4i-table-2pt:before {
  content: "\e9de";
}

.q4i-mobile-2pt:before {
  content: "\e9df";
}

.q4i-estimates-research-2pt:before {
  content: "\e9e0";
}

.q4i-print-2pt:before {
  content: "\e9e1";
}

.q4i-piechart-2pt:before {
  content: "\e9e2";
}

.q4i-lightbulb-1pt:before {
  content: "\e9e3";
}

.q4i-free-1pt:before {
  content: "\e9e4";
}

.q4i-data-2pt:before {
  content: "\e9e5";
}

.q4i-data-1pt:before {
  content: "\e9e6";
}

.q4i-customer-1pt:before {
  content: "\e9e7";
}

.q4i-closed-1pt:before {
  content: "\e9e8";
}

.q4i-ai-2pt:before {
  content: "\e9e9";
}

.q4i-ai-1pt:before {
  content: "\e9ea";
}

.q4i-volatility-2pt:before {
  content: "\e9eb";
}

.q4i-upcoming-events-2pt:before {
  content: "\e9ec";
}

.q4i-relative-performance-2pt:before {
  content: "\e9ed";
}

.q4i-past-events-2pt:before {
  content: "\e9ee";
}

.q4i-notifications-2pt:before {
  content: "\e9ef";
}

.q4i-note-2pt:before {
  content: "\e9f0";
}

.q4i-map-marker-2pt:before {
  content: "\e9f1";
}

.q4i-expected-trading-range-2pt:before {
  content: "\e9f2";
}

.q4i-admin-2pt:before {
  content: "\e9f3";
}

.q4i-activist-2pt:before {
  content: "\e9f4";
}

.q4i-lock-2pt:before {
  content: "\e9f5";
}

.q4i-fullscreen-4pt:before {
  content: "\e9f6";
}

.q4i-calendar-4pt:before {
  content: "\e9f7";
}

.q4i-add-4pt:before {
  content: "\e9f8";
}

.q4i-add-2pt:before {
  content: "\e9f9";
}

.q4i-time-4pt:before {
  content: "\e9fa";
}

.q4i-edit-2pt:before {
  content: "\e9fb";
}

.q4i-cog-2pt:before {
  content: "\e9fc";
}

.q4i-question-mark-2pt:before {
  content: "\e9fd";
}

.q4i-sec-2pt:before {
  content: "\e9fe";
}

.q4i-fund-2pt:before {
  content: "\e9ff";
}

.q4i-institution-2pt:before {
  content: "\ea00";
}

.q4i-press-releases-2pt:before {
  content: "\ea01";
}

.q4i-releases-2pt:before {
  content: "\ea02";
}

.q4i-transcript-2pt:before {
  content: "\ea03";
}

.q4i-caret-sm-left-4pt:before {
  content: "\ea04";
}

.q4i-caret-sm-down-4pt:before {
  content: "\ea05";
}

.q4i-caret-sm-right-4pt:before {
  content: "\ea06";
}

.q4i-caret-sm-up-4pt:before {
  content: "\ea07";
}

.q4i-mail-2pt:before {
  content: "\ea08";
}

.q4i-meeting-2pt:before {
  content: "\ea09";
}

.q4i-chat-2pt:before {
  content: "\ea0a";
}

.q4i-phone-2pt:before {
  content: "\ea0b";
}

.q4i-hamburger-desktop-2pt:before {
  content: "\ea0c";
}

.q4i-chart-4pt:before {
  content: "\e97d";
}

.q4i-chart-2pt:before {
  content: "\ea0d";
}

.q4i-list-2pt:before {
  content: "\ea0e";
}

.q4i-checklist-2pt:before {
  content: "\ea0f";
}

.q4i-team-2pt:before {
  content: "\ea10";
}

.q4i-sentiment-2pt:before {
  content: "\ea11";
}

.q4i-webcast-2pt:before {
  content: "\ea12";
}

.q4i-website-analytics-2pt:before {
  content: "\ea13";
}

.q4i-activity-2pt:before {
  content: "\ea14";
}

.q4i-security-2pt:before {
  content: "\ea15";
}

.q4i-contact-2pt:before {
  content: "\ea16";
}

.q4i-dashboard-2pt:before {
  content: "\ea17";
}

.q4i-events-transcripts-2pt:before {
  content: "\ea18";
}

.q4i-reports-4pt:before {
  content: "\e97f";
}

.q4i-reports-2pt:before {
  content: "\ea19";
}

.q4i-calculator-2pt:before {
  content: "\ea1a";
}

.q4i-stop-watch-2pt:before {
  content: "\ea1b";
}

.q4i-lightbulb-2pt:before {
  content: "\ea1c";
}

.q4i-free-2pt:before {
  content: "\ea1d";
}

.q4i-postchat-2pt:before {
  content: "\ea1e";
}

.q4i-sort-2pt:before {
  content: "\ea1f";
}

.q4i-coffee-2pt:before {
  content: "\ea20";
}

.q4i-growth-2pt:before {
  content: "\ea21";
}

.q4i-open-1pt:before {
  content: "\ea22";
}

.q4i-resolved-1pt:before {
  content: "\ea23";
}

.q4i-total-tickets-1pt:before {
  content: "\ea24";
}

.q4i-desktop-2pt:before {
  content: "\ea25";
}

.q4i-customer-2pt:before {
  content: "\ea26";
}

.q4i-employee-2pt:before {
  content: "\ea27";
}

.q4i-blog-2pt:before {
  content: "\ea28";
}

.q4i-blog-fill:before {
  content: "\ea29";
}

.q4i-moustache-2pt:before {
  content: "\ea2a";
}

.q4i-checkmark-2pt:before {
  content: "\ea2b";
}

.q4i-arrow-down-2pt:before {
  content: "\ea2c";
}

.q4i-arrow-left-2pt:before {
  content: "\ea2d";
}

.q4i-arrow-right-2pt:before {
  content: "\ea2e";
}

.q4i-arrow-up-2pt:before {
  content: "\ea33";
}

.q4i-close-2pt:before {
  content: "\ea34";
}

.q4i-close-4pt:before {
  content: "\ea35";
}

.q4i-link-2pt:before {
  content: "\ea36";
}

.q4i-play-2pt:before {
  content: "\ea37";
}

.q4i-drag-drop-2pt:before {
  content: "\ea38";
}

.q4i-hamburger-q4inc-2pt:before {
  content: "\ea39";
}

.q4i-print-4pt:before {
  content: "\ea3a";
}

.q4i-search-2pt:before {
  content: "\ea3b";
}

.q4i-logo:before {
  content: "\ea3c";
}

.q4i-nrhwnjehsd:before {
  content: "\e9ae";
}

.q4i-utility-4pt:before {
  content: "\ebbd";
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pop-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pop-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes loading-pulse {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.3;
  color: #545b62;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 1280px;
  background-color: #2a3035;
  background-size: cover;
  overflow: hidden;
}

h1, h2, h3, h4 {
  margin: 0;
  font-weight: 400;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #4696E0;
  text-decoration: none;
  transition: color 0.3s;
}

input, textarea, button {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

input,
textarea {
  border: none;
  outline: none;
}

button {
  border: none;
}
button:focus {
  outline: 0;
}

.hidden {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

::-ms-clear {
  display: none;
}

@keyframes fadein-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout-opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout-opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.q4-fade-in {
  -moz-animation-name: fadein-opacity;
  -o-animation-name: fadein-opacity;
  -ms-animation-name: fadein-opacity;
  -webkit-animation-name: fadein-opacity;
  animation-name: fadein-opacity;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  -ms-animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.q4-fade-in--transparent {
  opacity: 0;
}

.q4-fade-out {
  -moz-animation-name: fadeout-opacity;
  -o-animation-name: fadeout-opacity;
  -ms-animation-name: fadeout-opacity;
  -webkit-animation-name: fadeout-opacity;
  animation-name: fadeout-opacity;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  -ms-animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.grid--sidebar {
  display: flex;
}
.grid--sidebar .grid_body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.grid--sidebar .grid_table {
  flex: 1;
}
.grid_toolbar-dropdown--small {
  width: 140px;
}
.grid_toolbar-dropdown--large {
  width: 220px;
}
.grid_table {
  position: relative;
  z-index: 0;
  background-color: #ffffff;
}
.grid_table .cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}
.grid_table .cell--column {
  flex-direction: column;
  align-items: flex-end;
}
.grid_table .cell_category {
  position: relative;
  top: 4px;
  font-size: 20px;
  color: #297ac5;
}
.grid_table .cell_icon {
  padding-right: 12px;
  font-size: 18px;
  color: #297ac5;
}
.grid_table .cell_icon--fund {
  color: #1abc9c;
}
.grid_table .cell_icon--institution {
  color: #ec6a4c;
}
.grid_table .cell_icon--security {
  color: #dc9e27;
}
.grid_table .cell_content {
  flex: 1;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid_table .cell_content-name, .grid_table .cell_content-detail {
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid_table .cell_content-name--symbol, .grid_table .cell_content-detail--symbol {
  padding-right: 8px;
  font-weight: 600;
}
.grid_table .cell_content-detail {
  color: #939ba0;
}
.grid_table .cell_content--link {
  cursor: pointer;
}
.grid_table .cell_content--link a {
  color: inherit;
}
.grid_table .cell--disabled {
  color: #939ba0;
}
.grid_table .cell--non-clickable {
  cursor: default;
}
.grid_table .cell_indicator {
  padding: 0 0 0 8px;
  font-size: 18px;
  color: #dddddd;
}
.grid_table .cell_indicator:last-of-type {
  padding: 0 8px;
}
.grid_table .cell_indicator--target {
  color: #1abc9c;
}
.grid_table .cell_indicator--deal {
  color: #939ba0;
}
.grid_table .cell_indicator--activist {
  color: #ec6a4c;
}
.grid_table .cell_space {
  width: 22px;
}
.grid_table .cell_toggle {
  width: 22px;
  height: 22px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
}
.grid_table .cell_toggle--open {
  transform: rotate(-180deg);
}
.grid_table .cell_toggle:hover {
  background-color: #eeeeee;
}
.grid_table .cell_space {
  width: 22px;
  line-height: 48px;
}
.grid_table .cell_drag {
  font-size: 20px;
}

/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.chart-general g.highcharts-tooltip {
  display: none;
}
.chart-general .chart-tip {
  padding: 8px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  background-color: #ffffff;
  border-radius: 3px;
}
.chart-general .chart-tip_header {
  padding-bottom: 4px;
  font-size: 13px;
  color: #297ac5;
}
.chart-general .chart-tip_body {
  display: flex;
  font-size: 13px;
  line-height: 16px;
}
.chart-general .chart-tip_circle, .chart-general .chart-tip_name {
  margin-right: 4px;
}
.chart-general .chart-tip_circle {
  font-size: 16px;
  line-height: 16px;
}
.chart-general .chart-tip_name {
  color: #939ba0;
}
.chart-general .chart-tip_value {
  color: #545b62;
}
.chart-general .chart-tip--sized {
  width: 200px;
}
.chart-general .chart-tip--sized .chart-tip_body {
  white-space: normal;
}

/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.tooltip {
  position: absolute;
  font-size: 13px;
  line-height: 30px;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 14px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
  pointer-events: none;
  z-index: 15;
  background-color: #f1af0f;
  color: #22272b;
}
.tooltip, .tooltip--top, .tooltip--top-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-left-radius: 0;
  left: 0;
  bottom: calc(100% + 14px);
}
.tooltip:before, .tooltip--top:before, .tooltip--top-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip:before, .tooltip--top:before, .tooltip--top-left:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #dc9e27;
  left: 0;
  border-right-color: #dc9e27;
}
.tooltip--top-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-right-radius: 0;
  right: 0;
  bottom: calc(100% + 14px);
}
.tooltip--top-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--top-right:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #dc9e27;
  right: 0;
  border-left-color: #dc9e27;
}
.tooltip--bottom-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
  left: 0;
  top: calc(100% + 14px);
}
.tooltip--bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--bottom-left:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #dc9e27;
  left: 0;
  border-right-color: #dc9e27;
}
.tooltip--bottom, .tooltip--bottom-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
  right: 0;
  top: calc(100% + 14px);
}
.tooltip--bottom:before, .tooltip--bottom-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--bottom:before, .tooltip--bottom-right:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #dc9e27;
  right: 0;
  border-left-color: #dc9e27;
}
.tooltip--right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  left: calc(100% + 14px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tooltip--right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--right:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #dc9e27;
}
.tooltip--left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  right: calc(100% + 14px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tooltip--left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--left:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  left: 100%;
  border-left-color: #dc9e27;
}
.tooltip--right, .tooltip--left {
  top: calc(50% - 15px);
  white-space: nowrap;
}
.tooltip--error {
  text-transform: none;
  background-color: #ec6a4c;
  color: #ffffff;
}
.tooltip--error, .tooltip--error--top, .tooltip--error--top-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-left-radius: 0;
  left: 0;
  bottom: calc(100% + 14px);
}
.tooltip--error:before, .tooltip--error--top:before, .tooltip--error--top-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--error:before, .tooltip--error--top:before, .tooltip--error--top-left:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #d95608;
  left: 0;
  border-right-color: #d95608;
}
.tooltip--error--top-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-right-radius: 0;
  right: 0;
  bottom: calc(100% + 14px);
}
.tooltip--error--top-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--error--top-right:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #d95608;
  right: 0;
  border-left-color: #d95608;
}
.tooltip--error--bottom-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
  left: 0;
  top: calc(100% + 14px);
}
.tooltip--error--bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--error--bottom-left:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #d95608;
  left: 0;
  border-right-color: #d95608;
}
.tooltip--error--bottom, .tooltip--error--bottom-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
  right: 0;
  top: calc(100% + 14px);
}
.tooltip--error--bottom:before, .tooltip--error--bottom-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--error--bottom:before, .tooltip--error--bottom-right:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #d95608;
  right: 0;
  border-left-color: #d95608;
}
.tooltip--error--right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  left: calc(100% + 14px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tooltip--error--right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--error--right:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #d95608;
}
.tooltip--error--left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  right: calc(100% + 14px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tooltip--error--left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--error--left:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  left: 100%;
  border-left-color: #d95608;
}
.tooltip--error--right, .tooltip--error--left {
  top: calc(50% - 15px);
  white-space: nowrap;
}
.tooltip--error--right {
  left: 100%;
}
.tooltip--error_textarea--right {
  top: auto;
}
.tooltip--white {
  background-color: #ffffff;
  color: #2a3035;
}
.tooltip--white, .tooltip--white--top, .tooltip--white--top-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-left-radius: 0;
  left: 0;
  bottom: calc(100% + 14px);
}
.tooltip--white:before, .tooltip--white--top:before, .tooltip--white--top-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--white:before, .tooltip--white--top:before, .tooltip--white--top-left:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #f1f1f1;
  left: 0;
  border-right-color: #f1f1f1;
}
.tooltip--white--top-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-bottom-right-radius: 0;
  right: 0;
  bottom: calc(100% + 14px);
}
.tooltip--white--top-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--white--top-right:before {
  border-width: 5px 10px;
  top: 100%;
  border-top-color: #f1f1f1;
  right: 0;
  border-left-color: #f1f1f1;
}
.tooltip--white--bottom-left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-left-radius: 0;
  left: 0;
  top: calc(100% + 14px);
}
.tooltip--white--bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--white--bottom-left:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #f1f1f1;
  left: 0;
  border-right-color: #f1f1f1;
}
.tooltip--white--bottom, .tooltip--white--bottom-right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-top-right-radius: 0;
  right: 0;
  top: calc(100% + 14px);
}
.tooltip--white--bottom:before, .tooltip--white--bottom-right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--white--bottom:before, .tooltip--white--bottom-right:before {
  border-width: 5px 10px;
  bottom: 100%;
  border-bottom-color: #f1f1f1;
  right: 0;
  border-left-color: #f1f1f1;
}
.tooltip--white--right {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  left: calc(100% + 14px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tooltip--white--right:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--white--right:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  right: 100%;
  border-right-color: #f1f1f1;
}
.tooltip--white--left {
  border-radius: 3px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  right: calc(100% + 14px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tooltip--white--left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border: 0 solid transparent;
}
.tooltip--white--left:before {
  border-width: 15px 10px;
  top: calc(50% - 15px);
  left: 100%;
  border-left-color: #f1f1f1;
}
.tooltip--white--right, .tooltip--white--left {
  top: calc(50% - 15px);
  white-space: nowrap;
}
.tooltip_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 0 14px;
  background-color: #f1af0f;
  line-height: 40px;
}
.tooltip_header-icon {
  margin-right: 10px;
}
.tooltip_content {
  padding: 14px 14px 10px;
  line-height: 1.3;
  background-color: #ffffff;
  border-radius: 0 0 3px 3px;
  white-space: normal;
}
.tooltip_guide-button {
  padding: 0;
  line-height: 30px;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
}
.tooltip--no-arrow {
  border-radius: 3px !important;
}
.tooltip--no-arrow:before {
  display: none;
}
.tooltip--action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 200px;
  padding: 14px;
}
.tooltip--action .tooltip_info, .tooltip--action .tooltip_link {
  font-size: 13px;
  line-height: 20px;
  text-transform: none;
}
.tooltip--action .tooltip_link {
  margin-top: 10px;
}
.tooltip--action .tooltip_link:hover {
  color: #297ac5;
}
.tooltip--action .tooltip_button {
  margin-left: 10px;
}
.tooltip--guide, .tooltip--info {
  padding: 0;
  pointer-events: inherit;
  text-transform: none;
}
.tooltip--guide {
  width: 240px;
}
.tooltip--info {
  width: 270px;
  white-space: normal;
}
.tooltip--info .tooltip_header {
  padding: 14px;
  line-height: 1.3;
}
.tooltip--info .tooltip_content {
  padding: 10px 14px;
  line-height: 1.5;
}

.button {
  display: inline-block;
  opacity: 1;
  border-radius: 3px;
  padding: 0 16px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease;
  cursor: pointer;
  background-color: #22272b;
  color: #f1af0f;
}
.button:hover, .button:focus, .button.button--active {
  background: #f1af0f;
  color: #22272b;
}
.button.button--loading:focus {
  background: #22272b;
}
.button.button--loading:before {
  border-color: rgba(241, 175, 15, 0.3);
}
.button:disabled, .button.button--disabled {
  background: #22272b;
  color: rgba(241, 175, 15, 0.5);
  opacity: 0.5;
}
.button ~ .button:not(.button--hidden) {
  margin-left: 8px;
}
.button:hover .tooltip {
  display: block;
}
.button_icon, .button_label {
  pointer-events: none;
}
.button_icon {
  margin-right: 8px;
  line-height: 32px;
}
.button--square {
  position: relative;
  padding: 0;
  width: 32px;
}
.button--square .button_icon {
  margin-right: 0;
}
.button--square .tooltip {
  display: none;
}
.button--tall {
  height: 40px;
}
.button--tall, .button--tall .button_icon {
  line-height: 40px;
}
.button--tall.button--square {
  width: 40px;
}
.button--tall.button--square .button_icon {
  font-size: 16px;
}
.button--wide {
  min-width: 120px;
}
.button + .button--grouped {
  margin-left: 4px;
}
.button--utility {
  background-color: #22272b;
  color: #f1af0f;
}
.button--utility .button_icon {
  font-size: 16px;
}
.button--utility:hover, .button--utility:focus, .button--utility.button--active {
  background: #f1af0f;
  color: #22272b;
}
.button--utility.button--loading:focus {
  background: #22272b;
}
.button--utility.button--loading:before {
  border-color: rgba(241, 175, 15, 0.3);
}
.button--utility:disabled, .button--utility.button--disabled {
  background: #22272b;
  color: rgba(241, 175, 15, 0.5);
  opacity: 0.5;
}
.button--info {
  padding: 0;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #22272b;
  color: #f1af0f;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button--info:before {
  font-family: "iris";
  content: "\e975";
}
.button--info:before {
  font-size: 10px;
  line-height: 16px;
}
.button--rain {
  background-color: #297ac5;
  color: #ffffff;
}
.button--rain:hover, .button--rain:focus, .button--rain.button--active {
  background: #1f6bb0;
  color: #ffffff;
}
.button--rain.button--loading:focus {
  background: #297ac5;
}
.button--rain.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--rain:disabled, .button--rain.button--disabled {
  background: #297ac5;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--steel {
  background-color: #1f6bb0;
  color: #ffffff;
}
.button--steel:hover, .button--steel:focus, .button--steel.button--active {
  background: #0f5ca3;
  color: #ffffff;
}
.button--steel.button--loading:focus {
  background: #1f6bb0;
}
.button--steel.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--steel:disabled, .button--steel.button--disabled {
  background: #1f6bb0;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--q4-blue {
  background-color: #0f5ca3;
  color: #ffffff;
}
.button--q4-blue:hover, .button--q4-blue:focus, .button--q4-blue.button--active {
  background: #0A457C;
  color: #ffffff;
}
.button--q4-blue.button--loading:focus {
  background: #0f5ca3;
}
.button--q4-blue.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--q4-blue:disabled, .button--q4-blue.button--disabled {
  background: #0f5ca3;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--ink {
  background-color: #10528f;
  color: #ffffff;
}
.button--ink:hover, .button--ink:focus, .button--ink.button--active {
  background: #0A457C;
  color: #ffffff;
}
.button--ink.button--loading:focus {
  background: #10528f;
}
.button--ink.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--ink:disabled, .button--ink.button--disabled {
  background: #10528f;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--teal {
  background-color: #1abc9c;
  color: #ffffff;
}
.button--teal:hover, .button--teal:focus, .button--teal.button--active {
  background: #0da084;
  color: #ffffff;
}
.button--teal.button--loading:focus {
  background: #1abc9c;
}
.button--teal.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--teal:disabled, .button--teal.button--disabled {
  background: #1abc9c;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--cherry {
  background-color: #e74c3c;
  color: #ffffff;
}
.button--cherry:hover, .button--cherry:focus, .button--cherry.button--active {
  background: #e74c3c;
  color: #ffffff;
}
.button--cherry.button--loading:focus {
  background: #e74c3c;
}
.button--cherry.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--cherry:disabled, .button--cherry.button--disabled {
  background: #e74c3c;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--spice {
  background-color: #ec6a4c;
  color: #ffffff;
}
.button--spice:hover, .button--spice:focus, .button--spice.button--active {
  background: #e74c3c;
  color: #ffffff;
}
.button--spice.button--loading:focus {
  background: #ec6a4c;
}
.button--spice.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--spice:disabled, .button--spice.button--disabled {
  background: #ec6a4c;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--citrus {
  background-color: #f1af0f;
  color: #22272b;
}
.button--citrus:hover, .button--citrus:focus, .button--citrus.button--active {
  background: #dc9e27;
  color: #22272b;
}
.button--citrus.button--loading:focus {
  background: #f1af0f;
}
.button--citrus.button--loading:before {
  border-color: rgba(34, 39, 43, 0.3);
}
.button--citrus:disabled, .button--citrus.button--disabled {
  background: #f1af0f;
  color: rgba(34, 39, 43, 0.5);
  opacity: 0.5;
}
.button--slate {
  background-color: #2a3035;
  color: #f1af0f;
}
.button--slate:hover, .button--slate:focus, .button--slate.button--active {
  background: #dc9e27;
  color: #22272b;
}
.button--slate.button--loading:focus {
  background: #2a3035;
}
.button--slate.button--loading:before {
  border-color: rgba(241, 175, 15, 0.3);
}
.button--slate:disabled, .button--slate.button--disabled {
  background: #2a3035;
  color: rgba(241, 175, 15, 0.5);
  opacity: 0.5;
}
.button--charcoal {
  background-color: #1a1d21;
  color: #f1af0f;
}
.button--charcoal:hover, .button--charcoal:focus, .button--charcoal.button--active {
  background: #f1af0f;
  color: #22272b;
}
.button--charcoal.button--loading:focus {
  background: #1a1d21;
}
.button--charcoal.button--loading:before {
  border-color: rgba(241, 175, 15, 0.3);
}
.button--charcoal:disabled, .button--charcoal.button--disabled {
  background: #1a1d21;
  color: rgba(241, 175, 15, 0.5);
  opacity: 0.5;
}
.button--black-smoke {
  background-color: #121517;
  color: #f1af0f;
}
.button--black-smoke:hover, .button--black-smoke:focus, .button--black-smoke.button--active {
  background: #f1af0f;
  color: #22272b;
}
.button--black-smoke.button--loading:focus {
  background: #121517;
}
.button--black-smoke.button--loading:before {
  border-color: rgba(241, 175, 15, 0.3);
}
.button--black-smoke:disabled, .button--black-smoke.button--disabled {
  background: #121517;
  color: rgba(241, 175, 15, 0.5);
  opacity: 0.5;
}
.button--black-pearl {
  background-color: #14171a;
  color: #ffffff;
}
.button--black-pearl:hover, .button--black-pearl:focus, .button--black-pearl.button--active {
  background: #1a1d21;
  color: #f1af0f;
}
.button--black-pearl.button--loading:focus {
  background: #14171a;
}
.button--black-pearl.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--black-pearl:disabled, .button--black-pearl.button--disabled {
  background: #14171a;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--white {
  background-color: #ffffff;
  color: #2a3035;
}
.button--white:hover, .button--white:focus, .button--white.button--active {
  background: #f1f1f1;
  color: #2a3035;
}
.button--white.button--loading:focus {
  background: #ffffff;
}
.button--white.button--loading:before {
  border-color: rgba(42, 48, 53, 0.3);
}
.button--white:disabled, .button--white.button--disabled {
  background: #ffffff;
  color: rgba(42, 48, 53, 0.5);
  opacity: 0.5;
}
.button--silver {
  background-color: #939ba0;
  color: #ffffff;
}
.button--silver:hover, .button--silver:focus, .button--silver.button--active {
  background: #646d75;
  color: #ffffff;
}
.button--silver.button--loading:focus {
  background: #939ba0;
}
.button--silver.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--silver:disabled, .button--silver.button--disabled {
  background: #939ba0;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--soft-grey {
  background-color: #e0e0e0;
  color: #545b62;
}
.button--soft-grey:hover, .button--soft-grey:focus, .button--soft-grey.button--active {
  background: #cccccc;
  color: #545b62;
}
.button--soft-grey.button--loading:focus {
  background: #e0e0e0;
}
.button--soft-grey.button--loading:before {
  border-color: rgba(84, 91, 98, 0.3);
}
.button--soft-grey:disabled, .button--soft-grey.button--disabled {
  background: #e0e0e0;
  color: rgba(84, 91, 98, 0.5);
  opacity: 0.5;
}
.button--pale-grey {
  background-color: #f1f1f1;
  color: #545b62;
}
.button--pale-grey:hover, .button--pale-grey:focus, .button--pale-grey.button--active {
  background: #e0e0e0;
  color: #545b62;
}
.button--pale-grey.button--loading:focus {
  background: #f1f1f1;
}
.button--pale-grey.button--loading:before {
  border-color: rgba(84, 91, 98, 0.3);
}
.button--pale-grey:disabled, .button--pale-grey.button--disabled {
  background: #f1f1f1;
  color: rgba(84, 91, 98, 0.5);
  opacity: 0.5;
}
.button--ghost {
  background-color: #dddddd;
  color: #545b62;
}
.button--ghost:hover, .button--ghost:focus, .button--ghost.button--active {
  background: #e0e0e0;
  color: #545b62;
}
.button--ghost.button--loading:focus {
  background: #dddddd;
}
.button--ghost.button--loading:before {
  border-color: rgba(84, 91, 98, 0.3);
}
.button--ghost:disabled, .button--ghost.button--disabled {
  background: #dddddd;
  color: rgba(84, 91, 98, 0.5);
  opacity: 0.5;
}
.button--light-grey {
  background-color: #eeeeee;
  color: #545b62;
}
.button--light-grey:hover, .button--light-grey:focus, .button--light-grey.button--active {
  background: #e0e0e0;
  color: #545b62;
}
.button--light-grey.button--loading:focus {
  background: #eeeeee;
}
.button--light-grey.button--loading:before {
  border-color: rgba(84, 91, 98, 0.3);
}
.button--light-grey:disabled, .button--light-grey.button--disabled {
  background: #eeeeee;
  color: rgba(84, 91, 98, 0.5);
  opacity: 0.5;
}
.button--light {
  background-color: #eeeeee;
  color: #545b62;
}
.button--light:hover, .button--light:focus, .button--light.button--active {
  background: #e0e0e0;
  color: #545b62;
}
.button--light.button--loading:focus {
  background: #eeeeee;
}
.button--light.button--loading:before {
  border-color: rgba(84, 91, 98, 0.3);
}
.button--light:disabled, .button--light.button--disabled {
  background: #eeeeee;
  color: rgba(84, 91, 98, 0.5);
  opacity: 0.5;
}
.button--shaded, .button--dark {
  background-color: rgba(0, 0, 0, 0.15);
  color: #ffffff;
}
.button--shaded:hover, .button--shaded:focus, .button--shaded.button--active, .button--dark:hover, .button--dark:focus, .button--dark.button--active {
  background: rgba(0, 0, 0, 0.2);
  color: #f1af0f;
}
.button--shaded.button--loading:focus, .button--dark.button--loading:focus {
  background: rgba(0, 0, 0, 0.15);
}
.button--shaded.button--loading:before, .button--dark.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button--shaded:disabled, .button--shaded.button--disabled, .button--dark:disabled, .button--dark.button--disabled {
  background: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.button--cancel, .button--transparent {
  padding: 0 8px;
  text-transform: capitalize;
  background-color: transparent;
  color: #939ba0;
}
.button--cancel:hover, .button--cancel:focus, .button--cancel.button--active, .button--transparent:hover, .button--transparent:focus, .button--transparent.button--active {
  background: transparent;
  color: #ffffff;
}
.button--cancel.button--loading:focus, .button--transparent.button--loading:focus {
  background: transparent;
}
.button--cancel.button--loading:before, .button--transparent.button--loading:before {
  border-color: rgba(147, 155, 160, 0.3);
}
.button--cancel:disabled, .button--cancel.button--disabled, .button--transparent:disabled, .button--transparent.button--disabled {
  background: transparent;
  color: rgba(147, 155, 160, 0.5);
  opacity: 0.5;
}
.button--loading {
  position: relative;
  pointer-events: none;
}
.button--loading, .button--loading:focus {
  color: transparent;
}
.button--loading:before {
  content: "";
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}
.button--invisible {
  opacity: 0;
  pointer-events: none;
}
.button--hidden {
  display: none;
}
.button:disabled, .button--disabled {
  pointer-events: none;
}

.field {
  display: inline-block;
  vertical-align: top;
}
.field:not(:last-child):not(.field--grouped) {
  margin-bottom: 16px;
}
.field_set {
  margin: 0;
  padding: 0;
  border: 0;
}
.field_set > * {
  display: inline-block;
}
.field_set > *:not(:last-child) {
  margin-right: 10px;
}
.field-separator {
  height: 0;
  border: 0;
  margin: 8px;
  width: 100%;
  border-top: 1px solid #e0e0e0;
}
.field-separator--short {
  margin-top: 16px;
  width: 88%;
}
.field--full {
  display: block;
}
.field.field--flex {
  display: flex;
  align-items: center;
}
.field.field--flex > div:not(:first-child) {
  margin-left: 8px;
}
.field.field--flex .field_label--white {
  padding-left: 10px;
  min-width: 50px;
  line-height: 40px;
  background-color: #ffffff;
}
.field.field--grouped:not(:last-of-type) {
  margin-bottom: 7px;
}
.field--spacer {
  margin-left: 20px;
}
.field--text .field_label, .field--dropdown .field_label, .field--date .field_label, .field--radio .field_label, .field--set .field_label {
  display: block;
  margin-bottom: 4px;
  line-height: 22px;
  color: #545b62;
}
.field--text .field_label .required, .field--dropdown .field_label .required, .field--date .field_label .required, .field--radio .field_label .required, .field--set .field_label .required {
  font-size: 12px;
  color: #939ba0;
}
.field--text .field_input {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: none;
  background: #eeeeee;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #545b62;
  line-height: 40px;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.field--text .field_input--short {
  max-width: 150px;
}
.field--text .field_input--shorter {
  max-width: 80px;
}
.field--text .field_input--shortest {
  max-width: 60px;
}
.field--text .field_input::placeholder, .field--text .field_input:read-only {
  color: #939ba0;
  font-family: "Open Sans", sans-serif;
}
.field--text .field_input:focus {
  outline: none;
}
.field--text .field_input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.field--text .field_input + .button {
  vertical-align: top;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.field--text.field--full .field_input {
  width: 100%;
  max-width: none;
}
.field--text.field--half {
  width: calc(50% - 10px);
}
.field--text.field--half:nth-child(even) {
  margin-left: 20px;
}
.field--text.field--short {
  width: 260px;
}
.field--text.field--coupled {
  display: block;
}
.field--text.field--coupled .field_set {
  display: flex;
  margin-bottom: 4px;
}
.field--text.field--coupled .field_set > * {
  flex: 1;
}
.field--text.field--coupled .field_set > *:not(:first-child) {
  margin-left: 10px;
}
.field--text.field--coupled .field_error {
  display: flex;
  position: relative;
  color: #ec6a4c;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.field--text.field--coupled .field_error:before {
  font-family: "iris";
  content: "\e9bb";
}
.field--text.field--coupled .field_error:before {
  margin-right: 4px;
  padding: 2px;
  font-size: 9px;
  background-color: #ec6a4c;
  color: #ffffff;
  border-radius: 50%;
  height: 13px;
  width: 13px;
}
.field--text.field--white .field_input {
  background-color: #ffffff;
  color: #545b62;
}
.field--text.field--white .field_input:read-only {
  color: #939ba0;
}
.field--text.field--slate .field_input {
  background-color: #2a3035;
  color: #ffffff;
}
.field--text.field--slate .field_input::placeholder, .field--text.field--slate .field_input:read-only {
  color: #646d75;
}
.field--text.field--dark-slate .field_input {
  background-color: #22272b;
  color: #ffffff;
}
.field--text.field--dark-slate .field_input::placeholder, .field--text.field--dark-slate .field_input:read-only {
  color: #646d75;
}
.field--warning {
  color: red;
  white-space: pre-wrap;
}

.overview-layout {
  padding: 24px 32px 56px;
  min-width: 1230px;
  max-width: none;
}
.overview-layout--flyout {
  padding: 24px 32px 56px;
  min-width: 1046px;
  max-width: none;
}
.overview-layout .ownership-chart .card_children {
  min-height: 380px;
}

.fullscreen-modal-layout {
  padding: 24px 32px 56px;
  min-width: 1400px;
  max-width: 1400px;
}

.react-scrollbar > div:first-child[style*="margin-right: 0px; margin-bottom: 0px;"] {
  margin-right: -15px !important;
  margin-bottom: -15px !important;
}
.react-scrollbar > div:first-child[style*="margin-right: 0px; margin-bottom: 0px;"]::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
.react-scrollbar > div:first-child::-webkit-scrollbar, .react-scrollbar > div:first-child::-webkit-scrollbar-track, .react-scrollbar > div:first-child::-webkit-scrollbar-thumb, .react-scrollbar > div:first-child::-webkit-scrollbar-thumb:hover, .react-scrollbar > div:first-child::-webkit-scrollbar-button, .react-scrollbar > div:first-child::-webkit-scrollbar-track-piece, .react-scrollbar > div:first-child::-webkit-scrollbar-corner, .react-scrollbar > div:first-child::-webkit-resizer, .react-scrollbar > div:first-child::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}
.react-scrollbar_track {
  position: absolute;
  width: 4px;
  transition: opacity 200ms;
  opacity: 0;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  visibility: hidden;
}
.react-scrollbar_track--horizontal {
  width: auto;
  height: 4px;
  top: auto;
  left: 2px;
}
.react-scrollbar_track--horizontal .react-scrollbar_thumb {
  height: 4px;
}
.react-scrollbar_thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}
.react-scrollbar_thumb--light {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.table {
  background-color: #ffffff;
}
.table_row {
  height: 50px;
}
.table_row--clickable {
  cursor: pointer;
}
.table_row--noresults .data-table_cell {
  padding: 30px 14px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}
.table_cell {
  min-width: 40px;
  color: #545b62;
  padding: 4px 10px;
  border-bottom: 1px solid #e0e0e0;
}
.table_cell:first-child {
  padding-left: 32px;
}
.table_cell:last-child {
  padding-right: 30px;
}
.table_cell--wider {
  min-width: 300px;
}
.table_cell--wide {
  min-width: 180px;
}
.table_cell--default {
  min-width: 120px;
}
.table_cell--narrow {
  min-width: 100px;
}
.table_cell--narrower {
  min-width: 80px;
}
.table_cell--left {
  text-align: left;
}
.table_cell--right {
  text-align: right;
}
.table_cell--center {
  text-align: center;
}
.table_value--up {
  color: #1abc9c;
}
.table_value--down {
  color: #ec6a4c;
}
.table_value--indented {
  margin-left: 20px;
}
.table--alternating th.data-table_cell:nth-child(even) {
  background-color: #2f89c5;
}
.table--alternating th.data-table_cell:not(:first-child) {
  text-align: center;
}
.table--alternating td.data-table_cell:nth-child(even) {
  background-color: #f7f7f7;
}
.table--alternating td.data-table_cell:not(:first-child) {
  text-align: right;
}
.table--small .data-table_cell:first-child, .table--xsmall .data-table_cell:first-child {
  padding-left: 10px;
}
.table--small .data-table_cell:last-child, .table--xsmall .data-table_cell:last-child {
  padding-right: 10px;
}
.table--small .data-table_row {
  height: 40px;
}
.table--xsmall .data-table_row {
  height: 30px;
}
.table_pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin: 20px auto;
}
.table_pagination-item, .table_pagination-jump-next, .table_pagination-prev, .table_pagination-next {
  background-color: #f1f1f1;
  padding: 10px 6px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.table_pagination-item a, .table_pagination-jump-next a, .table_pagination-prev a, .table_pagination-next a {
  color: #939ba0;
  outline: none;
  transition: none;
}
.table_pagination-item a:hover, .table_pagination-jump-next a:hover, .table_pagination-prev a:hover, .table_pagination-next a:hover {
  color: #22272b;
}
.table_pagination-item-active a, .table_pagination-item-active a:hover, .table_pagination-jump-next-active a, .table_pagination-jump-next-active a:hover, .table_pagination-prev-active a, .table_pagination-prev-active a:hover, .table_pagination-next-active a, .table_pagination-next-active a:hover {
  color: #3498db;
}
.table_pagination-jump-next a:before {
  content: "...";
  letter-spacing: 1px;
}
.table_pagination-prev {
  width: 40px;
  margin: 0 1px;
  border-radius: 3px 0 0 3px;
}
.table_pagination-prev a {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3498db;
}
.table_pagination-prev a:before {
  font-family: "iris";
  content: "\e997";
}
.table_pagination-next {
  width: 40px;
  margin: 0 1px;
  border-radius: 0 3px 0 3px 0;
}
.table_pagination-next a {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3498db;
}
.table_pagination-next a:before {
  font-family: "iris";
  content: "\e9a1";
}
.table_pagination li:nth-of-type(2) {
  padding-left: 20px;
}
.table_pagination li:nth-last-of-type(2) {
  padding-right: 20px;
}

.timestamp-audit {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 16px;
  color: #939ba0;
}
.timestamp-audit_label {
  width: 56px;
}
.full-screen-form {
  min-width: 1230px;
  max-width: 1400px;
  padding-bottom: 30px;
}
.full-screen-form_columns {
  display: flex;
}
.full-screen-form_columns--left {
  flex: 1;
  max-width: calc(100% - 500px);
}
.full-screen-form_columns--right {
  width: 500px;
  padding-left: 30px;
}
