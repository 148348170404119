@import '../../../../resources/styles/utils';
@import '../../../../resources/styles/variables';

.event-transcript-grid_table {

  .grid_table, .spinner, .placeholder-message {
    min-height: calc(100vh - #{$app-header-height} - #{$global-banner-height});
  }

  .event-transcript-grid {
    .ag-header-cell:not(.ag-header-cell--first) {
      padding: 0 gux(2);
    }
  }
}

