@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/utils';

.tearsheet-export-modal_option {
  color: $light-slate;

  &-label {
    font-size: $font-size-medium;
  }

  &-items.MuiGrid-item {
    margin-top: -#{gux()};

    .field-separator--short {
      margin-left: 0;
      width: calc(88% + #{gux(1.5)});
    }
  }

  &-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div:not(:only-child):not(:last-child) {
      margin-right: gux(2);
    }
  }

  .button {
    height: 40px;
    font-size: $font-size-medium;
    background-color: $light-grey;

    &_label {
      top: -1px;
      position: relative;
      color: $light-slate;
    }

    &-output--pdf {
      color: $spice;

      &:hover, &--selected {
        color: $white;
        background-color: $spice;

        .button_label {
          color: $white;
        }
      }
    }

    &-output--csv {
      color: $teal;

      &:hover, &--selected {
        color: $white;
        background-color: $teal;

        .button_label {
          color: $white;
        }
      }
    }
  }
}
