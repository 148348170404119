.attributes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.attributes_item {
  flex-basis: calc(100% / 3 - 20px);
}
.attributes_item--label {
  text-transform: uppercase;
  color: #939ba0;
}
.attributes_item:nth-child(n+4) {
  margin-top: 10px;
}
