.sq-header {
  color: #ffffff;
  font-size: 13px;
  border-bottom: 1px solid #2a3035;
  padding: 5px 10px;
  cursor: pointer;
}
.sq-header_security {
  margin-bottom: 5px;
}
.sq-header_market, .sq-header_currency, .sq-header_text {
  margin-left: 10px;
  color: #939ba0;
}
.sq-header_price {
  font-size: 22px;
  line-height: 13px;
  margin-right: 10px;
}
.sq-header_label {
  color: #939ba0;
}
.sq-header_value {
  float: right;
  margin-left: 10px;
}
.sq-header_item--volume {
  position: absolute;
  top: 10px;
  right: 10px;
}
.sq-header_item--up {
  color: #1abc9c;
}
.sq-header_item--down {
  color: #ec6a4c;
}
.sq-header--light {
  color: #545b62;
  border-color: #e0e0e0;
}
