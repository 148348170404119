.detail-list_title {
  font-size: 16px;
  margin-bottom: 14px;
  color: #1a1d21;
}
.detail-list_list {
  list-style: none;
}
.detail-list_item {
  line-height: 40px;
  border-top: 1px solid #e0e0e0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.detail-list_item:last-child {
  border-bottom: 1px solid #e0e0e0;
}
.detail-list_icon {
  width: 30px;
}
.detail-list_icon--spice {
  color: #ec6a4c;
}
.detail-list_icon--teal {
  color: #1abc9c;
}
.detail-list_label {
  min-width: 70px;
  margin-right: 30px;
}
.detail-list_value span:not(:first-child) {
  margin-left: 20px;
}
.detail-list_value i {
  margin-right: 8px;
}
.detail-list + .detail-list {
  margin-top: 20px;
}
