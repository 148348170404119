/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.activity-form__activity-type .field_input {
  display: flex;
  align-items: center;
}
.activity-form__activity-type .field_input > div {
  margin-right: 14px;
}
.activity-form__activity-type .field_input > div:last-of-type {
  margin-right: 0;
}
.activity-form__activity-type .type-divider {
  width: 1px;
  height: 16px;
  background-color: #e0e0e0;
}
