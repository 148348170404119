.toolbar {
  width: 100%;
}
.toolbar_inner {
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;
}
.toolbar_row {
  position: relative;
}
.toolbar_row:first-child {
  padding-top: 16px;
}
.toolbar_row:last-child {
  padding-bottom: 16px;
}
.toolbar_row + .toolbar_row {
  padding-top: 8px;
}
.toolbar_row > *:not(:first-child) {
  margin-left: 16px;
}
.toolbar_row--justified {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toolbar_row--right {
  display: flex;
  justify-content: flex-end;
}
.toolbar_group {
  display: flex;
  align-items: center;
}
.toolbar_group > *:not(:first-child) {
  margin-left: 8px;
}
.toolbar_group--spaced > *:not(:first-child) {
  margin-left: 16px;
}
.toolbar .toolbar_docked-element {
  position: absolute;
}
.toolbar .toolbar_docked-element--right {
  right: 0;
}
.toolbar .toolbar_docked-element--left {
  left: 0;
}
.toolbar--rain {
  color: #ffffff;
  background-color: #297ac5;
}
.toolbar--steel {
  color: #ffffff;
  background-color: #1f6bb0;
}
.toolbar--q4-blue {
  color: #ffffff;
  background-color: #0f5ca3;
}
.toolbar--ink {
  color: #ffffff;
  background-color: #10528f;
}
