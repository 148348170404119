/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.institution-web-analytics-container g.highcharts-label {
  display: none;
}
.institution-web-analytics-container .highcharts-tooltip {
  margin: 0 !important;
  padding: 0 !important;
}
.institution-web-analytics-container .highcharts-tooltip_wrapper {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  background-color: #ffffff !important;
  border-radius: 3px;
  padding: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.institution-web-analytics-container .highcharts-tooltip_wrapper h3 {
  max-width: 200px;
  font-size: 13px;
  color: #939ba0;
  margin: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.institution-web-analytics-container .highcharts-tooltip_wrapper h3 span {
  margin-left: 10px;
  color: #545b62;
}
