@import '../resources/styles/variables';

.root-container {
  width: 100%;
  z-index: $z-index--base;

  &--nav-expanded {
    .app-body {
      margin-left: $app-nav-expanded-width;
    }

    .sencha-viewport {
      left: $app-nav-expanded-width;
    }

    .crm-email-viewport, .crm-manage-email-viewport  {
      left: $app-nav-expanded-width;
    }
  }

  &--fullscreen-enabled {
    transform: translate(-50px, -80px);
    width: calc(100vw + 50px);

    .app-header, .app-scrollable {
      width: 100vw;
    }

    .app-scrollable {
      height: 100vh;

      // containers within the app-scrollable to fill in the screen automatically
      & > div {
        min-height: 100%;
      }
    }

    .toast {
      top: $app-header-height + $padding-vertical-base;
    }
  }

  &--headless-mode {
    .app-body {
      margin: 0 !important;
    }
  }
}

.app-body {
  margin-left: $app-nav-width;
  transition: margin 0.3s ease;
}

.app-scrollable {
  position: relative;
  width: calc(100vw - 50px);
  min-width: $inner-min-width;
  height: calc(100vh - #{$app-header-height});
  overflow-y: auto;
}

.app-page-content {
  min-height: calc(100vh - #{$app-header-height});
}

.app-portal {
  position: relative;
}

.sencha-viewport, .crm-email-viewport, .crm-manage-email-viewport {
  position: absolute;
  top: $app-header-height;
  left: $app-nav-width;
  width: calc(100vw - 50px);
  min-width: $inner-min-width;
  bottom: 0;
  overflow-y: hidden;
  transition: 0.3s ease;
  transition-property: left, width;
  visibility: hidden;
  pointer-events: none;

  &--active {
    visibility: visible;
    pointer-events: auto;
  }

  &--fullscreen {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: $z-index--level-three + 1;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: $slate;
  }
}

.q4-wrapper--responsive {
  min-width: auto;

  .sencha-viewport, .app-header {
    display: none;
  }
}
