.section-tab_nav-item {
  display: inline-block;
  height: 64px;
  line-height: 64px;
  padding: 0 32px;
  border-right-width: 1px;
  border-right-style: solid;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: background-color, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.section-tab_view-item {
  display: none;
}
.section-tab_view-item--active, .section-tab_view-item--standalone {
  display: block;
}
.section-tab--compact > .section-tab_nav .section-tab_nav-item {
  height: auto;
  line-height: normal;
  padding: 0 16px;
  border-right: none;
}
.section-tab--compact > .section-tab_nav .section-tab_nav-item .section-tab_label {
  display: inline-block;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-bottom: 8px;
}
.section-tab--rain > .section-tab_nav {
  background-color: #297ac5;
}
.section-tab--rain > .section-tab_nav .section-tab_nav-item {
  border-right-color: #1f6bb0;
  color: #ffffff;
}
.section-tab--rain > .section-tab_nav .section-tab_nav-item:hover {
  background-color: #1f6bb0;
}
.section-tab--rain > .section-tab_nav .section-tab_nav-item--active {
  color: #ffffff;
  background-color: #0f5ca3;
  border-right-color: #0f5ca3;
}
.section-tab--rain > .section-tab_nav .section-tab_nav-item--active:hover {
  background-color: #0f5ca3;
}
.section-tab--rain.section-tab--compact > .section-tab_nav .section-tab_nav-item,
.section-tab--rain.section-tab--compact > .section-tab_nav .section-tab_nav-item:hover,
.section-tab--rain.section-tab--compact > .section-tab_nav .section-tab_nav-item--active,
.section-tab--rain.section-tab--compact > .section-tab_nav .section-tab_nav-item--active:hover {
  background-color: #297ac5;
}
.section-tab--rain.section-tab--compact > .section-tab_nav .section-tab_nav-item--active .section-tab_label,
.section-tab--rain.section-tab--compact > .section-tab_nav .section-tab_nav-item--active:hover .section-tab_label {
  border-bottom-color: #ffffff;
}
.section-tab--steel > .section-tab_nav {
  background-color: #1f6bb0;
}
.section-tab--steel > .section-tab_nav .section-tab_nav-item {
  border-right-color: #0f5ca3;
  color: #ffffff;
}
.section-tab--steel > .section-tab_nav .section-tab_nav-item:hover {
  background-color: #10528f;
}
.section-tab--steel > .section-tab_nav .section-tab_nav-item--active {
  color: #ffffff;
  background-color: #0f5ca3;
  border-right-color: #0f5ca3;
}
.section-tab--steel > .section-tab_nav .section-tab_nav-item--active:hover {
  background-color: #0f5ca3;
}
.section-tab--steel.section-tab--compact > .section-tab_nav .section-tab_nav-item,
.section-tab--steel.section-tab--compact > .section-tab_nav .section-tab_nav-item:hover,
.section-tab--steel.section-tab--compact > .section-tab_nav .section-tab_nav-item--active,
.section-tab--steel.section-tab--compact > .section-tab_nav .section-tab_nav-item--active:hover {
  background-color: #1f6bb0;
}
.section-tab--steel.section-tab--compact > .section-tab_nav .section-tab_nav-item--active .section-tab_label,
.section-tab--steel.section-tab--compact > .section-tab_nav .section-tab_nav-item--active:hover .section-tab_label {
  border-bottom-color: #ffffff;
}
.section-tab--slate > .section-tab_nav {
  background-color: #2a3035;
}
.section-tab--slate > .section-tab_nav .section-tab_nav-item {
  border-right-color: #1a1d21;
  color: #ffffff;
}
.section-tab--slate > .section-tab_nav .section-tab_nav-item:hover {
  background-color: #1a1d21;
}
.section-tab--slate > .section-tab_nav .section-tab_nav-item--active {
  color: #f1af0f;
  background-color: #1f2327;
  border-right-color: #1f2327;
}
.section-tab--slate > .section-tab_nav .section-tab_nav-item--active:hover {
  background-color: #1f2327;
}
.section-tab--slate.section-tab--compact > .section-tab_nav .section-tab_nav-item,
.section-tab--slate.section-tab--compact > .section-tab_nav .section-tab_nav-item:hover,
.section-tab--slate.section-tab--compact > .section-tab_nav .section-tab_nav-item--active,
.section-tab--slate.section-tab--compact > .section-tab_nav .section-tab_nav-item--active:hover {
  background-color: #2a3035;
}
.section-tab--slate.section-tab--compact > .section-tab_nav .section-tab_nav-item--active .section-tab_label,
.section-tab--slate.section-tab--compact > .section-tab_nav .section-tab_nav-item--active:hover .section-tab_label {
  border-bottom-color: #f1af0f;
}
