@import '../../../resources/styles/variables';
@import '../../../resources/styles/utils';
@import '../../../resources/styles/ux/chart';

.security-stock-overview {

  &_date-range {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &_label {
      padding-right: gux();
      color: $white;
    }

    .date-picker {
      width: 180px;

      &:not(:last-child) {
        margin-right: gux(2);
      }
    }
  }

  &_legend {
    font-size: $font-size-table;
    height: 32px;
    line-height: 32px;
    text-align: right;

    &-item {
      padding: 0 gux();
      display: inline-block;
    }

    &-circle,
    &-label {
      display: inline-block;
    }

    &-label {
      padding-left: gux();
      color: $silver;
    }

    &-circle {
      width: 8px;
      height: 8px;
      border-radius: $border-radius-circle;
      font-size: $font-size-medium;

      &--stock {
        background-color: $surf;
      }

      &--news {
        background-color: $tangerine;
      }
    }
  }

}
