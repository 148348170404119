@use 'sass:math';

@mixin pulsing-spinner($size: 24px, $borderSize: 2px, $borderColor: $silver) {
  position: absolute;
  top: calc(50% - math.div($size, 2));
  left: calc(50% - math.div($size, 2));
  height: $size;
  width: $size;
  border: $borderSize solid $borderColor;
  border-radius: $border-radius-circle;
  animation: loading-pulse 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  z-index: $z-index--mask + 1;
}