.chips_label, .chips_close {
  transition: 0.3s ease;
  transition-property: background-color, color;
}
.chips_chip {
  display: inline-block;
  border-radius: 3px;
}
.chips_label, .chips_close, .chips_close i {
  display: inline-block;
  line-height: 25px;
  vertical-align: top;
}
.chips_label {
  padding: 0 10px;
  background-color: #ffffff;
  border-radius: 3px 0 0 3px;
  color: #545b62;
}
.chips_close {
  width: 25px;
  background-color: #e0e0e0;
  border-radius: 0 3px 3px 0;
  font-size: 8px;
  color: #545b62;
  text-align: center;
  cursor: pointer;
}
.chips_close:hover {
  color: #297ac5;
}
.chips--clickable .chips_label {
  cursor: pointer;
}
.chips--clickable .chips_label:hover {
  color: #939ba0;
}
.chips--horizontal {
  display: inline-block;
  vertical-align: middle;
}
.chips--horizontal .chips_chip:not(:first-child) {
  margin-left: 3px;
}
.chips--vertical {
  display: block;
}
.chips--vertical .chips_chip {
  display: block;
}
.chips--vertical .chips_chip:not(:first-child) {
  margin-top: 3px;
}
.chips--input .chips_label, .chips--input .chips_close, .chips--input .chips_close i {
  line-height: 30px;
}
.chips--input .chips_close {
  width: 30px;
  font-size: 7px;
}
.chips--tall .chips_label, .chips--tall .chips_close, .chips--tall .chips_close i {
  line-height: 40px;
}
.chips--tall .chips_label {
  padding: 0 15px;
}
.chips--tall .chips_close {
  width: 40px;
}
.chips--rain .chips_label {
  background-color: #297ac5;
  color: #ffffff;
}
.chips--rain .chips_close {
  background-color: #1f6bb0;
  color: #ffffff;
}
.chips--rain .chips_close:hover {
  color: rgba(255, 255, 255, 0.3);
}
.chips--rain.chips--clickable .chips_label:hover {
  color: #ffffff;
}
.chips--light .chips_label, .chips--pale-grey .chips_label {
  background-color: #f1f1f1;
  color: #545b62;
}
.chips--light .chips_close, .chips--pale-grey .chips_close {
  background-color: #e0e0e0;
  color: #545b62;
}
.chips--light .chips_close:hover, .chips--pale-grey .chips_close:hover {
  color: rgba(84, 91, 98, 0.3);
}
.chips--light.chips--clickable .chips_label:hover, .chips--pale-grey.chips--clickable .chips_label:hover {
  color: #297ac5;
}
.chips--dark .chips_label, .chips--slate .chips_label {
  background-color: #2a3035;
  color: #ffffff;
}
.chips--dark .chips_close, .chips--slate .chips_close {
  background-color: #22272b;
  color: #f1af0f;
}
.chips--dark .chips_close:hover, .chips--slate .chips_close:hover {
  color: rgba(241, 175, 15, 0.3);
}
.chips--dark.chips--clickable .chips_label:hover, .chips--slate.chips--clickable .chips_label:hover {
  color: #ffffff;
}
.chips--charcoal .chips_label {
  background-color: #1a1d21;
  color: #ffffff;
}
.chips--charcoal .chips_close {
  background-color: #121517;
  color: #f1af0f;
}
.chips--charcoal .chips_close:hover {
  color: rgba(241, 175, 15, 0.3);
}
.chips--charcoal.chips--clickable .chips_label:hover {
  color: #ffffff;
}
