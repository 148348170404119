@import '../../../../../resources/styles/utils';

.current-holder-grid {
  .sidebar_body {
    padding-bottom: gux(3);
  }

  .loading-state {
    &,
    .ag-grid-root,
    > .nui-swapable {
      &,
      > .nui-swapable_layer {
        height: 100%;
      }
    }
  }
}
