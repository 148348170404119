.ownership-entity-chart {
  position: relative;
  width: 100%;
  height: 100%;
}
.ownership-entity-chart_legend {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  width: 99%;
  height: 30px;
}
.ownership-entity-chart_legend--position {
  flex: 1;
  position: relative;
  left: 24px;
  line-height: 30px;
  color: #939ba0;
}
.ownership-entity-chart_legend--position:before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: calc(50% - 3px);
  left: -14px;
  background-color: #0f5ca3;
  border-radius: 50%;
}
.ownership-entity-chart_legend .button {
  font-size: 12px;
  text-transform: capitalize;
}
.ownership-entity-chart_legend .button ~ .button:not(.button--hidden) {
  margin-left: 4px;
}
.ownership-entity-chart_legend .button--stock {
  background-color: #1a1d21;
  color: #4696E0;
}
.ownership-entity-chart_legend .button--stock:hover, .ownership-entity-chart_legend .button--stock:focus, .ownership-entity-chart_legend .button--stock.button--active {
  background: #121517;
  color: #4696E0;
}
.ownership-entity-chart_legend .button--stock.button--loading:focus {
  background: #1a1d21;
}
.ownership-entity-chart_legend .button--stock.button--loading:before {
  border-color: rgba(70, 150, 224, 0.3);
}
.ownership-entity-chart_legend .button--stock:disabled, .ownership-entity-chart_legend .button--stock.button--disabled {
  background: #1a1d21;
  color: rgba(70, 150, 224, 0.5);
  opacity: 0.5;
}
.ownership-entity-chart_legend .button--activity {
  background-color: #1a1d21;
  color: #f1af0f;
}
.ownership-entity-chart_legend .button--activity:hover, .ownership-entity-chart_legend .button--activity:focus, .ownership-entity-chart_legend .button--activity.button--active {
  background: #121517;
  color: #f1af0f;
}
.ownership-entity-chart_legend .button--activity.button--loading:focus {
  background: #1a1d21;
}
.ownership-entity-chart_legend .button--activity.button--loading:before {
  border-color: rgba(241, 175, 15, 0.3);
}
.ownership-entity-chart_legend .button--activity:disabled, .ownership-entity-chart_legend .button--activity.button--disabled {
  background: #1a1d21;
  color: rgba(241, 175, 15, 0.5);
  opacity: 0.5;
}
.ownership-entity-chart_legend .button--events {
  background-color: #1a1d21;
  color: #1abc9c;
}
.ownership-entity-chart_legend .button--events:hover, .ownership-entity-chart_legend .button--events:focus, .ownership-entity-chart_legend .button--events.button--active {
  background: #121517;
  color: #1abc9c;
}
.ownership-entity-chart_legend .button--events.button--loading:focus {
  background: #1a1d21;
}
.ownership-entity-chart_legend .button--events.button--loading:before {
  border-color: rgba(26, 188, 156, 0.3);
}
.ownership-entity-chart_legend .button--events:disabled, .ownership-entity-chart_legend .button--events.button--disabled {
  background: #1a1d21;
  color: rgba(26, 188, 156, 0.5);
  opacity: 0.5;
}
.ownership-entity-chart_legend .button--news {
  background-color: #1a1d21;
  color: #fc7e26;
}
.ownership-entity-chart_legend .button--news:hover, .ownership-entity-chart_legend .button--news:focus, .ownership-entity-chart_legend .button--news.button--active {
  background: #121517;
  color: #fc7e26;
}
.ownership-entity-chart_legend .button--news.button--loading:focus {
  background: #1a1d21;
}
.ownership-entity-chart_legend .button--news.button--loading:before {
  border-color: rgba(252, 126, 38, 0.3);
}
.ownership-entity-chart_legend .button--news:disabled, .ownership-entity-chart_legend .button--news.button--disabled {
  background: #1a1d21;
  color: rgba(252, 126, 38, 0.5);
  opacity: 0.5;
}
.ownership-entity-chart_legend .button--off, .ownership-entity-chart_legend .button--off:hover, .ownership-entity-chart_legend .button--disabled {
  color: #939ba0;
}
.ownership-entity-chart_circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.ownership-entity-chart_circle--stock {
  background-color: #0f5ca3;
}
.ownership-entity-chart_circle--position {
  background-color: #4696E0;
}
.ownership-entity-chart .highcharts-column-series text tspan {
  font-weight: normal;
}
.ownership-entity-chart .highcharts-column-series text tspan:last-of-type {
  fill: #939ba0;
}
.ownership-entity-chart .highcharts-tooltip .chart_tip {
  width: 200px;
  display: block;
  white-space: normal;
  cursor: pointer;
}
.ownership-entity-chart .highcharts-tooltip .chart_tip_header {
  color: #939ba0;
  display: block;
  margin-bottom: 4px;
}
.ownership-entity-chart .highcharts-tooltip .chart_tip_body {
  color: #545b62;
  margin-bottom: 4px;
}
.ownership-entity-chart .highcharts-tooltip .chart:hover {
  color: #3498db;
}
.ownership-entity-chart .highcharts-tooltip path {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0;
}
.ownership-entity-chart .spinner_pulse {
  margin-top: -50px;
}
