.security-menu-mask .security-menu {
  top: 80px !important;
  background-color: #22272b;
  width: 160px;
  padding: 10px 10px;
  border-radius: 0;
}
.security-menu-mask .security-menu ul {
  padding: 0;
}
.security-menu-mask .security-menu_item {
  padding: 0 14px;
  color: #ffffff;
  font-size: 13px;
  line-height: 40px;
  height: auto;
  transition: all 0.15s ease;
}
.security-menu-mask .security-menu_item--selected {
  color: #f1af0f;
  background-color: transparent !important;
}
.security-menu-mask .security-menu_item--selected:hover {
  background-color: #14171a !important;
}
.security-menu-mask .security-menu_item:hover {
  color: #f1af0f;
  background-color: #14171a;
}
