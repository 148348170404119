.widget-error {
  height: 100%;
  font-size: 13px;
  color: #ffffff;
}
.widget-error, .widget-error_header, .widget-error_link {
  transition: 0.3s ease;
}
.widget-error_header {
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  border-bottom: 1px solid #2a3035;
  border-radius: 3px 3px 0 0;
}
.widget-error_header h2 {
  font-size: 13px;
  text-transform: uppercase;
}
.widget-error_inner {
  padding: 10px;
}
.widget-error_message {
  font-size: 13px;
  line-height: 1.5;
}
.widget-error_link {
  color: #f1af0f;
}
.widget-error_link:hover {
  color: #dc9e27;
}
.widget-error--light {
  color: #545b62;
}
.widget-error--light .widget-error_header, .widget-error--light .widget-error_footer {
  border-color: #e0e0e0;
}
.widget-error--light .widget-error_link {
  color: #297ac5;
}
.widget-error--light .widget-error_link:hover {
  color: #0f5ca3;
}
