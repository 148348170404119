/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
.upcoming-activities .upcoming-activities-item {
  padding: 4px 10px 4px 0;
  border-bottom: 1px solid #2a3035;
  transition: 0.3s ease;
  cursor: pointer;
}
.upcoming-activities .upcoming-activities-item_box, .upcoming-activities .upcoming-activities-item_icon, .upcoming-activities .upcoming-activities-item_content {
  display: inline-block;
  vertical-align: top;
}
.upcoming-activities .upcoming-activities-item_box {
  width: 5px;
  height: 20px;
  background-color: #2a3035;
}
.upcoming-activities .upcoming-activities-item_box--note {
  background-color: #f1af0f;
}
.upcoming-activities .upcoming-activities-item_box--comment {
  background-color: #f1af0f;
}
.upcoming-activities .upcoming-activities-item_box--phone {
  background-color: #3498db;
}
.upcoming-activities .upcoming-activities-item_box--email {
  background-color: #fc7e26;
}
.upcoming-activities .upcoming-activities-item_box--meeting {
  background-color: #1abc9c;
}
.upcoming-activities .upcoming-activities-item_box--earnings {
  background-color: #aa2d40;
}
.upcoming-activities .upcoming-activities-item_box--roadshow {
  background-color: #e74c3c;
}
.upcoming-activities .upcoming-activities-item_box--conference {
  background-color: #6a3476;
}
.upcoming-activities .upcoming-activities-item_icon {
  padding: 2px 0;
  width: 38px;
  font-size: 18px;
  text-align: center;
}
.upcoming-activities .upcoming-activities-item_content {
  max-width: calc(100% - 43px);
}
.upcoming-activities .upcoming-activities-item_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 13px;
}
.upcoming-activities .upcoming-activities-item_date {
  display: block;
  color: #939ba0;
}
.upcoming-activities .upcoming-activities-item_date:first-child :after {
  content: "-";
  margin: 0 5px;
}
.upcoming-activities--light .upcoming-activities-item {
  border-bottom: 1px solid #e0e0e0;
}
