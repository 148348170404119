/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pop-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pop-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes loading-pulse {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.editable-text {
  display: inline-block;
  vertical-align: top;
}
.editable-text_value, .editable-text_input, .editable-text_edit-toggle, .editable-text_edit-submit {
  display: inline-block;
}
.editable-text_value, .editable-text_edit-toggle {
  vertical-align: middle;
}
.editable-text_input, .editable-text_edit-submit {
  vertical-align: top;
}
.editable-text_value, .editable-text_edit-container {
  animation: fade-in 0.3s ease;
}
.editable-text_value, .editable-text_input {
  margin-right: 10px;
}
.editable-text_value--hidden {
  visibility: hidden;
}
.editable-text_edit-toggle, .editable-text_edit-submit {
  font-size: 16px;
  cursor: pointer;
}
.editable-text_edit-toggle:hover, .editable-text_edit-submit:hover {
  color: #297ac5;
}
.editable-text_edit-toggle {
  transition: color 0.3s ease;
}
.editable-text_edit-submit {
  transition: opacity 0.3s ease, color 0.3s ease;
}
.editable-text_edit-submit--disabled {
  opacity: 0.3;
  pointer-events: none;
}
.editable-text .field_input {
  width: 350px;
  border-radius: 3px 0 0 3px;
  vertical-align: top;
}
.editable-text .button {
  height: 40px;
}
.editable-text .button_icon {
  font-size: 16px;
  line-height: inherit;
}
.editable-text--invisible .editable-text_value, .editable-text--invisible .editable-text_edit-toggle {
  vertical-align: top;
}
.editable-text--invisible .editable-text_value, .editable-text--invisible .editable-text_edit-container {
  animation: none;
}
.editable-text--invisible .editable-text_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
}
.editable-text--invisible .editable-text_input::placeholder {
  color: inherit;
  opacity: 0.3;
}
.editable-text--invisible .editable-text_edit-container {
  position: relative;
}
.editable-text--invisible .editable-text_edit-toggle, .editable-text--invisible .editable-text_edit-submit {
  animation: fade-in 0.3s ease;
}
.editable-text--invisible.editable-text--editing .editable-text_value, .editable-text--invisible.editable-text--editing .editable-text_input {
  min-width: 90px;
}
.editable-text--citrus .editable-text_edit-toggle, .editable-text--citrus .editable-text_edit-submit {
  color: #939ba0;
}
.editable-text--citrus .editable-text_edit-toggle:hover, .editable-text--citrus .editable-text_edit-submit:hover {
  color: #f1af0f;
}
.editable-text--white .editable-text_edit-toggle, .editable-text--white .editable-text_edit-submit {
  color: rgba(255, 255, 255, 0.5);
}
.editable-text--white .editable-text_edit-toggle:hover, .editable-text--white .editable-text_edit-submit:hover {
  color: #ffffff;
}
