.pagination {
  line-height: 40px;
  user-select: none;
  text-align: center;
}
.pagination_actions, .pagination_actions > *, .pagination_item, .pagination_item > * {
  display: inline-block;
}
.pagination_actions {
  background-color: #eeeeee;
}
.pagination_actions--pages {
  border-radius: 3px;
}
.pagination_actions--page-sizes {
  padding: 0 14px;
  border-radius: 0 3px 3px 0;
}
.pagination_item--break {
  padding: 0 4px;
  letter-spacing: 1px;
  color: #939ba0;
}
.pagination_item--per-page {
  margin-right: 10px;
}
.pagination_action {
  padding: 0 6px;
}
.pagination_action--next, .pagination_action--prev {
  width: 40px;
  border-right: 1px solid #ffffff;
  padding: 0;
}
.pagination_action--prev {
  margin-right: 14px;
}
.pagination_action--prev .pagination_link {
  color: #3498db;
}
.pagination_action--next {
  margin-left: 14px;
  border-left: 1px solid #ffffff;
}
.pagination_action--next .pagination_link {
  color: #3498db;
}
.pagination_action--active, .pagination_action--disabled {
  pointer-events: none;
}
.pagination_action--active .pagination_link {
  color: #3498db;
}
.pagination_action--disabled .pagination_link, .pagination_action--disabled .pagination_link:focus {
  color: rgba(147, 155, 160, 0.3);
}
.pagination_link {
  padding: 0;
  background-color: #eeeeee;
  cursor: pointer;
  color: #939ba0;
}
.pagination_link:hover {
  color: #22272b;
}
.pagination_link:focus {
  color: #3498db;
  outline: none;
}
.pagination--with-page-sizes .pagination_actions--pages {
  border-radius: 3px 0 0 3px;
}
