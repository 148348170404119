/* Boxed Layout
============================================================================== */
/* Pipe divider
============================================================================== */
/* Clear Fix
============================================================================== */
/* Truncate
============================================================================== */
/* Link Styling
============================================================================== */
/* Drag and Drop Styling
============================================================================== */
/*  Mixin to target IE 10+
============================================================================== */
/**
Mixin to style input placeholder.

Usage
  input {
    @include placeholder((text-align: right, color: black));
  }
*/
/* Very generalizable arrow for use in tooltips, popovers, etc
 *
 * @param $position - The edge of the parent you want the arrow on
 * @param $alignment - The corner you want the arrow on
 * @param $color - The color of the arrow
 *
 */
.contact-institution_overview .card_section-title {
  cursor: pointer;
}
.contact-institution_overview .card_section-positions {
  position: relative;
}
.contact-institution_overview .institution_header {
  min-height: 88px;
}
.contact-institution_overview .institution_position {
  min-width: 180px;
  padding: 12px 8px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.contact-institution_overview .institution_position-position {
  padding-left: 16px;
  font-size: 20px;
}
.contact-institution_overview--default .card_children {
  min-height: 425px;
}
.contact-institution_overview--default .institution_positions {
  display: flex;
  justify-content: flex-end;
}
.contact-institution_overview--default .institution_position:not(:last-child) {
  margin-right: 8px;
}
.contact-institution_overview--compact .card_section-position {
  display: flex;
  justify-content: flex-end;
}
.contact-institution_overview--dark .institution_position {
  background-color: #121517;
}
.contact-institution_overview--light .institution_position {
  background-color: #eeeeee;
}
